<template>
    <modal @save="onsubmit()" @close="$emit('close')">
        <template v-slot:header>
            {{ user.name() }} verwijderen
        </template>

        <p>
            Weet je zeker dat je {{ user.name() }} wilt verwijderen?
        </p>

        <template v-slot:close>Annuleren</template>
        <template v-slot:save>Doorgaan</template>
    </modal>
</template>

<script>
    import { useToast } from 'vue-toast-notification';
    export default{
        props: ['user'],

        methods: {
            onsubmit() {
                axios.delete('users/' + this.user.id).then(response => {
                    useToast().success('Medewerker verwijderd');

                    this.$router.push('/users');
                    this.$emit('close');
                    document.querySelector('body').classList = '';
                });
            }
        }
    };
</script>
