import ProductionForecastIndex from './../pages/ProductionForecast/Index.vue';

export default [
    {
        path: '/production-forecast',
        component: ProductionForecastIndex,
        name: 'production-forecast-index',
        meta: {
            permissions: ['production_forecast']
        },
    }
];
