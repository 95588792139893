<template>
    <div class="container">

        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Insights kosten</h1>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <input class="form-control searchbar" placeholder="Zoeken.." type="text" v-model="search">
            </div>
        </div>

        <div id="articles" class="row">
            <div class="col">
                <div class="articles-list">
                    <div
                        style="cursor:default"
                        class="articles-list-article-holder"
                        v-for="object of objects.filter(o => o.name.toLowerCase().includes(search.toLowerCase()))"
                        :key="object.modelName() + object.id"
                    >
                        <div class="articles-list-name">
                            <b>{{ object.modelName() }}: </b> {{ object.name }}
                        </div>
                        <div class="articles-list-content">

                            {{ formatNumber(object.cents) }}
                        </div>
                        <div style="float: right" class="articles-list-content">
                            <a @click="editModal = object" style="cursor: pointer;">
                                <Icon style="width: 18px" name="pencil" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <EditPriceModal
            v-if="editModal"
            :obj="editModal"
            @close="editModal = null"
            @saved="getData()"
        />

    </div>
</template>

<script setup>
    import CoverSheetService from '@/services/http/cover-sheet-service.js'
    import PackagingService from '@/services/http/packaging-service.js'
    import ChestService from '@/services/http/chest-service.js'
    import EditPriceModal from './EditPriceModal.vue'
    import collect from 'collect.js'
    import { ref } from 'vue'

    const emit = defineEmits(['breadcrumbs']);

    const objects = ref(collect());
    const search = ref('')

    const editModal = ref(null);

    emit('breadcrumbs', [
        {label: 'Instellingen', to: '/settings'},
        {label: 'Insights kosten'}
    ])

    const getCoverSheets = function() {
        return CoverSheetService.get();
    }
    const getPackagings = function() {
        return PackagingService.get();
    }
    const getChests = function() {
        return ChestService.get();
    }

    const getData = function() {
        Promise.all([
            getCoverSheets(),
            getPackagings(),
            getChests()
        ]).then((value) => {
            objects.value = collect([
                ...value[0],
                ...value[1],
                ...value[2]
            ]);
        })
    }
    getData();


    const formatNumber = function(number) {
        return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(number/100);
    }

</script>
