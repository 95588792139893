<template>
    <div id="profile-page" v-if="user">

        <div class="header">
            <div class="container">

                <div class="row no-gutters align-items-center header-holder">
                    <div class="col-8 col-lg-3 col-xl-2 offset-2 offset-lg-0">
                        <Avatar :user="user" :self="authUser.id === user.id" />
                    </div>
                    <div class="col-2 col-lg-1 order-lg-2 text-right">
                        <fab-button icon="ellipsis-vertical" style="margin-left:auto;">
                            <li v-if="user.fullyEditable() && user.email && (! user.invited || ! user.privacy_accepted)"><a @click="invite_user = (! invite_user)">Medewerker uitnodigen</a></li>
                            <li><a @click="edit_email = (! edit_email)">E-mailadres wijzigen</a></li>
                            <li v-if="user.fullyEditable() && user.email && user.id != authUser.id"><a @click="reset_password = (! reset_password)">Wachtwoord herstellen</a></li>
                            <li v-if="user.invited === 1 && user.id == authUser.id"><a @click="edit_password = (! edit_password)">Wachtwoord wijzigen</a></li>
                            <li v-if="user.fullyEditable() && user.id !== authUser.id"><a @click="absent_user = (! absent_user)">{{ user.absent ? "Medewerker aanwezig" : "Medewerker afwezig" }}</a></li>
                            <li v-if="user.fullyEditable() && user.id !== authUser.id"><a @click="deactivate_user = (! deactivate_user)">{{ user.deactivated_at ? "Medewerker activeren" : "Medewerker deactiveren" }}</a></li>
                            <li v-if="user.fullyEditable() && user.id !== authUser.id && user.deactivated_at !== null" class="danger-color"><a @click="delete_user = (! delete_user)">Medewerker verwijderen</a></li>
                        </fab-button>
                    </div>
                    <div class="col-12 col-lg-8 col-xl-9 order-lg-1">
                        <div class="user-info-holder">
                            <div class="main-header">
                                <h5>
                                    {{ user.name() }}
                                    <span class="deactivated-user" v-if="user.deactivated_at !== null">Inactief</span>
                                    <span class="deactivated-user" v-if="user.absent === true">Afwezig</span>
                                </h5>
                            </div>
                            <div class="sub-header">
                                <span>{{ user.roles.map(role => {return role.translate('name');}).join(', ') }}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- PERSONAL DATA -->
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="widget-holder">
                        <div class="col">
                            <PersonalDataForm @update="updateUser($event)" :user="user" :authUser="authUser" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- COMPANY DETAILS -->
        <div class="container" v-if="user && user.userType && user.userType.name === 'freelancer'">
            <div class="row">
                <div class="col">
                    <div class="widget-holder">
                        <div class="col">
                            <CompanyDetails @update="updateUser($event)" :user="user" :authUser="authUser" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- DOCUMENTS -->
        <div class="container" v-if="authUser.can('users')">
            <div class="row">
                <div class="col">
                    <Documents @refresh="getUser()" :user="user" />
                </div>
            </div>
        </div>

        <!-- OVEREENKOMSTEN -->
        <div class="container" v-if="(authUser.can('users') || authUser.id == user.id) && user.userType !== null && (user.userType.name === 'freelancer' || user.userType.name === 'temporary_worker')">
            <div class="row">
                <div class="col">
                    <Agreements @refresh="getUser()" :authUser="authUser" :user="user" />
                </div>
            </div>
        </div>

        <EditEmail v-if="edit_email" :user="user" @saved="emailUpdated" @close="edit_email = false" />
        <ResetPassword v-if="reset_password" :user="user" @close="reset_password = false" />
        <AbsentUser v-if="absent_user" @absent="user.absent = $event" :user="user" @close="absent_user = false" />
        <DeactivateUser v-if="deactivate_user" @deactivated="user.deactivated_at = $event" :user="user" @close="deactivate_user = false" />
        <DeleteUser v-if="delete_user" :user="user" @close="delete_user = false" />
        <InviteUser v-if="invite_user" :user="user" @close="invite_user = false" />
        <EditPassword v-if="edit_password" :user="user" @close="edit_password = false" />
    </div>
</template>

<script setup>
    import { mapState } from 'pinia';
    import Form from '../../helpers/form.js';
    import User from '../../models/user.js';
    import EditEmail from './Show/EditEmail.vue';
    import PersonalDataForm from './Show/PersonalDataForm.vue';
    import CompanyDetails from './Show/CompanyDetails.vue';
    import ResetPassword from './Show/ResetPassword.vue';
    import EditPassword from './Show/EditPassword.vue';
    import DeactivateUser from './Show/DeactivateUser.vue';
    import AbsentUser from './Show/AbsentUser.vue';
    import DeleteUser from './Show/DeleteUser.vue';
    import InviteUser from './Show/InviteUser.vue';
    import Documents from './Show/Documents.vue';
    import Agreements from './Show/Agreements.vue';
    import Avatar from './Show/Avatar.vue';
    import { useAuthStore } from '../../stores/auth';
    import { ref, computed, watch } from 'vue';
    import { useRouter } from 'vue-router'

    const props = defineProps(['id']);
    const emit = defineEmits(['breadcrumbs']);

    const user = ref(null);
    const edit_email = ref(false);
    const reset_password = ref(false);
    const deactivate_user = ref(false);
    const absent_user = ref(false);
    const delete_user = ref(false);
    const invite_user = ref(false);
    const edit_password = ref(false);

    const authUser = computed(() => useAuthStore().user);
    

    const router = useRouter();

    watch(
        () => props.id,
        (id) => {
            getUser();
        }
    )

    const getUser = function() {
        let id = ([null, undefined].includes(props.id) ? authUser.value.id : props.id);
        axios.get('users/' + id + '?include=agreements,userType,roles,mediumAvatar,documents.documentType&deactivated=1')
        .then(response => {
            createUserFromResponse(response.data);

            if (! [null, undefined].includes(props.id)) {
                emit('breadcrumbs', [
                    {to: `/users`, label: 'Medewerkers'},
                    {label: user.value.name()}
                ]);
            }
        });
    };

    const createUserFromResponse = function(response) {
        const userModel = new User();
        userModel.setAttributesFromResponse(response.data[0]);
        userModel.role_ids = userModel.roles.map(r => r.id);
        user.value = userModel;
    };

    const updateUser = function(response) {
        createUserFromResponse(response);
    };

    const emailUpdated = function() {
        edit_email.value = false;
    };

    const auth = function() {
        if (! authUser.value.can('users') && authUser.value.id != props.id && props.id != null) {
            router.push('/dashboard');
            return false;
        }

        return true;
    };

    if (auth()) {
        getUser();
    }
</script>
