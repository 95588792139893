<template>
    <div class="employee-details">
        <div class="detail-row">
            <b>{{ prev ? 'Jaar eerder' : 'Geselecteerde periode' }}</b>
        </div>

        <div class="detail-row">
            <span>
                Aantal uren gebost
            </span>
            <span>
                {{ formatHours(user[prev ? 'prevHarvestingHours' : 'harvestingHours']) }}
            </span>
        </div>

        <div class="detail-row">
            <span>
                Gem. uren gebost per gewerkte dag
            </span>
            <span>
                {{ formatHours(user[prev ? 'prevAvgHarvestingHourPerDay' : 'avgHarvestingHourPerDay']) }}
            </span>
        </div>

        <div class="detail-row">
            <span>
                Omzet per uur
            </span>
            <span>
                {{ formatMoney(user[prev ? 'prevMoneyPerHour' : 'moneyPerHour']) }}
            </span>
        </div>

        <hr/>

        <div class="detail-row" v-for="(product, index) of this.products" :key="index">
            <span>{{ contents.find(c => c.id === product[0]).name }} {{ types.find(t => t.id === product[1]).translate('name') }} {{ product[2] ? 'Mini\'s' : '' }}</span>
            <span>{{ harvestedOfType(user.id, product[0], product[1], product[2]).reduce((sum, hot) => (sum + hot.harvested), 0) || '-' }}</span>
        </div>

        <hr/>

        <div class="detail-row" v-for="(task, index) of productTasks" :key="'t' + index">
            <span>{{ task.name }}</span>
            <span>{{ formatHours(taskHours.filter(th => th.user_id === user.id && task.id === th.task_id).reduce((sum, th) => (sum + th.seconds_worked), 0) / 3600) }}</span>
        </div>
    </div>
</template>

<script>

    export default {
        props: ['harvestRegistrations', 'taskHours', 'user', 'products', 'productTasks', 'contents', 'types', 'prev'],

        methods: {

            orderProducts()
            {
                this.products.sort((a,b) => {
                    if (this.types.find(t => t.id === a[1]).sequence > this.types.find(t => t.id === b[1]).sequence) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
            },

            harvestedOfType(user_id, content_id, type_id, mini)
            {
                return this.harvestRegistrations.filter(harvestRegistration => {
                    return (
                        harvestRegistration.user_id === user_id
                        && harvestRegistration.content_id === content_id
                        && harvestRegistration.type_id === type_id
                        && (
                            mini && harvestRegistration.size_id === 5
                            || ! mini && harvestRegistration.size_id !== 5
                        )
                    );
                });
            },

            formatHours(hours)
            {
                hours = hours > 0 ? hours : 0;

                let hour = Math.floor(hours);
                let minutes = Math.round(60 * (hours - Math.floor(hours)));

                if (minutes === 60) {
                    hour += 1;
                    minutes = 0;
                }

                let formatted = String(hour).padStart(2, '0') + ':' + String(minutes).padStart(2, '0');

                return (formatted === '00:00' ? '-' : formatted);
            },

            formatMoney(cents)
            {
                let euros = Math.floor(cents);
                cents = Math.round((cents - euros) * 100);

                return '€ ' + euros + ',' + String(cents).padStart(2,0);
            }
        },

        created() {
            this.orderProducts();
        }
    }
</script>
