<template>
    <modal @save="store">
        <template v-slot:header>{{ breed.id ? 'Ras wijzigen' : 'Nieuw ras' }}</template>


        <div>

            <div class="alert-holder" v-if="errors.length > 0">
                <div class="alert alert-danger" v-for="error of errors">
                    {{ error[0] }}
                    <a class="add-breed-button" v-if="error[0].includes('Incorrect variety')" @click="openCreateEditBreedModal(error[0])">
                        +
                    </a>
                </div>
            </div>

            <span class="styled-label">Ras naam</span>
            <input class="styled-input" type="text" name="name" v-model="breed.name">
            <br/>
            <br/>
            <span class="styled-label">Type</span>
            <select class="styled-select" v-model="breed.typeId">
                <option v-for="typeModel of types" :key="typeModel.id" :value="typeModel.id">{{ typeModel.translate('name') }}</option>
            </select>
            <br/>
            <br/>
            <span class="styled-label">Fracties</span>
            <MultiSelect
                v-model="breed.fractionsIds"
                :options="fractions.map(f => ({label: f.milimeters.toString(), value: f.id})).all()"
            />
            <br/>
            <br/>
        </div>
    </modal>
</template>

<script setup>
    import breedService from '@/services/http/breed-service';
    import MultiSelect from '@/components/Form/MultiSelect.vue';
    import { useToast } from 'vue-toast-notification';
    import { ref } from 'vue';

    const props = defineProps(['breed', 'types', 'fractions'])
    const emit = defineEmits(['breedStored'])

    const errors = ref([]);

    const store = function(){
        errors.value = [];
        if (props.breed.id) {
            breedService.edit(props.breed.id, {
                name: props.breed.name,
                fraction_ids: props.breed.fractionsIds.filter(f => f !== null),
                type_id: props.breed.typeId
            }, {include: ['fractions', 'type']}).then((breed) => {

                emit('breedStored', breed);
                useToast().success('Ras gewijzigd');
                document.querySelector('body').classList = '';

            });
        } else {
            breedService.create({
                name: props.breed.name,
                fraction_ids: props.breed.fractionsIds,
                type_id: props.breed.typeId
            }, {include: ['fractions', 'type']}).then((breed) => {
                emit('breedStored', breed);
                useToast().success('Ras aangemaakt');
                document.querySelector('body').classList = '';

            }).catch(err => {
                if (err.response.status === 422) {
                    errors.value = Object.values(err.response.data.errors);
                }
            });
        }
    }
</script>
