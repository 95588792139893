<template>
    <a v-if="fabButton" v-on:click="onexport">
        <Icon name="arrow-down-tray" />
    </a>
    <FabButton v-tooltip="'Export excel'" v-on:click="onexport" icon="table-cells"></FabButton>
</template>

<script>
    import XLSX from "xlsx"
    import ProductionControlMeasurementsService from './../../services/http/production-control-service';
    import FabButton from "@/components/FabButton.vue";

    export default {
    props: {
        productionControl: Object,
        fabButton: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onexport() {
            const data = [];
            ProductionControlMeasurementsService.getById(this.productionControl.id, {
                include: ['productionControlMeasurements', 'location', 'type']
            }).then(productionControlModel => {
                const data = [];
                for (const productionControlMeasurement of productionControlModel.productionControlMeasurements) {

                    data.push({
                        'Measure date': productionControlMeasurement.createdAt.format('DD-MM-YYYY'),
                        'Sowing date': productionControlModel.sowedAt.format('DD-MM-YYYY'),
                        'location': productionControlModel.location.name,
                        'Row nr': productionControlModel.rowNumber,
                        'type': productionControlModel.type.name,
                        'Grow light': productionControlModel.growlight,
                        'Sowing density': productionControlModel.sowingDensity,
                        'Substrate strategy': productionControlModel.substrateStrategy,
                        'False mildew tuber': productionControlMeasurement.presentYesNo('falseMildewTuber'),
                        'False mildew leafs': productionControlMeasurement.presentYesNo('falseMildewLeafs'),
                        'Mite damage tuber ': productionControlMeasurement.presentYesNo('miteDamageTuber'),
                        'Mite damage leaf': productionControlMeasurement.presentYesNo('miteDamageLead'),
                        'Yellow germ': productionControlMeasurement.presentYesNo('yellowGerm'),
                        'Yellow leafs': productionControlMeasurement.presentYesNo('yellowLeafs'),
                        'Black spots': productionControlMeasurement.presentYesNo('blackSpots'),
                        'Physiological disease leaf': productionControlMeasurement.presentYesNo('physiologicalDiseaseLeaf'),
                        'Amount of leaves ': productionControlMeasurement.leavesAmountOfLeaves,
                        'Leafage length': productionControlMeasurement.leavesLength,
                        'Leafage weight': productionControlMeasurement.leavesWeight,
                        'Tuber weight': productionControlMeasurement.tuberWeight,
                        'Tuber diameter': productionControlMeasurement.tuberDiameter,
                        'Tuber height': productionControlMeasurement.tuberHeight,
                    });
                }
                // On Click Excel download button

                // export json to Worksheet of Excel
                // only array possible
                var workSheet = XLSX.utils.json_to_sheet(data)

                // A workbook is the naam given to an Excel file
                var workBook = XLSX.utils.book_new() // make Workbook of Excel
                // add Worksheet to Workbook
                // Workbook contains one or more worksheets
                XLSX.utils.book_append_sheet(workBook, workSheet, 'Measurements') // sheetAnaam is naam of Worksheet

                // export Excel file
                XLSX.writeFile(workBook, 'Production control ' + productionControlModel.at.format('YYYY-MM-DD')+'.xlsx');
            });
        }
    },
    data() {
        return {
            naam: 'export.xlsx'
        };
    },
    components: { FabButton }
}
</script>
