import Role from "../../models/role.js"
import { AbstractHttpService } from "./abstract-http-service.js";

class RoleService extends AbstractHttpService {

    endpoint() {
        return 'roles';
    }

    createModel(data) {
        return new Role(data);
    }
}

export default new RoleService;
