import Index from '../pages/CustomerInsights/Index.vue';
import Sold from '../pages/CustomerInsights/Sold.vue';
import Growth from '../pages/CustomerInsights/Growth.vue';
import Article from '../pages/CustomerInsights/Article.vue';
import Price from '../pages/CustomerInsights/Price.vue';

export default [
    {
        path: '/customer-insights',
        component: Index,
        name: 'customer-insights',
        meta: {
            label: 'Customer insights',
            permissions: ['customer-insights']
        }
    }, {
        path: '/customer-insights/sold',
        component: Sold,
        name: 'customer-insights.sold',
        props: false
    }, {
        path: '/customer-insights/article',
        component: Article,
        name: 'customer-insights.article',
        props: false
    }, {
        path: '/customer-insights/growth',
        component: Growth,
        name: 'customer-insights.growth',
        props: false
    }, {
        path: '/customer-insights/price',
        component: Price,
        name: 'customer-insights.price',
        props: false
    }
];
