import authGuard from './../guards/authGuard';
import PurchaseIndex from './../pages/Purchase/Index.vue';
import PurchaseLocation from './../pages/Purchase/Location.vue';

export default [
    {
        path: '/purchase/:from?/:till?',
        component: PurchaseIndex,
        name: 'purchase.index',
        meta: { permissions: ['purchase'] },
        props: true
    }, {
        path: '/purchase/:locationId/:from/:till',
        component: PurchaseLocation,
        name: 'purchase.location',
        meta: { permissions: ['purchase'] },
        props: true
    }
];
