import {AbstractModel} from './abstract-model.js'

export default class Size extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            name: null,
            latest_harvest_assignments_count: null,
            formula_factor: null
        }
    }

    translation() {
        return 'Size';
    }

    translate(field) {
        const translations =  {
            "extra-large": "XXL",
            "large": "Grof",
            "medium": "M/GR",
            "small": "Middel",
            "mini": "Mini"
        }

        return translations[this[field]];
    }

}