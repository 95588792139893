<template>
    <div v-if="harvestRegistrations.count() > 0 || dayHarvestRegistrations.count() > 0">
        <div class="widget-holder">
            <div class="row">
                <div class="col-12">
                    <table class="styled-table">
                        <thead class="styled-table-header">
                            <tr>
                                <th>
                                    {{ content.name }}
                                </th>
                                <th>
                                    <div>Medewerkers</div>
                                    <div>{{ harvestRegistrations.sum('harvested') }}</div>
                                </th>
                                <th>
                                    <div>Dagoogst</div>
                                    <div>{{ dayHarvestRegistrations.sum('amount')}}</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="styled-table-body">
                            <tr v-for="(user, index) in getUsers()" :key="index">
                                <td>{{ user.name }}</td>
                                <td>{{ user.m }}</td>
                                <td>{{ user.d }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

    const props = defineProps([
        'date', 'content', 'dayHarvests', 'users',
        'harvestRegistrations', 'dayHarvestRegistrations'
    ])

    const getUsers = function() {
        const userIds = [
            ...props.harvestRegistrations.pluck('userId').all(),
            ...props.dayHarvestRegistrations.pluck('userId').all()
        ];

        const users = props.users.whereIn('id', userIds);
        const compareUsers = [];

        for (const user of users) {
            compareUsers.push({
                name: user.name(),
                d: props.dayHarvestRegistrations.where('userId', user.id).sum('amount'),
                m: props.harvestRegistrations.where('userId', user.id).sum('harvested'),
            })
        }

        compareUsers.sort((a,b) => (a.name < b.name ? -1 : 1));

        return compareUsers;
    }
</script>
