import authGuard from './../guards/authGuard';
import NotificationList from './../pages/Notifications/List.vue';

export default [{
    path: '/notifications',
    component: NotificationList,
    name: 'notifications.list',
    meta: { permissions: ['notifications'] },
    props: true
}];
