<template>
    <div class="role-row">
        <form>
            <div class="role-name">
                {{ role.translate('name') }}
            </div>
            <div class="role-permission" v-for="permission in permissions" :key="role.id + '_' + permission.id">
                <input @change="update" type="checkbox" :id="permission.id" :value="permission.id" v-model="form.permission_ids">
            </div>
            <div class="role-remove">
                <a v-if="! role.default" @click="delete_role = (! delete_role)">
                    <Icon name="x-mark" />
                </a>
            </div>
        </form>

        <DeleteRole v-if="delete_role" :role="role" @close="delete_role = false" @roleRemoved="$emit('roleRemoved', role)" />
    </div>
</template>

<script>
    import Form from '../../../helpers/form.js';
    import DeleteRole from './DeleteRole.vue';

    export default {
        props: ['permissions', 'role'],

        components: {DeleteRole},

        data() {
            return {
                form: new Form({
                    permission_ids: []
                }),
                delete_role: false
            }
        },

        methods: {
            update() {
                this.form.put('roles/' + this.role.id);
            },
        },

        created() {
            for (let permission of this.role.permissions) {
                this.form.permission_ids.push(permission.id);
            }
        }
    };
</script>
