import authGuard from './../guards/authGuard';
import RegistrationsSelectUserOrDayHarvest from './../pages/Registrations/SelectUserOrDayHarvest.vue';
import RegistrationsSelectDate from './../pages/Registrations/SelectDate.vue';
import RegistrationsHarvestRegistrationsList from './../pages/Registrations/HarvestRegistrations/List.vue';

export default [
    {
        path: '/registrations/select-date',
        component: RegistrationsSelectDate,
        name: 'registrations.select_date',
        meta: { permissions: ['registrations'] },
    }, {
        path: '/registrations',
        component: RegistrationsSelectUserOrDayHarvest,
        name: 'registrations.select_user_or_day_harvest',
        meta: { permissions: ['registrations'] },
        props: true
    }, {
        path: '/registrations/harvest-registrations/:id',
        component: RegistrationsHarvestRegistrationsList,
        name: 'registrations.harvest-registrations.list',
        meta: { permissions: ['registrations'] },
        props: true
    }
];

