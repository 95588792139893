<template>
    <div class="container">
        <div class="settings-overview">
            <a class="settings-button" @click="$router.push('/customer-insights/sold')">
                <Icon name="chart-pie" />
                <span>Klant verkoop</span>
            </a>
            <a class="settings-button" @click="$router.push('/customer-insights/growth')">
                <Icon name="presentation-chart-line" />
                <span>Klant groei en krimp</span>
            </a>
            <a class="settings-button" @click="$router.push('/customer-insights/article')">
                <Icon name="chart-bar" />
                <span>Artikel groei en krimp</span>
            </a>
            <a class="settings-button" @click="$router.push('/customer-insights/price')">
                <Icon name="currency-euro" />
                <span>Prijs per klant</span>
            </a>
        </div>
    </div>
</template>
