<template>
    <div v-for="(document, index) of documents.filter(d => d.user)" :key="index" class="alert alert-danger">
        <div v-if="document.documentType.name == 'performance_evaluation'">
            <Icon name="chat-bubble-bottom-center-text" type="solid" />
            Het is laatste functioneringsgesprek van <template v-if="! authUser.can('users')">{{ document.user.name() }}</template><a v-else @click="$router.push('/users/' + document.user.id )">{{ document.user.name() }}</a> is van {{ document.docDate().format('DD-MM-YYYY') }}. Het is tijd om een nieuw functioneringsgesprek in te plannen.
        </div>
        <div v-if="document.documentType.name == 'contract'">
            <Icon name="document-text" type="solid" />
            Het contract van <template v-if="! authUser.can('users')">{{ document.user.name() }}</template><a v-else @click="$router.push('/users/' + document.user.id )">{{ document.user.name() }}</a> loopt op {{ (Array.isArray(document.docDate()) ? document.docDate()[1] : document.docDate()).format('DD-MM-YYYY') }} af.
        </div>
        <div v-if="document.documentType.name == 'id'">
            <Icon name="identification" type="solid" />
            Het ID van <template v-if="! authUser.can('users')">{{ document.user.name() }}</template><a v-else @click="$router.push('/users/' + document.user.id )">{{ document.user.name() }}</a> verloopt op {{ (Array.isArray(document.docDate()) ? document.docDate()[1] : document.docDate()).format('DD-MM-YYYY') }}.
        </div>
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import { collect } from "collect.js";

    /*
    |--------------------------------------------------------------------------
    | Documents
    |--------------------------------------------------------------------------
    */
    import documentService from '@/services/http/document-service';

    const documents = ref(collect());

    // Get docucments
    const getDocuments = function() {
        documentService.get({
            filters: {'needs_action': 1},
            include: ['documentType', 'user'],
        }).then(documentModels => {
            documents.value = documentModels;
        });
    }

    // Refresh data and initiate get data.
    const getData = function() {getDocuments();}
    getData();
    defineExpose({getData});

    /*
    |--------------------------------------------------------------------------
    | Auth user
    |--------------------------------------------------------------------------
    */
    import { useAuthStore } from "../../stores/auth";
    const authStore = useAuthStore();
    const authUser = computed(() => authStore.user);
</script>
