import {AbstractModel} from './abstract-model.js'
import Type from './type.js';

export default class SeedsPerSquaredMeter extends AbstractModel {
    

    relations()
    {
        return {
            type: Type
        }
    }

    relationPlural()
    {
        return {
            type: false
        }
    }
}