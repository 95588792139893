import CoverSheet from "../../models/cover-sheet.js"
import { AbstractHttpService } from "./abstract-http-service";

class CoverSheetService extends AbstractHttpService {

    endpoint() {
        return 'cover-sheets';
    }

    createModel(data) {
        return new CoverSheet(data);
    }
}

export default new CoverSheetService;
