<template>

    <modal
        @close="emit('close')"
        @save="save()"
        v-if="data"
    >

        <template v-slot:header>Eenmalige actie</template>

        <div>
            <p>
                <b>{{ data.article.name }}</b>
            </p>
            <span class="styled-label">Actie aantal</span>
            <input class="styled-input" type="number" v-model="data.amount">
            <p>
                <i>Het ingevulde aantal producten zal genegeerd worden voor de trend</i>
            </p>
        </div>

    </modal>

</template>

<script setup>
    import saleIgnoreService from '../../../services/http/sale-ignore-service';

    const props = defineProps(['data']);
    const emit = defineEmits(['close', 'stored']);

    const save = function() {
        saleIgnoreService.create({
            article_id: props.data.article.id,
            customer_id: props.data.customer.id,
            at: props.data.date.format('YYYY-MM-DD'),
            amount: props.data.amount
        }).then((saleIgnore) => {
            emit('stored');
            emit('close')
        });
    }
</script>
