import {AbstractModel} from './abstract-model.js';
import Document from './document.js';
import Agreement from './agreement.js';
import Role from './role.js';
import FileModel from './file-model.js';
import UserType from './user-type.js';
import PayOut from './pay-out.js';
import WorkSession from './work-session.js';
import HarvestRegistration from './harvest-registration.js';
import moment from 'moment';
import { useAuthStore } from '../stores/auth.js';


export default class User extends AbstractModel {


    constructor(data) {
        super(data);

        if (this.avatars && typeof this.avatars.count === 'function' ) {
            for (const avatar of this.avatars) {
                this[avatar.extra + 'Avatar'] = avatar;
            }
        }
    }

    getDefaults() {
        return {
            id: null,
            renew_password: null,
            email: null,
            phone: null,
            firstname: '',
            insertion: ' ',
            surname: ' ',
            absent: false,
            company_name: null,
            location: null,
            address: null,
            postal_code: null,
            kvk: null,
            btw: null,
            user_type_id: null,
            hourly_wage: null,
            invited: 0,
            role_ids: [],
            roles: [],
            avatars: [],
            smallAvatar: null,
            mediumAvatar: null,
            largeAvatar: null,
            originalAvatar: null,
            documents: [],
            agreements: [],
            currentAgreement: null,
            harvestRegistrations: [],
            latestHarvestRegistration: null,
            workSessions: [],
            activeWorkSessions: [],
            activeWorkSession: null,
            lastWorkSession: null,
            userType: null,
            deactivated_at: null,
            average_harvest_speed: null,
            privacy_accepted: 0,
            absent_since: moment(),
            updated_at: moment(),
            birthdate: moment(),

            harvest_registrations_count: 0,
            pivot: []
        }
    }

    getTransformers() {
        return {
            allRoles: Role,
            roles: Role,
            avatars: FileModel,
            documents: Document,
            agreements: Agreement,
            currentAgreement: Agreement,
            userType: UserType,
            harvestRegistrations: HarvestRegistration,
            latestHarvestRegistration: HarvestRegistration,
            workSessions: WorkSession,
            activeWorkSessions: WorkSession,
            activeWorkSession: WorkSession,
            lastWorkSession: WorkSession,
            smallAvatar: FileModel,
            mediumAvatar: FileModel,
            largeAvatar: FileModel,
            originalAvatar: FileModel
        }
    }


    initials(){
        return this.firstname.substr(0, 1) + this.surname.substr(0, 1);
    }

    shortName(){
        return this.firstname + ' ' + this.surname.substr(0, 1);
    }

    avatar(size) {
        return this.avatars.filter(function( obj ) {
            return obj.extra == size;
        }).first();

        return null;
    }

    document(type) {
        let documents = this.documents.filter(function(document){
            return document.documentType.name == type;
        });

        if (documents.count() <= 0) {
            return false;
        }

        return documents.get(documents.count()-1);
    }

    name() {
        return this.firstname + ' ' + ((this.insertion === null || this.insertion === '' || typeof this.insertion === 'undefined') ? '' : this.insertion + ' ') + this.surname;
    }

    editable() {
        return (
            useAuthStore().user.id == this.id
            || useAuthStore().user.can('users')
        );
    }

    fullyEditable() {
        return useAuthStore().user.can('users');
    }

    can(permission) {
        for (let role of this.roles) {
            for (let permissionModel of role.permissions) {
                if (
                    Array.isArray(permission) && permission.indexOf(permissionModel.name) >= 0
                    || ! Array.isArray(permission) && permissionModel.name === permission
                ) {
                    return true;
                }
            }
        }

        return false;
    }

    hasRole(role) {
        // Role is array: Has one of the given roles.
        if (Array.isArray(role)) {
            for (const curRole of role) {
                if (this.hasRole(curRole)) {
                    return true;
                }
            }

            return false;
        }

        // Role is string: Has given role
        for (let roleModel of this.roles) {
            if (roleModel.name === role) {
                return true;
            }
        }

        return false;
    }

    agreementAccepted()
    {
        return (
            this.userType === null
            || this.userType.name !== 'freelancer'
            || this.currentAgreement !== null
        );
    }


    // V2
    relations()
    {
        return {
            latestHarvestRegistration: HarvestRegistration,
            workSessions: WorkSession,
            activeWorkSession: WorkSession,
            roles: Role,
            agreements: Agreement,
            documents: Document,
            mediumAvatar: FileModel,
            avatars: FileModel,
            currentAgreement: Agreement,
            lastWorkSession: WorkSession,
            lastFourWeekWorkSessions: WorkSession,
            userType: UserType,
            payOuts: PayOut
        }
    }

    relationPlural()
    {
        return {
            latestHarvestRegistration: false,
            workSessions: true,
            activeWorkSession: false,
            roles: true,
            agreements: true,
            documents: true,
            mediumAvatar: false,
            avatars: true,
            agreements: false,
            lastWorkSession: false,
            lastFourWeekWorkSessions: true,
            avatars: true,
            userType: false,
            payOuts: true
        }
    }

    getMutators() {
        return {
            updatedAt: 'datetime'
        };
    }
}
