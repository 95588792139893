import { defineStore } from 'pinia'
import { ref, computed } from 'vue';
import authService from './../services/http/auth-service';
import storage from './../helpers/local-storage.js';
import { useRouter, useRoute } from 'vue-router';
import { useHarvestAssignmentStore } from './harvestAssignments';
import { useNotificationStore } from './notifications';
import { useCcstStore } from './ccst';
import { usePurchaseStore } from './purchases';
import { useStockStore } from './stocks';
import { useOrderStore } from './orders'

export const useAuthStore = defineStore('auth', () => {
    // Is the user authed
    const authed = ref(storage.get('authed') ?? false);

    // Authed user object
    const user = ref(null);

    // The authedUserPromise
    const authedUserPromise = ref(new Promise((resolve, reject) => {}));

    const fullyLoaded = ref(false);

    const loaded = computed(() =>
        authed.value && fullyLoaded.value
        || ! authed.value
    );

    // Authenticate the user, if succesfull, set and get the authed user.
    const authenticate =async function(email, password) {
        fullyLoaded.value = false;
        return axios({method: 'get', url: '/sanctum/csrf-cookie', baseURL: ''}).then(response => {

            return authService.auth(email, password).then(async (response) => {
                if (response.data === 'wrong-credentials') {
                    throw Error('wrong-credentials');
                }

                const user = await getAuthedUser();

                storage.set('authed', true);
                authed.value = true;

                return user;
            });
        }).catch((error) => {
            authed.value = false;
            user.value = null;
            fullyLoaded.value = true;

            throw error;
        })
    }

    const router = useRouter();

    // Get the authenticated user.
    const getAuthedUser = function() {
        authedUserPromise.value = renewAuthedUser().then(async authedUser => {

            if (user.value.renew_password === 1) {
                fullyLoaded.value = true;
                return authedUser;
            }

            const notificationPromise = useNotificationStore().getNotifications();
            const harvestAssignmentPromise = useHarvestAssignmentStore().getHarvestAssignments();
            const ccstPromise = useCcstStore().getCcst();
            const orderPromise = useOrderStore().getOrders();
            const purchasePromise = usePurchaseStore().getPurchases();
            const stockPromise = useStockStore().getStock();

            await Promise.all([
                notificationPromise,
                harvestAssignmentPromise,
                ccstPromise,
                orderPromise,
                purchasePromise,
                stockPromise
            ]);

            fullyLoaded.value = true;

            return authedUser;
        });

        return authedUserPromise;
    }

    // Log out the authenticated user
    const logout = function() {
        storage.set('authed', false);
        authed.value = false;
        user.value = null;

        window.location.href = '/logout';
    }

    const renewAuthedUser = function() {
        return authService.getAuthedUser().then(authedUser => {
            user.value = authedUser;

            if (user.value.avatars && typeof Array.isArray(user.value.avatars)) {
                for (const avatar of user.value.avatars) {
                    user.value[avatar.extra + 'Avatar'] = avatar;
                }
            }

            return user;
        })
    }

    // If authenticated, check by getting the authed user from the API.
    if (authed.value) {
        getAuthedUser();
    } else {
        fullyLoaded.value = true;
    }

    return {
        // State
        authed, user, authedUserPromise, loaded,

        // Actions
        authenticate, getAuthedUser, renewAuthedUser, logout
    };
});
