<template>
    <div class="dashboard">
        <div class="container">
            <div class="row">
                <div v-if="authUser.can([
                    'dashboard_presence', 'dashboard_planning', 'dashboard_unassigned_harvest_assignments',
                    'dashboard_user_notifications', 'notifications'
                ])" class="col-12">

                    <!-- MELDINGEN -->
                    <div class="alert-holder" v-if="authUser.can('dashboard_user_notifications')">

                        <!-- MISSING BIRTHDATE -->
                        <MissingBirthdate :ref="setChildRef" v-if="authUser.can('dashboard_order_notifications')" />

                        <!-- BIRTHDAYS -->
                        <Birthday :ref="setChildRef" v-if="authUser.can('dashboard_order_notifications')" />

                        <!-- NON FILLED IN SALE FORECASTS -->
                        <NonFilledInSaleForecasts :ref="setChildRef" v-if="authUser.can('dashboard_order_notifications')" />

                        <!-- ZERO CENTS ORDER LINES -->
                        <ZeroCentsOrderLines :ref="setChildRef" v-if="authUser.can('dashboard_order_notifications')" />

                        <!-- ORDER MELDINGEN -->
                        <OrderNotifications :ref="setChildRef" v-if="authUser.can('dashboard_order_notifications')" />

                        <!-- ABSENTIE MELDINGEN -->
                        <AbsentUsers :ref="setChildRef" />

                        <!-- BESTANDEN EN FORMULIEREN -->
                        <Documents :ref="setChildRef" />

                        <!-- VERLOPEN OVEREENKOMSTEN -->
                        <ExpiredAgreements :ref="setChildRef" />

                        <!-- GEDEACTIVEERDE GEBRUIKERS -->
                        <ToLongDeactivatedUsers :ref="setChildRef" />

                    </div>

                    <!-- MELDINGEN VAN GEBRUIKERS -->
                    <Notifications />

                    <!-- ORDERS ZONDER LAADTIJD-->
                    <OrdersWithoutLoadTime v-if="authUser.can('dashboard_planning')" />

                    <!-- ORDERS WITHOUT ORDERLINES-->
                    <OrdersWithoutOrderLines v-if="authUser.can('dashboard_planning')" />

                    <!-- HARVEST ASSIGNMENTS -->
                    <UnassignedHarvestAssignments v-if="authUser.can('dashboard_unassigned_harvest_assignments')" />

                    <!-- PRESENT USERS -->
                    <PresentUsers :ref="setChildRef" />

                </div>
            </div>
        </div>

    </div>
</template>


<script setup>
    import { computed, onBeforeUnmount, ref } from 'vue';

    /*
    |--------------------------------------------------------------------------
    | Child components
    |--------------------------------------------------------------------------
    */
    import OrderNotifications from './_OrderNotifications.vue';
    import AbsentUsers from './_AbsentUsers.vue';
    import Documents from './_Documents.vue';
    import ExpiredAgreements from './_ExpiredAgreements.vue';
    import ToLongDeactivatedUsers from './_ToLongDeactivatedUsers.vue';
    import Notifications from './_Notifications.vue';
    import OrdersWithoutLoadTime from './_OrdersWithoutLoadTime.vue';
    import OrdersWithoutOrderLines from './_OrdersWithoutOrderLines.vue';
    import UnassignedHarvestAssignments from './_UnassignedHarvestAssignments.vue';
    import ZeroCentsOrderLines from './_ZeroCentsOrderLines.vue';
    import PresentUsers from './_PresentUsers.vue';
    import NonFilledInSaleForecasts from './_NonFilledInSaleForecasts.vue';
    import MissingBirthdate from './_MissingBirthdate.vue';
    import Birthday from './_Birthday.vue';

    // References to child components that need to be updated every x seconds.
    const childs = ref([]);
    const setChildRef = function(ref) {childs.value.push(ref);}

    // Emits to child components to get/refresh data.
    const getData = function() {
        for (const child of childs.value) {
            child.getData();
        }
    };
    const timer = setInterval(getData, 60000); // Execute getData every 60 seconds after 60 seconds

    // Clear timer when dashboard is unmounted
    onBeforeUnmount(() => {clearInterval(timer)})


    /*
    |--------------------------------------------------------------------------
    | Auth user
    |--------------------------------------------------------------------------
    */
    import { useAuthStore } from "../../stores/auth";
    const authStore = useAuthStore();
    const authUser = computed(() => authStore.user);



</script>
