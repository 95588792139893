<template>
    <modal :large="true" id="create" class="edit-article" @save="onSubmit()" @close="$emit('close')">
        <template v-slot:header>Artikel voorraad toevoegen</template>
        <div>
            <form @submit.prevent enctype="multipart/form-data">

                <label class="styled-label">
                    <span>Verkoop product</span>
                    <select class="styled-select" autofocus v-model="articleId" id="article-id">
                        <option v-for="article of articles" :key="article.id" :value="article.id">{{ article.name }}</option>
                    </select>
                </label>

                <label class="styled-label">
                    <span>Aantal</span>
                    <input class="styled-input" id="number" type="number" v-model="amount">
                </label>

            </form>
        </div>
    </modal>
</template>

<script setup>
    import { useToast } from 'vue-toast-notification';
    import stockService from '../../services/http/stock-service';
    import articleService from '../../services/http/article-service';
    import { ref } from 'vue';

    const emits = defineEmits(['close']);

    const articles = ref([]);
    const articleId = ref('');
    const amount = ref(0);

    const onSubmit = function() {
        stockService.create({
            article_id: articleId.value,
            amount: amount.value
        }).then(stock => {
            emits('close');
            useToast().success('Voorraad gewijzigd');
        });
    };

    articleService.get({filters: {is_harvestable: 1}}).then(articleModels => {
        articles.value = articleModels.sortBy('name');
    });
</script>
