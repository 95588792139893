import Content from "../../models/content.js"
import { AbstractHttpService } from "./abstract-http-service";

class ContentService extends AbstractHttpService {

    endpoint() {
        return 'contents';
    }

    createModel(data) {
        return new Content(data);
    }
}

export default new ContentService;
