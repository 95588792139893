import HarvestAssignmentList from './../pages/HarvestAssignment/List.vue';
import HarvestAssignmentReport from './../pages/HarvestAssignment/Report/index.vue';
import authGuard from './../guards/authGuard';

export default [
    {
        path: '/harvest-assignments',
        component: HarvestAssignmentList,
        name: 'harvest-assignments'
    }, {
        path: '/harvest-assignments/report',
        component: HarvestAssignmentReport,
        name: 'harvest-assignments-report',
        meta: { permissions: ['harvest_assignments_report'] },
    }
];
