import OrderLine from "../../models/order-line";
import { AbstractHttpService } from "./abstract-http-service";

class OrderLineService extends AbstractHttpService {

    endpoint() {
        return 'order-lines';
    }

    createModel(data) {
        return new OrderLine(data);
    }
}

export default new OrderLineService;
