<template>
    <modal @save="save()" @close="$emit('close')">
        <template v-slot:header>Wachtwoord wijzigen</template>
        <form @submit.prevent>
            <label class="styled-label" for="password">
                <span>Nieuw {{ 'Wachtwoord'.toLowerCase() }}</span>
                <input id="password" type="password" class="styled-input" v-model="form.password" v-bind:class="{ 'is-invalid': form.errors.has('password') }">
                <div class="invalid-feedback" v-if="form.errors.has('password')" v-text="form.errors.get('password')"></div>
            </label>
            <label class="styled-label" for="password_confirmation">
                <span>Nieuw {{ 'Wachtwoord'.toLowerCase() }} herhalen</span>
                <input id="password_confirmation" type="password" class="styled-input" v-model="form.password_confirmation" v-bind:class="{ 'is-invalid': form.errors.has('password_confirmation') }">
                <div class="invalid-feedback" v-if="form.errors.has('password_confirmation')" v-text="form.errors.get('password_confirmation')"></div>
            </label>
        </form>
    </modal>
</template>

<script>
    import Form from '../../../helpers/form.js';
    import { useToast } from 'vue-toast-notification';

    export default{
        props: ['user'],

        data() {
            return {
                form: new Form({
                    password: '',
                    password_confirmation: ''
                })
            }
        },

        methods: {
            save() {
                this.form.put('users/' + this.user.id).then(response => {
                    useToast().success('Wachtwoord gewijzigd');
                    this.$emit('close');
                    document.querySelector('body').classList = '';
                });
            }
        }
    };
</script>
