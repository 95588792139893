<template>
    <div class="container" v-if="ready">

        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Werkverdeling</h1>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="tabs-holder">
                    <ul class="tabs">
                        <template v-for="tab of data">
                            <li @click="activeTab = tab.id" :class="{active: activeTab === tab.id}"><a>{{ tab.name }}</a></li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col" style="position: relative">
                <template v-for="content of data">
                    <div class="harvest-schedule-holder" v-show="activeTab === content.id">
                        <div class="harvest-schedule-column" v-for="(type, index) of content.types" :key="index">
                            <h4>{{ type.translatedName }}</h4>
                            <span class="harvest-schedule-total-percentage">{{ Math.round((totals[content.id][type.id] / totals.total) * 100) }}%</span>
                            <div class="harvest-schedule-employee" v-for="(user, index) of type.users" :key="index">
                                <span class="harvest-schedule-percentage">{{ user.amount }}%</span> <span class="harvest-schedule-name">{{ user.user.name() }}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

    </div>
</template>

<script setup>
    import { useCalculateHarvestSchedule } from '../../composables/useCalculateHarvestSchedule';
    import { ref, watch } from 'vue';

    const activeTab = ref(null);

    const { data, totals, ready, calculateHarvestSchedules } = useCalculateHarvestSchedule();


    calculateHarvestSchedules().finally(() => {
        activeTab.value = data.value[0].id;
    });

</script>
