<template>
    <div v-click-outside="close" class="time-picker-container">

        <input class="time-picker-input" ref="input" @click.stop :value="modelValue" @input="updateValue($event.target.value)">

        <Icon name="clock" class="time-picker-icon" @click.native="active = ! active" @click.stop :class="{active: this.active}"/>

        <div @click.stop v-if="active" class="time-picker">
            <div class="time-picker-hours">
                <a
                    @click.stop
                    class="time-picker-hour"
                    :class="{active: time[0] == hour}"
                    @click="selected(hour, 'hour')"
                    v-for="(hour, index) of [6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]"
                    :key="index"
                >
                    {{ pad(hour) }}
                </a>
            </div>
            <div class="time-picker-minutes">
                <a
                    @click.stop
                    class="time-picker-minute"
                    :class="{active: time[1] == minute}"
                    @click="selected(minute, 'minute')"
                    v-for="(value, minute) in 60"
                    :key="minute"

                >
                    <template v-if="minute % 15 === 0">{{ pad(minute) }}</template>
                </a>
            </div>
        </div>

    </div>
</template>

<script>
    export default {

        props: ['modelValue'],
        emits: ['update:modelValue'],


        data() {
            return  {
                active: false,
                time: [null, null]
            }
        },

        methods: {
            close() {
                this.active = false;
            },

            selected(value, type) {
                let time = this.getTimeFromValue();

                time[(type == 'hour') ? 0 : 1] = this.pad(value);

                this.time = time;

                this.$refs.input.value = this.time.join(':');

                this.$emit('update:modelValue', this.$refs.input.value)
            },

            getTimeFromValue() {
                let currentValue = this.$refs.input.value;

                if (currentValue.includes(':')) {
                    return currentValue.split(':');
                } else if(currentValue != '') {
                    if (currentValue.endsWith(':')) {
                        let hour = currentValue.split(':');
                        hour = hour[0];
                        return [hour, '00'];
                    } else {
                        return [currentValue, '00'];
                    }
                }

                return ['00', '00'];
            },



            updateValue(value) {
                this.time = this.getTimeFromValue();

                this.$emit('update:modelValue', value);
            },

            pad(value) {
                value = String(value);
                if (value.length < 2) {
                    return ('0' + value);
                }

                return value;
            }
        },

        mounted()
        {
            this.time = this.getTimeFromValue();
        }
    }
</script>
