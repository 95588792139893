<template>
    <div class="widget-holder">
        <div class="col">
            <div class="user-data">
                <h2>Overeenkomsten</h2>

                <div v-if="user.agreements && user.agreements.count() > 0" id="agreements">
                    <div v-for="agreement of user.agreements" :key="agreement.id" class="data-row">
                        <div class="row hover-row pb-1 pb-lg-0">
                            <div class="col-12 col-lg-3">
                                <div class="label">
                                    {{ agreement.starts_at.format('DD-MM-YYYY') }} t/m {{ agreement.ends_at.format('DD-MM-YYYY') }}
                                </div>
                            </div>
                            <div class="col-12 col-lg-5">
                                <a class="document-download" @click="download(agreement)">{{ agreement.file.filename }}</a>
                            </div>
                            <div class="col-12 col-lg-3 text-lg-center">
                                {{ agreement.accepted_at !== null ? 'Geaccordeerd' : 'Niet geaccordeerd' }}
                            </div>
                            <div class="col-12 col-lg-1 icons-holder">
                                <div v-if="agreement.accepted_at === null && authUser.can('users')">
                                    <a @click="toggle(agreement)"><Icon name="pencil" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <p class="no-data-placeholder">Er zijn nog geen overeenkomsten voor deze medewerker</p>
                </div>

                <div class="profile-settings-group">
                    <fab-button v-if="user.id !== null && authUser.can('users')" icon="plus" @click.native="create = true"></fab-button>
                </div>
            </div>
        </div>

        <CreateAgreement :lastAgreement="user.agreements.length > 0 ? user.agreements[user.agreements.length-1] : null" @agreementCreated="agreementCreated" v-if="create" @close="create=false" :user="user" />
        <EditAgreement :user="user" @agreementEdited="agreementEdited" @deleteAgreement="deleteAgreement" v-if="edit !== false" :agreement="edit" @close="edit = false" />
        <DeleteAgreement v-if="remove !== false" :agreement="remove" @close="remove = false" @deleted="deleted" />
    </div>
</template>

<script>
    import CreateAgreement from './Agreements/CreateAgreement.vue';
    import EditAgreement from './Agreements/EditAgreement.vue';
    import DeleteAgreement from './Agreements/DeleteAgreement.vue';

    export default{
        props: ['user', 'authUser'],

        components: {CreateAgreement, EditAgreement, DeleteAgreement},

        data() {
            return {
                create: false,
                edit: false,
                remove: false
            }
        },

        methods: {
            toggle(agreement) {
                this.edit = agreement;
            },

            agreementEdited(id, agreement) {
                let index = this.user.agreements.search(agreement => agreement.id === id);
                this.user.agreements.splice(index, 1, [agreement])
                this.edit = false;
            },

            deleteAgreement(agreement) {
                this.remove = this.edit;
            },

            deleted(id) {
                let index = this.user.agreements.search(agreement => agreement.id == id);
                this.user.agreements.splice(index, 1);
                this.remove = false;
                this.edit = false;
            },

            agreementCreated(agreement)
            {
                this.user.agreements.push(agreement);
            },

            download(agreement) {
                axios.get('files/' + agreement.file.id + '/show', {
                    responseType: 'arraybuffer'
                }).then((response) => {

                    let blob = new Blob([response.data], { type: agreement.file.mime_type } ),
                    url = window.URL.createObjectURL(blob)

                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = agreement.file.filename
                    link.click();
                });
            }
        },

        created() {
            this.user.agreements = this.user.agreements.sort((a,b) => a.starts_at < b.starts_at ? -1 : 1);
        }
    };
</script>
