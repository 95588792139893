import {AbstractModel} from './abstract-model.js'

export default class FileModel extends AbstractModel {
    
    getDefaults() {
        return {
            id: null,
            fileable: null,
            path: null, 
            filename: null, 
            image: null, 
            extra: null,
            url: null,
            public_url: null,
            imgData: null,
            mime_type: null
        }
    }
}