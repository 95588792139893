import CultivationCalendarDay from "../../models/cultivation-calendar-day.js"
import { AbstractHttpService } from "./abstract-http-service";

class CultivationCalendarDayService extends AbstractHttpService {

    endpoint() {
        return 'cultivation-calendar-days';
    }

    createModel(data) {
        return new CultivationCalendarDay(data);
    }

    create(payload, parameters = {}) {
        let url = this.makeUrl(parameters);

        return this.sendRequest('post', url, payload);
    }
}

export default new CultivationCalendarDayService;
