import Index from './../pages/Stocks/Index.vue';
//import Stocks from './../pages/Stocks/List.vue';

export default [
    {
        path: '/stocks',
        component: Index,
        name: 'stocks',
        meta: { permissions: ['stock'] },
    }
];
