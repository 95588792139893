<template>
    <div class="widget-holder">
        <div class="row">
            <div class="col-12">
                <div class="worklist-holder">
                    <table class="worklist-table worked-hours">
                        <thead class="worklist-table-header">
                            <tr>
                                <th>Uren</th>
                                <th>Ma<br/> {{ monday.clone().add(0, 'DAY').format('DD-MM') }}</th>
                                <th>Di<br/> {{ monday.clone().add(1, 'DAY').format('DD-MM') }}</th>
                                <th>Wo<br/> {{ monday.clone().add(2, 'DAY').format('DD-MM') }}</th>
                                <th>Do<br/> {{ monday.clone().add(3, 'DAY').format('DD-MM') }}</th>
                                <th>Vr<br/> {{ monday.clone().add(4, 'DAY').format('DD-MM') }}</th>
                                <th>Za<br/> {{ monday.clone().add(5, 'DAY').format('DD-MM') }}</th>
                                <th>Zo<br/> {{ monday.clone().add(6, 'DAY').format('DD-MM') }}</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                         <tbody>
                            <tr>
                                <td>Starttijd</td>
                                <td v-for="(dayData, index) of weekData">
                                    <span v-if="! editting">{{ dayData.startsAt }}</span>
                                    <input v-else v-model="formData[index].startsAt">
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Eindtijd</td>
                                <td v-for="(dayData, index) of weekData">
                                    <span v-if="! editting">{{ dayData.endsAt }}</span>
                                    <input v-else v-model="formData[index].endsAt">
                                </td>
                                <td></td>
                            </tr>
                            <tr class="worklist-pause-row">
                                <td>Pauze</td>
                                <td v-for="(dayData, index) of (editting ? formData : weekData)">
                                    <span>{{
                                        calculatable(dayData.startsAt, dayData.endsAt)
                                        ? formatMinutes(getPauseMinutes(dayData.startsAt, dayData.endsAt))
                                        :  '-'
                                    }}</span>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                        <tfoot class="worklist-table-total">
                            <tr>
                                <td>Totaal</td>
                                <td v-for="(dayData, index) of (editting ? formData : weekData)">
                                    <span>{{ formatMinutes(getMinutesWorked(dayData.startsAt, dayData.endsAt), false) }}</span>
                                </td>
                                <td>{{ formatMinutes(
                                    (editting ? formData : weekData).reduce((curAmount, d) =>
                                        curAmount + getMinutesWorked(d.startsAt, d.endsAt), 0
                                    ))
                                }}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import moment from 'moment';
    import { computed, ref, watch } from 'vue';
    import collect from 'collect.js';
    import workSessionService from '../../../services/http/work-session-service';

    const props = defineProps(['monday', 'id', 'activeWeek', 'editting']);

    watch(() => props.monday, () => {
        getWorkSessions();
    });
    /*
    |--------------------------------------------------------------------------
    | Get data
    |--------------------------------------------------------------------------
    */
    const workSessions = ref(collect());
    const getWorkSessions = function() {
        workSessionService.get({filters: {
            between: `${props.activeWeek.from.format('YYYY-MM-DD')},${props.activeWeek.till.format('YYYY-MM-DD')}`,
            user_id: props.id,
            order: 'starts_at,asc'
        }}).then(workSessionModels => {
            workSessions.value = workSessionModels;
        });
    };
    getWorkSessions();


    /*
    |--------------------------------------------------------------------------
    | View/Show
    |--------------------------------------------------------------------------
    */
    const weekData = computed(() => {
        const weekData = [];

        for (let dayOfWeek = 1; dayOfWeek <= 7; dayOfWeek++) {
            const workSession = workSessions.value.first(ws => ws.startsAt.isoWeekday() === dayOfWeek);
            const startsAt = workSession && workSession.startsAt ? workSession.startsAt.format('HH:mm') : '-';
            const endsAt = workSession && workSession.endsAt ? workSession.endsAt.format('HH:mm') : '-';

            const dayData = {
                startsAt: startsAt,
                endsAt: endsAt,
                workSession: workSession
            };

            weekData.push(dayData)
        }

        return weekData;
    });



    /*
    |--------------------------------------------------------------------------
    | Edit
    |--------------------------------------------------------------------------
    */

    const formData = ref([]);
    const edit = function() {
        formData.value = [];
        for (let dayOfWeek = 1; dayOfWeek <= 7; dayOfWeek++) {
            const workSession = workSessions.value.first(ws => ws.startsAt.isoWeekday() === dayOfWeek);
            formData.value.push({
                startsAt: workSession && workSession.startsAt ? workSession.startsAt.format('HH:mm') : null,
                endsAt:  workSession && workSession.endsAt ? workSession.endsAt.format('HH:mm') : null,
                id: workSession ? workSession.id : null
            });
        }
    };


    /*
    |--------------------------------------------------------------------------
    | Update
    |--------------------------------------------------------------------------
    */
    const update = function() {

        const dayOfTheWeek = props.monday.clone();

        const deleteIds = [];
        const updateData = [];

        for (const dayData of formData.value) {

            // Empty/Invalid time, delete if existed
            if (! isValidTime(dayData.startsAt)) {
                if (dayData.id) {
                    deleteIds.push(dayData.id);
                }
            }

            else {
                updateData.push({
                    id: dayData.id,
                    starts_at: dayOfTheWeek.format('YYYY-MM-DD') + ' ' + dayData.startsAt + ':00',
                    ends_at: dayData.endsAt ? dayOfTheWeek.format('YYYY-MM-DD') + ' ' + dayData.endsAt + ':00' : null,
                    user_id: props.id
                })
            }


            dayOfTheWeek.add(1, 'day');
        }

        return workSessionService.updateMany({
            updateData,
            deleteData: deleteIds
        }).then(() => {
            return getWorkSessions();
        });
    };


    /*
    |--------------------------------------------------------------------------
    | Time functions
    |--------------------------------------------------------------------------
    */
    const isValidTime = function(time) {
        return moment(time, 'HH:mm', true).isValid() || moment(time, 'H:mm', true).isValid();
    }

    const calculatable = function(startsAt, endsAt) {
        return isValidTime(startsAt) && isValidTime(endsAt);
    }

    const formatMinutes = function(minutes, formatZero = true) {
        if (minutes === 0 && ! formatZero) {
            return '';
        }

        const hours = Math.floor(minutes / 60);
        const formattedMinutes = String(minutes % 60).padStart(2, '0');

        return `${hours}:${formattedMinutes}`;
    }

    const getTotalMinutes = function(startsAt, endsAt) {
        if (! calculatable(startsAt, endsAt)) {
            return 0;
        }

        // Get total minutes;
        const start = moment(`2023-01-01 ${startsAt}`);
        const end = moment(`2023-01-01 ${endsAt}`);

        return end.diff(start, 'minute');
    }

    const getPauseMinutes = function(startsAt, endsAt) {
        const totalMinutes = getTotalMinutes(startsAt, endsAt);

        // Hours worked => pause in minutes: 2.5 => 15  |   5 => 45   |   7.5 => 60
        return totalMinutes > 450 ? 60 :
            totalMinutes > 300 ? 45 :
            totalMinutes > 150 ? 15
            : 0
    }

    const getMinutesWorked = function(startsAt, endsAt) {
        const totalMinutes = getTotalMinutes(startsAt, endsAt);
        const pauseMinutes = getPauseMinutes(startsAt, endsAt);

        return totalMinutes - pauseMinutes;
    }





    defineExpose({edit, update});
</script>
