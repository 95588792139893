<template>
    <div class="users-list">
        <user v-for="(user, index) in usersFiltered" :key="index" :user="user"></user>
    </div>
</template>

<script>
    import user from './User.vue';

    export default {
        props: ['users', 'showInactive'],

        computed: {
            usersFiltered: function() {
                const users = this.users.sort((a,b) => {
                    return a.name() >= b.name() ? 1 : -1
                });
                if (! this.showInactive) {
                    return users.filter(u => (! u.deactivated_at));
                }

                return users;
            }
        },

        components: {
            user: user
        }
    };
</script>
