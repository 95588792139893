<template>
    <modal @save="$emit('delete')" @close="$emit('close')">
        <template v-slot:header>Inkoop verwijderen</template>

        <div class="container">
            <div class="row">
                <p>Weet je zeker dat je deze inkoop wilt verwijderen?</p>
            </div>
        </div>
    </modal>
</template>

<script>
    export default{
        methods: {
        }
    };
</script>
