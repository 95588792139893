import {AbstractModel} from './abstract-model.js'
import Location from './location.js';
import Article from './article.js';
import Type from './type.js';
import Chest from './chest.js';
import Content from './content.js';
import Size from './size.js';
import DayHarvestRegistration from './day-harvest-registration.js';
import User from './user.js';
import HarvestRegistration from './harvest-registration.js';
import { collect } from 'collect.js';
import moment from 'moment';

export default class HarvestAssignment extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            harvested: 0,
            amount: 0,
            location_id: null,
            sequence: null,
            location: null,
            rush: false,
            new: false,
            users: [],
            harvestRegistrations: [],
            chest_id: null,
            content_id: null,
            size_id: null,
            type_id: null,
            type: null,
            chest: null,
            content: null,
            size: null,
            pivot: [],
            last_harvest: moment(),
            completes_at_prediction: moment(),
            planned_at: moment(),
            created_at: moment(),
            updated_at: moment()
        }
    }

    amountForStock() {
        return (this.amount > this.harvested ? this.amount : this.harvested);
    }

    getCcst()
    {
        return {
            chest_id: (this.chest_id ? this.chest_id : this.chestId),
            content_id: (this.content_id ? this.content_id : this.contentId),
            size_id: (this.size_id ? this.size_id : this.sizeId),
            type_id: (this.type_id ? this.type_id : this.typeId),
        }
    }

    getTransformers() {
        return {
            location: Location,
            users: User,
            harvestRegistrations: HarvestRegistration,
            type: Type,
            chest: Chest,
            content: Content,
            size: Size
        }
    }

    name() {
        return this.chest.name + ' ' + this.size.translate('name') + ' ' + this.type.translate('name') + ' ' + this.content.name;
    }

    getTime(quality) {
        // (Totaal benodigde oogst - geoogste oogst) * (300 gedeeld door het aantal bosjes)
        let time = ( (this.amount - this.harvested) * (300 / this.content.amount()));
        quality = (quality/3) * .25;

        // Kwaliteit van radijs meeberekenen
        time *= (1 + quality);

        return time;
    }

    bunches()
    {
        return (this.content.amount() * this.amount);
    }

    correctedBunchesFactor(formulaFactors)
    {
        return this.content.amount()
        * this.content.formula_factor
        * this.type.formula_factor
        * this.size.formula_factor
        * formulaFactors.find(ff => ff.name == 'seeds_per_meter').formula_factor
        * this.type.quality_formula_factor
        * formulaFactors.find(ff => ff.name == 'temperature').formula_factor;
    }

    correctedSpeed(formulaFactors)
    {
        let speedFactorReverse = this.content.formulaFactor
        * this.type.formulaFactor
        * this.size.formulaFactor
        * formulaFactors.find(ff => ff.name == 'seeds_per_meter').formula_factor
        * this.type.qualityFormulaFactor
        * formulaFactors.find(ff => ff.name == 'temperature').formula_factor;

        return 1/(speedFactorReverse > 0 ? speedFactorReverse : 1);
    }

    done() {
        return (
            this.harvested >= this.amount
            && this.location_id !== null
        );
    }

    dayHarvestDate() {
        return this.plannedAt;
    }

    // V2
    relations()
    {
        return {
            chest: Chest,
            content: Content,
            size: Size,
            type: Type,
            harvestRegistrations: HarvestRegistration,
            users: User,
            location: Location
        }
    }

    relationPlural()
    {
        return {
            chest: false,
            content: false,
            size: false,
            type: false,
            harvestRegistrations: true,
            users: true,
            location: false
        }
    }

    getMutators() {
        return {
            'plannedAt': 'datetime',
            'lastHarvest': 'datetime'
        };
    }

    getModelName() {
        return 'harvest-assignment';
    }
}
