<template>

    <modal
        @close="emit('close')"
        @save="save()"
        v-if="data"
    >

        <template v-slot:header>{{ data.customer.name }} - {{ data.type.name }} - {{ data.date.format('DD-MM-YYYY') }}</template>

        <div>
            <span class="styled-label">Prognose aantal</span>
            <input class="styled-input" type="number" v-model="saleForecast.amount">
            <p>
                <i>
                    Het ingevulde getal zal getoond worden naast
                    {{ data.date.clone().startOf('week').isAfter(new Date) ? 'de prognose' : 'het verkochte aantal' }}.
                </i>
            </p>
        </div>

        <div class="forecast-log" v-if="saleForecasts.count() > 0">
            <div class="forecast-log-header">
                <div style="width: 30%"><b>Naam</b></div>
                <div style="width: 30%; text-align: center;"><b>Aantal</b></div>
                <div style="width: 40%; text-align: right;"><b>Toegevoegd op</b></div>
            </div>
            <div class="forecast-log-row" v-for="saleForecast of saleForecasts">
                <div style="width: 30%">{{ saleForecast.user.name() }}</div>
                <div style="width: 30%; text-align: center;">{{ saleForecast.amount }}</div>
                <div style="width: 40%; text-align: right;">{{ saleForecast.createdAt.format('DD-MM-YYYY HH:mm') }}</div>
            </div>
        </div>

    </modal>

</template>

<script setup>
    import saleForecastService from '@/services/http/sale-forecast-service';
    import { watch, ref, computed } from 'vue';
    import { collect } from "collect.js";
    import moment from 'moment';

    const props = defineProps(['data']);
    const emit = defineEmits(['close', 'stored', 'editted']);


    import { useAuthStore } from '../../../stores/auth';
    const user = computed(() => useAuthStore().user);

    watch(() => props.data, (nVal, oVal) => {
        if (nVal) {
            getSaleForecasts();
        }
    });

    const save = function() {
        if (saleForecast.value.amount === null || saleForecast.value.amount === '') {
            return;
        }

        if (! saleForecast.value.id) {
            saleForecastService.create({
                customer_id: props.data.customer.id,
                type_id: props.data.type.id,
                at: props.data.date.format('YYYY-MM-DD'),
                amount: saleForecast.value.amount
            }, {include: ['user']}).then((saleForecast) => {
                emit('stored', saleForecast);
            });
        } else {
            saleForecastService.edit(saleForecast.value.id, {
                amount: saleForecast.value.amount
            }, {include: ['user']}).then((saleForecast) => {
               emit('editted', saleForecast);
            });
        }
    }

    const saleForecasts = ref(collect());
    const saleForecast = ref({});

    const getSaleForecasts = function() {
        saleForecastService.get({filters: {
            customer_id_is: props.data.customer.id,
            type_id_is: props.data.type.id,
            at_is: props.data.date.format('YYYY-MM-DD'),
        }, include: ['customer', 'type', 'user']}).then((saleForecastModels) => {
            saleForecasts.value = saleForecastModels;

            const curSaleForecast = saleForecasts.value.first(sf => (
                sf.userId == user.value.id,
                sf.createdAt.isSame(moment(), 'day')
            ));

            if (curSaleForecast) {
                saleForecast.value = curSaleForecast;
            } else {
                saleForecast.value = ({
                    id: null,
                    amount: null
                })
            }
        });
    }

</script>
