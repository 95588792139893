import {AbstractModel} from './abstract-model.js'
import BreedPerType from './breed-per-type.js'
import Breed from './breed.js'

export default class Type extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            name: null,
            latest_harvest_assignments_count: null,
            formula_factor: null,
            quality_formula_factor: null,
            sequence: null
        }
    }

    translation() {
        return 'Type';
    }

    relations()
    {
        return {
            children: Type,
            breedPerTypes: BreedPerType,
            breeds: Breed
        }
    }

    relationPlural()
    {
        return {
            children: true,
            breedPerTypes: true,
            breeds: true
        }
    }

    translate(field) {
        const translations =  {
            "red": "Rood",
            "white": "Wit",
            "whitepoint": "Witpunt",
            "mix": "Mix",
            "big_bunches": "Grote bossen",
            "icicles": "Ijspegels",
            "purple": "Paars",
            "rainbow": "Rainbow",
            "micro": "Micro",
            "yellow": "Geel",
            "rainbow_whitepoint": "Rainbow witpunt",
            "purple_whitepoint": "Paarse witpunt",
            "tyger": "Tyger",
            "rood_wit": "Combi rood/wit",
            "bonte": "Bonte"
        }

        if (! (this[field] in translations)) {
            return this[field];
        }
        return translations[this[field]];
    }

}
