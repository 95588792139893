<template>
    <div class="widget-holder">
        <div class="row">
            <div class="col-12">
                <div class="worklist-holder">
                    <table class="worklist-table harvested">
                        <thead class="worklist-table-header">
                            <tr>
                                <th>Extra taken</th>
                                <th>Ma</th>
                                <th>Di</th>
                                <th>WO</th>
                                <th>Do</th>
                                <th>Vr</th>
                                <th>Za</th>
                                <th>Zo</th>
                                <th>Totaal</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="task of tasksWithHours" :key="task.id">
                                <td>{{ task.name }}</td>
                                <td v-for="(weekday, index) in 7" :key="index">
                                    <span v-if="! editting">{{ taskHourData[task.id][activeWeek.from.clone().add(weekday-1, 'day').format('YYYY-MM-DD')] }}</span>
                                    <input v-else v-model="taskHourData[task.id][activeWeek.from.clone().add(weekday-1, 'day').format('YYYY-MM-DD')]">
                                </td>
                                <td>
                                    {{ secondsToTime(Object.keys(taskHourData[task.id]).reduce((a,b) => a + getSeconds(taskHourData[task.id][b]), 0)) }}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot class="worklist-table-total">
                            <tr>
                                <td colspan="8">Totaal</td>
                                <td>
                                    {{ secondsToTime(Object.keys(taskHourData).reduce(
                                        (a,b) => a + Object.keys(taskHourData[b]).reduce(
                                            (c, d) => c + getSeconds(taskHourData[b][d]), 0)
                                        , 0)
                                    ) }}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed, ref, watch } from 'vue';
    import collect from 'collect.js';
    import taskHourService from '../../../services/http/task-hour-service'
    import taskService from '../../../services/http/task-service'
    import moment from 'moment';

    const props = defineProps(['id', 'activeWeek', 'editting'])


    /*
    |--------------------------------------------------------------------------
    | Edit
    |--------------------------------------------------------------------------
    */
    const originalTaskHourData = ref({});
    const edit = function() {
        originalTaskHourData.value = _.cloneDeep(taskHourData.value);
    }

    const reset = function() {
        taskHourData.value = originalTaskHourData.value;
    }


    /*
    |--------------------------------------------------------------------------
    | Time functions
    |--------------------------------------------------------------------------
    */

    const secondsToTime = function(seconds) {
        let minutes = seconds / 60;
        let hours = Math.floor(minutes/60);
        minutes = minutes % 60;

        var s = minutes+"";
        while (s.length < 2) s = "0" + s;

        return hours + ':' + s;
    };

    const getSeconds = function(time) {
        time = time.split(':');

        let seconds = ((time[0] * 3600) + (time[1] * 60));

        return isNaN(seconds) ? 0 : seconds;
    };

    const getHoursWorked = function(weekday, task){
        const filteredTaskHours = taskHours.value.filter(taskHour => taskHour.is(task.id, props.activeWeek.from.clone().add(weekday-1, 'day')));
        let secondsWorked = filteredTaskHours.reduce((a, b) => a + b.secondsWorked, 0);

        return secondsWorked ? moment('00:00:00', 'HH:mm:ss').add(secondsWorked, 'second').format('HH:mm') : '';
    };


    /*
    |--------------------------------------------------------------------------
    | Manipulate data
    |--------------------------------------------------------------------------
    */

    const tasksWithHours = computed(() => {
        return tasks.value.filter(task => {
            if (props.editting && ! task.deletedAt) {
                return true;
            }

            return (task.id in taskHourData.value && Object.values(taskHourData.value[task.id]).find(val => val !== ''));
        });
    });


    const taskHourData = ref({});
    const setTaskHourData = function() {
        taskHourData.value = {};
        if (tasks.value.count() > 0) {
            for (let task of tasks.value) {
                taskHourData.value[task.id] = {};
                let date = props.activeWeek.from.clone();
                for (let d = 1; d <= 7; d++) {
                    taskHourData.value[task.id][date.format('YYYY-MM-DD')] = getHoursWorked(d, task);
                    date.add(1, 'day');
                }
            }
        }
    };

    /*
    |--------------------------------------------------------------------------
    | Update
    |--------------------------------------------------------------------------
    */
    const saveTaskHours = function() {
        let diffData = [];
        for (let task_id in taskHourData.value) {
            for (let date in taskHourData.value[task_id]) {
                if (taskHourData.value[task_id][date] != originalTaskHourData.value[task_id][date]) {
                    let seconds = moment(taskHourData.value[task_id][date], 'HH:mm').format('HH') * (60*60);
                    seconds += moment(taskHourData.value[task_id][date], 'HH:mm').format('mm') * (60);
                    diffData.push({
                        task_id: task_id,
                        date: date,
                        seconds_worked: seconds,
                        user_id: props.id
                    });
                }
            }
        }

        if (diffData.length > 0) {
            axios.put('task-hours/update-many', diffData).then(() => {
                getData();
            });
        }
    };

    /*
    |--------------------------------------------------------------------------
    | Get data
    |--------------------------------------------------------------------------
    */

    watch(() => props.activeWeek, () => {
        getData();
    })

    const taskHours = ref(collect());
    const getTaskHours = function() {
        return taskHourService.get({filters: {
            user_id: props.id,
            from: props.activeWeek.from.format('YYYY-MM-DD'),
            till: props.activeWeek.till.format('YYYY-MM-DD'),
        }}).then(taskHourModels => {
            taskHours.value = taskHourModels;
        })
    };


    const tasks = ref(collect());
    const getTasks = function(){
        return taskService.get({filters: {
            'with_deactivated': 1
        }}).then(taskModels => {
            tasks.value = taskModels;
        });
    };

    const getData = function() {
        Promise.all([
            getTasks(),
            getTaskHours()
        ]).then(() => {
            setTaskHourData();
        })
    }
    getData();




    defineExpose({saveTaskHours, edit, reset});
</script>
