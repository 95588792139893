<template>
    <div class="row hover-row pb-1 pb-lg-0">
        <div class="row">
            <div class="col-12 col-lg-3">
                <div v-if="docIndex === 0 || document.documentType.name === 'document'" class="label">{{ document.docType() }}</div>
            </div>
                <div class="col-12 col-lg-5">
                <a class="document-download" @click="download">{{ document.file.filename }}</a>
            </div>
            <div class="col-12 col-lg-3 text-lg-center">
                {{ document.dateFormatted() }}
            </div>
            <div class="col-12 col-lg-1">
                <div class="icons-holder">
                    <a @click="delete_document = document.id">
                        <Icon name="trash" />
                    </a>
                </div>
            </div>
        </div>

        <EditDocument v-if="edit_document" :document="document" :documentTypes="documentTypes" @close="toggle()" @documentEdited="documentEdited" @deleteDocument="deleteDocument" />
        <DeleteDocument v-if="delete_document" :document="document" @deleted="deleted" @close="delete_document = false" />
    </div>
</template>

<script>
    import EditDocument from './EditDocument.vue';
    import DeleteDocument from './DeleteDocument.vue';

    export default{
        props: ['document', 'docIndex', 'documentTypes'],

        components: { EditDocument, DeleteDocument },

        data() {
            return {
                edit_document: false,
                delete_document: false
            }
        },

        methods: {
            toggle() {
                this.edit_document = (! this.edit_document)
            },

            documentEdited(document) {
                this.toggle();
                this.$emit('documentEdited', document);
            },

            deleteDocument() {
                this.delete_document = (! this.delete_document);
            },

            deleted(id) {
                this.$emit('documentDeleted', id);
                this.delete_document = false;
            },

            download() {
                axios.get('files/' + this.document.file.id + '/show', {
                    responseType: 'arraybuffer'
                }).then((response) => {

                    let blob = new Blob([response.data], { type: this.document.file.mime_type } ),
                    url = window.URL.createObjectURL(blob)

                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = this.document.file.filename
                    link.click();
                });
            }
        }
    };
</script>
