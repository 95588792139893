export function useDateHelper() {

    function presentMonth(month) {
        return [
            'Januari', 'Februari', 'Maart',
            'April', 'Mei', 'Juni',
            'Juli', 'Augustus', 'September',
            'Oktober', 'November', 'December'
        ][month-1]
    }

    return { presentMonth }
}
