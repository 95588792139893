import {AbstractModel} from './abstract-model';
import User from './user.js';
import FileModel from './file-model.js';

export default class ProductionControlMeasurement extends AbstractModel {

    getMutators() {
        return {
            'createdAt': 'datetime',
            'tuberWeight': 'float',
            'tuberDiameter': 'float',
            'tuberHeight': 'float',
            'leavesWeight': 'float',
            'leavesLength': 'float'
        };
    }

    relations() {
        return {
            user: User
        }
    }

    relationPlural() {
        return {
            user: false
        }
    }

    presentYesNo(attribute) {
        return this[attribute] ? 'Yes' : 'No';
    }

    roundness() {
        if (
            ! this.tuberDiameter || this.tuberDiameter <= 0
            || ! this.tuberHeight || this.tuberHeight <= 0
        ) {
            return 0;
        }

        return Math.round((this.tuberHeight / this.tuberDiameter)*100)/100;
    }
}
