<template>
    <div class="graph">
        <div class="sold-graph price-graph" :style="`width: ${(periods.length*96)+3}px`">
            <div :class="{today: props.showPer !== 'day' && getLabel(period) === getLabel(now) || period.isSame(now, 'day')}" class="column" v-for="period of periods">
                <div class="bar-container">
                    <div v-for="dataPoint of dataPointsForPeriod(period)" :class="[dataPoint.class]" class="point" :style="`bottom: ${getBottom(dataPoint)}`">
                        <div class="point-value">
                            <span class="bar-euro-sign">€</span>{{ formatNumber(dataPoint.data.amount) }}
                        </div>
                    </div>
                </div>
                <div class="label-holder">
                    <div class="label" v-html="getLabel(period)"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed, onMounted } from 'vue';
    import moment from 'moment'
    import collect from 'collect.js'

    const props = defineProps(['data', 'formatNumber', 'from', 'till', 'showPer'])

    const now = moment();

    const periods = computed(() => {
        let periods = [];
        const fromMoment = moment(props.from, 'YYYY-MM-DD').clone();
        const tillMoment = moment(props.till, 'YYYY-MM-DD');

        while (fromMoment.isSameOrBefore(tillMoment)) {
            periods.push(fromMoment.clone());
            fromMoment.add(1, props.showPer);
        }

        return periods;
    });

    const dataPointsForPeriod = function(period) {
        const year = period.isoWeekYear();
        let periods = collect();

        if (props.showPer === 'day'){
            const wknr = period.format('WW');
            periods = collect([
                {period: year + wknr + (period.isoWeekday()-1), class: 'current'},
                {period: year-1 + wknr + (period.isoWeekday()-1), class: 'previous'},
                {period: year-2 + wknr + (period.isoWeekday()-1), class: 'super-previous'}
            ]);
        } else if (props.showPer === 'week') {
            const wknr = period.format('WW');
            periods = collect([
                {period: year + wknr, class: 'current'},
                {period: year-1 + wknr, class: 'previous'},
                {period: year-2 + wknr, class: 'super-previous'}
            ]);
        } else {
            const month = period.format('-MM');
            periods = collect([
                {period: year + month, class: 'current'},
                {period: (year-1) + month, class: 'previous'},
                {period: (year-2) + month, class: 'super-previous'}
            ]);
        }

        return props.data.filter(di => {
            return periods.pluck('period').all().includes(di.period.toString());
        }).map(di => ({
            data: di,
            class: periods.firstWhere('period', di.period.toString()).class
        }))
    }

    const getBottom = function(dataPoint) {
        const amount = dataPoint.data.amount;

        if (! amount || amount < 0) {
            return '0px';
        }

        if (amount > 0.96) {
            return 'calc(100% - 16px)';
        }

        return amount * 100 + '%';
    }

    const getLabel = function(period) {
        if (props.showPer === 'day') {
            return (period.dayOfYear() === 1 ? period.format('YYYY') + ' ' : '')
                + (period.weekday() === 0 ? ('Week ' + period.isoWeek() + ' ')  : '' )
                + period.format('dddd')
        }

        if (props.showPer === 'week') {
            return (period.isoWeekYear() === now.year() ? '' : period.isoWeekYear() + ' ') + 'w' +period.isoWeek();
        }

        return period.format('MMMM YYYY');
    }
</script>
