import collect from 'collect.js';
import moment from 'moment';
import { computed, ref } from 'vue';
import purchaseService from './../services/http/purchase-service';
import { defineStore } from 'pinia';

export const usePurchaseStore = defineStore('purchases', () => {
    const purchasesLoaded = ref(false);
    const purchases = ref(collect());

    const defaultSort = (a, b) => ((a.date < b.date) ? -1 : 1);

    const purchasesToday = computed(() => purchases.value.filter(p => p.date.isSame(new Date(), "day")).sort(defaultSort));
    const purchasesMadeToday = computed(() => purchases.value.filter(p => p.createdAt.isSame(new Date(), "day")).sort(defaultSort));

    const getPurchases = function() {
        return purchaseService.get({
            include: ['size'],
            filters: {created_at_is_greater_than: moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD')}
        }).then(purchaseModels => {
            purchases.value = purchaseModels;
            purchasesLoaded.value = true
        })
    }

    const setPurchases = (purchases) => {};
    const addPurchase = (purchase) => {
        purchases.value.push(purchase);
    };

    const editPurchase = (purchase) => {
        let index = purchases.value.search(o => o.id === purchase.id);
        purchases.value.splice(index, 1, [purchase]);
    };

    const removePurchase = (purchase) => {
        let index = purchases.value.findIndex(o => o.id === purchase.id);
        if (index >= 0) {
            purchases.value.splice(purchases.value.findIndex(o => o.id === purchase.id), 1);
        }
    };

    const removePurchaseById = (id) => {
        let index = purchases.value.search(o => o.id === id);
        if (index >= 0) {
            purchases.value.splice(purchases.value.search(o => o.id === id), 1);
        }
    };

    return {
        purchasesLoaded, purchases,

        purchasesToday, purchasesMadeToday,

        getPurchases, addPurchase,
        editPurchase, removePurchase, removePurchaseById
    }
});
