import SowingPlanningSalesForecastIndex from '../pages/SowingPlanning/SaleForecast/Index.vue';
import Index from './../pages/SowingPlanning/Index.vue';
import SowingPlanningSalesForecastTotals from '../pages/SowingPlanning/SalesForecastTotals.vue';
import SowingPlanningAvailableM2 from '../pages/SowingPlanning/AvailableM2.vue';
import SowingPlanningNeededm2 from '../pages/SowingPlanning/NeededM2.vue';

export default [
    {
        path: '/sowing-planning',
        component: Index,
        name: 'sowing-planning-index',
        meta: { permissions: ['sowing_planning'] }
    }, {
        path: '/sowing-planning/sales-forecast',
        component: SowingPlanningSalesForecastIndex,
        name: 'sales-forecast',
        meta: { permissions: ['sowing_planning'] }
    }, {
        path: '/sowing-planning/sales-forecast/totals',
        component: SowingPlanningSalesForecastTotals,
        name: 'sales-forecast-totals',
        meta: { permissions: ['sowing_planning'] },
    }, {
        path: '/sowing-planning/sow-per-m2',
        component: SowingPlanningNeededm2,
        name: 'sow_per_m2',
        meta: { permissions: ['sowing_planning'] },
    }, {
        path: '/sowing-planning/available-m2',
        component: SowingPlanningAvailableM2,
        name: 'available_m2',
        meta: { permissions: ['sowing_planning'] },
    }
];
