import Purchase from "../../models/purchase.js"
import { AbstractHttpService } from "./abstract-http-service";

class PurchaseService extends AbstractHttpService {

    endpoint() {
        return 'purchases';
    }

    createModel(data) {
        return new Purchase(data);
    }
}

export default new PurchaseService;
