<template>
    <modal @save="onsubmit()" @close="$emit('close')">
        <template v-slot:header>{{ user.name() }} op {{ user.absent ? 'aanwezig' : 'afwezig' }} zetten</template>

        <p>
            Weet je zeker dat je {{ user.name() }} op {{ user.absent ? 'aanwezig' : 'afwezig' }} wilt zetten?
        </p>

        <template v-slot:close>Annuleren</template>
        <template v-slot:save>Doorgaan</template>
    </modal>
</template>

<script>
import { useToast } from 'vue-toast-notification';
    export default{
        props: ['user'],

        methods: {
            onsubmit() {
                let absent = (! this.user.absent)
                axios.put('users/' + this.user.id + '?deactivated=1', {
                    absent: (! this.user.absent)
                }).then(response => {
                    useToast().success(this.user.absent ? 'Medewerker aanwezig gezet' : 'Medewerker afwezig gezet');
                    this.$emit('close');
                    this.$emit('absent', absent);
                    document.querySelector('body').classList = '';
                })
            }
        }
    };
</script>
