import Location from "../../models/location.js"
import ReviewCategory from "../../models/review-category.js"
import { AbstractHttpService } from "./abstract-http-service";

class ReviewCategoryService extends AbstractHttpService {

    endpoint() {
        return 'review-categories';
    }

    createModel(data) {
        return new ReviewCategory(data);
    }
}

export default new ReviewCategoryService;
