<template>
    <div id="notifications" class="container">

        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Meldingen</h1>
                </div>

                <div class="page-header-settings">
                    <div class="row">
                        <div class="col-6 col-md-4 col-lg-3">
                            <div class="datepicker-styling">
                                <span class="styled-label">Van</span>
                                <DatePicker v-model="from" />
                            </div>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3">
                            <div class="datepicker-styling">
                                <span class="styled-label">Tot</span>
                                <DatePicker v-model="till" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="users-search-holder">
                                <input class="text-left" type="text" placeholder="Medewerker zoeken" v-model="search">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="widget-holder">
            <div class="row">
                <div class="col-12">
                    <div class="notification" :class="{read: notification.read}" v-for="(notification, index) of filteredNotifications" :key="index">
                        <div class="row">
                            <div class="col-5">
                                <div class="notification-user">{{ notification.user ? notification.user.name() : '-' }}</div>
                                <div class="notification-body">{{ notification.body }}</div>
                                <div class="notification-date">{{ notification.createdAt.format('DD-MM-YYYY HH:mm') }}</div>
                            </div>
                            <div class="col-5">
                                <a class="notification-file-image" target="_blank" v-if="notification.file" :href="notification.file.publicUrl">
                                    <div class="button-icon-holder">
                                        <Icon name="photo" />
                                    </div>
                                    <span>Bijlage openen</span>
                                </a>
                            </div>
                            <div class="col-2">
                                <div class="notification-read-button">
                                    <a v-if="notification.read" @click="toggleRead(notification)">
                                        <Icon name="eye-slash" />
                                    </a>
                                    <a v-else @click="toggleRead(notification)">
                                        <Icon name="eye" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>
    import notificationService from '../../services/http/notification-service';
    import moment from 'moment';
    import { ref, computed } from 'vue';
    import { storeToRefs } from 'pinia';
    import { useNotificationStore } from './../../stores/notifications';

    const search = ref('');
    const from = ref(moment().startOf('YEAR').format('YYYY-MM-DD'));
    const till = ref(moment().format('YYYY-MM-DD'));

    const store = useNotificationStore();
    const { notifications } = storeToRefs(store);

    const filteredNotifications = computed(() => {
        notifications.value.sort((a,b) => (a.user && b.user && a.user.name() >= b.user.name() ? 1 : -1));

        return notifications.value.filter((notification) => {
            if (! notification.user.name().toLowerCase().includes(search.value.toLowerCase())) {
                return false;
            }
            let fromDate = moment(from.value, 'YYYY-MM-DD').startOf('DAY');
            let tillDate = moment(till.value, 'YYYY-MM-DD').endOf('DAY');

            if (notification.createdAt.isBefore(fromDate) || notification.createdAt.isAfter(tillDate)) {
                return false;
            }

            return true;
        });
    });

    const toggleRead = function(notification) {
        store.updateNotification(notification.id, {read: notification.read ? 0 : 1});
    }
</script>
