<template>
    <div class="container">

        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Productieprognose</h1>
                </div>
            </div>
        </div>

        <div class="page-header-settings" style="border-bottom: 0; margin-bottom: 0;">
            <div class="row">
                <div class="col-6 col-md-4 col-lg-3">
                    <span class="styled-label">Jaar</span>
                    <select class="styled-select" @change="yearChanged()" v-model="selectedYear">
                        <option v-for="year of filterableYears">{{ year }}</option>
                    </select>
                </div>
                <div class="col-6 col-md-4 col-lg-3">
                    <span class="styled-label">Week</span>
                    <select class="styled-select" @change="weekChanged()" v-model="selectedWeek">
                        <option v-for="week in weeksInYear" :value="week">{{ week }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <div style="overflow-x: scroll;">
        <table class="styled-table production-forecast-table">
            <thead class="styled-table-header">
                <tr>
                    <th>Week</th>
                    <th
                        @click="selectedWeek = week; weekChanged()"
                        :class="{selected: selectedWeek === week}"
                        v-for="week in weeksInYear"
                        :ref="(el) => { if (selectedWeek === week-3 || selectedWeek + 3 > weeksInYear) scrollToWeekEle = el }"
                    >
                        {{ week }}
                    </th>
                </tr>
            </thead>
            <tbody class="styled-table-body">
                <tr class="styled-table-row" v-for="(type, index) of types">

                    <!-- TYPE LABEL-->
                    <td>{{ type.translate('name') }}</td>

                    <!-- PROGNOSIS -->
                    <td v-for="week in weeks">

                        <!-- FILLED IN PRODUCTION FORECAST -->
                        <div
                            @click="openCreateEditProductionForeast(week, type.id)"
                            v-if="takeProductionForecastByWeekAndType(week.isoWeek(), type.id)"
                            :id="`${week.isoWeek()}-${type.id}`"
                        >
                            <span class="clickable-span">
                                {{ presentProductionForecastAmount(week.isoWeek(), type.id) }}
                            </span>
                            <div class="mini-bar-holder">
                                <div class="mini-bar" :style="`width: ${((takeProductionPerWeek(week, type.id) / presentProductionForecastAmount(week.isoWeek(), type.id)) *100)}%;`"></div>
                            </div>
                        </div>

                        <!-- FUTURE -->
                        <template v-else>
                            <span class="clickable-span sale-forecast" @click="openCreateEditProductionForeast(week, type.id)">{{ getSaleHistoryOfWeekAndType(saleHistory.get(String(week.isoWeek()).padStart(2, '0')), type.id , String(week.isoWeek()).padStart(2, '0')) }}</span>
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <WeekOverview
        :types="types"
        :productionPerDay="productionPerDay"
        :productionForecasts="currentsWeekProductionForecasts"
        :year="selectedYear"
        :week="selectedWeek"
        :saleHistory="saleHistory.get(String(selectedWeek).padStart(2, '0'))"
    />

    <CreateEditProductionForecast
        :data="createEditProductionForecast"
        :weeklyProductionForecasts="weeklyProductionForecasts"
        @stored="productionForecastStored()"
        @close="createEditProductionForecast = null"
    />
</template>

<script setup>
    import moment from 'moment';
    import { ref, computed, onMounted } from 'vue';
    import collect from 'collect.js';
    import orderService from '@/services/http/order-service';
    import productionForecastService from '@/services/http/production-forecast-service';
    import CreateEditProductionForecast from './Index/CreateEditProductionForecast.vue';
    import WeekOverview from './Index/WeekOverview.vue';

    const emit = defineEmits(['breadcrumbs']);

    const refresh = function() {
        return Promise.all([
            getProductionForecasts(),
            getSaleHistory(),
            getProductionPerWeek()
        ])
    }

    /*
    |--------------------------------------------------------------------------
    | Dates
    |--------------------------------------------------------------------------
    */
    const now = ref(moment());

    // Page year filter.
    const selectedYear = ref(moment().isoWeekYear());
    const filterableYears = computed(() => {
        const years = [];
        for (let year = 2022; year <= now.value.isoWeekYear()+1; year++) {
            years.push(year);
        }

        return years;
    })

    // Page week filter.
    const selectedWeek = ref(moment().isoWeek());
    const weeksInYear = computed(() => moment(`${selectedYear.value}-06-01`, 'YYYY-MM-DD').isoWeeksInYear());

    // Start of selected year.
    const startDate = computed(() => {
        return moment(`${selectedYear.value}-06-01`, 'YYYY-MM-DD').isoWeek(1).startOf('isoWeek');
    });

    // End of selected year.
    const endDate = computed(() => {
        return moment(`${selectedYear.value}-06-01`, 'YYYY-MM-DD').isoWeek(weeksInYear.value).endOf('isoWeek');
    });

    // All weeks in year as array.
    const weeks = computed(() => {
        const date = startDate.value;
        const weeks = [];
        for (let weekNumber = 1; weekNumber <= weeksInYear.value; weekNumber++) {
            weeks.push(date.clone().isoWeek(weekNumber));
        }

        return weeks;
    });

    // Event: After selected year changes.
    const yearChanged = function(){
        if (selectedWeek.value > weeksInYear.value) {
            selectedWeek.value = 52;
        }

        refresh();
    }

    // Event: After selected week changes.
    const weekChanged = function() {
        getProductionPerDay();
    }

    /*
    |--------------------------------------------------------------------------
    | Sale history
    |--------------------------------------------------------------------------
    */
    const saleHistory = ref(collect());
    const getSaleHistory = function() {
        return orderService.getSaleHistory(startDate.value, endDate.value, ['type_id', 'week'], ['average_sold'])
            .then((results) => {
                saleHistory.value = collect(results).groupBy('week');
            });
    }

    // Get saleHistory of the given 'typeId' from the given 'saleHistories of the week'
    const getSaleHistoryOfWeekAndType = function(weeklySaleHistory, typeId) {
        if (! weeklySaleHistory) return '-';
        const sh = weeklySaleHistory.firstWhere('type_id', typeId);

        return sh ? Math.round(sh.average_sold) : '-';
    }

    /*
    |--------------------------------------------------------------------------
    | Production Forecast
    |--------------------------------------------------------------------------
    */
    const productionForecasts = ref(collect());
    const weeklyProductionForecasts = ref(collect());
    const getProductionForecasts = function() {
        return productionForecastService.get({
            filters: {year_is: selectedYear.value, orderBy: 'updated_at'},
            include: ['user']
        })
        .then((productionForecastModels) => {
            productionForecasts.value = productionForecastModels;
            weeklyProductionForecasts.value = productionForecastModels.groupBy((pf) => pf.weekNumber);
        });
    }

    // Present filled in harvested/expected amount of productionForecast on given 'week' and 'typeId'.
    const presentProductionForecastAmount = function(week, typeId, expected = true) {
        const productionForecast = takeProductionForecastByWeekAndType(week, typeId, expected);

        return productionForecast ? productionForecast.amount : '-';
    }


    // Get filled in harvested/expected amount of productionForecast on given 'week' and 'typeId'.
    const takeProductionForecastByWeekAndType = function(week, typeId, expected = true) {
        const pfs = weeklyProductionForecasts.value.get(week);
        if (! pfs) {
            return null;
        }

        return pfs.filter(pf => (
            pf.typeId === typeId && pf.weekNumber === week && pf.isExpected === expected
        )).last();
    }

    // Current active Production Forecast
    const currentsWeekProductionForecasts = computed(() => {
        const pfs = weeklyProductionForecasts.value.get(selectedWeek.value);
        if (! pfs) return collect();

        return pfs;
    })


    /*
    |--------------------------------------------------------------------------
    | Created/Edit production Forecast
    |--------------------------------------------------------------------------
    */
    const createEditProductionForecast = ref(null);
    const openCreateEditProductionForeast = function(week, typeId) {
        createEditProductionForecast.value = {week, typeId, year: selectedYear.value};
    }

    // Event: Production Forecast stored
    const productionForecastStored = function() {
        const id = createEditProductionForecast.value.week.isoWeek() + '-' + createEditProductionForecast.value.typeId;

        createEditProductionForecast.value = null;
        refresh().then(() => {
            document.getElementById(id).classList.add('updated');
            setTimeout(() => {
                document.getElementById(id).classList.remove('updated')
            }, 5000);
        });
    }

    /*
    |--------------------------------------------------------------------------
    | Production
    |--------------------------------------------------------------------------
    */
    import harvestRegistrationService from '@/services/http/harvest-registration-service';

    const productionPerWeek = ref(collect());
    const getProductionPerWeek = function() {
        return harvestRegistrationService.getProductionPerWeek(startDate.value, endDate.value).then(productionPerWeekData => {
            productionPerWeek.value = collect(productionPerWeekData).groupBy('week');
        });
    }

    // Get production of given 'week' and 'typeId'.
    const takeProductionPerWeek = function(week, typeId) {
        let productionWeek = productionPerWeek.value.get(week.isoWeekYear() + (String(week.isoWeek()).padStart(2, '0')))

        if (! productionWeek) {
            return 0;
        }

        productionWeek = productionWeek.first(ppw => {
            return ppw.type_id === typeId
        });

        if (! productionWeek) {
            return 0;
        }

        return productionWeek.production;
    }

    // Get and set production per day of the selected week.
    const productionPerDay = ref(collect());
    const getProductionPerDay = function() {
        const from = moment(`${selectedYear.value}-06-01`, 'YYYY-MM-DD').isoWeek(selectedWeek.value).startOf('isoWeek');
        const till = from.clone().endOf('isoWeek');

        return harvestRegistrationService.getProductionPerDay(from, till).then(productionPerDayData => {
            productionPerDay.value = collect(productionPerDayData);
        });
    }
    getProductionPerDay();

    /*
    |--------------------------------------------------------------------------
    | Types
    |--------------------------------------------------------------------------
    */
    import typeService from '@/services/http/type-service';
    const types = ref(collect());
    const getTypes = function() {
        typeService.get()
        .then((typeModels) => {
            types.value = typeModels;
        });
    }
    getTypes();

    /*
    |--------------------------------------------------------------------------
    | Other
    |--------------------------------------------------------------------------
    */
    const scrollToWeekEle = ref(null);
    onMounted(() => {
        scrollToWeekEle.value.scrollIntoView({ behavior: "instant" });
    })

    refresh();

    emit('breadcrumbs', []);
</script>
