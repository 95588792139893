import axios from 'axios';
import FormulaFactor from '../models/formula-factor';
import harvestAssignmentService from '../services/http/harvest-assignment-service';
import { ref, computed } from 'vue';
import calculateHarvestAssignmentTimes from './../helpers/calculate-harvest-assignment-times';
import { defineStore } from 'pinia'
import moment from 'moment';
import { collect } from 'collect.js';

export const useHarvestAssignmentStore = defineStore('harvest-assignments', () => {

    const harvestAssignments = ref([]);
    const allHarvestAssignments = ref([]);
    const formulaFactors = ref([]);

    const defaultSort = (a, b) => {
        if (a.rush > b.rush) {
            return -1;
        }

        if (b.rush > a.rush) {
            return 1;
        }

        return ((a.sequence < b.sequence) ? -1 : 1)
    };

    const openHarvestAssignments = computed(() => {
        let users = [];
        let openHarvestAssignments = _.cloneDeep(harvestAssignments.value).filter(ha => ! ha.done()).sort(defaultSort);

        for (let harvestAssignment of openHarvestAssignments) {
            for (let user of harvestAssignment.users) {
                if (! users.map(u => u.id).includes(user.id)) {
                    users.push(user);
                }
            }
        }

        calculateHarvestAssignmentTimes(openHarvestAssignments, users, formulaFactors.value);

        return openHarvestAssignments;
    });

    const todaysOpenHarvestAssignments = computed(() => {
        return openHarvestAssignments.value.filter(ha => ha.plannedAt.isSameOrBefore(moment(), 'day'));
    });
    const completedJFHarvestAssignments = computed(() => {
        return harvestAssignments.value.filter(ha => (ha.done())).sort(defaultSort)
    });
    const rushHarvestAssignments = computed(() => {
        return openHarvestAssignments.value.filter(ha => ha.rush == 1 && ha.new == 0 && (! ha.done()));
    });
    const assignedHarvestAssignments = computed(() => {
        return openHarvestAssignments.value.filter(ha => ha.rush == 0 && ha.new == 0 && (! ha.done()));
    });
    const newHarvestAssignments = computed(() => {
        return harvestAssignments.value.filter(ha => ha.rush == 0 && ha.new == 1 && (! ha.done()));
    });
    const unassignedHarvestAssignments = computed(() => {
        return openHarvestAssignments.value.filter(ha => ha.users.count() === 0 && (! ha.done()));
    });


    /*
    |--------------------------------------------------------------------------
    | Altered harvest assignments
    |--------------------------------------------------------------------------
    */
    const alteredHarvestAssignment = ref(null);
    const alteredOpenHarvestAssignments = computed(() => {
        if (! alteredHarvestAssignment.value) {
            return openHarvestAssignments;
        }

        let users = [];
        let openHarvestAssignments = _.cloneDeep(allHarvestAssignments.value).filter(ha => ! ha.done()).sort(defaultSort);

        /* openHarvestAssignments.splice(
            openHarvestAssignments.search((ha) => alteredHarvestAssignment.value.id === ha.id),
            1,
            alteredHarvestAssignment.value
        ); */

        for (let harvestAssignment of openHarvestAssignments) {
            for (let user of harvestAssignment.users) {
                if (! users.map(u => u.id).includes(user.id)) {
                    users.push(user);
                }
            }
        }

        calculateHarvestAssignmentTimes(openHarvestAssignments, users, formulaFactors.value);

        return openHarvestAssignments;
    });

    const setAlteredHarvestAssignment = function(harvestAssignment) {
        alteredHarvestAssignment.value = harvestAssignment;
    }



    const workingOn = computed(() => {
        const workingOn = {};

        for (const harvestAssignment of harvestAssignments.value) {
            let users = harvestAssignment.users.filter(user => (! [undefined, 0, null].includes(user.pivot.starts_at)));

            for (let user of users) {
                if (
                    (! (user.id in workingOn) || workingOn[user.id].date < user.pivot.starts_at)
                    && user.activeWorkSession !== null
                ) {
                    workingOn[user.id] = {
                        id: harvestAssignment.id,
                        location: harvestAssignment.location,
                        date: user.pivot.starts_at
                    };
                }
            }
        }

        return workingOn;
    });

    const harvestAssignmentUsers = computed(() =>
    {
        let users = [];

        for (let harvestAssignment of openHarvestAssignments.value) {
            let busyUsers = harvestAssignment.users.filter(u => u.busyUntil !== undefined);

            for (let user of busyUsers) {
                if (users.findIndex(u => u.id === user.id) < 0) {
                    users.push(user);
                } else if (users.find(u => u.id === user.id).busyUntil < user.busyUntil) {
                    users[users.findIndex(u => u.id = user.id)] = user;
                }
            }
        }

        return users;
    });

    const getHarvestAssignments = function() {
        return harvestAssignmentService.get({
            filters: {0: 'non_complete_or_completed_today', 'with_last_harvest': 1},
            include: ['defaultWith']
        }).then(harvestAssignmentObjects => {
            allHarvestAssignments.value = harvestAssignmentObjects;
            harvestAssignments.value = harvestAssignmentObjects.filter((ha) =>
                ha.plannedAt.isSameOrBefore(new Date(), 'day')
            );
        });
    }

    const completeHarvestAssignment = async (id) => await axios.post('harvest-assignments/' + id + '/complete');

    const deleteHarvestAssignment = async (id) => await axios.delete('harvest-assignments/' + id);

    const storeHarvestAssignment = (data) => axios.post('harvest-assignments', data);

    const updateHarvestAssignment = async (id, data) => await axios.put('harvest-assignments/' + id, data);

    const move = function(from_id, to_id, to_rush) {
        return axios.post('harvest-assignments/move', {
            id: from_id,
            to_id,
            rush: to_rush
        });
    };

    const moveHarvestAssignments = function(data) {
        for (const harvestAsssignment of data) {
            let ha = harvestAssignments.value.first(ha => parseInt(ha.id) === parseInt(harvestAsssignment.id));

            if (ha) {
                ha.sequence = harvestAsssignment.sequence;
                ha.rush = harvestAsssignment.rush;
                ha.new = harvestAsssignment.new;
            }
            ha = allHarvestAssignments.value.first(ha => parseInt(ha.id) === parseInt(harvestAsssignment.id));
            if (ha) {
                ha.sequence = harvestAsssignment.sequence;
                ha.rush = harvestAsssignment.rush;
                ha.new = harvestAsssignment.new;
            }
        }
    };

    const setHarvestAssignments = (harvestAssignmentObjects) => (harvestAssignments.value = harvestAssignmentObjects);
    const setAllHarvestAssignments = (harvestAssignmentObjects) => (allHarvestAssignments.value = harvestAssignmentObjects);
    const addHarvestAssignment = (id) => {
        return harvestAssignmentService.first({
            filters: {'with_last_harvest': 1, 'id_is': id},
            include: ['defaultWith']
        }).then(harvestAssignment => {
            allHarvestAssignments.value.push(harvestAssignment);
            harvestAssignments.value.push(harvestAssignment);
        });
    };
    const editHarvestAssignment = (id) => {
        return harvestAssignmentService.first({filters: {'with_last_harvest': 1, 'id_is': id}, include: ['defaultWith']}).then(harvestAssignment => {
            let index = harvestAssignments.value.search(ha => ha.id === harvestAssignment.id);
            if (index !== false) {
                harvestAssignments.value.splice(index, 1, [harvestAssignment]);
            }

            index = allHarvestAssignments.value.search(ha => ha.id === harvestAssignment.id);
            if (index !== false) {
                allHarvestAssignments.value.splice(index, 1, [harvestAssignment]);
            }
        });
    };
    const removeHarvestAssignment = (id) => {
        harvestAssignments.value = harvestAssignments.value.filter(harvestAssignment => harvestAssignment.id !== parseInt(id));
        allHarvestAssignments.value = allHarvestAssignments.value.filter(harvestAssignment => harvestAssignment.id !== parseInt(id));
    };


    const getFormulaFactors = function()
    {
        axios.get('/formula-factors').then((response) => {
            for (let formulaFactorData of response.data.data) {
                let formulaFactor = new FormulaFactor();
                formulaFactor.setAttributesFromResponse(formulaFactorData);
                formulaFactors.value.push(formulaFactor);
            }
        } );

    };
    getFormulaFactors();

    return {
        // State
        harvestAssignments, allHarvestAssignments, alteredOpenHarvestAssignments,

        alteredHarvestAssignment, setAlteredHarvestAssignment,

        // Getters
        openHarvestAssignments, todaysOpenHarvestAssignments, completedJFHarvestAssignments, rushHarvestAssignments,
        assignedHarvestAssignments, newHarvestAssignments, unassignedHarvestAssignments,
        workingOn, harvestAssignmentUsers,

        // Actions (with api)
        getHarvestAssignments, completeHarvestAssignment, deleteHarvestAssignment, storeHarvestAssignment, updateHarvestAssignment, move,

        // Actions (mutations)
        moveHarvestAssignments, setHarvestAssignments, setAllHarvestAssignments, addHarvestAssignment,
        editHarvestAssignment, removeHarvestAssignment
    }
});
