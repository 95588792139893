import {AbstractModel} from './abstract-model.js'
import Order from './order.js';
import Article from './article.js';

export default class OrderLine extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            external_id: null,
            order_id: null,
            article_id: null,
            amount: null,
            cents: null,
            article: null,
            order: null,
            amount_done: 0,
            status: null
        }
    }

    amountForStock() {
        return - (this.remainingAmount() / this.article.conversion());
    }

    remainingAmount()
    {
        return this.amount - (this.amount_done !== undefined ? this.amount_done : this.amountDone);
    }

    done()
    {
        return (
            ! this.article.isHarvestable()
            || (this.amount_done === undefined ? this.amountDone : this.amount_done ) >= this.amount
        );
    }

    getTransformers()
    {
        return {
            article: Article,
            order: Order,
        }
    }

    getCcst()
    {
        return {
            chest_id: this.chest_id,
            content_id: this.content_id,
            size_id: this.size_id,
            type_id: this.type_id
        }
    }

    presentStatus() {
        return {
            "inStock": "Voorraad",
            "transporting": "Transport",
            "harvesting": "Oogsten",
            "sorted": "Ingedeeld",
            "new": "Nieuw",
            "unknown": "Onbekend",
            "in_progress": "In behandeling",
            "done": "Gereed",
            "finished": "Afgerond"
        }[this.status]
    }

    bunches()
    {
        return this.amount * this.getAmountOfBunches();
    }

    bunchesDone()
    {
        return this.amount_done * this.getAmountOfBunches();
    }

    getAmountOfBunches()
    {
        return (this.article.content !== null ? this.article.content.amount() : 0);
    }

    // V2
    relations()
    {
        return {
            article: Article,
            order: Order
        }
    }

    relationPlural()
    {
        return {
            article: false,
            order: false
        }
    }
}
