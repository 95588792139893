import HarvestRegistration from "../../models/harvest-registration";
import { AbstractHttpService } from "./abstract-http-service";
import moment from 'moment';

class HarvestRegistrationService extends AbstractHttpService {

    endpoint() {
        return 'harvest-registrations';
    }

    createModel(data) {
        return new HarvestRegistration(data);
    }

    getProductionPerWeek(from, till) {
        if (from.isAfter(moment(), 'isoWeek')) {
            return Promise.resolve(collect());
        }

        if (till.isAfter(moment(), 'isoWeek')) {
            till = moment().endOf('isoWeek');
        }

        let url = this.makeUrl({filters: {
            from: from.clone().subtract(1, 'week').format('YYYY-MM-DD'),
            till: till.clone().add(1, 'week').format('YYYY-MM-DD')
        }}, 'production-per-week');

        return axios.get(url).then(data => {
            return data.data;
        });
    }

    getProductionPerDay(from, till) {
        let url = this.makeUrl({filters: {
            from: from.clone().format('YYYY-MM-DD'),
            till: till.clone().format('YYYY-MM-DD')
        }}, 'production-per-day');

        return axios.get(url).then(data => {
            return data.data;
        });
    }
}

export default new HarvestRegistrationService;
