<template>
    <modal @save="onsubmit()" @close="$emit('close')">
        <template v-slot:header>Document verwijderen</template>

        <p>
            Weet je zeker dat je dit document wilt verwijderen?
        </p>

        <template v-slot:close>Annuleren</template>
        <template v-slot:save>Doorgaan</template>
    </modal>
</template>

<script>
    import { useToast } from 'vue-toast-notification';
    export default{
        props: ['document'],

        methods: {
            onsubmit() {
                axios.delete('/users/' + this.document.user_id + '/documents/' + this.document.id)
                .then(response => {
                    useToast().success('Document verwijderd');
                    this.$emit('deleted', this.document.id);
                    document.querySelector('body').classList = '';
                })
            }
        }
    };
</script>
