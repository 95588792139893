<template>
    <button style="margin-left: 10px;" type="button" v-on:click="$emit('test')">
        <fab-button style="background-color: darkgreen" icon="arrow-down-tray"></fab-button>
    </button>
</template>

<script>
    import moment from 'moment';
    import XLSX from 'xlsx'

    export default {
        props: ['data', 'compareData', 'compare', 'subjects', 'mode', 'from', 'till', 'compareFrom', 'compareTill', 'type', 'articles', 'locations'],

        watch: {},

        methods: {

            filterMode(subject, typeOrProduct) {
                return (
                    this.mode === 'articles'
                    && subject.content_id === typeOrProduct.content_id
                    && subject.chest_id === typeOrProduct.chest_id
                    && subject.size_id === typeOrProduct.size_id
                    && subject.type_id === typeOrProduct.type_id
                    ||
                    this.mode === 'types'
                    && subject.type_id === typeOrProduct.id
                )
            },

            async onexport () {

                const result = await this.queryHarvestRegistrations(this.from, this.till);
                /* const data = [];

                for (let subject of this.subjects) {
                    let dataItem = {
                        Naam: subject.name,
                        Aantal: this.data
                            .filter((fd) => {
                                return (
                                    this.mode === 'articles'
                                    && subject.content_id === fd.content_id
                                    && subject.chest_id === fd.chest_id
                                    && subject.size_id === fd.size_id
                                    && subject.type_id === fd.type_id
                                    ||
                                    this.mode === 'types'
                                    && subject.id === fd.type_id
                                )
                            })
                            .map((fd) => {
                                return (parseInt(fd.amount) * (fd.content_id !== 28 ? fd.content_id : 1));
                            })
                            .reduce((a, b) => a + b, 0)
                    }

                    if (this.compare) {
                        dataItem['Vergelijk aantal'] = this.compareData
                            .filter((fd) => {
                                return (
                                    this.mode === 'articles'
                                    && subject.content_id === fd.content_id
                                    && subject.chest_id === fd.chest_id
                                    && subject.size_id === fd.size_id
                                    && subject.type_id === fd.type_id
                                    ||
                                    this.mode === 'types'
                                    && subject.id === fd.type_id
                                )
                            })
                            .map((fd) => {
                                return (parseInt(fd.amount) * (fd.content_id !== 28 ? fd.content_id : 1));
                            })
                            .reduce((a, b) => a + b, 0)
                    }

                    data.push(dataItem);
                }
                let dataItem = {
                    Naam: 'Totaal',
                    Aantal: this.data.map((fd) => {
                        return (parseInt(fd.amount) * (fd.content_id !== 28 ? fd.content_id : 1));
                    }).reduce((a, b) => a + b, 0)
                };


                if (this.compare) {
                    dataItem['Vergelijk aantal'] = this.compareData.map((fd) => {
                        return (parseInt(fd.amount) * (fd.content_id !== 28 ? fd.content_id : 1));
                    }).reduce((a, b) => a + b, 0);
                }

                data.push({Naam: '', Aantal: ''});
                data.push({Naam: 'Van', Aantal: this.from});
                data.push({Naam: 'Tot', Aantal: this.till});

                if (this.compare) {
                    data.push({Naam: 'Vergelijk van', Aantal: this.compareFrom});
                    data.push({Naam: 'Vergelijk tot', Aantal: this.compareTill});
                }

                data.push({Naam: 'Type', Aantal: this.type});
                data.push({Naam: 'Artikelen', Aantal: this.articles.length > 0 ? this.articles.map(a => a.name).join(', ') : 'Geen'});
                data.push({Naam: 'Locaties', Aantal: this.locations.filter(l => l !== undefined).length > 0 ? this.locations.filter(l => l !== undefined).map(a => a.name).join(', ') : 'Geen'});

                // On Click Excel download button

                // export json to Worksheet of Excel
                // only array possible
                var workSheet = XLSX.utils.json_to_sheet(data);

                // A workbook is the naam given to an Excel file
                var workBook = XLSX.utils.book_new() // make Workbook of Excel

                // add Worksheet to Workbook
                // Workbook contains one or more worksheets
                XLSX.utils.book_append_sheet(workBook, workSheet, 'Oogst totalen') // sheetAnaam is naam of Worksheet

                // export Excel file
                //XLSX.writeFile(workBook, this.naam) */
            },

            queryHarvestRegistrations(from, till)
            {
                const filters = {
                    after: from + ' 00:00:00',
                    before: till + ' 23:59:59',
                    'group-by': 'chest_id,content_id,size_id,type_id,location_id,DATE(`date`)'
                };

                const queryString = new URLSearchParams(filters).toString();

                let url = '/harvest-registrations/group-by?';

                return axios.get(url + queryString)
                .then(response => {
                    for (let dataItem of response.data) {
                        dataItem['name'] = this.name(dataItem);
                        dataItem['id'] = Math.floor(Math.random() * 1000);
                    }

                    return response.data;
                });
            },
        },


        data() {
            return {
                naam: `Oogsttotalen_${moment(this.from, 'DD-MM-YYYY').format('GGGG-WW')}_${moment(this.till, 'DD-MM-YYYY').format('GGGG-WW')}.xlsx`
            }
        },

        created()
        {
        }
    }
</script>
