import {AbstractModel} from './abstract-model.js'

export default class Pallet extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            name: null
        }
    }

}