<template>
    <div class="login-container">
        <div class="login-column">
            <div class="login-content-container right-half shadow-lvl-5">
                <div class="login-content">
                    <div class="jongfresh-login-logo"></div>

                    <div class="explain-block">
                        Vul je e-mailadres in
                    </div>

                    <form @submit.prevent="onSubmit">

                        <div class="form-group">
                            <label for="email">
                                <input id="email" type="text" autofocus class="form-control" v-model="form.email" placeholder="E-mailadres" v-bind:class="{ 'is-invalid': form.errors.has('email') }">
                                <div class="invalid-feedback" v-if="form.errors.has('email')" v-text="getEmailError(form.errors.get('email'))"></div>
                            </label>
                        </div>

                        <button class="btn btn-block btn-success login-button">
                            Versturen
                        </button>

                        <a @click="$router.go(-1)" class="login-sub-button">
                            Terug
                        </a>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Form from './../helpers/form.js';
    import { useToast } from 'vue-toast-notification';

    export default {

        data() {
            return {
                form: new Form({
                    email: ''
                })
            }
        },

        methods: {
            onSubmit() {
                this.form.post('forgot-password/email')
                .then(response => {
                    this.$router.push({path: '/', query: {'forgot-password': 1}});
                    useToast().success('E-mail verstuurd');
                })
            },

            getEmailError()
            {
                if (this.form.errors.get('email') == 'The selected E-mailadres is invalid.') {
                    return 'Het e-mailadres is niet bekend.';
                } else if (this.form.errors.get('email') == 'The E-mailadres must be a valid email address.') {
                    return 'Het e-mailadres is geen geldig e-mailadres';
                } else {
                    return this.form.errors.get('email');
                }
            }

        }
    }
</script>
