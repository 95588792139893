<template>

</template>

<script>
    export default {
        props: {
            crumbs: {
                type: Array,
                required: true
            }
        },

        setup() {

            const goTo = function(crumb) {
                /* if (this.$route.path !== crumb.path){
                    this.$router.push(crumb.path);
                } */
            }

            return goTo;
        }
    }
</script>
