import {AbstractModel} from './abstract-model.js'

export default class ReviewCategory extends AbstractModel {

    getDefaults() {
        return {
            id: null,
        }
    }
    
    translation() {
        return 'review-category';
    }
}