<template>
    <modal :large="true" @save="save()" @close="$emit('close')">
        <template v-slot:header>Profielfoto wijzigen</template>
        <Cropper ref="cropper" :src="image" :stencil-props="{
            aspectRatio: 1/1,
        }" />
            <!-- <vue-cropper
                :src="image"
                :aspectRatio="1"
                :viewMode="1"
                :zoomable="false"
                ref='cropper'
                :ready="init"
            >
            </vue-cropper> -->
            <!-- <vue-cropper
                v-if="image"
                :src="image"
                :aspectRatio="1"
                :viewMode="1"
                :zoomable="false"
                ref='cropper'
            >
            </vue-cropper> -->

        <div class="toolbar">
            <button class="toolbar__button" @click="rotate(-90)" data-action="rotate-left" title="Rotate Left (L)">
                <Icon name="arrow-uturn-left" type="solid" style="width: 24px; height: 24px" />
            </button>
            <button class="toolbar__button" @click="rotate(90)" data-action="rotate-right" title="Rotate Right (R)">
                <Icon name="arrow-uturn-right" type="solid" style="width: 24px; height: 24px"  />
            </button>
        </div>
    </modal>
</template>

<script setup>
    import { Cropper } from 'vue-advanced-cropper';
    import 'vue-advanced-cropper/dist/style.css';
    import { ref } from 'vue';
    import userService from '@/services/http/user-service-new';

    const props = defineProps(['user']);
    const emit = defineEmits(['close', 'updated', 'updating']);

    const image = ref(null);
    const cropImage = ref(null);
    const cropper = ref(null);

    const rotate = function(amount) {
        cropper.value.rotate(amount);
    }

    const save = function() {
        const { coordinates, canvas } = cropper.value.getResult();

        const rotatedElement = document.querySelector('img.vue-advanced-cropper__image');
        const cssTransform = rotatedElement.style.transform;
        const rotateStart = cssTransform.indexOf('rotate(') + 7;
        const rotateEnd = cssTransform.indexOf('deg)', rotateStart);
        const rotation = cssTransform.substring(rotateStart, rotateEnd)

        //this.$emit('edited', canvas.toDataURL());
        emit('updating');
        document.querySelector('body').classList = '';

        userService.edit(props.user.id + '/avatars', {
            cropTop: coordinates.top,
            cropLeft: coordinates.left,
            cropHeight: coordinates.height,
            cropWidth: coordinates.width,
            rotate: -rotation
        }, {include: ['mediumAvatar']}).then(user => {
            emit('updated', user);
        });
    }

    axios.get('users/' + props.user.id + '/avatar/original').then(
        data => {
            image.value = data.data
        }
    );

</script>
