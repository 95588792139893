import moment from 'moment';
import { collect } from "collect.js";
import settingService from '../../../services/http/setting-service';

export default {

    data() {
        return {
            f: moment().subtract(1, 'month').startOf('isoweek').format('YYYY-MM-DD'),
            t: moment().add(3, 'month').endOf('isoweek').format('YYYY-MM-DD'),

            from: null,
            till: null,

            lastYearFrom: null,
            lastYearTill: null,

            twoYearFrom: null,
            twoYearTill: null,

            threeYearFrom: null,
            threeYearTill: null,

            trendFrom: null,
            trendTill: null,

            lastYearTrendFrom: null,
            lastYearTrendTill: null,

            today: moment(),

            weeklyTrend: 0
        }
    },

    computed: {
        weeks() {
            let weeks = [];

            if (this.from) {
                for (let weekNr = 0; weekNr <= this.till.diff(this.from, 'week'); weekNr++) {
                    let week = {
                        current: this.from.clone().add(weekNr, 'week'),
                        prev: this.lastYearFrom.clone().add(weekNr, 'week'),
                        prevTwo: this.twoYearFrom.clone().add(weekNr, 'week'),
                        prevThree: this.threeYearFrom.clone().add(weekNr, 'week')
                    };
                    weeks.push(week);
                }
            }
            return weeks;
        },

        betweens() {
            return [{
                from: this.from.format('YYYY-MM-DD HH:mm:ss'),
                till: this.till.format('YYYY-MM-DD HH:mm:ss')
            }, {
                from: this.lastYearFrom.format('YYYY-MM-DD HH:mm:ss'),
                till: this.lastYearTill.format('YYYY-MM-DD HH:mm:ss')
            }, {
                from: this.trendFrom.format('YYYY-MM-DD HH:mm:ss'),
                till: this.trendTill.format('YYYY-MM-DD HH:mm:ss')
            }, {
                from: this.lastYearTrendFrom.format('YYYY-MM-DD HH:mm:ss'),
                till: this.lastYearTrendTill.format('YYYY-MM-DD HH:mm:ss')
            }, {
                from: this.twoYearFrom.format('YYYY-MM-DD HH:mm:ss'),
                till: this.twoYearTill.format('YYYY-MM-DD HH:mm:ss')
            }, {
                from: this.threeYearFrom.format('YYYY-MM-DD HH:mm:ss'),
                till: this.threeYearTill.format('YYYY-MM-DD HH:mm:ss')
            }];
        },
    },

    methods: {
        setDates (from = null, till = null)
        {
            if (! from) {
                this.from = moment(this.f).startOf('isoWeek');
            }
            if (! till) {
                this.till = moment(this.t).endOf('isoWeek');
            }

            this.lastYearFrom = moment(`06-${this.from.isoWeekYear()-1}`, 'MM-YYYY').isoWeek(this.from.isoWeek()).startOf('isoWeek');
            this.lastYearTill = moment(`06-${this.till.isoWeekYear()-1}`, 'MM-YYYY').isoWeek(this.till.isoWeek()).endOf('isoWeek');

            this.twoYearFrom = moment(`06-${this.from.isoWeekYear()-2}`, 'MM-YYYY').isoWeek(this.from.isoWeek()).startOf('isoWeek');
            this.twoYearTill = moment(`06-${this.till.isoWeekYear()-2}`, 'MM-YYYY').isoWeek(this.till.isoWeek()).endOf('isoWeek');

            this.threeYearFrom = moment(`06-${this.from.isoWeekYear()-3}`, 'MM-YYYY').isoWeek(this.from.isoWeek()).startOf('isoWeek');
            this.threeYearTill = moment(`06-${this.till.isoWeekYear()-3}`, 'MM-YYYY').isoWeek(this.till.isoWeek()).endOf('isoWeek');

            if (this.from.isAfter(this.today)) {
                this.trendFrom = moment().subtract(this.weeklyTrend, 'week').startOf('isoWeek');
                this.trendTill = moment().subtract(1, 'week').endOf('isoWeek');
            } else {
                this.trendFrom = this.from.clone().subtract(this.weeklyTrend, 'week').startOf('isoWeek');
                this.trendTill = this.from.clone().subtract(1, 'week').endOf('isoWeek');
            }

            this.lastYearTrendFrom = moment(`06-${this.trendFrom.isoWeekYear()-1}`, 'MM-YYYY').isoWeek(this.trendFrom.isoWeek()).startOf('isoWeek');
            this.lastYearTrendTill = moment(`06-${this.trendTill.isoWeekYear()-1}`, 'MM-YYYY').isoWeek(this.trendTill.isoWeek()).endOf('isoWeek');
        },

        customFormatter(date) {
            date = moment(date);

            if (date.isoWeekYear() !== moment().isoWeekYear()) {
                return date.isoWeekYear() + ' - Week ' + date.isoWeek();
            }

            return 'Week ' + date.isoWeek();
        },
        // ---------------------------------------------------------------------------------------------------------------------
        // Trend & Gains
        // ---------------------------------------------------------------------------------------------------------------------

        calculateTrend(gains) {
            let gainsThatCount = gains.filter(g => g !== null);

            if (gainsThatCount.length === 0) {
                return 1;
            }

            return 1+((gainsThatCount.reduce((a,b) => (a+b)) / gainsThatCount.length) / 100);
        },

        calculateGainsComparedToLastYear(bunches, lastYearBunches)
        {
            if (bunches === 0 || lastYearBunches === 0) {
                return null;
            }

            return (-((1- (bunches/lastYearBunches))*100));
        },

        getTrent() {
            return settingService.first({filters: {
                key_is: 'weekly_trend'
            }}).then(setting => {
                this.weeklyTrend = (setting === undefined ? 0 : parseInt(setting.value));
            });
        },
    }
}
