import {AbstractModel} from './abstract-model.js'
import FileModel from './file-model.js';
import moment from 'moment';

export default class Agreement extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            user_id: null,
            company: null,
            established_at: null,
            postal_code: null,
            address: null,
            btw: null,
            accepted_at: moment(),
            starts_at: moment(),
            ends_at: moment(),
            hourly_wage: null,

            file: null,
        }
    }

    getTransformers() {
        return {
            file: FileModel,
        }
    }

    isActive()
    {
        return (
            this.starts_at.format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')
            && this.ends_at >= moment().format('YYYY-MM-DD')
        );
    }

    getDateFormats() {
        return {
            date: 'YYYY-MM-DD'
        }
    }

    getMutators() {
        return {
            startsAt: 'datetime',
            endsAt: 'datetime'
        }
    }
}
