<template>
    <div class="widget-holder" v-if="unassignedHarvestAssignments.count() > 0">
        <div class="widget-header">
            <div class="widget-title">
                <router-link to="/harvest-assignments">
                    {{ unassignedHarvestAssignments.count() }} Bosopdracht{{ unassignedHarvestAssignments.count() > 1 ? 'en' : '' }} zonder medewerker
                </router-link>
            </div>
            <div class="widget-subtitle">{{ unassignedHarvestAssignments.map(ha => ha.amount).reduce((prev, next) => prev + next) }} kisten in totaal</div>
        </div>
        <div class="widget-content">
            <table class="styled-table">
                <tr class="styled-table-row" v-for="(harvestAssignment, index) of unassignedHarvestAssignments" :key="index">
                    <td>
                        {{ harvestAssignment.name() }}
                    </td>
                    <td class="text-right font-weight-light">
                        {{ harvestAssignment.amount }}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script setup>
    import { computed, ref, watch } from 'vue';
    import { useHarvestAssignmentStore } from "../../stores/harvestAssignments";

    const harvestAssignmentStore = useHarvestAssignmentStore();
    watch(() => harvestAssignmentStore.unassignedHarvestAssignments, (newVal) => {
        unassignedHarvestAssignments.value = newVal;
    })
    const unassignedHarvestAssignments = ref(harvestAssignmentStore.unassignedHarvestAssignments);
</script>
