<template>

    <!-- PERSONAL DATA -->
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="widget-holder">

                    <div v-if="form">
                        <form @submit.prevent="submitForm(form)">

                            <div class="widget-header">
                                <div class="widget-title">
                                    Onvoltooide orders e-mail
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="styled-checkbox">
                                    <label for="mail_uncompleted_orders">
                                        <input id="mail_uncompleted_orders" type="checkbox" v-model="form.mail_uncompleted_orders">
                                        <span>E-mail onvoltooide orders om 17:00</span>
                                    </label>
                                </div>

                                <label class="styled-label" for="uncompleted_orders_email">
                                    <span>Verzenden naar</span>
                                    <input id="uncompleted_orders_email" placeholder="E-mailadres" type="email" class="styled-input" v-model="form.uncompleted_orders_email">
                                </label>
                            </div>

                            <div class="widget-header mt-5">
                                <div class="widget-title">
                                    Order meldingen e-mail
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="styled-checkbox">
                                    <label for="mail_order_notifications">
                                        <input id="mail_order_notifications" type="checkbox" v-model="form.mail_order_notifications">
                                        <span>E-mail alle order meldingen van vandaag om 17:00</span>
                                    </label>
                                </div>

                                <label class="styled-label" for="order_notifications_email">
                                    <span>Verzenden naar</span>
                                    <input id="order_notifications_email" placeholder="E-mailadres" type="email" class="styled-input" v-model="form.order_notifications_email">
                                </label>
                            </div>

                            <div class="row mt-5">
                                <div class="col-3">
                                    <a @click="$router.go(-1)" class="btn btn-block btn-danger">
                                        Annuleren
                                    </a>
                                </div>
                                <div class="col-6">
                                </div>
                                <div class="col-3">
                                    <button class="btn btn-block btn-success">
                                        Opslaan
                                    </button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


    import settingService from '../../services/http/setting-service';
    import { useToast } from 'vue-toast-notification';

    export default {


        data() {
            return {
                form: {
                    mail_uncompleted_orders: '0',
                    uncompleted_orders_email: '',

                    mail_order_notifications: '0',
                    order_notifications_email: ''
                }
            }
        },

        methods: {
            getSettings()
            {
                settingService.get().then(settings => {
                    this.form.mail_uncompleted_orders = settings.firstWhere('key', 'mail_uncompleted_orders').value == '1' ? true : false;
                    this.form.uncompleted_orders_email = settings.firstWhere('key', 'uncompleted_orders_email').value;

                    this.form.mail_order_notifications = settings.firstWhere('key', 'mail_order_notifications').value == '1' ? true : false;
                    this.form.order_notifications_email = settings.firstWhere('key', 'order_notifications_email').value;
                });
            },

            submitForm(form)
            {
                settingService.create(form).then(settings => {
                    useToast().success('Instellingen opgeslagen');
                });
            }
        },

        created() {
            this.getSettings();

            this.$emit('breadcrumbs', [
                {label: 'Instellingen', to: '/settings'},
                {label: 'E-mails'}
            ])
        }
    }
</script>
