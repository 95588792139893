<template>
    <div @click="emit('select')" class="sub-graph" v-if="data.filter().count() > 0">
        <h3>{{ name }}</h3>
        <div class="graph-holder sold-graph-holder" v-if="data.count() > 0">

            <div class="y-labels">
                <div v-for="n in 6" class="y-label">
                    {{ formatNumber(Math.round(((maxValue/6) * (6-n+1)) / 10) * 10) }}
                </div>
                <div class="y-label">
                    0
                </div>
            </div>

            <div class="graph">
                <div class="sold-graph" :style="`width: ${(periods.length*64)+3}px`">
                    <div class="column" v-for="period of formattedPeriods">
                        <div class="bar-container">
                            <div
                                v-for="barDetails of allBarDetails"
                                v-wbtooltip:cursor="dataOfPeriod(period[barDetails.period]) ? formatNumber(dataOfPeriod(period[barDetails.period]).amount) : ''"
                                :class="barDetails.class" class="bar"
                                :style="`height: ${barHeight(period[barDetails.period])}%`"
                            >
                                <div class="inner-bar" style="height: 100%" :class="barClass"></div>
                            </div>
                        </div>
                        <div class="label-holder">
                            <div class="label" v-html="getLabel(period.period)"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import useCustomerInsightsFunctionalities from './../useCustomerInsightsFunctionalities';
    import { computed, onMounted } from 'vue'

    const props = defineProps(['name', 'data', 'periods', 'getLabel', 'getClassForRadish', 'formattedPeriods', 'years', 'barClass'])
    const emit = defineEmits(['select'])

    const {
        // Functions
        formatNumber, getMaxValueRounded
    } = useCustomerInsightsFunctionalities();

    const maxValue = computed(() => {
        return getMaxValueRounded(props.data.values().pluck('amount').max());
    });

    const barHeight = function(period) {
        if (! dataOfPeriod(period)) {
            return 0;
        }

        return ((dataOfPeriod(period).amount/maxValue.value) * 100);
    }

    const dataOfPeriod = function(period) {
        return props.data.first(di => (di.period+'') === period)
    }

    const allBarDetails = computed(() => {
        const details = [
            {class: 'current-bar', period: 'cur'},
            {class: 'prev-bar', period: 'prev'},
            {class: 'super-prev-bar', period: 'superPrev'},
        ];
        const arr = [];
        for (let i = 0; i < props.years; i++) {
            arr.push(details[i]);
        }

        return arr.reverse();
    });
</script>
