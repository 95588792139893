<template>
    <div v-if="users.count() > 0" class="alert alert-success">
        <Icon name="cake" type="solid" />
        De volgende gebruiker(s) zijn vandaag jarig: {{ users.map(u => u.name()).implode(', ') }}
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { collect } from "collect.js";
    import moment from 'moment';
    import UserService from '@/services/http/user-service.js';

    const users = ref(collect())
    const getUsers = function()
    {
        UserService.get({filters: {
            0: 'birthday',
            'only-real-persons': 1
        }}).then(userModels => {
            users.value = userModels;
        })
    }
    getUsers();


</script>
