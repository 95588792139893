<template>
    <div v-if="orderWithoutLoadingTime.count() > 0" class="widget-holder">

        <div class="widget-header">
            <div class="widget-title">
                <router-link to="/orders">{{ orderWithoutLoadingTime.count() }} orders zonder laadtijd</router-link>
            </div>
        </div>

        <div class="widget-content">
            <table class="styled-table">
                <tr class="styled-table-row" v-for="(order, index) of orderWithoutLoadingTime" :key="index">
                    <td>{{ order.customer.name }}</td>
                    <td width="25%" class="picked-up-at-holder">
                        <a class="set-time-button" v-if="editOrder !== order" @click="editOrder = order; pickedUpAt = '00:00'">
                            Laadtijd instellen
                        </a>
                        <a v-if="editOrder === order">
                            <timePicker class="edit-input" v-model="pickedUpAt"></timePicker>
                        </a>

                        <a class="edit-save" v-if="editOrder === order" @click="saveOrder()"><Icon name="check" /></a>
                        <a class="edit-cancel" v-if="editOrder === order" @click="editOrder = false"><Icon name="x-mark" /></a>
                    </td>
                    <td class="text-right font-weight-light">
                        {{ order.bunchesDone() }}/{{ order.bunches() }}
                    </td>
                </tr>
            </table>
        </div>

    </div>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import { useToast } from 'vue-toast-notification';

    /*
    |--------------------------------------------------------------------------
    | Orders without loading time
    |--------------------------------------------------------------------------
    */
    import { useOrderStore } from "../../stores/orders";
    const orderStore = useOrderStore();
    const orderWithoutLoadingTime = computed(() => orderStore.orderWithoutLoadingTime);


    /*
    |--------------------------------------------------------------------------
    | Edit order
    |--------------------------------------------------------------------------
    */
    const editOrder = ref(false);
    const pickedUpAt = ref('00:00');

    const saveOrder = async function() {
        await axios.put('orders/' + editOrder.value.id, {
            picked_up_at: (editOrder.value.pickedUpAt.format('YYYY-MM-DD') + ' ' + pickedUpAt.value + ':00')
        });

        useToast().success('Laadtijd gewijzigd');
    }
</script>
