import Errors from './errors.js';
import moment from 'moment';
import { Collection } from 'collect.js';

export default class Form {
    /**
     * Create a new Form instance.
     *
     * @param mixed data
     */
    constructor(data, model = null) {
        this.model = model;
        this.originalData = data;
        this.with_files = false;
        this.saving = false;

        if(this.model) {
            for (let field of data) {
                if (field in this.model) {
                    this[field] = this.model[field];
                } else {
                    this[field] = null;
                }
            }
        } else {
            for (let field in data) {
                this[field] = data[field];
            }
        }

        this.errors = new Errors();
    }

    add (key, value) {
        this[key] = value;
    }

    remove (key) {
        delete this[key];
    }


    /**
     * Fetch all relevant data for the form.
     */
    data(extraData = {}){
        let data = {};

        if(this.model) {
            for (let field of this.originalData) {
                if (typeof(this.model[field]) === 'object' && this.model[field] !== null && this.model[field].constructor !== Array && this.model[field].constructor !== Collection)  {
                    data[field] = this.model[field].id;
                } else {
                    if(typeof(this.model[field]) === 'object'&& this.model[field] !== null && this.model[field].constructor === Collection){
                        data[field] = this.model[field];
                    }
                    else if (this.model[field] !== null) {
                        data[field] = this.model[field];
                    }
                }
            }
        } else {
            for (let property in this.originalData) {
                data[property] = this[property];
            }
        }

        for (const property in extraData) {
            data[property] = extraData[property];
        }

        return data;
    }

    formData()
    {
        let data = new FormData();

        if(this.model) {

            for (let field of this.originalData) {
                if (typeof(this.model[field]) === 'object' && this.model[field] !== null && this.model[field].constructor !== Array) {

                    data.append(field, this.model[field]);
                } else {

                    if (this.model[field] !== null) {
                        data.append(field, this.model[field]);
                    }
                }
            }
        } else {
            for (let property in this.originalData) {
                if (this[property] !== null) {
                    data.append(property, this[property]);
                }
            }
        }

        return data;
    }


    /**
     * Send a POST request to the given URL.
     * .
     * @param {string} url
     */
    post(url, extraData = {}) {
        return this.submit('post', url, extraData);
    }


    /**
     * Send a PUT request to the given URL.
     * .
     * @param {string} url
     */
    put(url) {
        return this.submit('put', url);
    }


    /**
     * Send a PATCH request to the given URL.
     * .
     * @param {string} url
     */
    patch(url) {
        return this.submit('patch', url);
    }


    /**
     * Send a DELETE request to the given URL.
     * .
     * @param {string} url
     */
    delete(url) {
        return this.submit('delete', url);
    }


    /**
     * Submit the form.
     *
     * @param {string} requestType
     * @param {string} url
     */
    submit(requestType, url, extraData = {}) {
        if (! this.saving) {
            this.saving = true;
            let formData = this.with_files ? this.formData() : this.data();
            for (let index of Object.keys(formData)) {
                if (formData[index] instanceof Date) {
                    formData[index] = moment(formData[index]).format('YYYY-MM-DD HH:mm:ss');
                }
            }


            for (let index of Object.keys(extraData)) {
                formData[index] = extraData[index];
            }

            return new Promise((resolve, reject) => {
                axios[requestType](url, formData)
                .then(response => {
                    this.saving = false;
                    this.errors.clear();

                    resolve(response.data);
                })
                .catch(error => {
                    this.saving = false;
                    this.onFail(error.response.data);

                    reject(error.response.data);
                });
            });
        }
    }


    reset(originalData) {
        for (let field of this.originalData) {
            this.model[field] = originalData[field];
        }
    }

    /**
     * Handle a failed form submission.
     *
     * @param {object} errors
     */
    onFail(errors) {
        this.errors.record(errors);
    }
}
