import {AbstractModel} from './abstract-model.js'

export default class Role extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            name: null,
            default: 1,
            permissions: []
        }
    }

    translation() {
        return 'Role';
    }

    nameTranslated() {
        if (this.default == 1) {
            return this.translate(this.name);
        } else {
            return this.name
        }
    }

    translate(field) {
        const translations =  {
            "admin": "Beheerder",
            "teamleader": "Teamleider",
            "harvest-worker": "Oogstmedewerker",
            "transporter": "Transporteur",
            "washer": "Spoeler",
            "cleaner": "Schoonmaker",
            "packer": "Inpakker",
            "unloader": "Uitlader",
            "stock-manager": "Voorraadbeheerder",
            "optimus-prime": "Optimus prime",
            "minis-harvest-worker": "Mini's oogster"
        }

        if (! (this[field] in translations)) {

            return this[field];
        }

        return translations[this[field]];
    }

}
