import {AbstractModel} from './abstract-model.js'
import Task from './task.js'
import User from './user.js'
import moment from 'moment';

export default class TaskHour extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            user_id: null,
            task_id: null,
            seconds_worked: null,
            date: moment(),
            user: null,
            task: null
        }
    }

    is (task_id, date) {
        return (
            (this.task_id ? this.task_id : this.taskId) == task_id
            && date.format('YYYY-MM-DD') == this.date.format('YYYY-MM-DD')
        );
    }

    isBetween(from, till)
    {
        return this.date.isBetween(from, till);
    }

    getTransformers() {
        return {
            user: User,
            task: Task,
        }
    }

    getDateFormats() {
        return {
            date: 'YYYY-MM-DD'
        }
    }

    getMutators() {
        return {
            date: 'date'
        };
    }
}
