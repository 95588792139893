<template>
    <div v-for="(orderNotification, index) of orderNotifications" :key="'O' + index" class="alert alert-danger">
        <div>
            <Icon name="exclamation-triangle" type="solid" />
            <b>Ordermelding - {{ orderNotification.type() }}</b>
        </div>
        <div>
            {{ orderNotification.order.customer.name }} <span class="splitter">|</span> {{ orderNotification.order.toDate('pickedUpAt').format('DD-MM-YYYY - HH:mm') }}
        </div>
        <div>
            Opmerking van medewerker: <i>{{ orderNotification.description }}</i>
        </div>
        <div class="alert-close-button" @click="removeOrderNotificationId = orderNotification.id"><Icon name="x-mark" /></div>
    </div>

    <ConfirmModal
        v-if="removeOrderNotificationId"
        body="Weet je zeker dat je deze ordermelding wilt verwijderen?"
        @yes="removeOrderNotification"
        @no="removeOrderNotificationId = null"
    />
</template>

<script setup>
    import { ref } from 'vue';
    import { collect } from "collect.js";
    import { useToast } from 'vue-toast-notification';

    /*
    |--------------------------------------------------------------------------
    | Order notifications
    |--------------------------------------------------------------------------
    */
    const orderNotifications = ref(collect());

    // Get order notifications
    import orderNotificationService from '@/services/http/order-notification-service';
    const getOrderNotifications = function() {
        return orderNotificationService.get({filters: {0: 'today'}, include: ['order.customer']}).then(orderNotificationModels => {
            orderNotifications.value = orderNotificationModels;
        });
    }

    // Refresh data and initiate get data.
    const getData = function() {getOrderNotifications();}
    getData();
    defineExpose({getData});

    /*
    |--------------------------------------------------------------------------
    | Remove order notification
    |--------------------------------------------------------------------------
    */
    // Confirm modal
    import ConfirmModal from '@/components/ConfirmModal.vue';

    const removeOrderNotificationId = ref(null);

    const removeOrderNotification = function() {
        orderNotificationService.delete(removeOrderNotificationId.value).then(orderNotification => {
            useToast().success('Ordermelding verwijderd');
            let orderNotificationIndex = orderNotifications.value.search(on => on.id == removeOrderNotificationId.value);
            if (orderNotificationIndex !== false) {
                orderNotifications.value.splice(orderNotificationIndex, 1);
            }
            removeOrderNotificationId.value = null;
        })
    };

</script>
