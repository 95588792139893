import moment from 'moment';
import { collect } from 'collect.js';

export class AbstractModel {

    /**
     * Create a new Form instance.
     *
     * @param {object} data
     */
    constructor(data) {
        this['transformers'] = {};
        if (typeof this['getTransformers'] != 'undefined') {
            this['transformers'] = this.getTransformers();
        }


        this['dateFormats'] = {};
        if (typeof this['getDateFormats'] != 'undefined') {
            this['dateFormats'] = this.getDateFormats();
        }

        if (this.constructor === AbstractModel) {
            throw new TypeError('Abstract class "AbstractModel" cannot be instantiated directly.');
        }

        if (data) {
            this.v2Parse(data);
        } else {
            this.attributes = this.getDefaults();
            for (let field in this.attributes) {
                this[field] = this.attributes[field];
            }
        }

        /* // Overwrite the default fields with the data given
        for (let field in data) {
            this[field] = data[field];
        } */

    }

    data() {
        let data = {};

        for (let property in this.default) {
            data[property] = this[property];
        }

        return data;
    }

    toDate(attribute) {
        return moment(this[attribute], 'YYYY-MM-DD HH:mm:ss');
    }

    setAttributesFromResponse(response) {
        let attributes = Object.keys(this.attributes);
        let ids = collect();
        let models = collect();

        for (let attribute of attributes) {
            if (attribute in response) {
                if (attribute in this['transformers']) {
                    if (Array.isArray(this[attribute]) && response[attribute] !== null) {
                        this[attribute] = collect();
                        for (let i in response[attribute]['data']) {
                            let obj = new this['transformers'][attribute];
                            obj.setAttributesFromResponse(response[attribute]['data'][i]);
                            this[attribute].push(obj);
                        }
                    } else {
                        let obj = null;
                        if (response[attribute] !== null) {
                            if (typeof this['transformers'][attribute] === 'string') {
                                obj = new (this[this['transformers'][attribute]]());
                            } else {
                                obj = new this['transformers'][attribute]
                            }
                            if (response[attribute]['data'] != null && response[attribute]['data'].length > 0) {

                                obj.setAttributesFromResponse(response[attribute]['data'][0]);
                            } else {
                                obj = null;
                            }
                        }
                        this[attribute] = obj;
                    }
                } else {
                    if (this[attribute] instanceof Object && typeof this[attribute]._isAMomentObject !== "undefined") {
                        if (response[attribute] === null) {
                            this[attribute] = response[attribute];
                        } else {
                            let format = (attribute in this['dateFormats']) ? this['dateFormats'][attribute] : 'YYYY-MM-DD HH:mm:ss';

                            this[attribute] = moment(response[attribute], format);
                        }

                    } else {
                        this[attribute] = this.parseAttribute(response[attribute]);
                    }
                }
            }
        }


        for (let attribute of models) {
            let models = collect();
        }

        if ('sortBy' in this) {
            let sortBy = this.sortBy();
            for (let relation in sortBy) {
                if(this[relation]) {
                    this[relation] = this[relation].sort(sortBy[relation]);
                }
            }
        }
    }

    parseAttribute(attribute) {
        if (typeof attribute === "object" && attribute !== null && "data" in attribute) {
            return this.parseRelation(attribute['data']);
        } else {
            return attribute;
        }
    }

    parseRelation(models) {
        for (let model in models) {
            for (let attribute in models[model]) {
                models[model][attribute] = this.parseAttribute(models[model][attribute]);
            }
        }

        return models;
    }


    // V2
    v2Parse(data)
    {
        let mutators = this.getMutators();
        for (let attribute in data) {
            let key = attribute.replace(
                /([-_][a-z])/g,
                (group) => group.toUpperCase()
                                .replace('-', '')
                                .replace('_', '')
            );

            let value = data[attribute] === null ? data[attribute] : this.mutateAttribute(data[attribute], key, mutators);

            if (key in this.relations()) {
                if (this.relationPlural()[key]) {
                    if (value !== null) {
                        this[key] = collect();

                        for (let modelData of value.data) {
                            this[key].push(new (this.relations()[key])(modelData));
                        }
                    }
                } else {
                    if (value && value.data[0]) {
                        this[key] = new (this.relations()[key])(value.data[0]);
                    } else {
                        this[key] = null;
                    }
                }
            } else {
                this[key] = value;
            }
        }

    }

    relations() {
        return {
        }
    }

    getMutators() {
        return {};
    }


    mutateAttribute(value, attribute, mutators) {
        if (attribute in mutators) {
            if (mutators[attribute] === 'date') {
                value = moment(value+'T00:00:00.000');
            }

            if (mutators[attribute] === 'datetime') {
                value = moment(value);
            }

            if (mutators[attribute] === 'float' && value !== null) {
                value = parseFloat(value);
            }
        }

        return value;
    }

}
