<template>
    <div class="login-container">
        <div class="login-column">
            <div class="login-content-container">
                <div class="login-content">

                    <div class="jongfresh-login-logo"></div>

                    <div v-if="showForgotPasswordExplanation" class="explain-block">
                        Controleer je e-mailadres en volg de stappen om een nieuw wachtwoord in te stellen.
                    </div>


                    <form @submit.prevent="onSubmit">

                        <div class="form-group">
                            <label for="username">
                                <input id="username" type="text" autofocus class="form-control" v-model="form.email" placeholder="E-mailadres">
                            </label>
                        </div>

                        <div class="form-group">
                            <label for="password">
                                <input id="password" type="password" class="form-control" v-model="form.password" placeholder="Wachtwoord">
                            </label>
                        </div>

                        <input type="submit" class="login-button" value="Inloggen">

                        <a @click="goToForgotPassword()" class="login-sub-button">
                            Wachtwoord vergeten
                        </a>

                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useAuthStore } from '@/stores/auth';
    import { ref } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { useToast } from 'vue-toast-notification';

    const auth = useAuthStore();
    const router = useRouter();
    const showForgotPasswordExplanation = ref(useRoute().query['forgot-password'] ?? false);


    const form = ref({
        email: useRoute().query.email !== undefined ? useRoute().query.email : '',
        password: useRoute().query.token !== undefined ? useRoute().query.token : ''
    });

    const onSubmit = function() {
        const f = form.value;
        auth.authenticate(f.email, f.password).then((response) => {
            if(response.constructor && response.constructor.name === 'AxiosError') {
                throw response;
            }

            router.push('dashboard');
        }).catch(error => {
            useToast().error('Inloggegevens incorrect. Probeer het opnieuw.');
        });
    };

    if (useRoute().query.email !== undefined && useRoute().query.token !== undefined) {
        onSubmit();
    }

    const goToForgotPassword = function() {
        router.push('forgot-password');
    };
</script>
