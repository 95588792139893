<template>
    <template v-for="mini of [false, true]">
        <tr @click="goTo(mini)" id="product-details" v-if="! hide(mini)">
            <td class="type-label">
                <span>
                    {{ data.label }}{{ mini ? ' mini' : '' }}
                </span>
            </td>
            <td v-for="(content, cindex) of data.contents" :key="cindex">
                <div class="compare">
                    <div class="compare-left" :class="{
                        'more': amount(content, mini, false) > amount(content, mini, true),
                        'less': amount(content, mini, false) < amount(content, mini, true)
                    }">
                        {{ amount(content, mini, false) || amount(content, mini, true) ? amount(content, mini, false) : (total ? 0 : '&nbsp;') }}
                    </div>
                    <div class="compare-right" :class="{
                        'more': amount(content, mini, false) < amount(content, mini, true),
                        'less': amount(content, mini, false) > amount(content, mini, true)
                    }">
                        {{ amount(content, mini, false) || amount(content, mini, true) ? amount(content, mini, true) : (total ? 0 : '&nbsp;') }}
                    </div>
                </div>
            </td>
        </tr>
    </template>
</template>

<script setup>
    import { useRouter } from 'vue-router';
    import collect from 'collect.js';

    const props = defineProps(['data', 'total', 'from', 'till']);
    const router = useRouter();

    const amount = function(data, mini, employee) {
        return data[employee ? 'm' : 'd'][mini ? 'mini' : 'nonMini'];
    }

    const hide = function(mini) {
        return collect(props.data.contents).sum((content) => (
            content['m'][mini ? 'mini' : 'nonMini']
            + content['d'][mini ? 'mini' : 'nonMini']
        )) <= 0;
    }

    const goTo = function(mini = false) {
        const queryString = [
            `from=${props.from.format('YYYY-MM-DD')}`,
            `till=${props.till.format('YYYY-MM-DD')}`,
        ];

        if (mini) {
            queryString.push('mini=1');
        }

        if (! props.total) {
            router.push(`/compare/${props.data.type.id}?${queryString.join('&')}`)
        }
    }
</script>
