<template>
    <a v-if="fabButton" v-on:click="onexport">
        <Icon name="arrow-down-tray" />
    </a>
    <FabButton v-on:click="onexport" icon="table-cells"></FabButton>
</template>

<script setup>
    import XLSX from "xlsx"
    import moment from 'moment'

    import FabButton from "@/components/FabButton.vue";

    const props = defineProps({
        data: Object,
        from: String,
        till: String,
        mode: String,
        weeks: Array,
        fabButton: {
            type: Boolean,
            default: false,
        },
    });

    const today = moment();

    const onexport = function() {
        const exportData = [];
        for (const customer of props.data) {
            let first = true;

            for (const article of customer.groups) {
                const row = {
                    Klant: first ? customer.name : '',
                    [props.type === 'types' ? 'Type' : 'Artikel']: article.name,
                }
                for (const weekIndex in props.weeks) {
                    const week = props.weeks[weekIndex];

                    let value = 0;
                    if (week.current.isAfter(today, 'isoWeek')) {
                        value = article.weeklyData[weekIndex].amount
                    } else {
                        value = article.weeklyData[weekIndex].currentBunches !== 0 ? article.weeklyData[weekIndex].currentBunches : ''
                    }
                    row['W'+week.current.isoWeek().toString()] = value;

                }

                first = false;
                exportData.push(row);
            }
        }

        // export json to Worksheet of Excel
        // only array possible
        var workSheet = XLSX.utils.json_to_sheet(exportData)

        // A workbook is the naam given to an Excel file
        var workBook = XLSX.utils.book_new() // make Workbook of Excel
        // add Worksheet to Workbook
        // Workbook contains one or more worksheets
        XLSX.utils.book_append_sheet(workBook, workSheet, 'Verkoopprognose') // sheetAnaam is naam of Worksheet

        // export Excel file
        XLSX.writeFile(workBook, 'Verkoop prognose ' + moment(props.from).format('DD-MM-YYYY') + ' t/m ' + moment(props.till).format('DD-MM-YYYY') +'.xlsx');

    }
</script>
