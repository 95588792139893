<template>
    <modal @save="save()" @close="$emit('close')">
        <template v-slot:header>Overeenkomst wijzigen</template>
        <form @submit.prevent  enctype="multipart/form-data">

            <label class="styled-label">
                <span>Onderneming</span>
                <div>{{ form.company ? form.company : '-' }}</div>
            </label>

            <label class="styled-label">
                <span>Gevestigd te</span>
                <div>{{ form.established_at ? form.established_at : '-' }}</div>
            </label>

            <label class="styled-label">
                <span>Postcode</span>
                <div>{{ form.postal_code ? form.postal_code : '-' }}</div>
            </label>

            <label class="styled-label">
                <span>Straat en huisnummer</span>
                <div>{{ form.address ? form.address : '-' }}</div>
            </label>

            <label class="styled-label">
                <span>BTW nr</span>
                <div>{{ form.btw ? form.btw : '-' }}</div>
            </label>

            <label class="styled-label">
                <span>Startdatum:</span>
                <DatePicker @input="dateChanged" id="starts_at" v-model="form['starts_at']" :class="{ 'is-invalid': form.errors.has('starts_at') }" />
                <div class="invalid-feedback" v-if="form.errors.has('starts_at')" v-text="form.errors.get('starts_at')"></div>
            </label>

            <label class="styled-label">
                <span>Einddatum:</span>
                <DatePicker @input="dateChanged" id="ends_at" v-model="form['ends_at']" :class="{ 'is-invalid': form.errors.has('ends_at') }" />
                <div class="invalid-feedback" v-if="form.errors.has('ends_at')" v-text="form.errors.get('ends_at')"></div>
            </label>

            <label class="styled-label">
                <span>Uurtarief</span>
                <input id="hourly_wage" class="form-control" type="text" v-model="form['hourly_wage']" :class="{ 'is-invalid': form.errors.has('hourly_wage') }">
                <div class="invalid-feedback" v-if="form.errors.has('hourly_wage')" v-text="form.errors.get('hourly_wage')"></div>
            </label>

        </form>
        <a class="btn btn-block btn-outline-danger" @click="$emit('deleteAgreement', agreement)">Verwijderen</a>
    </modal>
</template>

<script setup>
    import Form from '../../../../helpers/form.js';
    import Agreement from '../../../../models/agreement.js';
    import { onMounted, ref } from 'vue';
    import { useToast } from 'vue-toast-notification';

    const props = defineProps(['agreement', 'user']);
    const emits = defineEmits(['agreementEdited', 'deleteAgreement']);

    const form = new Form({
        company: props.agreement.company,
        established_at: props.agreement.established_at,
        postal_code: props.agreement.postal_code,
        address: props.agreement.address,
        btw: props.agreement.btw,
        starts_at: props.agreement.starts_at.format('YYYY-MM-DD'),
        ends_at: props.agreement.ends_at.format('YYYY-MM-DD'),
        user_id: props.agreement.user_id,
        hourly_wage: props.agreement.hourly_wage
    });

    const disabledDates = ref({'ranges': []})

    const save = function() {
        let url = 'users/' + props.agreement.user_id + '/agreements/' + props.agreement.id;
        this.form.hourly_wage = this.form.hourly_wage * 100;
        this.form.put(url).then(response => {
            this.handleResponse(response);
        });
    }

    const handleResponse = function(response) {
        let agreementModel = new Agreement();
        agreementModel.setAttributesFromResponse(response.data[0]);
        useToast().success('Overeenkomst gewijzigd');
        emits('agreementEdited', props.agreement.id, agreementModel);
        document.querySelector('body').classList = '';
    }

    const dateChanged = function() {
        if (form.starts_at !== null && form.ends_at !== null && form.starts_at > form.ends_at) {
            const tempStartsAt = form.starts_at;
            form.starts_at = form.ends_at;
            form.ends_at = tempStartsAt;
        }
    }

    onMounted(() => {
        for (let agreement of props.user.agreements) {
            if (agreement.id !== props.agreement.id) {
                disabledDates.value.ranges.push({
                    from: new Date(agreement.starts_at.format('YYYY'), agreement.starts_at.format('MM')-1, agreement.starts_at.format('DD')),
                    to: new Date(agreement.ends_at.format('YYYY'), agreement.ends_at.format('MM')-1, parseInt(agreement.ends_at.format('DD'))+1)
                });
            }
        }
    })
</script>
