<template>
    <modal class="edit-article" @save="save()" @close="$emit('close')">
        <template v-slot:header>{{ isArticle ? article.name : article.name() }}</template>
        <div>
            <form @submit.prevent enctype="multipart/form-data">
                <select class="styled-input" v-model="form.image_url" style="margin-top: 10px; width: 100%;">
                    <option value="">Geen afbeelding</option>
                    <option :value="productImage.url" v-for="(productImage, index) of productImages" :key="index">{{ productImage.name.replace(/_/gi, ' ').split('.').slice(0, -1).join('.') }}</option>
                </select>
            </form>

            <img class="selected-article-photo" :src="form.image_url ? form.image_url : 'https://s3.eu-central-1.amazonaws.com/jongfresh-dev/products/images/jongfresh_kist.png'" />
        </div>
    </modal>
</template>

<script>
import Article from './../../../../models/article.js';
import Form from './../../../../helpers/form.js';
import { useToast } from 'vue-toast-notification';

export default {
    props: ['article', 'productImages'],

    methods: {
        save() {
            this.$emit('updated', this.form.image_url);
            document.querySelector('body').classList = '';
            this.$emit('close');

            this.form.put(this.isArticle ? '/articles/' + this.article.id : '/harvest-products')
            .then(function(response){
                useToast().success((this.isArticle ? 'Artikel' : 'Oogstproduct') + ' gewijzigd');
            }.bind(this));
        },
    },

    data() {
        return {
            isArticle: (this.article instanceof Article),
            form: new Form({
                chest_id: this.article.chest_id,
                content_id: this.article.content_id,
                size_id: this.article.size_id,
                type_id: this.article.type_id,
                image_url: this.article.image_url ? this.article.image_url : "",
            }),
        }
    }
};
</script>
