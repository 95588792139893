<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div id="review-categories">
                    <div class="page-header">
                        <h1>Beoordelingscategorieën</h1>
                        <fab-button @click.native="create = (! create)" icon="plus"></fab-button>
                    </div>

                    <div class="widget-holder">
                        <table class="styled-table">
                            <tr class="styled-table-row" v-for="reviewCategory of reviewCategories" :key="reviewCategory.id">
                                <td>{{ reviewCategory.name }}</td>
                                <td class="styled-table-row-icons">
                                    <a @click="edit = reviewCategory;"><Icon name="pencil" /></a>
                                    <a class="alert-color" @click="confirm.active = true; confirm.id = reviewCategory.id"><Icon name="x-mark" /></a>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <Create v-if="create" @close="create = false" @saved="setReviewCategory"/>
        <Edit v-if="edit" @close="edit = false" :task="edit"/>
        <ConfirmModal v-if="confirm.active" body="Weet je zeker dat je deze beoordelingscategorie wilt verwijderen?" @yes="remove" @no="confirm.active = ! confirm.active" />
    </div>
</template>

<script>
    import ConfirmModal from './../../../components/ConfirmModal.vue';
    import reviewCategoryService from '../../../services/http/review-category-service';
    import Create from './Create.vue';
    import Edit from './Edit.vue';
    import { useToast } from 'vue-toast-notification'

    export default {

        components: {Create, Edit, ConfirmModal},

        data() {
            return {
                create: false,
                edit: false,
                reviewCategories: [],
                confirm: {body: '', active: false, id: null},
            }
        },

        methods: {
            setReviewCategory(reviewCategory) {
                this.reviewCategories.push(reviewCategory);
            },

            async remove() {
                this.confirm.active = false;
                this.reviewCategories = this.reviewCategories.filter((rc) => rc.id !== this.confirm.id)
                await reviewCategoryService.delete(this.confirm.id);
                this.confirm.id = null;

                useToast().success('Beoordelingscategorie verwijderd');
            },

            getReviewCategories() {
                reviewCategoryService.get().then(reviewCategories => {
                    this.reviewCategories = reviewCategories;
                });

            },
        },

        created() {
            this.getReviewCategories();

            this.$emit('breadcrumbs', [
                {label: 'Instellingen', to: '/settings'},
                {label: 'Beoordelingscategoriën'}
            ])
        }
    }
</script>
