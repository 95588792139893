import TaskHour from "../../models/task-hour.js"
import { AbstractHttpService } from "./abstract-http-service";

class TaskHourService extends AbstractHttpService {

    endpoint() {
        return 'task-hours';
    }

    createModel(data) {
        return new TaskHour(data);
    }
}

export default new TaskHourService;
