<template>
    <div class="container">

        <div class="settings-overview">
            <a class="settings-button" v-if="authUser.can('sowing_planning')" @click="$router.push('/sowing-planning/sales-forecast')">
                <Icon name="light-bulb" />
                <span>Verkoopprognose</span>
            </a>
        </div>

        <div class="settings-overview">
            <a class="settings-button" v-if="authUser.can('sowing_planning')" @click="$router.push('/sowing-planning/sales-forecast/totals')">
                <Icon name="funnel" />
                <span>Verkoopprognose totalen</span>
            </a>
        </div>

        <div class="settings-overview">
            <a class="settings-button" v-if="authUser.can('sowing_planning')" @click="$router.push('/sowing-planning/sow-per-m2')">
                <Icon name="calculator" />
                <span>Benodigd M2</span>
            </a>
        </div>

        <div class="settings-overview">
            <a class="settings-button" v-if="authUser.can('sowing_planning')" @click="$router.push('/sowing-planning/available-m2')">
                <Icon name="square-3-stack-3D" />
                <span>Beschikbare oppervlakte</span>
            </a>
        </div>


    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { useAuthStore } from '@/stores/auth';

    const authUser = computed(() => useAuthStore().user);
</script>
