import {AbstractModel} from './abstract-model.js'

export default class Packaging extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            name: null,
            code: null
        }
    }

    modelName() {
        return 'Packaging';
    }

}
