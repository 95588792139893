import ProductionControl from "../../models/production-control";
import { AbstractHttpService } from "./abstract-http-service";

class ProductionControlService extends AbstractHttpService {

    endpoint() {
        return 'production-controls';
    }

    createModel(data) {
        return new ProductionControl(data);
    }
}

export default new ProductionControlService;
