import DayHarvestRegistration from "../../models/day-harvest-registration";
import { AbstractHttpService } from "./abstract-http-service";

class DayHarvestRegistrationService extends AbstractHttpService {

    endpoint() {
        return 'day-harvest-registrations';
    }

    createModel(data) {
        return new DayHarvestRegistration(data);
    }
}

export default new DayHarvestRegistrationService;
