<template>
    <div v-click-outside="() => {open = false;}" class="multi-select">
        <div class="selected" @click="toggle()">
            <span v-if="numberOfSelectedOptionsShown > 0 && selected.length > numberOfSelectedOptionsShown"  class="selected-item">
                {{ selectedNames.length > 0 ? selectedNames.join(', ') : placeholder }}
            </span>
            <template v-else>
                <template v-if="selected.length > 0">
                    <span v-for="(selectedItem, index) of selected" :key="index" class="selected-item">
                        {{ selectedItem.label }}
                        <a class="remove-selection"  @click.stop="removeOption(selectedItem)">
                            <Icon name="x-mark" style="width:12px" />
                        </a>
                    </span>
                </template>
                <span class="multi-select-placeholder" v-else>
                    {{ placeholder }}
                </span>
            </template>
        </div>
        <div class="options" :class="{open: open}">
            <div style="position: relative">
                <input type="text" v-model="search" ref="searchInput" class="search" :class="{'show-search': open && includeSearch}">
                <Icon name="x-mark" @click="search = ''" v-show="search != ''" style="position:absolute; right: 24px; top: 6px; width: 24px" />
            </div>
            <label v-if="selectAllOption" class="option">
                <input :checked="selectedAll" type="checkbox" @click="toggleAll()">
                <span><b>Selecteer alles</b></span>
            </label>
            <label v-for="(option, index) of filteredOptions" :key="index" class="option">
                <input type="checkbox" @change="$emit('update:modelValue', modelValue);" :value="option.value" v-model="modelValue" />
                <span>{{ option.label }}</span>
            </label>
        </div>
    </div>
</template>

<script setup>
    import { options } from 'floating-vue';
    import { ref, computed, watch } from 'vue';

    const props = defineProps({
        modelValue: Array,
        options: Array,
        placeholder: {
            type: String,
            default: 'Selecteer één of meerdere opties'
        },
        numberOfSelectedOptionsShown: {
            type: Number,
            default: 0
        },
        includeSearch: {
            type: Boolean,
            default: true
        },
        selectAllOption: {
            type: Boolean,
            default: false
        }
    });

    const emits = defineEmits(['update:modelValue'])
    watch(() =>
        props.modelValue
    , (newValue) => {
        modelValue.value = newValue
    });

    // Options
    const modelValue = ref(props.modelValue);
    const filteredOptions = computed(() => props.options.filter(o => o.label.toLowerCase().includes(search.value.toLowerCase())))

    const selected = computed(() => props.options.filter(o => modelValue.value.includes(o.value)));


    // Selected
    const selectedNames = computed(() => {
        let names = props.options.filter(o => modelValue.value.includes(o.value)).map(o => o.label);
        if (modelValue.value.length > props.numberOfSelectedOptionsShown) {
            names = names.slice(0, props.numberOfSelectedOptionsShown);
            names.push('en ' + (modelValue.value.length - props.numberOfSelectedOptionsShown) + ' meer...');
        }

        return names;
    });

    // Search
    const search = ref('');
    const searchInput = ref(null)
    const focusSearchInput = function() {
        setTimeout(() => {
            searchInput.value.focus()
        }, 1);
    }

    // Active/non-active
    const open = ref(false);
    const toggle = function() {
        open.value = ! open.value;
        search.value = '';
        if (open.value) {
            focusSearchInput();
        }
    }

    // Remove
    const removeOption = function(selectedItem) {
        const index = modelValue.value.findIndex(item => item === selectedItem.value);
        delete modelValue.value[index];
        modelValue.value = modelValue.value.filter(u => u !== undefined);
        emits('update:modelValue', modelValue.value);
    }

    const selectedAll = computed(() => {
        return (modelValue.value.length === props.options.length)
    });

    const toggleAll = function() {
        if (selectedAll.value) {
            modelValue.value = [];
        } else {
            modelValue.value = props.options.map(option => option.value);
        }

        emits('update:modelValue', modelValue.value);
    }
</script>
