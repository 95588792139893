import Setting from "../../models/setting";
import { AbstractHttpService } from "./abstract-http-service";

class SettingService extends AbstractHttpService {

    endpoint() {
        return 'settings';
    }

    createModel(data) {
        return new Setting(data);
    }
}

export default new SettingService;
