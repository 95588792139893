<template>
    <modal @save="onsubmit()" @close="$emit('close')">
        <template v-slot:header>Profielfoto verwijderen</template>

        <p v-if="self">
            Weet je zeker dat je jouw profielfoto wilt verwijderen?
        </p>
        <p v-else>
            Weet je zeker dat je de profielfoto van {{ user.name() }} wilt verwijderen?
        </p>

        <template slot="close">Annuleren</template>

        <template slot="save">Doorgaan</template>
    </modal>
</template>

<script>
    import FileModel from '../../../../models/file-model.js';
    import { useToast } from 'vue-toast-notification';
    import userService from '@/services/http/user-service-new.js';

    export default{
        props: ['user', 'self'],

        methods: {
            onsubmit() {
                userService.delete(this.user.id + '/avatars')
                .then(user => {
                    this.user.avatars = [];
                    useToast().success('Profielfoto verwijderd');
                    this.$emit('deleted', user);
                    document.querySelector('body').classList = '';
                })
            }
        }
    };
</script>
