import './bootstrap';
import './websockets';

// Initialize app
import { createApp } from 'vue'
import App from './App.vue';
const app = createApp(App);

// Setup bugsnag
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

if (
    import.meta.env.VITE_VUE_BUGSNAG_ENABLED === true
    || import.meta.env.VITE_VUE_BUGSNAG_ENABLED === undefined
) {
    Bugsnag.start({
        apiKey: import.meta.env.VITE_VUE_BUGSNAG_API_KEY,
        plugins: [new BugsnagPluginVue()]
    });

    const bugsnagVue = Bugsnag.getPlugin('vue');
    app.use(bugsnagVue);
}


import drag from './drag.js';
app.directive('draggable', {
    mounted(el) {
        el.draggable = true;
        el.addEventListener('dragstart', drag.handleDragStart, false);
        el.addEventListener('dragend', drag.handleDragEnd, false);
    },

    updated(el) {
        el.draggable = true;
        el.removeEventListener('dragstart', drag.handleDragStart, false);
        el.removeEventListener('dragend', drag.handleDragEnd, false);
        el.addEventListener('dragstart', drag.handleDragStart, false);
        el.addEventListener('dragend', drag.handleDragEnd, false);
    }
});

/** Pinia: state management **/
import { createPinia } from 'pinia'
const pinia = createPinia();
app.use(pinia);

// Routes
import routes from './routes';
app.use(routes);

/** Toasts **/
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
app.use(ToastPlugin);

/**
 * Global components
 * All components that are usable without local
 * registration of the component are registered here.
 */

/** DatePicker **/
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
app.component('VueDatePicker', VueDatePicker);

/** Custom DatePicker **/
import DatePicker from './components/DatePicker.vue';
app.component('DatePicker', DatePicker);

/** FabButton **/
import FabButton from './components/FabButton.vue';
app.component('FabButton', FabButton);

/** TimePicker **/
import TimePicker from './components/TimePicker.vue';
app.component('TimePicker', TimePicker);

/** Icons **/
import Icon from './components/Icon.vue';
app.component('Icon', Icon);

/** Modal **/
import Modal from './components/Modal.vue';
app.component('Modal', Modal);

/** Breadcrumbs **/
import Breadcrumbs from './components/Breadcrumbs.vue';
app.component('Breadcrumbs', Breadcrumbs);

/** Tooltip components **/
//import { Dropdown, Tooltip, Menu } from 'floating-vue'
//app.component('VDropdown', Dropdown)
//app.component('VTooltip', Tooltip)
//app.component('VMenu', Menu)


/**
 * Directives
 * All directes ex. v-directive are registered here.
 */

/** v-tooltip **/
import { VTooltip, VClosePopper } from 'floating-vue'
app.directive('tooltip', VTooltip);
//app.directive('close-popper', VClosePopper)

/** Webbundels tooltip **/
import WbTooltip from './directives/wb-tooltip.js'
app.directive('wbtooltip', WbTooltip);

/** Click outside **/
import clickOutside from './directives/click-outside.js';
app.directive("click-outside", clickOutside);

app.mount('#app');

window['toUpperCamelCase'] = function (str) {
    let string =  toCamelCase(str);
    return (string.charAt(0).toUpperCase() + string.slice(1));
}


const toCamelCase = function(str) {
    return str.replace(/-./g, x=>x[1].toUpperCase());
}

// CSS for tooltips
import 'floating-vue/dist/style.css';
