<template>
    <modal
        @close="$emit('close')"
        @save="onSubmit()"
    >
        <template v-slot:header>
            <span>{{ header }}</span>
        </template>

        <label class="styled-label">
            <span>Datum</span>
            <input type="date" class="styled-input" required v-model="date">
        </label>

        <label class="styled-label">
            <span>Type</span>
            <select class="styled-input" v-model="typeId">
                <option :value="null">Kies een type</option>
                <option v-for="(type, index) of types" :value="type.id">
                    {{ type.translate('name')}}
                </option>
            </select>
        </label>

        <label class="styled-label">
            <span>Sortering</span>
            <select class="styled-input" v-model="sizeId">
                <option :value="null">Kies een sortering</option>
                <option v-for="(size, index) of sizes.filter(s => ['small', 'large'].includes(s.name))" :value="size.id">
                    {{ size.translate('name')}}
                </option>
            </select>
        </label>

        <label v-if="locations" class="styled-label">
            <span>Locatie</span>
            <select class="styled-input" v-model="locationId">
                <option :value="null">Kies een locatie</option>
                <option v-for="(location, index) of locations" :value="location.id">
                    {{ location.name }}
                </option>
            </select>
        </label>

        <label class="styled-label">
            <span>Aantal</span>
            <input type="number" step="1" class="styled-input" min="0" v-model="amount">
        </label>

    </modal>
</template>

<script setup>
    import { useToast } from 'vue-toast-notification';
    import { watch, ref, computed } from 'vue';
    import moment from 'moment';
    import { useCcstStore } from '../../stores/ccst';

    const props = defineProps({
        'header': String,

        'date': [String, Object],

        'locations': Array,
        'locationId': {type: [String, Number], default: null},

        'product': Object
    });

    const sizes = computed(() => useCcstStore().sizes);
    const types = computed(() => useCcstStore().types);

    const emit = defineEmits(['submitted', 'close']);

    const date = ref(
        props.product ? props.product.date.format('YYYY-MM-DD')
        : (
            props.date instanceof moment
            ? props.date.toDate()
            : props.date
        )
    );

    const amount = ref(props.product ? props.product.amount : 0)
    const sizeId = ref(props.product ? props.product.sizeId : null);
    const typeId = ref((props.product ? props.product.typeId : null));
    const locationId = ref((props.product && props.product.locationId ? props.product.locationId : (props.locationId ? parseInt(props.locationId) : null)));

    const onSubmit = function() {
        if (
            ! locationId.value || ! typeId.value
            || ! sizeId.value || ! date.value
            || ! amount.value || amount.value == 0
        ) {
            useToast().error('Vul alle velden in')
            return;
        }

        emit('submitted', {
            size_id: sizeId.value,
            type_id: typeId.value,
            location_id: locationId.value,
            amount: amount.value,
            date: date.value
        });
    }

</script>
