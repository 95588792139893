import Customer from "../../models/customer";
import { AbstractHttpService } from "./abstract-http-service";

class CustomerService extends AbstractHttpService {

    endpoint() {
        return 'customers';
    }

    createModel(data) {
        return new Customer(data);
    }

    dashboardSaleForecastCustomers() {
        return this.sendGetRequest('dashboard-sale-forecast-customers');
    }
}

export default new CustomerService;
