import User from "../../models/user";
import { AbstractHttpService } from "./abstract-http-service";

class UserServiceNew extends AbstractHttpService {

    endpoint() {
        return 'users';
    }

    createModel(data) {
        return new User(data);
    }
}

export default new UserServiceNew;
