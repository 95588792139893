<template>
    <div v-if="customers.count() > 0" class="alert alert-danger" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
        <Icon name="viewfinder-circle" />Er moeten nog verkoopprognoses worden ingevuld voor: {{ presentCustomers }}
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import { collect } from "collect.js";
    import moment from 'moment';
    import SaleForecastService from '@/services/http/sale-forecast-service';
    import CustomerService from '@/services/http/customer-service';

    const customers = ref(collect())

    const nonFilledInSaleForecasts = function() {
        CustomerService.dashboardSaleForecastCustomers().then(customersData => {
            customers.value = collect(customersData);
        });
    }

    nonFilledInSaleForecasts();

    const presentCustomers = computed(() => {
        return customers.value.map(c => c.name).implode(', ');
    });

</script>
