<template>
    <modal @save="onsubmit()" @close="$emit('close')">
        <template v-slot:header>Wachtwoord herstellen</template>

        <p v-if="authUser.id === user.id">
            Weet je zeker dat je het wachtwoord wilt herstellen?
        </p>
        <p v-else>
            Weet je zeker dat je het wachtwoord van {{ user.name() }} wilt herstellen?
        </p>

        <template v-slot:close>Annuleren</template>

        <template v-slot:save>Doorgaan</template>
    </modal>
</template>

<script setup>
    import { useToast } from 'vue-toast-notification';
    import { useAuthStore } from '../../../stores/auth';

    const { user: authUser } = useAuthStore();

    const props = defineProps(['user']);
    const emits = defineEmits(['close']);

    const onsubmit = function() {
        axios.post('forgot-password/email', {email: props.user.email})
        .then(response => {
            useToast().success('Herstel e-mail verzonden' );
            emits('close');
            document.querySelector('body').classList = '';
        });
    }
</script>
