<template>
    <div v-for="(absentUser, index) of absentUsers" :key="index" class="alert alert-danger">
        <div>
            <Icon name="calendar" type="solid" />
            <template v-if="! authUser.can('users')">{{ absentUser.name() }}</template><a v-else @click="$router.push('/users/' + absentUser.id )">{{ absentUser.name() }}</a> is sinds {{ absentUser.toDate('absentSince').format('DD-MM-YYYY') }} afwezig.
        </div>
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import { collect } from "collect.js";
    import moment from 'moment'

    /*
    |--------------------------------------------------------------------------
    | Absent users
    |--------------------------------------------------------------------------
    */
    import userServiceNew from '@/services/http/user-service-new.js';
    
    const absentUsers = ref(collect());

    // Get absent users.
    const getAbsentUsers = function(){
        return userServiceNew.get({
            filters: {
                'absent_is': 1,
                'absent_since_is_lower_than': moment().subtract('10', 'day').format('YYYY-MM-DD')
            }
        }).then(userModels => {
            absentUsers.value = userModels;
        });
    }

    // Refresh data and initiate get data.
    const getData = function() {getAbsentUsers();}
    getData();
    defineExpose({getData});

    /*
    |--------------------------------------------------------------------------
    | Auth user
    |--------------------------------------------------------------------------
    */
    import { useAuthStore } from "../../stores/auth";
    const authStore = useAuthStore();
    const authUser = computed(() => authStore.user);
</script>
