import Type from "../../models/type.js"
import { AbstractHttpService } from "./abstract-http-service";

class TypeService extends AbstractHttpService {

    endpoint() {
        return 'types';
    }

    createModel(data) {
        return new Type(data);
    }
}

export default new TypeService;
