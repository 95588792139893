import {AbstractModel} from './abstract-model';
import DayHarvestRegistration from './day-harvest-registration.js';
import Type from './type.js';
import Size from './size.js';
import User from './user.js';
import Location from './location.js';

export default class DayHarvestKilo extends AbstractModel {

    getMutators()
    {
        return {
            createdAt: 'datetime'
        }
    }

    relations() {
        return {
            type: Type,
            size: Size,
            user: User,
            location: Location
        }
    }

    relationPlural()
    {
        return {
            type: false,
            size: false,
            user: false,
            location: false
        }
    }

    kilo() {
        return Math.round(this.gram/100)/10;
    }

    name() {
        return this.size.translate('name') + ' ' + this.type.translate('name');
    }

    dayHarvestDate() {
        return this.createdAt;
    }

    getModelName() {
        return 'day-harvest-kilo';
    }
}
