<template>
    <div class="create-step">
        <div v-for="option of latest" :key="option.id">
            <label>
                <input :checked="option.id === modelValue" type="radio" :name="name" @input="$emit('update:modelValue', option.id);$emit('chosen')">
                <span>{{ (typeof option.translation === 'function' ? option.translate('name') : option.name) }}</span>
            </label>
        </div>
        <div v-if="old.length > 0">
            <a class="show-more-button" @click="old_options_active = ! old_options_active">
                <span v-text="old_options_active ? 'Minder' : 'Meer'"></span> tonen
            </a>
            <template v-if="old_options_active">
                <div v-for="option of old" :key="option.id">
                    <label>
                        <input type="radio" :name="name" @input="$emit('update:modelValue', option.id);$emit('chosen')">
                        <span>{{ (typeof option.translation === 'function' ? option.translate('name') : option.name) }}</span>
                    </label>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue';

    const props = defineProps(['options', 'modelValue', 'name']);
    defineEmits(['update:modelValue', 'chosen', 'input']);

    const old_options_active = ref(false);

    const latest = computed(() =>
        props.options.filter(option => {return (props.name === 'location' || option.latest_harvest_assignments_count);})
    );

    const old = computed(() =>
        props.options.filter(option => ! (props.name === 'location' || option.latest_harvest_assignments_count))
    );

    if (latest.value.length < 1) {
        old_options_active.value = true;
    }
</script>
