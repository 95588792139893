<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div id="tasks">
                    <div class="page-header">
                        <h1>Rassen</h1>
                        <fab-button @click.native="openCreateBreedModal" icon="plus"></fab-button>
                    </div>

                    <div class="widget-holder">
                        <input class="text-left styled-input" type="text" placeholder="Ras zoeken" v-model="search">
                        <table class="styled-table">
                            <tr class="styled-table-row" v-for="(breed, index) of breedsFiltered" :key="index">
                                <td>{{ breed.name }}</td>
                                <td class="text-left">{{ breed.type.translate('name') }}</td>
                                <td class="text-left">{{ breed.fractions.count() ? breed.fractions.implode('milimeters', ', ') : '-' }}</td>
                                <td class="styled-table-row-icons">
                                    <a @click="openEditBreedModal(breed)"><icon name="pencil" /></a>
                                </td>
                            </tr>
                        </table>
                    </div>

                </div>
            </div>
        </div>

        <CreateEditBreedModal
            v-if="createEditFractionsModal"
            :breed="createEditFractionsModal"
            :types="types" :fractions="fractions"
            @close="createEditFractionsModal = false"
            @breedStored="breedStored"
        />
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import fractionService from '../../services/http/fraction-service';
    import typeService from '../../services/http/type-service';
    import breedService from '@/services/http/breed-service';
    import { collect } from "collect.js";
    import MultiSelect from '../../components/Form/MultiSelect.vue';
    import CreateEditBreedModal from './CreateEditBreedModal.vue';

    const emit = defineEmits(['breadcrumbs'])

    const fractions = ref(collect());
    const breeds = ref(collect());
    const types = ref(collect());
    const search = ref('');
    const createEditFractionsModal = ref(false);

    const breedsFiltered = computed(() => {
        const orderedBreeds = breeds.value.sort((a, b) => {
            if (a.type.sequence > b.type.sequence) {
                return 1;
            } else if (a.type.sequence < b.type.sequence) {
                return -1;
            } else if (a.name > b.name) {
                return 1;
            } else {
                return -1;
            }
        });

        if (search.value === '') {
            return orderedBreeds;
        }

        return orderedBreeds.filter((breed) => {
            return breed.name.toLowerCase().includes(search.value.toLowerCase())
        });
    })


    const openCreateBreedModal = function() {
        createEditFractionsModal.value = {
            id: null,
            fractionsIds: [],
            type_id: null,
            name: ''
        }
    }

    const openEditBreedModal = function(breed) {
        createEditFractionsModal.value = {
            id: breed.id,
            typeId: breed.type.id,
            fractionsIds: breed.fractions.pluck('id').all(),
            name: breed.name
        }
    }

    const getFractions = function() {
        fractionService.get().then(fractionModels => {
            fractions.value = fractionModels;
        });
    }

    const getBreeds = function() {
        breedService.get({include: ['fractions', 'type']}).then(breedModels => {
            breeds.value = breedModels;
        });
    }

    const getTypes = function() {
        typeService.get({filters: {0: 'hasOneChild'}}).then(typeModels => {
            types.value = typeModels;
        });
    }

    const breedStored = function() {
        getBreeds();
        createEditFractionsModal.value = false;
    }

    getFractions();
    getBreeds();
    getTypes();

    emit('breadcrumbs', [
        {label: 'Instellingen', to: '/settings'},
        {label: 'Rassen'}
    ])
</script>
