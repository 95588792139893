import {AbstractModel} from './abstract-model.js'

export default class DocumentType extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            name: null,
        }
    }

    translation() {
        return 'DocumentType';
    }

    dateFields() {
        if (this.name === 'kvk') {
            return ['delivered_at'];
        } else if (this.name === 'id') {
            return ['expires_at'];
        } else if (this.name === 'performance_evaluation') {
            return ['starts_at'];
        }

        return ['starts_at', 'ends_at']
    }

    translate(field) {
        const translations =  {
            "kvk": "KvK document",
            "id": "ID",
            "contract": "contract",
            "performance_evaluation": "functioneringsgesprek",
            "document": "document"
        }

        return translations[this[field]];
    }
}