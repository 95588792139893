<template>
    <div class="user-data">
        <h2>Persoonsgegevens</h2>
        <form @submit.prevent>
            <DataRow v-if="roles.count() > 0" v-for="(item, index) in dataRows" :key="index" :item="item"
                v-model="user[item.field]"
                :edit="('only_label' in item && item['only_label']) ? false : edit"
                :errors="form.errors"
            />

            <div class="profile-settings-group">
                <EndOfForm @submit="onSubmit" @toggle="toggleEdit" :edit="edit" />
            </div>
        </form>
    </div>
</template>

<script>
    import Form from '../../../helpers/form.js';
    import Role from '../../../models/role.js';
    import User from '../../../models/user.js';
    import UserType from '../../../models/user-type.js';
    import DataRow from './Components/DataRow.vue';
    import EndOfForm from './../Components/EndOfForm.vue';
    import RoleService from '../../../services/http/role-service.js'
    import { collect } from "collect.js";
    import { useToast } from 'vue-toast-notification';

    export default{
        props: ['user', 'authUser'],
        components: {DataRow, EndOfForm},
        data() {
            return {
                roles: collect(),
                edit: false,
                options: {},
                dataRows: [
                    {field: 'email', label: "E-mailadres", only_label: true},
                    {field: 'phone', label: "Telefoon"},
                    {field: 'firstname', label: "Voornaam"},
                    {field: 'insertion', label: "Tussenvoegsel"},
                    {field: 'surname', label: "Achternaam"},
                    {field: 'birthdate', label: "Geboortedatum", type: "date"},
                    {field: 'user_type_id', label: "Type", options: [], only_label: (! this.authUser.can('users'))},
                    {field: 'role_ids', label: "Rollen", checkboxes: [], only_label: (! this.authUser.can('users'))},
                    {field: 'hourly_wage', label: "Uurtarief", only_label: (! this.authUser.can('users'))}
                ],
                form: null,
                originalData: {}
            }
        },


        methods: {
            onSubmit() {
                this.form.put('/users/' + this.user.id + '?include=agreements,userType,roles,mediumAvatar,documents.documentType&deactivated=1')
                .then(function(response){
                    this.$emit('update', response);
                    useToast().success("Profiel opgeslagen");
                    this.edit = (! this.edit);
                }.bind(this));
            },

            toggleEdit() {
                if (! this.edit) {
                    this.createForm()
                    this.originalData = _.cloneDeep(this.form.data());
                } else {
                    this.form.reset(this.originalData);
                }
                this.edit = (! this.edit);
            },

            getUserTypes() {
                axios.get('user-types/')
                .then(response => {
                    for (let UserTypeData of response.data.data) {
                        let userType = new UserType();
                        userType.setAttributesFromResponse(UserTypeData);
                        this.dataRows.filter(function(obj){return obj.field === 'user_type_id'})[0].options.push({id: userType.id, name: userType.translate('name')});
                    }
                });
            },

            getRoles() {
                RoleService.get().then(roles => {
                    this.roles = roles;

                    for (const role of roles) {
                        let data ={id: role.id, name: role.translate('name')};
                        if (! this.authUser.can('remove_admin_roles') && role.name == 'admin') {
                            data['disabled'] = true;
                        }
                        this.dataRows.find((obj) => obj.field === 'role_ids').checkboxes.push(data);


                    }
                })
            },

            createForm() {
                this.form = new Form(
                    ['phone', 'firstname', 'insertion', 'surname', 'user_type_id', 'role_ids', 'hourly_wage', 'birthdate'],
                    this.user
                );
            }
        },

        created() {
            this.createForm();
            this.getUserTypes();
            this.getRoles();
        }

    }
</script>
