<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>{{ this.user.name() }}</h1>
                    <div class="horizontal-fab-button-holder">
                        <fab-button @click.native="create = true" icon="plus"></fab-button>
                    </div>
                </div>

                <div class="page-header-settings">
                    <div class="row">
                        <div class="col col-md-6 col-lg-4">
                            <div class="form-control datepicker-styling">
                                <span class="styled-label">
                                    Datum
                                </span>
                                <DatePicker v-model="date" @input="changeDate" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="tabs-holder">
                    <ul class="tabs">
                        <li :class="{active: harvestAssignments == true}" @click="harvestAssignments = true">
                            <a>Bosopdrachten</a>
                        </li>
                        <li :class="{active: harvestAssignments == false}" @click="harvestAssignments = false">
                            <a>Eigen registraties</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="styled-list">
            <ListRow @click.native="edit = harvestRegistration" v-for="(harvestRegistration, index) of this.filteredHarvestRegistrations" :harvestAssignments="harvestAssignments" :harvestRegistration="harvestRegistration" :key="index"></ListRow>
        </div>

        <div v-if="filteredHarvestRegistrations.length > 0" class="styled-list"></div>
        <div v-else class="row">
            <div class="col">
                <div class="no-data-placeholder">
                    Geen oogstregistraties gevonden
                </div>
            </div>
        </div>

        <CreateProduct
            v-if="create"
            @close="create = false"
            @submitted="addHarvestRegistration"
            header="Nieuwe eigen registratie"
            amountAs="harvested"
            :locations="locations"
            :date="{date: dateObj.format('YYYY-MM-DD')}"
            step="2"
        />

        <CreateProduct
            v-if="edit"
            @close="edit = false"
            :product="edit"
            @submitted="editHarvestRegistration"
            @delete="confirm = edit; edit = false"
            header="Eigen registratie wijzigen"
            amountAs="harvested"
            :locations="locations"
            :date="{date: dateObj.format('YYYY-MM-DD')}"
            :includeDelete="true"
        />

        <Modal v-if="confirm" @save="deleteHarvestRegistration(confirm.id)" @close="confirm = false">
            <template v-slot:header>Bevestigen</template>

            Weet je zeker dat je deze dagoogst wilt verwijderen?

            <template v-slot:save>Ja</template>
            <template v-slot:close>Nee</template>

        </Modal>

    </div>
</template>

<script>

import moment from 'moment';
import ListRow from './ListRow.vue'
import HarvestRegistration from '../../../models/harvest-registration.js'
import Content from '../../../models/content.js';
import Chest from '../../../models/chest.js';
import Type from '../../../models/type.js';
import Size from '../../../models/size.js';
import Location from '../../../models/location.js';
import User from '../../../models/user.js';
import CreateProduct from '../../../components/CreateProduct/CreateProduct.vue';
import axios from 'axios';
import { useToast } from 'vue-toast-notification';

export default {
    props: ['id'],

    components: { ListRow, CreateProduct },

    computed: {
        filteredHarvestRegistrations() {
            return this.harvestRegistrations.filter(hr => {
                return this.harvestAssignments && hr.harvest_assignment_id != null
                || ! this.harvestAssignments && hr.harvest_assignment_id == null
            });
        },
        dateObj: function() {return moment(this.date, 'YYYY-MM-DD');}
    },

    data(){
        return {
            date: (this.$route.query.date_string ? this.$route.query.date_string : moment().format('YYYY-MM-DD')),
            user: new User(),
            contents: [],
            sizes: [],
            types: [],
            chests: [],
            harvestRegistrations: [],
            locations: [],
            include: 'size,content,type,chest,location,harvestAssignment',
            create: false,
            edit: false,
            del: false,
            harvestAssignments: true,

            confirm: false
        }
    },

    methods: {
        changeDate()
        {
            this.getData();
            this.breadcrumbs();
        },

        async editHarvestRegistration(form) {
            const response = await form.put('harvest-registrations/' + this.edit.id + '?include=' + this.include);

            document.querySelector('body').classList = '';
            this.getData();

            useToast().success('Registratie gewijzigd');
        },

        async addHarvestRegistration(form) {
            const response = await form.post('harvest-registrations?include=' + this.include, {
                user_id: this.id
            });

            document.querySelector('body').classList = '';
            this.getData();
            useToast().success('Registratie opgeslagen');
        },

        async deleteHarvestRegistration(id) {
            const response = await axios.delete('harvest-registrations/' + id);

            document.querySelector('body').classList = '';
            this.getData();
            this.confirm = false;
            useToast().success('Registratie verwijderd');
        },

        breadcrumbs() {
            this.$emit('breadcrumbs', [
                {label: 'Registraties', to: '/registrations/select-date'},
                {label: this.dateObj.format('DD-MM-YYYY'), to: '/registrations?date_string=' + this.dateObj.format('YYYY-MM-DD')},
                {label: this.user.name()},
            ])
        },

        getData() {
            axios.get(`harvest-registrations?user_id=${this.id}&date=${this.dateObj.format('YYYY-MM-DD')}&include=${this.include}`).then(response => {
                this.harvestRegistrations = [];
                for (let harvestRegistrationData of response.data.data) {
                    let harvestRegistration = new HarvestRegistration();
                    harvestRegistration.setAttributesFromResponse(harvestRegistrationData);
                    this.harvestRegistrations.push(harvestRegistration);
                }
            });
        }
    },

    created() {

        axios.get('users?id=' + this.id).then(response => {
            this.user.setAttributesFromResponse(response.data.data[0]);
            this.breadcrumbs();
        });


        axios.get('/locations?with-count=latestHarvestAssignments').then(response => {
            for (let locationData of response.data.data) {
                let location = new Location();
                location.setAttributesFromResponse(locationData);
                this.locations.push(location);
            }
        });

        axios.get('/contents?with-count=latestHarvestAssignments').then(response => {
            for (let contentData of response.data.data) {
                let content = new Content();
                content.setAttributesFromResponse(contentData);
                this.contents.push(content);
            }
        });

        axios.get('/chests?with-count=latestHarvestAssignments')
        .then(response => {
            for (let chestData of response.data.data) {
                let chest = new Chest();
                chest.setAttributesFromResponse(chestData);
                this.chests.push(chest);
            }
        });

        axios.get('/sizes?with-count=latestHarvestAssignments')
        .then(response => {
            for (let sizeData of response.data.data) {
                let size = new Size();
                size.setAttributesFromResponse(sizeData);
                this.sizes.push(size);
            }
        });

        axios.get('/types?with-count=latestHarvestAssignments')
        .then(response => {
            for (let typeData of response.data.data) {
                let type = new Type();
                type.setAttributesFromResponse(typeData);
                this.types.push(type);
            }
        });

        this.getData();
    }
};
</script>
