import {AbstractModel} from './abstract-model';
import User from './user.js';
import Location from './location.js';
import Type from './type.js';
import Breed from './breed.js';
import Fraction from './fraction.js';
import FileModel from './file-model.js';
import ProductionControlMeasurement from './production-control-measurement.js';

export default class ProductionControl extends AbstractModel {

    getMutators() {
        return {
            'at': 'date',
            'sowedAt': 'date',
            'createdAt': 'datetime',
        };
    }

    relations()
    {
        return {
            user: User,
            breed: Breed,
            location: Location,
            type: Type,
            fraction: Fraction,
            productionControlMeasurements: ProductionControlMeasurement,
            file: FileModel
        }
    }

    relationPlural()
    {
        return {
            user: false,
            location: false,
            type: false,
            fraction: false,
            file: false,
            productionControlMeasurements: true,
            breed: false
        }
    }

    presentSubstrateStrategy() {
        return this.normalSubstrateStrategy ? 'Normal' : 'Deep';
    }

    yield() {
        return this.productionControlMeasurements.count() > 0
            ? (
                this.productionControlMeasurements.avg('tuberWeight')
                * this.sowingDensity
            )
            : 0;
    }

    yieldPerDay() {
        if (this.yield() === 0 || productionControl.at.diff(productionControl.sowedAt, 'day') === 0) {
            return 0;
        }

        return this.yield() / productionControl.at.diff(productionControl.sowedAt, 'day');
    }
}
