import {AbstractModel} from './abstract-model.js'
import Breed from './breed.js';
import Type from './type.js';
import Fraction from './fraction.js';

export default class BreedPerType extends AbstractModel {

    relations()
    {
        return {
            type: Type,
            breed: Breed,
            fraction: Fraction,
        }
    }

    relationPlural()
    {
        return {
            type: false,
            breed: false,
            fraction: false,
        }
    }

    name() {
        return `${this.breed ? this.breed.name : '??'} ${this.fraction ? this.fraction.milimeters : '??'}`;
    }
}