<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Prijs per klant</h1>
                </div>

                <div class="page-header-settings">
                    <div class="row">
                        <div class="col-xl-6 col-xxl-3">
                            <div class="datepicker-styling">
                                <span class="styled-label">
                                    Van
                                </span>
                                <DatePicker @input="getData" v-model="from" :clearable="false"  />
                            </div>
                        </div>
                        <div class="col-xl-6 col-xxl-3">
                            <div class="datepicker-styling">
                                <span class="styled-label">
                                    Tot
                                </span>
                                <DatePicker @input="getData" v-model="till" :clearable="false" />
                            </div>
                        </div>

                        <div class="col-xl-6 col-xxl-3" style="position: relative">
                            <span class="styled-label">Klanten</span>
                            <MultiSelect
                                :numberOfSelectedOptionsShown="2"
                                :selectAllOption="true"
                                v-model="customerIds"
                                placeholder="Kies één of meerdere klanten"
                                :options="customers.sortBy('name').map(c => ({label: c.name, value: c.id})).all()"
                                @update:modelValue="prevCustomerIds = customerIds; getData()"
                            /><a
                                @click="customerIds = prevCustomerIds;getData()"
                                style="position:absolute; right: 20px; top:52px; background-color:white; border-radius:20px;"
                                v-if="prevCustomerIds !== customerIds"
                            >
                                <Icon name="arrow-path" style="width:24px;" type="solid" />
                            </a>
                        </div>

                        <div class="col-6 col-xl-4 col-xxl-3">
                            <span class="styled-label">
                                Artikelgroepen
                            </span>
                            <MultiSelect
                                :numberOfSelectedOptionsShown="2"
                                :selectAllOption="true"
                                v-model="typeIds"
                                placeholder="Kies één of meerdere artikelgroepen"
                                :options="types.map(t => ({label: t.translate('name'), value: t.id})).all()"
                                @update:modelValue="getData()"
                            />
                        </div>
                        <div class="col-6 col-xl-6">
                            <span class="styled-label">
                                Toon
                            </span>
                            <div class="styled-checkbox d-inline-block">
                                <label>
                                    <input type="checkbox" v-model="withDataOnly">
                                    <span>Klanten met gegevens</span>
                                </label>
                            </div>
                            <div class="styled-checkbox d-inline-block">
                                <label>
                                    <input type="checkbox" disabled checked>
                                    <span>Producten zonder kilo's</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-6 col-xl-2 col-xxl-2">
                            <span class="styled-label">
                                Verpakking
                            </span>
                            <div class="styled-checkbox d-inline-block">
                                <select v-model="packaging" @change="getData()" class="styled-input">
                                    <option :value="null">Beide</option>
                                    <option :value="true">Ja</option>
                                    <option :value="false">Nee</option>
                                </select>
                            </div>
                        </div>
                        <div v-if="customerIds.length === 1" class="col-6 col-xl-2 col-xxl-1">
                            <span class="styled-label">Per</span>
                            <select class="styled-input" @change="getData()" v-model="showPer">
                                <option value="day">Dag</option>
                                <option value="week">Week</option>
                                <option value="month">Maand</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="preparedData.count() > 0 || dataPerPeriod.count() > 0" class="graph-holder price-graph-holder">
        <div class="y-labels">
            <div class="y-label" v-for="n in 10">
                {{ formatNumber((11-n)/10) }}
            </div>
            <div class="y-label">
                0
            </div>
        </div>

        <MultiCustomerGraph
            v-if="customerIds.length > 1"
            @customerClicked="(customer) => {
                customerIds = [customer.id];
                getData();
            }"
            :formatNumber="formatNumber"
            :preparedData="preparedData"
        />

        <OneCustomerGraph
            v-else
            :formatNumber="formatNumber"
            :data="dataPerPeriod" :from="from" :till="till"
            :showPer="showPer"
        />
    </div>
    <div v-else class="no-data-placeholder">
        Geen gegevens gevonden met de huidige filters.
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import MultiSelect from '@/components/Form/MultiSelect.vue';
    import OrderService from '@/services/http/order-service';
    import moment from 'moment';
    import collect from 'collect.js'
    import useCustomerInsightsFunctionalities from './useCustomerInsightsFunctionalities';
    import MultiCustomerGraph from './Price/MultiCustomerGraph.vue';
    import OneCustomerGraph from './Price/OneCustomerGraph.vue';

    const emit = defineEmits(['breadcrumbs']);

    emit('breadcrumbs', [
        {label: 'Insights', to: '/customer-insights'},
        {label: 'Prijs per klant'}
    ]);

    const {
        // Variables
        from, till,
        customerIds, customers,
        typeIds, types,

        // Computed
        selectedCustomers,

        // Functions
        getCustomers, getTypes
    } = useCustomerInsightsFunctionalities();

    const withDataOnly = ref(true);
    const data = ref(collect());
    const packaging = ref(null);
    const prevCustomerIds = ref([])
    const dataPerPeriod = ref(collect())
    const showPer = ref('week');

    const getData = function() {
        if (customerIds.value.length === 0 || typeIds.value.length === 0) {
            data.value = collect();
            dataPerPeriod.value = collect()
            return;
        }
        const filterData = {
            from: from.value,
            till: till.value,
            packaging: packaging.value,
            type_ids: typeIds.value
        };


        if (customerIds.value.length === 1) {
            return OrderService.getCustomerInsightsPricePerPeriod({
                ...filterData,
                customer_id: customerIds.value[0],
                show_per: showPer.value
            }).then(dataItems => {
                dataPerPeriod.value = collect(dataItems);
            });
        }

        filterData['customer_ids'] = customerIds.value;
        if (packaging.value) {
            filterData['packaging'] = packaging.value
        }

        return OrderService.getCustomerInsightsPrice(filterData).then(dataItems => {
            data.value = collect(dataItems);
        });
    }

    const maxValue = computed(() => {
        return 1;
        /* const amount = data.value.max('amount');

        return amount ? amount : 100; */
    });

    const preparedData = computed(() => {
        const customerData = collect();

        for(const customer of selectedCustomers.value) {

            const customerDataItems = data.value.where('customer_id', customer.id);
            const superPrevData = customerDataItems.firstWhere('period', 'super_previous');
            const prevData = customerDataItems.firstWhere('period', 'previous');
            const curData = customerDataItems.firstWhere('period', 'current');

            if (
                withDataOnly.value && (! superPrevData || superPrevData.amount == 0)
                && (! prevData || prevData.amount == 0) && (! curData || curData.amount == 0)
            ){
                continue;
            }

            customerData.push({
                superPrevious: superPrevData ? superPrevData.amount : 0,
                previous: prevData ? prevData.amount : 0,
                current: curData ? curData.amount : 0,
                name: customer.name,
                id: customer.id
            });
        }

        return customerData;
    });

    const formatNumber = function(number) {
        return new Intl.NumberFormat('nl-NL', { minimumSignificantDigits: 2, maximumSignificantDigits: 2 }).format(number);
    }

    Promise.all([
        getCustomers(),
        getTypes()
    ]).then(() => {
        getData();
        prevCustomerIds.value = customerIds.value
    })
</script>
