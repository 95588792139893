<template>
    <modal class="edit-article" @save="save()" @close="$emit('close')">
        <template v-slot:header>Kosten {{ obj.name }} wijzigen</template>

        <label class="styled-label">
            <span>Kosten in centen</span>
            <input class="styled-input" type="number" v-model="cents">
        </label>

    </modal>
</template>

<script setup>
    import ChestService from '@/services/http/chest-service';
    import CoverSheetService from '@/services/http/cover-sheet-service';
    import PackagingService from '@/services/http/packaging-service';
    import { ref } from 'vue'
    const props = defineProps(['obj']);
    const emit = defineEmits(['saved', 'close']);

    const cents = ref(props.obj.cents);

    const save = function() {
        getService().edit(props.obj.id, {
            cents: cents.value
        }).then(() => {
            emit('saved');
            emit('close');
        });
    }

    const getService = function() {
        if (props.obj.modelName() === 'Fust') {
            return ChestService;
        }

        if (props.obj.modelName() === 'Dekvel') {
            return CoverSheetService;
        }

        return PackagingService;
    }
</script>
