import StartStock from "../../models/start-stock";
import { AbstractHttpService } from "./abstract-http-service";

class StartStockService extends AbstractHttpService {

    endpoint() {
        return 'start-stocks';
    }

    createModel(data) {
        return new StartStock(data);
    }
}

export default new StartStockService;
