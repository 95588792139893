<template>
    <div class="container">
        <div class="page-header">
            <h1>Werklijsten</h1>
        </div>
        <div class="page-toolbar">
            <div class="toolbar-item">

                <div class="styled-checkbox">
                    <label>
                        <input type="checkbox" id="inactive" v-model="showInactive">
                        <span for="inactive">Inactieve medewerkers weergeven</span>
                    </label>
                </div>

            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="tabs-holder">
                    <ul class="tabs">
                        <li v-for="year in years" @click="activeYear=year" :class="{active: year === activeYear}" :key="year">
                            <a>{{ year }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="users-search-holder">
            <input class="styled-input" type="text" placeholder="Medewerker zoeken" v-model="search">
        </div>

        <div class="row">
            <div class="col-12">
                <div id="users">
                    <div class="users-list">
                        <User
                            v-for="(index, user) in usersFiltered"
                            :activeYear="activeYear"
                            :user="index" :key="user.id"
                        ></User>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import UserService from '@/services/http/user-service-new';
    import User from './User.vue';
    import { ref, computed } from 'vue';

    const users = ref([]);
    const years = _.range((new Date()).getFullYear(), 2016, -1);
    const activeYear = ref((new Date()).getFullYear());
    const showInactive = ref(false);
    const search = ref('');

    const usersFiltered = computed(() => {

        let filteredUsers = users.value.filter(u => u.firstname !== 'Erik' && u.lastname !== 'Jong');

        if (search.value !== '') {
            filteredUsers = filteredUsers.filter((u) => u.name().toLowerCase().includes(search.value.toLowerCase()));
        }

        filteredUsers = filteredUsers.sort((a,b) => a.name() >= b.name() ? 1 : -1);

        if (! showInactive.value) {
            return filteredUsers.filter(u => (! u.deactivatedAt));
        }

        return filteredUsers;
    });

    const getUsers = function() {
        UserService.get({
            filters: {'only-real-persons': 1, deactivated: 1, orders: 'surname'},
            include: ['lastFourWeekWorkSessions', 'avatars', 'documents', 'userType', 'lastWorkSession'],
            includeFilter: {'payOuts': {last_x_weeks: 4}}
        }).then(function(userModels) {
            users.value = userModels;

        });
        /* axios.get('users?include=lastFourWeekWorkSessions,avatars,documents,userType,lastWorkSession&only-real-persons=1&deactivated=1&order=surname')
        .then(response => {
            users.value = [];
            for (let userData of response.data.data) {
                let user = new User();
                console.log(user);
                user.setAttributesFromResponse(userData);
                users.value.push(user);
            }
        }); */
    };



    getUsers();
</script>
