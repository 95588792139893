import DayHarvestIndex from '@/pages/DayHarvest/Index.vue';

export default [
    {
        path: '/day-harvest',
        component: DayHarvestIndex,
        name: 'day-harvest.index',
        meta: { permissions: ['registrations'] }
    }
];
