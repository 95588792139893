import HarvestCorrection from "../../models/harvest-correction";
import { AbstractHttpService } from "./abstract-http-service";

class HarvestCorrectionService extends AbstractHttpService {

    endpoint() {
        return 'harvest-corrections';
    }

    createModel(data) {
        return new HarvestCorrection(data);
    }
}

export default new HarvestCorrectionService;
