<template>
    <div class="container">

        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Oogst totalen</h1>
                    <a class="page-header-button" @click="mode = (mode === 'types' ? 'articles' : 'types');">Toon per {{ mode === 'types' ? 'verkoopartikel' : 'artikelgroep' }}</a>
                    <div>
                        <button style="margin-left: 10px;" @click="exportPerWeek()">
                            <fab-button icon="arrow-down-tray"></fab-button>
                        </button>
                    </div>
                </div>

                <div class="page-header-settings">
                    <div class="row">

                        <div class="col-12 col-md-5">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-control datepicker-styling">
                                        <span class="styled-label">
                                            Van
                                        </span>
                                        <DatePicker @input="getHarvestRegistrations" v-model="from" />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-control datepicker-styling">
                                        <span class="styled-label">
                                            Tot
                                        </span>
                                        <DatePicker @input="getHarvestRegistrations" v-model="till" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-2 styled-checkbox text-center">
                            <label style="margin: 40px auto">
                                <input type="checkbox" v-model="compare">
                                <span>Vergelijken</span>
                            </label>
                        </div>

                        <div class="col-12 col-md-5">
                            <div class="row" style="border-radius: 10px" :style="{ 'background-color': (compare ? '#b6e1b6' : '#eee'), 'opacity': (compare ? '1' : '.35')}">
                                <div class="col-6">
                                    <div class="form-control datepicker-styling">
                                        <span class="styled-label">
                                            Van
                                        </span>
                                        <DatePicker :disabled="! compare" @input="getCompareHarvestRegistrations" v-model="compareFrom" />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-control datepicker-styling">
                                        <span class="styled-label">
                                            Tot
                                        </span>
                                        <DatePicker :disabled="! compare" @input="getCompareHarvestRegistrations" v-model="compareTill" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-6 col-md-4 col-lg-3">
                            <div class="form-control datepicker-styling">
                                <span class="styled-label">
                                    Type
                                </span>
                                <select class="styled-select" v-model="filters.typeId">
                                    <option :value="null">Selecteer een type</option>
                                    <option v-for="(type, index) of types" :key="index" :value="type.id">{{ type.translate('name') }}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-6 col-md-4 col-lg-3">
                            <span class="styled-label">
                                Artikelen
                            </span>
                            <MultiSelect
                                v-model="filters.articles"
                                :options="articleFilters.map(af => ({label: af.name, value: af}))"
                            />
                        </div>

                        <div class="col-6 col-md-4 col-lg-3" v-if="locations.count() > 0">
                            <span class="styled-label">
                                Locaties
                            </span>
                            <MultiSelect
                                v-model="filters.locations"
                                :options="locations.all().map(l => ({label: l.name, value: l}))"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="widget-holder">
            <div v-if="chests.length > 0 && contents.length > 0 && sizes.length > 0 && types.length > 0 && currentData.length > 0" id="harvest-report-container">

                <table class="styled-table" >
                    <thead>
                        <tr>
                            <th style="text-align: left; padding-left: 15px;">Naam</th>
                            <th>Aantal</th>
                            <th v-if="compare">Vergelijk Aantal</th>
                        </tr>
                    </thead>

                    <tr class="styled-table-row" v-for="(dataRow, index) of subjects" :key="index" >
                        <td>{{ mode === 'articles' ? dataRow.name : dataRow.translate('name')  }}</td>
                        <td class="text-center">
                            {{
                                filteredData
                                .filter((fd) => filterMode(fd, dataRow))
                                .map((fd) => {
                                    const content = contents.find((c) => c.id === fd.content_id);
                                    return (parseInt(fd.amount) * (content.name !== 'Kilo' ? fd.content_id : 1));
                                })
                                .reduce((a, b) => a + b, 0)
                            }}
                        </td>
                        <td class="text-center" v-if="compare">
                            {{
                                filteredCompareData
                                .filter((fd) => filterMode(fd, dataRow))
                                .map((fd) => {
                                    const content = contents.find((c) => c.id === fd.content_id);
                                    return (parseInt(fd.amount) * (content.name !== 'Kilo' ? fd.content_id : 1));
                                })
                                .reduce((a, b) => a + b, 0)
                            }}
                        </td>
                    </tr>
                    <tr class="styled-table-row">
                        <td >Totaal</td>
                        <td class="text-center">
                            {{
                                filteredData
                                .map((fd) => {
                                    const content = contents.find((c) => c.id === fd.content_id);
                                    return (parseInt(fd.amount) * (content.name !== 'Kilo' ? fd.content_id : 1));
                                })
                                .reduce((a, b) => a + b, 0)
                            }}
                        </td>
                        <td class="text-center" v-if="compare">
                            {{
                                filteredCompareData
                                .map((fd) => {
                                    const content = contents.find((c) => c.id === fd.content_id);
                                    return (parseInt(fd.amount) * (content.name !== 'Kilo' ? fd.content_id : 1));
                                })
                                .reduce((a, b) => a + b, 0)
                            }}
                        </td>
                    </tr>

                </table>
            </div>
            <div v-else class="no-data-placeholder">
                <p>Er zijn geen oogstregistraties</p>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import moment from 'moment';
    import exportPerWeekExcel from './ExportPerWeekExcel.vue';
    import LocationService from '../../services/http/location-service';
    import collect from 'collect.js';
    import MultiSelect from '../../components/Form/MultiSelect.vue';
    import { useCcstStore } from '../../stores/ccst';
    import { hydrate } from 'vue';
    import XLSX from 'xlsx'
    import { compileScript } from 'vue/compiler-sfc';

    export default {
        props: ['year', 'month'],

        components: {MultiSelect, exportPerWeekExcel},

        computed: {
            ...mapState(useCcstStore, ['chests', 'contents', 'sizes', 'types']),

            subjects() {
                if (this.mode === 'types') {
                    return this.types;
                }

                let groupedData = this.getHarvestProductsFromHarvestRegistration(this.filteredData);

                if (this.compare) {
                    groupedData = this.getHarvestProductsFromHarvestRegistration(this.filteredCompareData, groupedData)
                }

                for (const dataItem of groupedData) {
                    dataItem['sequence'] = this.types.find((type) => type.id === dataItem.type_id).sequence;
                }

                groupedData.sort((a, b) => (a.sequence > b.sequence ? 1 : -1));

                return groupedData;
            },

            articleFilters() {
                let groupedData = this.getHarvestProductsFromHarvestRegistration(this.currentData);

                if (this.compare) {
                    groupedData = this.getHarvestProductsFromHarvestRegistration(this.compareData, groupedData)
                }

                groupedData = groupedData.filter(gd => gd !== undefined);

                return groupedData;
            },

            filteredData() {
                return this.filterData(this.currentData);
            },

            filteredCompareData() {
                return this.filterData(this.compareData);
            }
        },

        data() {
            return {
                currentData: [],
                from: moment().startOf('day').format('YYYY-MM-DD'),
                till: moment().endOf('day').format('YYYY-MM-DD'),

                compare: false,
                compareData: [],
                compareFrom: moment().startOf('day').subtract(1, 'year').format('YYYY-MM-DD'),
                compareTill: moment().endOf('day').subtract(1, 'year').format('YYYY-MM-DD'),

                mode: 'articles',

                articles: collect(),
                locations: collect(),

                filters: {
                    articles: [],
                    locations: [],
                    typeId: null
                }
            }
        },


        methods: {
            filterMode(subject, typeOrProduct) {
                return (
                    this.mode === 'articles'
                    && subject.content_id === typeOrProduct.content_id
                    && subject.chest_id === typeOrProduct.chest_id
                    && subject.size_id === typeOrProduct.size_id
                    && subject.type_id === typeOrProduct.type_id
                    ||
                    this.mode === 'types'
                    && subject.type_id === typeOrProduct.id
                )
            },

            getHarvestProductsFromHarvestRegistration(data, groupedData = []) {
                for (const dataItem of data) {

                    const groupedDataItem = groupedData.find(gd => (
                        gd.chest_id === dataItem.chest_id
                        && gd.content_id === dataItem.content_id
                        && gd.size_id === dataItem.size_id
                        && gd.type_id === dataItem.type_id
                    ));

                    if (! groupedDataItem) {
                        groupedData.push(dataItem);
                    }

                    groupedData.push()
                }

                return groupedData;
            },

            name(row) {
                if (this.mode === 'types' || ! ('chest_id' in row)) {
                    return this.types.filter(type => type.id === row['type_id'])[0].translate('name');
                }

                return this.chests.filter(chest => chest.id === row['chest_id'])[0].name + ' '
                    + this.sizes.filter(size => size.id === row['size_id'])[0].translate('name') + ' '
                    + (this.types.filter(type => type.id === row['type_id']).length ? this.types.filter(type => type.id === row['type_id'])[0].translate('name') : '') + ' '
                    + this.contents.filter(content => content.id === row['content_id'])[0].name;
            },

            setArticles()
            {
                this.articles = collect();
                for (let article of this.currentData) {
                    this.articles.push({...article, name: name(dataRow)})
                }
            },

            getLocations()
            {
                LocationService.get().then(locations => {
                    this.locations = locations;
                })
            },

            filterData(dataToFilter)
            {
                let locationIds = [];

                if (this.filters.locations.length) {
                    locationIds = this.filters.locations.filter(l => l !== undefined).map(l => l.id);
                }

                return dataToFilter.filter((cd) => {

                    if (this.filters.typeId && cd.type_id !== this.filters.typeId) {
                        return false;
                    }

                    if (this.filters.articles.length && this.filters.articles.filter((a) =>
                        a.chest_id === cd.chest_id
                        && a.content_id === cd.content_id
                        && a.size_id === cd.size_id
                        && a.type_id === cd.type_id
                    ).length === 0) {
                        return false;
                    }

                    if (locationIds.length && ! locationIds.includes(cd.location_id)) {
                        return false;
                    }

                    return true;
                });
            },


            getHarvestRegistrations()
            {
                this.queryHarvestRegistrations(this.from, this.till).then(data => {
                    this.filters.articles = [];
                    this.currentData = data;
                });
            },

            getCompareHarvestRegistrations()
            {
                this.queryHarvestRegistrations(this.compareFrom, this.compareTill).then(data => {
                    this.compareData = data;
                });
            },

            queryHarvestRegistrations(fromUnparsed, tillUnparsed, groupOnWeek = false)
            {
                let from = moment(fromUnparsed, "YYYY-MM-DD");
                let till = moment(tillUnparsed, "YYYY-MM-DD");

                const filters = {
                    after: from.format('YYYY-MM-DD') + ' 00:00:00',
                    before: till.format('YYYY-MM-DD') + ' 23:59:59',
                    'group-by': 'chest_id,content_id,size_id,type_id,location_id' + (groupOnWeek ? ',YEARWEEK(`date`, 3)'  : ''),
                    'select': 'chest_id,content_id,size_id,type_id,location_id' + (groupOnWeek ? ',YEARWEEK(`date`, 3) as week'  : '')
                };

                const queryString = new URLSearchParams(filters).toString();

                let url = '/harvest-registrations/group-by?';

                return axios.get(url + queryString)
                .then(response => {

                    for (let dataItem of response.data) {
                        dataItem['name'] = this.name(dataItem);
                        dataItem['id'] = Math.floor(Math.random() * 1000);
                    }

                    return response.data;
                });
            },

            async exportPerWeek() {
                const promises = [this.queryHarvestRegistrations(this.from, this.till, true)];
                if (this.compare) {
                    promises.push(this.queryHarvestRegistrations(this.compareFrom, this.compareTill, true))
                }
                const results = await Promise.all(promises);

                const result = results[0];
                const compareResult = this.compare ? results[1] : null;

                const data = [];
                const today = moment();


                for (let subject of this.subjects) {
                    let dataItem = {
                        Naam: subject.name
                    }

                    const current = moment(this.from + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss').startOf('isoWeek');
                    const end = moment(this.till + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss').endOf('isoWeek');

                    while(current.isSameOrBefore(end)) {
                        dataItem[
                            (today.isoWeekYear() === current.isoWeekYear() ? '' : current.isoWeekYear() + ' ')
                            + 'Week ' + current.isoWeek()
                        ] = this.getPerWeekExportData(result, current, subject);

                        current.add(1, 'week');
                    }

                    dataItem['totaal'] = result
                        .filter((fd) => {
                            return (
                                this.mode === 'articles'
                                && subject.content_id === fd.content_id
                                && subject.chest_id === fd.chest_id
                                && subject.size_id === fd.size_id
                                && subject.type_id === fd.type_id
                                ||
                                this.mode === 'types'
                                && subject.id === fd.type_id
                            )
                        })
                        .map((fd) => {
                            return (parseInt(fd.amount) * (fd.content_id !== 28 ? fd.content_id : 1));
                        })
                        .reduce((a, b) => a + b, 0)


                    if (this.compare) {
                        const current = moment(this.compareFrom + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss').startOf('isoWeek');
                        const end = moment(this.compareTill + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss').endOf('isoWeek');

                        while(current.isSameOrBefore(end)) {
                            dataItem[
                                'vergelijk '
                                + (today.isoWeekYear() === current.isoWeekYear() ? '' : current.isoWeekYear() + ' ')
                                + 'Week ' + current.isoWeek()
                            ] = this.getPerWeekExportData(compareResult, current, subject);

                            current.add(1, 'week');
                        }

                        dataItem['Vergelijk totaal'] = compareResult
                            .filter((fd) => {
                                return (
                                    this.mode === 'articles'
                                    && subject.content_id === fd.content_id
                                    && subject.chest_id === fd.chest_id
                                    && subject.size_id === fd.size_id
                                    && subject.type_id === fd.type_id
                                    ||
                                    this.mode === 'types'
                                    && subject.id === fd.type_id
                                )
                            })
                            .map((fd) => {
                                return (parseInt(fd.amount) * (fd.content_id !== 28 ? fd.content_id : 1));
                            })
                            .reduce((a, b) => a + b, 0)
                    }


                    data.push(dataItem);
                }


                /* data.push({Naam: '', Aantal: ''});
                data.push({Naam: 'Van', Aantal: this.from});
                data.push({Naam: 'Tot', Aantal: this.till});

                if (this.compare) {
                    data.push({Naam: 'Vergelijk van', Aantal: this.compareFrom});
                    data.push({Naam: 'Vergelijk tot', Aantal: this.compareTill});
                }

                data.push({Naam: 'Type', Aantal: this.type});
                data.push({Naam: 'Artikelen', Aantal: this.articles.length > 0 ? this.articles.map(a => a.name).join(', ') : 'Geen'});
                data.push({Naam: 'Locaties', Aantal: this.locations.filter(l => l !== undefined).length > 0 ? this.locations.filter(l => l !== undefined).map(a => a.name).join(', ') : 'Geen'});
 */
                // On Click Excel download button

                // export json to Worksheet of Excel
                // only array possible
                var workSheet = XLSX.utils.json_to_sheet(data);

                // A workbook is the naam given to an Excel file
                var workBook = XLSX.utils.book_new() // make Workbook of Excel

                // add Worksheet to Workbook
                // Workbook contains one or more worksheets
                XLSX.utils.book_append_sheet(workBook, workSheet, 'Oogst totalen') // sheetnaam is naam of Worksheet

                // export Excel file
                const naam = `Oogsttotalen_${moment(this.from, 'YYYY-MM-DD').format('GGGG-WW')}_${moment(this.till, 'YYYY-MM-DD').format('GGGG-WW')}.xlsx`;
                XLSX.writeFile(workBook, naam)
            },

            getPerWeekExportData(result, date, subject) {
                return result
                    .filter((fd) => {

                        return (
                            this.mode === 'articles'
                            && subject.content_id === fd.content_id
                            && subject.chest_id === fd.chest_id
                            && subject.size_id === fd.size_id
                            && subject.type_id === fd.type_id
                            && fd.week == date.isoWeekYear() + date.format('WW')
                            ||
                            this.mode === 'types'
                            && subject.id === fd.type_id
                            && fd.week == date.isoWeekYear() + date.format('WW')
                        )
                    })
                    .map((fd) => {
                        return (parseInt(fd.amount) * (fd.content_id !== 28 ? fd.content_id : 1));
                    })
                    .reduce((a, b) => a + b, 0);
            }

        },

        created() {
            this.getHarvestRegistrations();
            this.getLocations();
        }
    }
</script>
