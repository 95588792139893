<template>
    <div id="formula" class="container">

        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Formule doorlooptijden</h1>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-2">
                <h5>Inhoud</h5>
            </div>
            <div class="col-2">
                <h5>Type</h5>
            </div>
            <div class="col-2">
                <h5>Sortering</h5>
            </div>
            <div class="col-2">
                <h5>Zaden per meter</h5>
            </div>
            <div class="col-2">
                <h5>Kwaliteit</h5>
            </div>
            <div class="col-2">
                <h5>Buitentemperatuur</h5>
            </div>
        </div>

        <hr>

        <div class="row align-items-center">
            <div class="col-2">
                <div v-for="content of contentsFiltered" :key="content.id">
                    <label>
                        <span class="label">{{ content.name }}</span>
                        <input class="form-control text-center" @change="changed('/contents/' + content.id, 'formula_factor', $event)" type="number" step="0.01" v-model="content.formula_factor">
                    </label>
                </div>
            </div>
            <div class="col-2">
                <div v-for="TypeModel of typesFiltered" :key="TypeModel.id">
                    <label>
                        <span>{{ TypeModel.translate('name') }}</span>
                        <input class="form-control text-center" @change="changed('/types/' + TypeModel.id, 'formula_factor', $event)" type="number" step="0.01" v-model="TypeModel.formula_factor">
                    </label>
                </div>
            </div>
            <div class="col-2">
                <div v-for="size of sizes" :key="size.id">
                    <label>
                        <span>{{ size.translate('name') }}</span>
                        <input class="form-control text-center" @change="changed('/sizes/' + size.id, 'formula_factor', $event)" type="number" step="0.01" v-model="size.formula_factor">
                    </label>
                </div>
            </div>
            <div class="col-2">
                <div v-if="this.formulaFactors.length != 0">
                    <label>
                        <span>Zaden per meter</span>
                        <input class="form-control text-center" type="number" step="0.01" @change="changed('/formula-factors/' + formulaFactors.find(formulaFactor => formulaFactor.name == 'seeds_per_meter').id, 'formula_factor', $event)" v-model="formulaFactors.find(formulaFactor => formulaFactor.name == 'seeds_per_meter').formula_factor">
                    </label>
                </div>
            </div>
            <div class="col-2">
                <div v-for="TypeModel of qualityFiltered" :key="TypeModel.id">
                    <label>
                        <span>{{ TypeModel.translate('name') }}</span>
                        <input class="form-control text-center" @change="changed('/types/' + TypeModel.id, 'quality_formula_factor', $event)" type="number" step="0.01" v-model="TypeModel.quality_formula_factor">
                    </label>
                </div>
            </div>
            <div class="col-2">
                <div v-if="this.formulaFactors.length != 0">
                    <label>
                        <span>Buitentemperatuur</span>
                        <input class="form-control text-center" type="number" step="0.01" @change="changed('/formula-factors/' + formulaFactors.find(formulaFactor => formulaFactor.name == 'temperature').id, 'formula_factor', $event)" v-model="formulaFactors.find(formulaFactor => formulaFactor.name == 'temperature').formula_factor">
                    </label>
                </div>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col text-right">
                <button type="button" class="btn-save btn btn-success" @click="save()">Opslaan</button>
            </div>
        </div>

    </div>
</template>

<script>
    import FormulaFactor from '../../models/formula-factor.js';
    import { mapState } from 'pinia';
    import { useCcstStore } from '../../stores/ccst.js';
    import { useToast } from 'vue-toast-notification';

    export default {
        computed: {
            contentsFiltered() {
                return this.contents.filter(content => (['x8', 'x9', 'x10', 'x12', 'x15', 'x20'].indexOf(content.name) >= 0));
            },
            typesFiltered() {
                return this.types.filter(type => (type.name !== 'micro'));
            },
            qualityFiltered() {
                return this.types.filter(type => (['mix', 'rainbow'].indexOf(type.name) < 0));
            },

            ...mapState(useCcstStore, ['chests', 'contents', 'sizes', 'types']),
        },

        data() {
            return {
                formulaFactors: [],
                changes: {}
            }
        },

        methods: {
            save() {
                for (let index in this.changes) {
                    axios.put(this.changes[index].url, this.changes[index].data).then(response => {
                        delete this.changes[index];
                    })
                }

                useToast().success('Opgeslagen');
            },

            getFormulaFactors() {
                axios.get('formula-factors')
                .then(response => {
                    this.formulaFactors = [];
                    for (let formulaFactorData of response.data.data) {
                        let formulaFactor = new FormulaFactor();
                        formulaFactor.setAttributesFromResponse(formulaFactorData);
                        this.formulaFactors.push(formulaFactor);
                    }
                });
            },

            changed(url, key, evt)
            {
                if (! (url in this.changes)) {
                    this.changes[url] = {url: url, data: {}};
                }

                this.changes[url].data[key] = evt.target.value;
            },

        },

        created() {

            this.getFormulaFactors();


            this.$emit('breadcrumbs', [
                {label: 'Instellingen', to: '/settings'},
                {label: 'Formule doorlooptijden'}
            ])
            //breadcrumbs
        }
    }
</script>
