<template>
    <modal @save="onsubmit()" @close="$emit('close')">
        <template v-slot:header>Overeenkomst verwijderen</template>

        <p >
            Weet je zeker dat je deze overeenkomst wilt verwijderen?
        </p>

        <template v-slot:close>Annuleren</template>

        <template v-slot:save>Doorgaan</template>
    </modal>
</template>

<script setup>
    import { useToast } from 'vue-toast-notification';
    const props = defineProps(['agreement']);
    const emits = defineEmits(['deleted']);

    const onsubmit = function() {
        axios.delete('/users/' + props.agreement.user_id + '/agreements/' + props.agreement.id)
        .then(response => {
            useToast().success('Overeenkomst verwijderd');
            emits('deleted', props.agreement.id);
            document.querySelector('body').classList = '';
        })
    }

</script>
