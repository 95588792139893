<template>
    <modal @save="onsubmit()" @close="$emit('close')">
        <template v-slot:header>
            {{ user.name() }} uitnodigen
        </template>

        <p>
            Weet je zeker dat je {{ user.name() }} uitnodigen?
        </p>


        <template v-slot:close>Annuleren</template>

        <template v-slot:save>Doorgaan</template>
    </modal>
</template>

<script>
import { useToast } from 'vue-toast-notification';

    export default {
        props: ['user'],
        methods: {
            onsubmit() {
                axios.get('users/invite/' + this.user.id).then(response => {
                    useToast().success('Medewerker uitgenodigd');
                    this.$emit('close');
                    document.querySelector('body').classList = '';
                });
            }
        }
    };
</script>
