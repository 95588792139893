<template>
    <modal id="create" @save="onSubmit()" @close="$emit('close')">
        <template v-slot:header>Voorraad wijzigen</template>
        <div class="row">
            <div class="col text-center">
                <form @submit.prevent>
                    <label class="styled-label" for="stock-amount">
                        Voorraad wijzigen naar
                    </label>
                    <input class="styled-input" style="text-align:center; width: 120px" id="stock-amount" type="number" v-model="newAmount">
                </form>
            </div>
        </div>
    </modal>
</template>

<script setup>
    import stockService from '../../services/http/stock-service';
    import Form from './../../helpers/form.js';
    import { useToast } from 'vue-toast-notification';
    import { ref } from 'vue';

    const props = defineProps(['stockInfo']);
    const emits = defineEmits(['close']);

    const newAmount = ref(props.stockInfo.amount)

    const onSubmit = function() {
        const data = {...props.stockInfo};
        data.amount = newAmount.value - props.stockInfo.amount;

        stockService.create(data).then(() => {
            emits('close');
            document.querySelector('body').classList = '';
            useToast().success('Voorraad gewijzigd');
        });
    }
</script>
