import HarvestProduct from "../../models/harvest-product";
import { AbstractHttpService } from "./abstract-http-service";

class HarvestProductService extends AbstractHttpService {

    endpoint() {
        return 'harvest-products';
    }

    createModel(data) {
        return new HarvestProduct(data);
    }
}

export default new HarvestProductService;
