<template>
    <!-- <download-excel
        class   = "holder"
        :data   = "json_data"
        :fields = "json_fields"
        :naam    = "naam"
    >
        <fab-button icon="ion-android-download"></fab-button>

    </download-excel> -->
    <button type="button" v-on:click="onexport">
        <fab-button icon="arrow-down-tray"></fab-button>
    </button>
</template>

<script>
    import moment from 'moment';
    import XLSX from "xlsx"

    export default {
        props: ['payOutsData', 'activeWeek', 'activeYear', 'user'],

        watch: {
            payOutsData() {
                this.json_data = [];
                let totaal = 0;
                for (let index in this.payOutsData) {
                    let group = this.payOutsData[index];
                    let obj = {};
                    obj['naam'] = group['name'];
                    obj['aantal'] = group['amount'];
                    obj['prijs'] = '€' + (group['price'] / 100);
                    let totaalRow = (group['amount'] * group['price']) / 100;
                    obj['totaal'] = '€' + totaalRow;
                    totaal += totaalRow;

                    if (group['name'] == 'Uren') {
                        this.json_data.push({'naam': '', 'aantal' : '', 'prijs': '', 'totaal' : ''});
                    }

                    this.json_data.push(obj);
                }

                this.json_data.push({'naam': '', 'aantal' : '', 'prijs': '', 'totaal' : ''});
                this.json_data.push({'naam': '', 'aantal' : '', 'prijs': 'Subtotaal week', 'totaal' : '€' + Math.round(totaal * 100) / 100});


            },

            user() {
                this.naam = 'Werklijst_' + this.activeYear + '_' + (parseInt(this.activeWeek)) + '_' + this.user.name().replace(/ /g, '_') + '.xlsx';
            },

            activeWeek() {
                this.naam = 'Werklijst_' + this.activeYear + '_' + (parseInt(this.activeWeek)) + '_' + this.user.name().replace(/ /g, '_') + '.xlsx';
            },
        },

        methods: {
            onexport () {
                // On Click Excel download button

                // export json to Worksheet of Excel
                // only array possible
                var workSheet = XLSX.utils.json_to_sheet(this.json_data)

                // A workbook is the naam given to an Excel file
                var workBook = XLSX.utils.book_new() // make Workbook of Excel
                // add Worksheet to Workbook
                // Workbook contains one or more worksheets
                XLSX.utils.book_append_sheet(workBook, workSheet, 'Uitbetaling') // sheetAnaam is naam of Worksheet

                // export Excel file
                XLSX.writeFile(workBook, this.naam)
            }
        },

        data() {
            return {
                json_fields: {
                    ["Week " + (parseInt(this.activeWeek)+1)]: 'naam',
                    "Aantal kisten": 'aantal',
                    'Prijs per eenheid': 'prijs',
                    'Bedrag': 'totaal',
                },
                json_data: [
                    {
                        'naam': 'Tony Peña',
                        'aantal': 'New York',
                        'prijs': 'United States',
                        'totaal': '1978-03-15',

                    },
                ],
                json_meta: [
                    [
                        {
                            'key': 'charset',
                            'value': 'utf-8'
                        }
                    ]
                ],
                naam: 'Werklijst_' + this.activeYear + '_' + (parseInt(this.activeWeek)) + '_' + this.user.name().replace(/ /g, '_') + '.xlsx'
            }
        },

        created()
        {
        }
    }
</script>
