/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import { useAuthStore } from './stores/auth';
import axios from 'axios';
import { useRouter } from 'vue-router';
window.axios = axios;

import _ from 'lodash';
window._ = _;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true;
window.axios.defaults.baseURL = '/api/';

axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {

    if (error.response.status === 401 && error.response.data.message === 'Password needs to be renewed.') {
        //useRouter().push('/renew-password');
        //window.location.href = '/renew-password';
    }

    if (
        [401, 419].includes(error.response.status)
        && (
            error.response.data.message !== 'Password needs to be renewed.'
            && window.location.pathname !== '/renew-password'
        )
    ) {
        useAuthStore().logout();
        window.location.href = '/';
    }

    return Promise.reject(error);
});




