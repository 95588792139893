export default {

    // called before bound element's attributes
    // or event listeners are applied
    created(el, binding, vnode, prevVnode) {},

    // called right before the element is inserted into the DOM.
    beforeMount(el, binding, vnode, prevVnode) {},

    // called when the bound element's parent component
    // and all its children are mounted.
    mounted(el, binding, vnode, prevVnode) {
        el.tooltipText = binding.value;
        el.cursorTooltip = (binding.arg === 'cursor');
        el.tooltipId = Math.round(Math.random() * 99999);

        if (el.tooltipText !== null) {
            el.addEventListener('mouseenter', createTooltip);

            if (el.cursorTooltip) {
                el.addEventListener('mousemove', moveTooltip);
            }

            el.addEventListener('mouseleave', removeTooltip);
        }
    },

    // called before the parent component is updated
    beforeUpdate(el, binding, vnode, prevVnode) {
        el.tooltipText = binding.value;
        el.cursorTooltip = (binding.arg === 'cursor');

        if (el.tooltipText !== null) {
            el.removeEventListener('mouseenter', createTooltip);

            if (el.cursorTooltip) {
                el.removeEventListener('mousemove', moveTooltip);
                removeTooltip(el)
            }

            el.removeEventListener('mouseleave', removeTooltip);
        }
    },

    // called after the parent component and
    // all of its children have updated
    updated(el, binding, vnode, prevVnode) {
        el.tooltipText = binding.value;
        el.cursorTooltip = (binding.arg === 'cursor');

        if (el.tooltipText !== null) {
            el.addEventListener('mouseenter', createTooltip);

            if (el.cursorTooltip) {
                el.addEventListener('mousemove', moveTooltip);
            }

            el.addEventListener('mouseleave', removeTooltip);
        }
    },

    // called before the parent component is unmounted
    beforeUnmount(el, binding, vnode, prevVnode) {
        el.tooltipText = binding.value;
        el.cursorTooltip = (binding.arg === 'cursor');

        if (el.tooltipText !== null) {
            el.removeEventListener('mouseenter', createTooltip);

            if (el.cursorTooltip) {
                el.removeEventListener('mousemove', moveTooltip);
            }
            
            el.removeEventListener('mouseleave', removeTooltip);
            removeTooltipFromElement(el);
        }
    },

    // called when the parent component is unmounted
    unmounted(el, binding, vnode, prevVnode) {}
}

function createTooltip(evt) {
    const tooltipEl = document.createElement('DIV');

    tooltipEl.classList.add('wb-tooltip');

    if (typeof evt.currentTarget.tooltipText === 'function') {
        tooltipEl.innerHTML = '...'

        evt.currentTarget.tooltipText().then(value => {
            tooltipEl.innerHTML = value;
        })

    } else if (typeof evt.currentTarget.tooltipText === 'object') {
        tooltipEl.innerHTML = evt.currentTarget.tooltipText.text;
        if ('class' in evt.currentTarget.tooltipText && evt.currentTarget.tooltipText.class) {
            tooltipEl.classList.add(...evt.currentTarget.tooltipText.class.split(' '));
        }
    } else {
        tooltipEl.innerHTML = evt.currentTarget.tooltipText;
    }

    if (evt.currentTarget.cursorTooltip) {
        tooltipEl.id = evt.currentTarget.tooltipId;
        tooltipEl.style.position = 'fixed';

        //tooltipEl.style.top = evt.clientY + 'px';
        tooltipEl.style.bottom = document.documentElement.clientHeight-evt.clientY + 'px';
        tooltipEl.style.top = 'initial';


        if (evt.clientX + 220 > document.documentElement.clientWidth) {
            tooltipEl.style.right = (document.documentElement.clientWidth - evt.clientX) + 'px';
            tooltipEl.classList.add('to-the-left');
            tooltipEl.classList.remove('to-the-right');
        } else {
            tooltipEl.style.left = evt.clientX + 'px';
            tooltipEl.classList.add('to-the-right');
            tooltipEl.classList.remove('to-the-left');
        }

        tooltipEl.classList.add('cursor-tooltip');
        document.querySelector('body').appendChild(tooltipEl);

        return;
    }


    const position = getComputedStyle(evt.currentTarget).position
    evt.currentTarget.dataset.position = position;
    if (evt.currentTarget.style.position === '') {
        evt.currentTarget.style.position = position;
        evt.currentTarget.classList.add('active-tooltip');
    }

    evt.currentTarget.appendChild(tooltipEl);
}

function removeTooltip(evt){
    removeTooltipFromElement(evt.currentTarget);
}

function removeTooltipFromElement(el) {
    if (! el) {
        return;
    }

    if (el.cursorTooltip) {
        const tooltip = document.getElementById(el.tooltipId)
        if (tooltip) {
            tooltip.remove()
        }
    } else {
        // Remove tooltip element
        const tooltipEle = el.querySelector('div.wb-tooltip');
        if (tooltipEle) tooltipEle.remove();
        el.style.position = el.dataset.position;
    }

    // Remove active tooltip class from parent element
    el.classList.remove('wb-tooltip');
}


function moveTooltip(evt){
    const tooltipEl = document.getElementById(evt.currentTarget.tooltipId);
    //tooltipEl.style.top = evt.clientY + 'px';
    tooltipEl.style.bottom = document.documentElement.clientHeight-evt.clientY + 'px';
    if (evt.clientX + 220 > document.documentElement.clientWidth) {
        tooltipEl.style.right = (document.documentElement.clientWidth - evt.clientX) + 'px';
        tooltipEl.style.left = 'initial';
        tooltipEl.classList.add('to-the-left');
        tooltipEl.classList.remove('to-the-right');
    } else {
        tooltipEl.style.left = evt.clientX + 'px';
        tooltipEl.style.right = 'initial';
        tooltipEl.classList.add('to-the-right');
        tooltipEl.classList.remove('to-the-left');
    }
}
