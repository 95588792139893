import User from "../../models/user";
import { AbstractHttpService } from "./abstract-http-service";

class UserService extends AbstractHttpService {

    endpoint() {
        return 'users';
    }

    createModel(data) {
        let model = new User;
        model.setAttributesFromResponse(data);
        return model;
    }
}

export default new UserService;
