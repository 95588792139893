<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Klant groei en krimp</h1>
                    <ul class="switch">
                        <li :class="{active: showAs == 'percentage'}" @click="showAs = 'percentage'">
                            %
                        </li>
                        <li :class="{active: showAs == 'numbers'}" @click="showAs = 'numbers'">
                            1
                        </li>
                    </ul>
                </div>

                <div class="page-header-settings">
                    <div class="row">
                        <div class="col-xl-6 col-xxl-2">
                            <div class="datepicker-styling">
                                <span class="styled-label">
                                    Van
                                </span>
                                <DatePicker :clearable="false"  @input="getData" v-model="from" />
                            </div>
                        </div>
                        <div class="col-xl-6 col-xxl-2">
                            <div class="datepicker-styling">
                                <span class="styled-label">
                                    Tot
                                </span>
                                <DatePicker :clearable="false"  @input="getData" v-model="till" />
                            </div>
                        </div>

                        <div class="col-xl-6 col-xxl-3">
                            <span class="styled-label">
                                Klanten
                            </span>
                            <MultiSelect
                                :numberOfSelectedOptionsShown="2"
                                :selectAllOption="true"
                                v-model="customerIds"
                                placeholder="Kies één of meerdere klanten"
                                :options="customers.sortBy('name').map(c => ({label: c.name, value: c.id})).all()"
                                @update:modelValue="getData()"
                            />
                        </div>

                        <div class="col-6 col-xl-4 col-xxl-3">
                            <span class="styled-label">
                                Artikelgroepen
                            </span>
                            <MultiSelect
                                :numberOfSelectedOptionsShown="2"
                                :selectAllOption="true"
                                v-model="typeIds"
                                placeholder="Kies één of meerdere artikelgroepen"
                                :options="types.map(t => ({label: t.translate('name'), value: t.id})).all()"
                                @update:modelValue="getData()"
                            />
                        </div>
                        <div class="col-6 col-xl-12">
                            <span class="styled-label">
                                Toon
                            </span>
                            <div class="styled-checkbox d-inline-block">
                                <label>
                                    <input type="checkbox" v-model="withDataOnly">
                                    <span>Klanten met gegevens</span>
                                </label>
                            </div>
                            <div class="styled-checkbox d-inline-block">
                                <label>
                                    <input type="checkbox" disabled checked>
                                    <span>Producten zonder kilo's</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="graph-holder growth-graph-holder" v-if="preparedData.count() > 0">
        <div class="y-labels">
            <div class="y-label" v-if="showAs === 'percentage'" v-for="n in 9">
                {{ Math.round((100/4) * (4-n+1)) }}%
            </div>
            <div class="y-label" v-else v-for="n in 9">
                {{ formatNumber(Math.round(maxValue/4) * (4-n+1)) }}
            </div>
        </div>

        <div class="graph">
            <div class="growth-graph" :style="`width: ${(preparedData.count()*60)+90}px`">
                <div @click="goToCustomer(customer.id)" class="column" v-for="customer of preparedData">
                    <div class="bar-container">
                        <div
                            class="bar"
                            :style="`height: ${barHeight(customer) !== 0 ? (Math.abs(barHeight(customer)) / 2) : 0}%`"
                            :class="{
                                negative: barHeight(customer) < 0,
                                positive: barHeight(customer) > 0,
                                infinite: customer.infinity
                            }"
                            v-wbtooltip:cursor="`
                                Selectie: ${formatNumber(customer.currentAmount)}<br/>
                                Jaar eerder: ${formatNumber(customer.previousAmount)}<br/>
                                <b>Verschil: ${formatNumber(customer.diffrence)}</b>
                            `"
                        >
                            <div class="percentage">
                                <div v-if="showAs !== 'percentage'">{{ formatNumber(customer.diffrence) }}</div>
                                <div>{{ customer.infinity ? '∞' : Math.round(customer.percentage) + '%' }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="label-holder">
                        <div class="label" v-html="customer.name">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="no-data-placeholder">
        Geen gegevens gevonden met de huidige filters.
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import MultiSelect from '@/components/Form/MultiSelect.vue';
    import OrderService from '@/services/http/order-service';
    import collect from 'collect.js'
    import { useRoute, useRouter } from 'vue-router'
    import useCustomerInsightsFunctionalities from './useCustomerInsightsFunctionalities';

    const emit = defineEmits(['breadcrumbs']);

    emit('breadcrumbs', [
        {label: 'Insights', to: '/customer-insights'},
        {label: 'Klant groei en krimp'}
    ]);

    const router = useRouter();

    const {
        // Variables
        from, till,
        customerIds, customers,
        typeIds, types,

        // Computed
        selectedCustomers,

        // Functions
        getCustomers, getTypes, formatNumber, getMaxValueRounded
    } = useCustomerInsightsFunctionalities();

    const data = ref(collect());

    const showAs = ref('percentage');
    const withDataOnly = ref(true);

    const getData = function() {
        if (customerIds.value.length === 0 || typeIds.value.length === 0) {
            data.value = collect();
            return;
        }

        OrderService.getCustomerInsightsGrowth({
            from: from.value,
            till: till.value,
            customer_ids: customerIds.value,
            type_ids: typeIds.value,
        }).then(dataItems => {
            data.value = collect(dataItems);
        });
    }

    const preparedData = computed(() => {
        const customerData = collect();

        for(const customer of selectedCustomers.value) {
            customerData.push({
                ...percentage(customer),
                name: customer.name,
                id: customer.id
            });
        }

        const sortOn = (showAs.value === 'percentage' ? 'percentage' : 'diffrence');

        return customerData
            .filter(c => (! withDataOnly.value || c.currentAmount != 0 || c.previousAmount != 0))
            .sort((a, b) => {

                if (sortOn === 'percentage') {
                    if (a.infinity && ! b.infinity) {
                        return -1;
                    } else if (! a['infinity'] && b['infinity']) {
                        return 1;
                    }
                }

                if (a[sortOn] > b[sortOn]) {
                    return -1;
                }

                return 1;
            });
    });

    const percentage = function(customer) {
        const customerData = data.value.where('customer_id', customer.id);
        const currentData = customerData.firstWhere('period', 'current');
        const previousData = customerData.firstWhere('period', 'previous');

        let percentageData = {};
        if (! currentData || ! previousData) {
            percentageData = {
                infinity: ! currentData ? false : true,
                percentage: currentData ? 100 : (! previousData ? 0 : -100),
                currentAmount: currentData ? currentData.amount : 0,
                previousAmount: previousData ? previousData.amount : 0,
            }
        } else {
            const amount = (currentData.amount - previousData.amount) / previousData.amount * 100;

            percentageData = {
                infinity: false,
                percentage: amount,
                currentAmount: currentData.amount,
                previousAmount: previousData.amount
            }
        }

        percentageData['diffrence'] = (percentageData.currentAmount - percentageData.previousAmount);

        return percentageData;
    }

    const maxValue = computed(() => getMaxValueRounded(preparedData.value.map(c => Math.abs(c.diffrence)).max()));

    const barHeight = function(customer) {
        if (showAs.value === 'numbers') {
            return (customer.diffrence > 0 ? 1 : -1) * (Math.abs(customer.diffrence) / maxValue.value * 100);
        }

        if (customer.percentage > 100) {
            return 100;
        }

        return customer.percentage < -100 ? -100 : customer.percentage;
    }

    const goToCustomer = function(id) {
        const url = router.resolve({
            path: '/customer-insights/article',
            query: {
                'customer_id': id,
                'from': from.value,
                'till': till.value
            }
        });

        window.open(url.href, '_blank')
    }

    Promise.all([
        getCustomers(),
        getTypes()
    ]).then(() => {
        getData();
    })
</script>
