import seedsPerSquaredMeter from "../../models/seeds-per-squared-meter.js"
import { AbstractHttpService } from "./abstract-http-service";

class seedsPerSquaredMeterService extends AbstractHttpService {

    endpoint() {
        return 'seeds-per-squared-meters';
    }

    createModel(data) {
        return new seedsPerSquaredMeter(data);
    }
}

export default new seedsPerSquaredMeterService;
