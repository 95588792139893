import Size from "../../models/size.js"
import { AbstractHttpService } from "./abstract-http-service";

class SizeService extends AbstractHttpService {

    endpoint() {
        return 'sizes';
    }

    createModel(data) {
        return new Size(data);
    }
}

export default new SizeService;
