<template>
    <div class="widget-holder">
        <div class="col">
            <div class="user-data">
                <h2>Documenten</h2>

                <div v-if="user.documents && user.documents.count() > 0" id="documents">
                    <template v-for="(type, index) of documentTypes.map((type) => type.name)" :key="index">
                        <div v-if="user.documents.filter(doc => doc.documentType.name == type).count() > 0" class="data-row">

                            <div v-for="(document, docIndex) of user.documents.filter(function(document){return document.documentType.name === type}.bind(type))" :key="document.id">
                                <Document :document="document" :docIndex="docIndex" :documentTypes="documentTypes" @documentEdited="documentEdited" @documentDeleted="documentDeleted" />
                            </div>
                        </div>
                    </template>
                </div>
                <div v-else>
                    <p class="no-data-placeholder">Er zijn nog geen documenten toegevoegd</p>
                </div>

                <div v-if="documentTypes.length > 0" class="profile-settings-group">
                    <fab-button class="top-list" icon="plus">
                        <li v-for="documentType of documentTypes" :key="documentType.id" :class="{disabled: isDisabled(documentType.name)}">
                            <a @click="toggle(documentType.name);">
                                Nieuw {{ documentType.translate('name') }}
                            </a>
                        </li>
                    </fab-button>
                </div>
            </div>
        </div>

        <template v-if="documentTypes.length > 0" v-for="documentType of documentTypes">
            <CreateDocument v-if="create_document === documentType.name" @update="$emit('refresh')" :user="user" :key="documentType.id" :documentType="documentType" @close="create_document = false" />
        </template>
    </div>
</template>

<script>
    import DocumentType from '../../../models/document-type.js';
    import CreateDocument from './Documents/CreateDocument.vue';
    import Document from './Documents/Document.vue';

    export default{
        props: ['user'],

        components: {CreateDocument, Document},


        data() {
            return {
                documentTypes: [],
                create_document: false,
                console: console
            }
        },

        methods: {
            getDocumentTypes() {
                axios.get('document-types').then(response => {
                    for (let documentType of response.data.data) {
                        this.documentTypes.push(new DocumentType(documentType));
                    }
                });
            },

            isDisabled(type) {
                if (type === 'contract' || type === 'document' || type === 'performance_evaluation') {
                    return false
                }

                return (this.user.documents.filter(function(document){
                    return (document.documentType.name === type);
                }).length > 0);
            },

            toggle(name) {
                if (this.create_document !== false || ! this.isDisabled(name)) {
                    this.create_document = name;
                }
            },

            documentEdited(document) {
                for (let index in this.user.documents) {
                    if (this.user.documents[index].id === document.id) {
                        Vue.set(this.user.documents, index, document);
                    }
                }
            },

            documentDeleted(id) {

                const deletedDocumentIndex = this.user.documents.search(d => d.id === id);
                if (deletedDocumentIndex !== false) {
                    this.user.documents.splice(deletedDocumentIndex, 1)
                }
            }
        },

        created() {
            this.getDocumentTypes()
        }
    };
</script>
