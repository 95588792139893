import axios from 'axios';
import Stock from '../models/stock.js';
import { ref } from 'vue';
import { defineStore } from 'pinia'
import StockService from './../services/http/stock-service';

export const useStockStore = defineStore('stocks', () => {

    const isConnected = ref(false);
    const stock = ref([]);
    const stockLoaded = ref(false);

    const getStock = function() {
        return StockService.get({
            filters: {today: 1},
            include: ['article', 'stockable']
        }).then(stocks => {
            stock.value = stocks;
            setStockLoaded();
        })

    }

    const addStock = (data) => axios.post(`stocks`, data);

    const setStockLoaded = () => (stockLoaded.value = true);
    const addStockItem = (stockItem) => {
        stock.value.push(stockItem);
    }

    return {
        // State
        isConnected, stock, stockLoaded,

        // Getters
        getStock,

        // Mutations
        addStock, setStockLoaded, addStockItem
    }
});
