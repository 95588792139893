<template>
    <modal @save="save()" @close="$emit('close')">
        <template v-slot:header>
            Nieuw {{ documentType.translate('name') }}
        </template>
        <form @submit.prevent enctype="multipart/form-data">

            <label class="styled-label">
                <span>Bestand</span>
                <input id="file" type="file" class="styled-label" name="file" @change="fileChange($event.target.files)" :class="{ 'is-invalid': form.errors.has('file') }">
                <div class="invalid-feedback" v-if="form.errors.has('file')" v-text="form.errors.get('file')"></div>
            </label>

            <label v-if="documentType.name === 'document'" class="styled-label">
                <span>Naam</span>
                <input id="type" type="text" class="styled-input" v-model="form['type']">
                <div class="invalid-feedback" v-if="form.errors.has('type')" v-text="form.errors.get('type')"></div>
            </label>

            <label v-for="field of documentType.dateFields()" :key="field" class="styled-label">
                <span>{{ labels[documentType.name][field] }}</span>
                <DatePicker  :id="field" v-model="form[field]" :class="{ 'is-invalid': form.errors.has(field) }" />
                <div class="invalid-feedback" v-if="form.errors.has(field)" v-text="form.errors.get(field)"></div>
            </label>

        </form>
    </modal>
</template>

<script>
    import Form from '../../../../helpers/form.js';
    import moment from 'moment';
    import { useToast } from 'vue-toast-notification';

    export default{
        props: ['user', 'documentType'],

        data() {
            return {
                labels: {
                    kvk: {delivered_at: 'Afgiftedatum'},
                    id: {expires_at: 'Verloopdatum'},
                    contract: {starts_at: 'Contractdatum', ends_at: 'Einde contract'},
                    performance_evaluation: {starts_at: 'Gesprek op'},
                    document: {starts_at: 'Datum-1', ends_at: 'Datum-2'}
                },
                form: new Form({
                    file: null,
                    type: null,
                    delivered_at: null,
                    expires_at: null,
                    starts_at: null,
                    ends_at: null,
                    document_type_id: this.documentType.id,
                }),
            }
        },

        methods: {
            save() {
                if (this.form.file === null) {
                    this.form.post('users/' + this.user.id + '/documents');
                } else {
                    this.form.with_files = true;
                    this.form.post('users/' + this.user.id + '/documents', {
                        'Content-Type': `multipart/form-data; boundary=${this.form.file._boundary}`,
                    }).then(response => {

                        useToast().success('Document opgeslagen');
                        this.$emit('close');
                        this.$emit('update');
                        document.querySelector('body').classList = '';
                    });
                }
            },

            fileChange(files) {
                this.form.file = files[0]; //where file is a property on the this.originalData
            }
        }
    };
</script>
