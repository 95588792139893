import {AbstractModel} from './abstract-model.js'

export default class PayOut extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            year: null,
            weeknumber: null,
            from: moment(),
            till: moment()
        }
    }
    
    
}