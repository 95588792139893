import { AbstractModel } from './abstract-model';
import Type from './type';
import User from './user';

export default class ProductionForecast extends AbstractModel {

    getMutators()
    {
        return {
            createdAt: 'datetime',
            updatedAt: 'datetime',
        }
    }

    relations() {
        return {
            type: Type,
            user: User
        }
    }

    relationPlural()
    {
        return {
            type: false,
            user: false
        }
    }

}
