<template>
    <div v-if="users.count() > 0" class="alert alert-danger">
        <Icon name="calendar" type="solid" />
        Geboortedatum moet nog ingevuld voor voor de volgende gebruiker(s): {{ users.map(u => u.name()).implode(', ') }}
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { collect } from "collect.js";
    import moment from 'moment';
    import UserService from '@/services/http/user-service.js';

    const users = ref(collect())
    const getUsers = function()
    {
        UserService.get({filters: {
            0: 'birthdate_is_null',
            'only-real-persons': 1,
            'roles': {'name_is': 'harvest-worker'}
        }}).then(userModels => {
            users.value = userModels;
        })
    }
    getUsers()


</script>
