<template>
    <div id="report">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="page-header">
                        <h1>Bosopdrachten rapportage</h1>
                    </div>
                </div>
            </div>

            <div class="page-header-settings">

                <div class="row">

                    <div class="col-6 col-md-4 col-lg-3 datepicker-styling">
                        <span class="styled-label">Van</span>
                        <DatePicker @input="dateChanged" :clearable="false" v-model="from" />
                    </div>
                    <div class="col-6 col-md-4 col-lg-3 datepicker-styling">
                        <span class="styled-label">Tot</span>
                        <DatePicker @input="dateChanged" :clearable="false" v-model="till" />
                    </div>

                    <div class="col-6 col-md-4 col-lg-2">
                        <span class="styled-label">Medewerkers</span>
                        <MultiSelect
                            v-model="userIds"
                            :options="users.sortBy('surname').map(u => ({
                                label: u.name(),
                                value: u.id
                            })).all()"
                            placeholder="Kies medewerkers"
                            @update:modelValue="dateChanged()"
                        ></MultiSelect>
                    </div>

                    <div class="col-6 col-md-4 col-lg-2">
                        <span class="styled-label">Locaties</span>
                        <MultiSelect
                            v-model="locationIds"
                            :options="locations.sort((a, b) => {
                                if (a.default > b.default) {
                                    return -1;
                                } else if (b.default > a.default) {
                                    return 1;
                                } else if (a.name.includes('NF ') && ! b.name.includes('NF ')) {
                                    return 1;
                                } else if (b.name.includes('NF ') && ! a.name.includes('NF ')) {
                                    return -1;
                                } else if (a.name > b.name) {
                                    return 1;
                                } else if (b.name > a.name) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            }).map(l => ({
                                label: l.name,
                                value: l.id
                            })).all()"
                            placeholder="Kies locaties"
                            @update:modelValue="dateChanged()"
                        ></MultiSelect>
                    </div>

                    <div class="col-6 col-md-4 col-lg-2">
                        <span class="styled-label">Artikelgroepen</span>
                        <MultiSelect
                            v-model="typeIds"
                            :options="types.map(t => ({
                                label: t.translate('name'),
                                value: t.id
                            })).all()"
                            placeholder="Kies artikelgroepen"
                            @update:modelValue="dateChanged()"
                        ></MultiSelect>
                    </div>
                </div>
            </div>

            <div id="results">
                <span class="no-data-placeholder" v-if="loading">
                    <Loader />
                </span>

                <span class="no-data-placeholder" v-else-if="users && harvestAssignments && locations && Object.keys(harvestAssignments).length <= 0">
                    Er zijn geen bosopdrachten gevonden tussen deze datums
                </span>

                <template v-else>
                    <div v-for="(has, date) in harvestAssignments" :key="date">

                        <h3>{{ date }}</h3>

                        <div class="harvest-assignment" :class="{rush:harvestAssignment.rush}" v-for="harvestAssignment of has" :key="harvestAssignment.id">
                            <div class="harvest-assignment-row has-active-users">
                                <div class="row no-gutters align-items-center">

                                    <div class="col-12 col-md-5">
                                        <div class="harvest-assignment-customer">
                                            {{ harvestAssignment.name() }}
                                        </div>
                                        <div class="harvest-assignment-id">
                                            #{{ harvestAssignment.id }}
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-5 text-center">
                                        <div class="harvest-assignment-employees">
                                            <div v-wbtooltip="user.name" class="harvest-assignment-employee" v-for="(user, index) in harvestAssignment.registrations" :key="`${harvestAssignment.id}${index}`">
                                                <span class="employee-initials" >{{ user.model.initials() }}</span>
                                                <img :src="user.model.avatars && user.model.avatars.length > 0 ? user.model.avatar('small').public_url : '/img/profile-placeholder.jpg'" />
                                                <div class="harvested-amount">{{ harvestAssignment.harvestRegistrations.where('userId', user.model.id).sum('harvested') }}</div>
                                            </div>
                                            <div class="harvest-assignment-location">
                                                <span>
                                                    {{ locations.firstWhere('id', harvestAssignment.locationId) ? locations.firstWhere('id', harvestAssignment.locationId).name.substring(0, 2) : '-' }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-2">
                                        <div class="harvest-assignment-progress">
                                            <span>{{ harvestAssignment.harvestRegistrations.sum('harvested') }} / </span>{{ harvestAssignment.originalAmount }}
                                            <br/>{{  harvestAssignment.lastHarvest.format('HH:mm') }}
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

    </div>
</template>

<script setup>
    import { ref, watch, nextTick } from 'vue';
    import collect from 'collect.js';
    import HarvestAssignmentService from './../../../services/http/harvest-assignment-service';
    import UserService from './../../../services/http/user-service';
    import LocationService from './../../../services/http/location-service';
    import moment from 'moment';
    import MultiSelect from '@/components/Form/MultiSelect.vue';
    import typeService from '@/services/http/type-service';
    import Loader from '../../../components/Loader.vue';


    const from = ref(moment().format('YYYY-MM-DD'));
    const till = ref(moment().format('YYYY-MM-DD'));
    const userIds = ref([]);
    const locationIds = ref([]);
    const typeIds = ref([]);

    const loading = ref(false);

    const harvestAssignments = ref({});
    const locations = ref(collect());
    const users = ref(collect());
    const types = ref(collect());

    const dateChanged = function() {
        getHarvestAssignments().then(() => setRegistrations())
    };

    const getUsers = function() {
        return UserService.get({filters: {
            deactivated: 1,
            harvestRegistrations: {
                created_at_is_greater_than: moment().subtract(3, 'year').format('YYYY-MM-DD 00:00:00')
            }
        }}).then((userModels) => {
            users.value = userModels;
        });
    }

    const getLocations = function() {
        LocationService.get({filters: {with_deactivated: 1}}).then((locationModels) => {
            locations.value = locationModels;
        });
    };

    const startLoader = async function() {
        loading.value = true;
        await nextTick()
    }

    const getHarvestAssignments = function(executeSetRegistrations = true) {
        startLoader()

        const filters = {
            where_last_harvest_after: moment(from.value).format('YYYY-MM-DD 00:00:00'),
            where_last_harvest_before: moment(till.value).format('YYYY-MM-DD 23:59:59')
        };

        if (typeIds.value.length > 0) {
            filters['type_id_is_in'] = typeIds.value;
        }

        if (locationIds.value.length > 0) {
            filters['location_id_is_in'] = locationIds.value;
        }

        if (userIds.value.length > 0) {
            filters['harvestRegistrations'] = {user_id_is_in: userIds.value};
        }

        return HarvestAssignmentService.get({
            filters,
            include: ['harvestRegistrations', 'chest', 'content', 'size', 'type']
        }).then(harvestAssignmentModels => {
            harvestAssignments.value = harvestAssignmentModels.reverse().groupBy(
                (ha) => moment(ha.lastHarvest).format('DD-MM-YYYY')
            ).all();

            if (executeSetRegistrations) {
                setRegistrations();
            }
        });
    };

    const getTypes = function() {
        return typeService.get().then((typeModels) => {
            types.value = typeModels;
        });
    }

    const setRegistrations = function() {
        for (const date in harvestAssignments.value) {
            harvestAssignments.value[date] = harvestAssignments.value[date].map(ha => {
                ha['registrations'] = ha.harvestRegistrations.pluck('userId').unique().map((uid) => {
                    const user = users.value.firstWhere('id', uid);

                    return {
                        uid,
                        name: user.firstname + ' ' + user.surname,
                        model: user
                    };
                }).sortBy('name').unique();
                return ha;
            });
        }
        loading.value = false;
    }

    getLocations();

    Promise.all([
        getUsers(),
        getHarvestAssignments(false),
        getTypes(),
    ]).then(() => {
        setRegistrations();
    })
</script>
