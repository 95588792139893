import {AbstractModel} from './abstract-model.js';
import Article from './article.js';
import Order from './order.js';
import DayHarvestKilo from './day-harvest-kilo.js';
import moment from 'moment';

export default class Stock extends AbstractModel
{
    getDefaults() {
        return {
            id: null,
            chest_id: null,
            content_id: null,
            size_id: null,
            type_id: null,
            article_id: null,
            amount: 0,
            created_at: moment(),
            order: null,

            article: null
        }
    }

    amountForStock() {
        return this.amount;
    }

    getCcst()
    {
        return {
            chest_id: (this.chest_id ?? this.chestId),
            content_id: (this.content_id ?? this.contentId),
            size_id: (this.size_id ?? this.sizeId),
            type_id: (this.type_id ?? this.typeId)
        }
    }

    isHarvest(chest_id = null, content_id = null, size_id = null, type_id = null) {
        if (chest_id) {
            return (
                this.chest_id == chest_id
                && this.content_id == content_id
                && this.size_id == size_id
                && this.type_id == type_id
            );
        }

        return ! this.article_id;
    }

    isArticle(article_id = null) {
        if (article_id) {
            return this.article_id == article_id;
        }

        return this.article_id !== null;
    }

    getTransformers() {
        return {
            article: Article,
            order: Order
        }
    }


    // V2
    relations()
    {
        return {
            order: Order,
            article: Article,
            dayHarvestKilo: DayHarvestKilo,
        }
    }
    relationPlural()
    {
        return {
            order: false,
            article: false,
            dayHarvestKilo: false
        }
    }
    getMutators() {
        return {
            'createdAt': 'datetime'
        };
    }
}
