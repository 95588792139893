<template>
    <div class="sub-graph" v-if="data.filter().count() > 0" @click="emit('select')">
        <h3>{{ type.translate('name') }}</h3>
        <div class="graph-holder sold-graph-holder" v-if="data.count() > 0">

            <div class="y-labels">
                <div v-for="n in 6" class="y-label">
                    {{ formatNumber(Math.round(((maxValue/6) * (6-n+1)) / 10) * 10) }}
                </div>
                <div class="y-label">
                    0
                </div>
            </div>

            <div class="graph">
                <div class="sold-graph" :style="`width: ${(periods.length*64)+3}px`">
                    <div class="column" v-for="period of formattedPeriods">
                        <div class="bar-container">
                            <div
                                v-for="barDetails of [
                                    {class: 'super-prev-bar', period: 'superPrev'},
                                    {class: 'prev-bar', period: 'prev'},
                                    {class: 'current-bar', period: 'cur'}
                                ]"
                                v-wbtooltip:cursor="data.get(period[barDetails.period]) ? formatNumber(data.get(period[barDetails.period]).amount) : ''"
                                :class="[barDetails.class]" class="bar"
                                :style="`height: ${barHeight(period[barDetails.period])}%`"
                            >
                                <div class="inner-bar" style="height: 100%" :class="getClassForRadish(data.get(period[barDetails.period]))"></div>
                            </div>
                            <!-- <div v-wbtooltip:cursor="data.get(period.prev) ? formatNumber(data.get(period.prev).amount) : ''" class="prev-bar bar" :style="`height: ${barHeight(period.prev)}%`">
                                <div class="inner-bar" style="height: 100%" :class="getClassForRadish(data.get(period.prev))"></div>
                            </div>
                            <div v-wbtooltip:cursor="data.get(period.cur) ? formatNumber(data.get(period.cur).amount) : ''" class="current-bar bar" :style="`height: ${barHeight(period.cur)}%`">
                                <div class="inner-bar" style="height: 100%" :class="getClassForRadish(data.get(period.cur))"></div>
                            </div> -->
                        </div>
                        <div class="label-holder">
                            <div class="label" v-html="getLabel(period.period)"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import useCustomerInsightsFunctionalities from './../useCustomerInsightsFunctionalities';
    import { computed, onMounted } from 'vue'
    import collect from 'collect.js'

    const props = defineProps(['type', 'data', 'periods', 'getLabel', 'getClassForRadish', 'formattedPeriods', 'class'])
    const emit = defineEmits(['select'])

    const {
        // Functions
        formatNumber, getMaxValueRounded
    } = useCustomerInsightsFunctionalities();

    const maxValue = computed(() => {
        return getMaxValueRounded(props.data.values().pluck('amount').max());
    });

    const barHeight = function(period) {
        if (! props.data.get(period)) {
            return 0;
        }

        return ((props.data.get(period).amount/maxValue.value) * 100);
    }
</script>
