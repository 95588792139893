import {AbstractModel} from './abstract-model.js'

import moment from 'moment';

import User from './user.js'
import Content from './content.js'
import Type from './type.js'

export default class HarvestCorrection extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            amount: 0,
            user_id: null,
            type_id: null,
            content_id: null,
            user: null,
            type: null,
            content: null,
            date: moment(),
        }
    }

    getTransformers() {
        return {
            user: User,
            type: Type,
            content: Content
        }
    }

    getDateFormats() {
        return {
            date: 'YYYY-MM-DD'
        }
    }

    is(content_id, type) {
        return (
            content_id == this.content_id
            && (
                type === 'mini' && this.size.name == 'mini'
                || this.type_id == type
            )
        );
    }

    getMutators() {
        return {
            createdAt: 'datetime',
            date: 'date'
        };
    }



}
