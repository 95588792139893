import HarvestAssignment from "../../models/harvest-assignment";
import { AbstractHttpService } from "./abstract-http-service";

class HarvestAssignmentService extends AbstractHttpService {

    endpoint() {
        return 'harvest-assignments';
    }

    createModel(data) {
        return new HarvestAssignment(data);
    }

    force(harvestAssignmentId, userId) {
        let url = this.makeUrl({}, `${harvestAssignmentId}/force/${userId}`);
        return axios.get(url);
    }
}

export default new HarvestAssignmentService;
