<template>
    <div v-if="! ('show' in item) || item.show" class="data-row">
        <div class="row">
            <!-- LABEL -->
            <div class="col-5 col-md-4">
                <div class="label">{{ item.label }}</div>
            </div>

            <!-- VIEW -->
            <div class="col-7 col-md-8" v-if="! edit">
                <div v-if="modelValue && showValue().includes('<a target')" v-html="showValue()"></div>
                <div class="input" v-else-if="modelValue" v-text="showValue()"></div>
                <div v-else>-</div>
            </div>

            <!-- EDIT -->
            <div class="col" v-else>

                <!-- OPTION NIET LEEG/LEEG -->
                <select v-if="('options' in item) && item.options !== {}" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)">
                    <option v-for="option in item.options" :key="option.id" :value="option.id" v-text="option.name"></option>
                </select>

                <div v-else-if="('type' in item) && item.type === 'date'">
                    <DatePicker :clearable="false" :modelValue="modelValue && typeof modelValue === 'object' ? modelValue.format('YYYY-MM-DD') : modelValue" @input="(value) => $emit('update:modelValue', value)" />
                </div>

                <div v-else-if="('options' in item) && item.options === {}"></div>

                <!-- MULTI SELECT / CHECKBOXES-->
                <div v-else-if="('checkboxes' in item)" class="data-row-checkboxes">
                    <label v-for="checkbox of item.checkboxes">
                        <input :disabled="checkbox.disabled" type="checkbox" :value="checkbox.id" :checked="modelValue.contains(checkbox.id)" @change="updateCheckboxValue($event.target)">
                        <span>{{ checkbox.name }}</span>
                    </label>
                </div>

                <!-- TEXT -->
                <input v-else :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" class="form-control data-input-styled" :class="{ 'is-invalid': errors.has(item.label.toLowerCase()) }">
            </div>
        </div>
    </div>
</template>

<script setup>
    import {watch} from 'vue'
    const props = defineProps(['modelValue', 'edit', 'errors', 'item'])

    const showValue = function() {
        if ('type' in props.item && props.item.type === 'date') {
            return (typeof props.modelValue === 'string') ? props.modelValue : props.modelValue.format('DD-MM-YYYY');
        } else if ('checkboxes' in props.item) {
            var value = [];
            for (let id of props.modelValue) {
                let obj = props.item.checkboxes.filter(obj => obj.id == id);
                if (obj.length > 0) {
                    value.push(obj[0].name);
                }
            }

            return value.join(', ');
        } else if ('options' in props.item)  {
            return this.getOptionValue();
        } else {
            return props.modelValue;
        }
    }

    const updateCheckboxValue = function(target) {
        // Emit the number value through the input event
        if (target.checked) {
            props.modelValue.push(target.value)
        } else {
            const index = props.modelValue.search((i) => i == target.value);

            props.modelValue.splice(index, 1);
        }

        this.$emit('update:modelValue', props.modelValue);
    }

    const getOptionValue = function() {
        if (('options' in props.item) && props.item.options !== {}) {
            for (let option of props.item.options) {
                if (option.id == props.modelValue) {
                    return option.name;
                }
            }
        }

        return '-';
    }
</script>
