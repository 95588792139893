import {AbstractModel} from './abstract-model.js'
import Location from './location.js'
import Chest from './chest.js'
import Content from './content.js'
import Size from './size.js'
import Type from './type.js'

export default class Purchase extends AbstractModel {

    getDefaults() {
        return {}
    }

    amountForStock() {
        return this.amount;
    }

    is(content_id, type = null)
    {
        if (! type) {
            let attributes = content_id;

            for (let attribute in attributes) {
                let value = (attribute == 'date' ? this[attribute].format('YYYY-MM-DD') : this[attribute]);

                if (
                    value != attributes[attribute] && ! Array.isArray(attributes[attribute])
                    || Array.isArray(attributes[attribute]) && ! attributes[attribute].includes(value)
                ) {
                    return false;
                }
            }

            return true;
        }

        return (
            content_id == this.contentId
            && (
                type === 'mini' && this.size.name == 'mini'
                || this.typeId == type
            )
        );
    }

    getCcst()
    {
        return {
            chest_id: this.chestId,
            content_id: this.contentId,
            size_id: this.sizeId,
            type_id: this.typeId
        }
    }

    name()
    {
        let name = '';

        name += this.chest.name + ' ';
        name += this.size.translate('name') + ' ';
        name += this.type.translate('name') + ' ';
        name += this.content.name + ' ';

        return name;
    }


    getMutators() {
        return {
            'createdAt': 'datetime',
            'updatedAt': 'datetime',
            'date': 'date',
        };
    }

    relations()
    {
        return {
            location: Location,
            chest: Chest,
            content: Content,
            size: Size,
            type: Type,
        }
    }

    relationPlural()
    {
        return {
            location: false,
            chest: false,
            content: false,
            size: false,
            type: false,
        }
    }
}
