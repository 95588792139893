<template>

    <div class="y-labels">
        <div class="y-label" v-if="showAs === 'percentage'" v-for="n in 9">
            {{ Math.round((100/4) * (4-n+1)) }}%
        </div>
        <div class="y-label" v-else v-for="n in 9">
            {{ formatNumber(Math.round(maxValue/4) * (4-n+1)) }}
        </div>
    </div>
    <div class="graph">
        <div class="article-graph" :style="`width: ${(preparedData.count()*60)+90}px`">
            <div @click="emit('articleSelected', article)" class="column" v-for="article of preparedData">
                <div class="bar-container">
                    <div
                        class="bar"
                        :style="`height: ${barHeight(article) !== 0 ? (Math.abs(barHeight(article)) / 2) : 0}%`"
                        :class="{
                            negative: barHeight(article) < 0,
                            positive: barHeight(article) > 0,
                            infinite: article.infinity
                        }"
                        v-wbtooltip:cursor="`
                            Selectie: ${formatNumber(article.currentAmount)}<br/>
                            Jaar eerder: ${formatNumber(article.previousAmount)}<br/>
                            <b>Verschil: ${formatNumber(article.diffrence)}</b>
                        `"
                    >
                        <div class="percentage">
                            <div v-if="showAs !== 'percentage'">{{ formatNumber(article.diffrence) }}</div>
                            <div>{{ article.infinity ? '∞' : Math.round(article.percentage) + '%' }}</div>
                        </div>
                    </div>
                </div>
                <div class="label-holder">
                    <div class="label" v-html="article.name" :title="article.name">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

    const props = defineProps(['preparedData', 'formatNumber', 'barHeight', 'showAs', 'maxValue'])
    const emit = defineEmits(['articleSelected'])



</script>
