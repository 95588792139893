import {AbstractModel} from './abstract-model.js'

export default class Task extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            name: null,
            worked_amount: null,
            deleted_at: null
        }
    }
    
    translation() {
        return 'Task';
    }

}