<template>
    <div class="page-header-subtitle" style="height:auto; padding: 0 20px">
        <div class="row">
            <div class="col-md-2">
                <div class="page-header">
                    <h1 style="padding: 0; margin-left: 20px">Voorraad</h1>
                </div>
            </div>
            <div class="col-md-3 text-center">
                <input class="searchbar fullwidth" v-model="search" type="text" name="search" placeholder="Zoeken">
            </div>
            <div class="col-md-3 text-center">
                <div class="select-day">
                    <div class="select-arrow" @click="changeDate(-1)" v-if="date.diff(today, 'days') !== 0">
                        <icon name="arrow-left"></icon>
                    </div>
                    <span class="current-selection">
                        {{ date.format('DD-MM-YYYY') }}
                    </span>
                    <div class="select-arrow" @click="changeDate(1)" v-if="moment().add(1, 'week').isAfter(date)" >
                        <icon name="arrow-right"></icon>
                    </div>
                </div>
            </div>
            <div class="col-md-2 text-center">
                <div class="styled-checkbox">
                    <label>
                        <input type="checkbox" v-model="negative_only">
                        <span>Alleen tekorten</span>
                    </label>
                </div>
            </div>
            <div class="col-md-2 stock-buttons">
                <a class="fab-button" v-tooltip="'Oogstproduct voorraad toevoegen'" @click="createHarvestAssignmentModal = {}"><Icon name="scissors" style="font-size:1.5rem; color:orange" /></a>
                <a class="fab-button" v-tooltip="'Artikel voorraad toevoegen'" @click="editArticleStock = true"><Icon name="inbox" style="font-size:1.5rem; color:grey" /></a>
                <a class="fab-button" v-tooltip="'Documentatie'" @click="showDocumentationModal = true"><Icon name="question-mark-circle" style="font-size:1.5rem; color:grey" /></a>
            </div>
        </div>
    </div>

    <StockTable
        :today="today"
        :date="date"
        :negative-only="negative_only"
        :search="search"
        v-show="! loading"

        @openAddStockModal="(data) => {addStockModal = data}"
        @openStockHistoryModal="(product) => {stockHistoryModal = product}"
        @openCreateHarvestAssignmentModal="(product) => {createHarvestAssignmentModal = product}"
        @loaded="loading = false"
    ></StockTable>

    <div v-show="loading">
        <Loader />
    </div>

    <CreateProduct
        :step="('getCcst' in createHarvestAssignmentModal ? 5 : 1)"
        @submitted="storeHarvestAssignment"
        :product="{...('getCcst' in createHarvestAssignmentModal ? createHarvestAssignmentModal.getCcst() : {})}"
        :date="{
            date: date,
            disabledDates: {
                to: today.clone().subtract(1, 'day').toDate()
            }
        }"
        v-if="createHarvestAssignmentModal"
        @close="createHarvestAssignmentModal = false"
    />

    <EditArticleStockModal
        v-if="editArticleStock"
        @close="editArticleStock = false"
    />

    <StockHistoryModal
        v-if="stockHistoryModal"
        :product="stockHistoryModal"
        @close="stockHistoryModal = false"
    />

    <AddStockModal
        v-if="addStockModal"
        @close="addStockModal = false"
        :stockInfo="addStockModal"
    />

    <Modal v-if="showDocumentationModal" excludeSaveButton="true" id="create" @close="showDocumentationModal = false">
        <template v-slot:header>Documentatie</template>

        <div>
            <ul>
                <li>
                    <span style="background-color: #e9edf7; color: #47477c;">
                        Blauw = Bopdrachten
                    </span>
                </li>
                <li>
                    <span style="background-color: #f3eee7; color: #7c4747;">
                        Oranje = Inkoopregels
                    </span>
                </li>
                <li>
                    <span style="background-color: #e7ecdc; color: green;">
                        Groen = Afgeronde bosopdrachten
                    </span>
                </li>
            </ul>
        </div>

    </Modal>
</template>

<script setup>
    import moment from 'moment';
    import { useToast } from 'vue-toast-notification';
    import StockTable from './StockTable.vue';
    import { ref, watch } from 'vue';
    import harvestAssignmentService from '../../services/http/harvest-assignment-service';
    import Loader from '@/components/Loader.vue';

    defineEmits(['breadcrumbs']);

    const today = moment();

    /*
    |--------------------------------------------------------------------------
    | Table filters
    |--------------------------------------------------------------------------
    */
    const date = ref(moment());
    const search = ref('');
    const negative_only = ref(false);
    const loading = ref(true);

    watch(() => search.value, (newValue, oldValue) => {
        loading.value = true;
    })

    watch(() => negative_only.value, (newValue, oldValue) => {
        loading.value = true;
    })

    const loadingDone = function() {
        loading.value = false
    }

    const changeDate = function(amount) {
        loading.value = true;
        date.value = date.value.add(amount, 'days');
        date.value = moment(date.value);
    };



    /*
    |--------------------------------------------------------------------------
    | Modals
    |--------------------------------------------------------------------------
    */

    // Documentation
    const showDocumentationModal = ref(false);

    // Add stock
    import AddStockModal from './AddStockModal.vue';
    const addStockModal =  ref(false);

    // Stock history info
    import StockHistoryModal from './StockHistoryModal.vue';
    const stockHistoryModal= ref(false);

    // Create Harvest assignment
    import CreateProduct from './../../components/CreateProduct/CreateProduct.vue';
    const createHarvestAssignmentModal= ref(false);
    const storeHarvestAssignment = function(form) {
        if (! form.date || ! form.content_id || ! form.size_id || ! form.chest_id || ! form.type_id) {
            useToast().success('Niet alle benodigde gegevens zijn ingevuld', 'alert');
            return;
        }

        harvestAssignmentService.create(form.data({planned_at: form.date})).then(harvestAssignment => {
            createHarvestAssignmentModal.value = false;
            document.querySelector('body').classList = '';
            useToast().success('Bosopdracht opgeslagen');
        });
    };

    // Edit article stock
    import EditArticleStockModal from './EditArticleStockModal.vue';
    const editArticleStock = ref(false);

    const breadCrumbs = function() {
        emits('breadcrumbs', [
        ]);
    }
</script>
