import authGuard from './../guards/authGuard';
import UsersList from './../pages/Users/List.vue';
import UsersShow from './../pages/Users/Show.vue';

export default [
    {
        path: '/users',
        component: UsersList,
        name: 'users',
        meta: {
            label: 'Medewerkers',
            permissions: ['users']
        }
    }, {
        path: '/users/:id',
        component: UsersShow,
        name: 'users.show',
        props: true
    }, {
        path: '/profile',
        component: UsersShow,
        name: 'profile',
        props: true
    }
];
