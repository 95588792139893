<template>
    <modal @save="onSubmit()" @close="$emit('close')">
        <template v-slot:header>
            <span >Nieuwe medewerker</span>
        </template>

        <form @submit.prevent>

            <label class="styled-label">
                <span>Voornaam</span>
                <input id="firstname" type="firstname" class="styled-input" v-model="form.firstname" :class="{ 'is-invalid': form.errors.has('firstname') }">
                <div class="invalid-feedback" v-if="form.errors.has('firstname')" v-text="form.errors.get('firstname')"></div>
            </label>

            <label class="styled-label">
                <span>Tussenvoegsel</span>
                <input id="insertion" type="insertion" class="styled-input" v-model="form.insertion" :class="{ 'is-invalid': form.errors.has('insertion') }">
                <div class="invalid-feedback" v-if="form.errors.has('insertion')" v-text="form.errors.get('insertion')"></div>
            </label>

            <label class="styled-label">
                <span>Achternaam</span>
                <input id="surname" type="surname" class="styled-input" v-model="form.surname" :class="{ 'is-invalid': form.errors.has('surname') }">
                <div class="invalid-feedback" v-if="form.errors.has('surname')" v-text="form.errors.get('surname')"></div>
            </label>

            <label class="styled-label">
                <span>E-mailadres</span>
                <input id="email" type="email" class="styled-input" v-model="form.email" v-bind:class="{ 'is-invalid': form.errors.has('email') }">
                <div class="invalid-feedback" v-if="form.errors.has('email')" v-text="form.errors.get('email')"></div>
            </label>

            <label class="styled-label">
                <span>Telefoon</span>
                <input id="phone" type="phone" class="styled-input" v-model="form.phone" :class="{ 'is-invalid': form.errors.has('phone') }">
                <div class="invalid-feedback" v-if="form.errors.has('phone')" v-text="form.errors.get('phone')"></div>
            </label>

            <label class="styled-label">
                <span>Type</span>
                <select class="styled-select" v-model="form.user_type_id">
                    <option v-for="typeModel in userTypes" :key="typeModel.id" :value="typeModel.id" v-text="typeModel.translate('name')" :class="{ 'is-invalid': form.errors.has('user_type_id') }"></option>
                </select>
                <div class="invalid-feedback" v-if="form.errors.has('user_type_id')" v-text="form.errors.get('user_type_id')"></div>
            </label>

            <label class="styled-label">
                <span for="roles.id">Rollen</span>
                <div class="invalid-feedback" v-if="form.errors.has('role_ids')" v-text="form.errors.get('role_ids')"></div>
            </label>

            <div class="styled-checkbox">
                <label class="" v-for="role in roles" :key="role.id" :for="role.id">
                    <input type="checkbox" :id="role.id" :value="role.id" v-model="form.role_ids">
                    <span>{{ role.translate('name') }}</span>
                </label>
            </div>

        </form>
    </modal>
</template>

<script>
    import Form from '../../../helpers/form.js';
    import User from '../../../models/user.js';
    import UserType from '../../../models/user-type.js';
    import Role from '../../../models/role.js';

    export default {
        data() {
            return {
                form: new Form({
                    email: '',
                    firstname: '',
                    insertion: '',
                    surname: '',
                    phone: '',
                    user_type_id: 1,
                    role_ids: [],
                }),
                userTypes: {},
                roles: {}
            }
        },

        methods: {
            onSubmit() {
                this.form.post('/users')
                .then(response => {
                    let user = new User(response.data[0]);
                    this.$emit('saved', user);
                    this.$emit('close');
                    document.querySelector('body').classList = '';
                });
            },

            getUserTypes() {
                axios.get('/user-types')
                .then(response => {
                    this.userTypes = [];
                    for (let userTypeData of response.data.data) {
                        let userType = new UserType();
                        userType.setAttributesFromResponse(userTypeData);
                        this.userTypes.push(userType);
                    }
                });
            },

            getRoles() {
                axios.get('/roles')
                .then(response => {
                    this.roles = [];
                    for(let roleData of response.data.data) {
                        let role = new Role();
                        role.setAttributesFromResponse(roleData);
                        this.roles.push(role);
                    }
                });
            }
        },

        created() {
            this.getUserTypes();
            this.getRoles();
        }
    };
</script>
