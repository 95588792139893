import {AbstractModel} from './abstract-model.js'
import Size from './size.js'
import Type from './type.js'

export default class TubersPerArticle extends AbstractModel {

    relations()
    {
        return {
            size: Size,
            type: Type,
        }
    }

    relationPlural()
    {
        return {
            size: false,
            type: false
        }
    }

}