<template>
    <template v-if="loaded">

        <template v-if="showMenu">

            <fab-button
                icon="bars-3"
                class="big main-menu-fab"
                @click.native="menuOpen = true"
                data-ignore="close-menu"
            ></fab-button>

            <Menu :user="user" :menuOpen="menuOpen" @close="menuOpen = false" v-click-outside="clickedOutsideMenu" />

            <div id="logged-in-user-holder">
                <div class="user-avatar">
                    <router-link to="/profile">
                        <img :src="user.smallAvatar ? user.smallAvatar.public_url : '/img/profile-placeholder.jpg'"/>
                    </router-link>
                    <a @click="logout" class="logout-button">
                        <div class="logout-icon">
                            <Icon name="power" />
                        </div>
                    </a>
                </div>
                <router-link to="/profile"><div class="user-name">{{ user.name() }}</div></router-link>
            </div>
        </template>

        <div class="site-container" :class="{'login-page': (! authed || ! showMenu)}">
            <template v-if="showMenu">

                <!-- LOGO -->
                <div class="jongfresh-logo-fixed"></div>

                <div class="container">
                    <div class="row">
                        <div class="col">
                            <nav id="breadcrumbs" aria-label="breadcrumb">
                                <ol v-if="crumbs.length > 0" class="breadcrumb">
                                    <li :class="{...crumb.class, active: crumb.to === undefined}" class="breadcrumb-item" v-for="(crumb, index) in crumbs" :key="index">
                                        <router-link v-if="crumb.to !== undefined" :to="crumb.to">
                                            {{ crumb.label }}
                                        </router-link>
                                        <span v-else>
                                            {{ crumb.label }}
                                        </span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </template>

            <!-- CONTENT -->
            <router-view @breadcrumbs="setBreadcrumbs($event)"></router-view>

        </div>

    </template>


</template>

<script setup>
    import { useAuthStore } from './stores/auth';
    import { useRouter, useRoute } from 'vue-router';
    import { storeToRefs } from 'pinia';
    import { ref, computed } from 'vue';
    import Menu from './Menu.vue';
    const crumbs = ref([]);

    const route = useRoute();
    const currentRoute = computed(() => route);
    const showMenu = computed(() => {
        return (
            authed.value
            && user.value
            && (! currentRoute.value.meta || currentRoute.value.meta.requiredUserAction !== true)
        );
    });

    const auth = useAuthStore();
    const router = useRouter();

    const user = computed(() => useAuthStore().user);

    const { authed, loaded, fullyLoaded } = storeToRefs(auth);

    const logout = function() {
        auth.logout();
    }

    const clickedOutsideMenu = function(el) {
        if (
            el.dataset.ignore !== 'close-menu'
            && ! el.closest('[data-ignore="close-menu"]')
        ) {
            menuOpen.value = false;
        }
    }

    router.beforeResolve((to, from) => {
        crumbs.value = [];
        menuOpen.value = false;
    });

    const setBreadcrumbs = function(breadcrumbs) {
        crumbs.value = breadcrumbs;
    };

    const menuOpen = ref(false);

</script>
