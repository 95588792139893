<template>

    <div class="y-labels">
        <div class="y-label" v-if="showAs === 'percentage'" v-for="n in 9">
            {{ Math.round((100/4) * (4-n+1)) }}%
        </div>
        <div class="y-label" v-else v-for="n in 9">
            {{ formatNumber(Math.round(maxValue/4) * (4-n+1)) }}
        </div>
    </div>
    <div class="graph">
        <div class="article-graph" >
            <div class="column" v-for="customer of preparedData" :style="`width: ${(preparedData.count()*60)+90}px`">
                <div class="bar-container">
                    <div
                        class="bar"
                        :style="`height: ${barHeight(customer) !== 0 ? (Math.abs(barHeight(customer)) / 2) : 0}%`"
                        :class="{
                            negative: barHeight(article) < 0,
                            positive: barHeight(article) > 0,
                            infinite: article.infinity
                        }"
                        v-wbtooltip:cursor="`
                            Selectie: ${formatNumber(customer.currentAmount)}<br/>
                            Jaar eerder: ${formatNumber(customer.previousAmount)}<br/>
                            <b>Verschil: ${formatNumber(customer.diffrence)}</b>
                        `"
                    >
                        <div class="percentage">
                            <div v-if="showAs !== 'percentage'">{{ formatNumber(customer.diffrence) }}</div>
                            <div>{{ customer.infinity ? '∞' : Math.round(customer.percentage) + '%' }}</div>
                        </div>
                    </div>
                </div>
                <div class="label-holder">
                    <div class="label" v-html="customer.name" :title="customer.name">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { watch, onMounted, computed, ref } from 'vue'
    import collect from 'collect.js'
    import OrderService from '@/services/http/order-service';

    const props = defineProps([
        'article', 'customerIds', 'from',
        'till', 'percentage', 'customers',
        'formatNumber', 'showAs'
    ]);

    const data = ref(collect())

    const preparedData = computed(() => {
        const customerData = collect();
        for(const customerId of data.value.pluck('customer_id').unique()) {
            customerData.push({
                ...percentage(customerId),
                name: props.customers.firstWhere('id', customerId).name,
                id: customerId
            });
        }

        const sortOn = (props.showAs === 'percentage' ? 'percentage' : 'diffrence');

        return customerData
            .filter(c => ((! c.infinity || c.percentage !== 0)))
            .sort((a, b) => {
                if (sortOn === 'percentage') {
                    if (a.infinity && ! b.infinity) {
                        return -1;
                    } else if (! a['infinity'] && b['infinity']) {
                        return 1;
                    }
                }

                if (a[sortOn] > b[sortOn]) {
                    return -1;
                }

                return 1;
            });
    });


    const percentage = function(customerId) {
        const articleData = data.value.where('customer_id', customerId);
        const currentData = articleData.firstWhere('period', 'current');
        const previousData = articleData.firstWhere('period', 'previous');



        let percentageData = {};
        if (! currentData || ! previousData) {
            percentageData = {
                infinity: ! currentData ? false : true,
                percentage: currentData ? 100 : (! previousData ? 0 : -100),
                currentAmount: currentData ? currentData.amount : 0,
                previousAmount: previousData ? previousData.amount : 0
            }
        } else {
            const amount = (currentData.amount - previousData.amount) / previousData.amount * 100;
            percentageData = {
                infinity: false,
                percentage: amount,
                currentAmount: currentData.amount,
                previousAmount: previousData.amount
            }
        }

        percentageData['diffrence'] = (percentageData.currentAmount - percentageData.previousAmount);

        return percentageData;
    }

    const getData = function() {
        if (props.customerIds.length === 0) {
            data.value = collect();
            return;
        }

        OrderService.getSingleArticleInsightsGrowth({
            from: props.from,
            till: props.till,
            customer_ids: props.customerIds,
            article_id: props.article.id
        }).then(dataItems => {
            data.value = collect(dataItems);
        });
    }


    const barHeight = function(customer) {
        if (props.showAs === 'numbers') {

            return (customer.diffrence > 0 ? 1 : -1) * (Math.abs(customer.diffrence) / maxValue.value * 100);
        }

        if (customer.percentage > 100) {
            return 100;
        }

        return customer.percentage < -100 ? -100 : customer.percentage;
    }

    const maxValue = computed(() => {
        return preparedData.value.map(c => Math.abs(c.diffrence)).max()
    });

    onMounted(() => {
        getData();
    })
</script>
