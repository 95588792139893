import Location from "../../models/location.js"
import { AbstractHttpService } from "./abstract-http-service";

class LocationService extends AbstractHttpService {

    endpoint() {
        return 'locations';
    }

    createModel(data) {
        return new Location(data);
    }
}

export default new LocationService;
