<template>
    <div class="loader" :class="{center: center}">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</template>

<script setup>
    const props = defineProps({
        color: {
            default: '#69bf49',
            type: String
        },
        center: {
            default: false,
            type: Boolean
        },
    });
</script>

<style scoped>

    .loader {
        display: block;
        position: relative;
        width: 80px;
        height: 80px;
        margin: 0 auto;

        &.center {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute
        }
    }

    .loader div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid v-bind('props.color');
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: v-bind('props.color') transparent transparent transparent;
    }

    .loader div:nth-child(1) {
        animation-delay: -0.45s;
    }

    .loader div:nth-child(2) {
        animation-delay: -0.3s;
    }

    .loader div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
