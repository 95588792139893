<template>
    <modal @save="close('yes')" @close="close('no')">
        <template v-slot:header>
            <span>{{ title_text }}</span>
        </template>
        
        <span>
            <div class="container">
                <div class="row">
                    <p>{{ body_text }}</p>
                </div>
            </div>
        </span>
        
        <template v-slot:save>
            <span>{{ confirm }}</span>
        </template>
        <template v-slot:close>
            <span>{{ cancel }}</span>
        </template>
    </modal>
</template>

<script>
    export default {
        props: ['confirmButton', 'cancelButton', 'body', 'title'],

        data() {
            return {
                confirm: this.confirmButton ? this.confirmButton : 'Ja',
                cancel: this.cancelButton ? this.cancelButton : 'Nee',
                body_text: this.body ? this.body : 'Weet je het zeker?',
                title_text: this.title ? this.title : 'Bevestigen'
            } 
        },

        methods: {
            close(answer) {
                this.$emit(answer);
                document.querySelector('body').classList = '';
            }
        },
    }
</script>