<template>
    <modal @save="onSubmit()" @close="$emit('close')">
        <template v-slot:header>Taak wijzigen</template>
        <div>
            <form @submit.prevent>

                <label class="styled-label" for="name">
                    <span>Naam</span>
                    <input autofocus id="name" type="name" class="styled-input" v-model="form.name" v-bind:class="{ 'is-invalid': form.errors.has('name') }">
                    <div class="invalid-feedback" v-if="form.errors.has('name')" v-text="form.errors.get('name')"></div>
                </label>

            </form>
        </div>
    </modal>
</template>

<script>
    import Form from '../../../helpers/form.js';
    import { useToast } from 'vue-toast-notification';

    export default {
        props: ['task'],

        data() {
            return {
                form: new Form({
                    name: this.task.name,
                })
            }
        },

        methods: {
            onSubmit() {
                this.form.put('/tasks/' + this.task.id)
                .then(response => {
                    this.task.name = this.form.name;
                    useToast().success('Taak wijzigen');
                    this.$emit('saved', response.data[0]);
                    this.$emit('close');
                    document.querySelector('body').classList = '';
                });
            },
        }
    };
</script>
