<template>
    <div>
        <input id="amount" ref="numpadInput" @input="updateValue('keyboard', $event)" :value="modelValue" autofocus>

        <div class="keypad">
            <div class="key" @click="updateValue(1)">1</div>
            <div class="key" @click="updateValue(2)">2</div>
            <div class="key" @click="updateValue(3)">3</div>

            <div class="key" @click="updateValue(4)">4</div>
            <div class="key" @click="updateValue(5)">5</div>
            <div class="key" @click="updateValue(6)">6</div>

            <div class="key" @click="updateValue(7)">7</div>
            <div class="key" @click="updateValue(8)">8</div>
            <div class="key" @click="updateValue(9)">9</div>

            <div v-tooltip="'Backspace'" class="col key backspace" @click="updateValue('backspace')"><Icon name="arrow-left"/></div>
            <div class="key" @click="updateValue(0)">0</div>
            <div v-tooltip="'Reset'" class="col key correction" @click="updateValue('reset')"><Icon name="arrow-path"/></div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

    const props = defineProps(['modelValue'])
    const emits = defineEmits(['update:modelValue'])

    const editableValue = ref(props.modelValue);
    const numpadInput = ref(null);

    const updateValue = function(keyPressed, event) {
        if (keyPressed === 'keyboard') {
            if (['0','1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(event.data)) {
                editableValue.value = '' + numpadInput.value.value;
            } else {
                numpadInput.value.value = editableValue.value;
            }
        } else if (keyPressed === 'backspace') {
            if (String(editableValue.value).length <= 1) {
                editableValue.value = 0;
            } else {
                editableValue.value = parseInt(String(editableValue.value).substring(0, String(editableValue.value).length - 1));
            }
        } else if(keyPressed === 'reset') {
            editableValue.value = '';
        } else if(keyPressed !== 'keyboard') {
            editableValue.value = parseInt(editableValue.value + '' + keyPressed);
        } else {
            editableValue.value += keyPressed;
        }

        emits('update:modelValue', editableValue.value)
    }
</script>
