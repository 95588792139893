import {AbstractModel} from './abstract-model.js'
import Order from './order.js'

export default class Customer extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            external_id: null,
            name: null,
            default_time: null
        }
    }

    relations()
    {
        return {
            orders: Order
        }
    }

    relationPlural()
    {
        return {
            orders: true
        }
    }

}