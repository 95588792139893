import Breed from "../../models/breed.js"
import { AbstractHttpService } from "./abstract-http-service";

class BreedService extends AbstractHttpService {

    endpoint() {
        return 'breeds';
    }

    createModel(data) {
        return new Breed(data);
    }
}

export default new BreedService;
