import moment from 'moment';
import Type from '@/models/type.js';
import { collect } from "collect.js";
import CustomerService from '@/services/http/customer-service';
import TypeService from '@/services/http/type-service';
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router'

export default function useCustomerInsightsFunctionalities() {
    const route = useRoute()

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString)
    const from = ref(urlParams.get('from') ?? moment().startOf('year').format('YYYY-MM-DD'));
    const till = ref(urlParams.get('till') ?? moment().format('YYYY-MM-DD'));

    const customerIds = ref([]);
    const customers = ref(collect());

    const typeIds = ref([]);
    const typeWithMinis = ref([]);
    const types = ref(collect());

    const getCustomers = function() {
        return CustomerService.get().then(customerModels => {
            customers.value = customerModels;
            if ('customer_id' in route.query) {
                customerIds.value = [parseInt(route.query.customer_id)]
            } else {
                customerIds.value = customerModels.pluck('id').all();
            }
        });
    }

    const getTypes = function() {
        return TypeService.get().then(typeModels => {
            types.value = typeModels.sortBy('sequence');
            types.value.push(new Type({
                id: 'mini',
                name: 'Mini\'s',
                sequence: types.value.max('sequence')+1
            }))
            typeIds.value = types.value.pluck('id').all();
        });
    }

    const selectedCustomers = computed(() => {
        return customers.value.filter(customer => customerIds.value.includes(customer.id))
    })

    const weeks = computed(() => {
        let weeks = [];

        const fromMoment = moment(from.value, 'YYYY-MM-DD');
        const tillMoment = moment(till.value, 'YYYY-MM-DD');

        for (let weekNr = 0; weekNr <= moment(tillMoment, 'YYYY-MM-DD').diff(fromMoment, 'week'); weekNr++) {
            weeks.push(fromMoment.clone().add(weekNr, 'week'));
        }

        return weeks;
    });

    const formatNumber = function(number) {
        return new Intl.NumberFormat('nl-NL').format(number);
    }

    const getMaxValueRounded = function(maxValue) {
        const value = maxValue*1.04;

        if (value > 1000){
            return Math.ceil(value/100)*100;
        }

        if (value > 100){
            return Math.ceil(value/10)*10;
        }

        return Math.ceil(value/5)*5;
    }


    return {

        // Variables
        from, till,
        customerIds, customers,
        typeIds, types, typeWithMinis,

        // Computed
        weeks, selectedCustomers,

        // Functions
        getCustomers, getTypes, formatNumber, getMaxValueRounded
    }
}
