<template>
    <modal class="edit-article" @close="emit('close')" @save="update">
        <template v-slot:header>{{ article.name }}</template>

        <div class="info">
            Hoeveel stuks van {{ article.name }} kunnen er gemaakt worden van <b>1
            {{ article.content ? article.content.name : '-' }}
            {{ article.type ? article.type.translate('name') : '-' }}
            {{ article.size ? article.size.translate('name') : '-' }}
            {{ article.chest ? article.chest.name : '-' }}
            </b>?
            <i>(Voor een product van totaal 10kg is dit 0,1)</i>
        </div>

        <span class="styled-label">Conversion rate</span>
        <input autofocus class="styled-input" type="number" steps="0.00000001" v-model="consversionRate">
        Dit kan tot 8 decimalen.
    </modal>
</template>

<script setup>
    import articleService from '@/services/http/article-service';
    import { ref, computed } from 'vue';
    import { useToast } from 'vue-toast-notification'

    const emit = defineEmits(['close', 'saved']);
    const props = defineProps(['article']);

    const consversionRate = ref(props.article.manual_conversion_rate);

    const conversionRateSavable = computed(() =>
    {
        return consversionRate.value !== 0 && consversionRate.value !== '0';
    })

    const update = function() {
        if (! conversionRateSavable.value) {
            useToast().error('Conversion rate kan niet 0 zijn');
            return;
        }
        articleService.edit(props.article.id, {
            'manual_conversion_rate': consversionRate.value
        }).then(() => {
            emit('saved');
        })
    }
</script>
