import { defineStore } from 'pinia'
import notificationService from './../services/http/notification-service';
import collect from 'collect.js';
import { ref, computed } from 'vue';

export const useNotificationStore = defineStore('notifications', () => {

    const notificationsLoaded = ref(false);
    const notificationObjects = ref(collect());

    const notifications = computed(() => notificationObjects.value.sort(defaultSort));
    const unreadNotifications = computed(() => {
        return notifications.value.filter(notification => (! notification.read))
    });

    const defaultSort = (a, b) => ((a.createdAt < b.createdAt) ? 1 : -1);

    const getNotifications = function() {
        return notificationService.get({include: ['user', 'file']}).then(notificationModels => {
            notificationObjects.value = notificationModels;
            notificationsLoaded.value = true
        });
    }

    const storeNotification = function(data) {
        return notificationService.create(data, {include: ['user', 'file']});
    }

    const updateNotification = function (id, data) {
        return notificationService.edit(id, data);
    }

    const removeNotification = function(notification) {
        let index = notificationsObjects.value.findIndex(o => o.id === notification.id);
        if (index >= 0) {
            notificationObjects.value.splice(index, 1);
        }
    }

    const editNotification = function(model) {
        const index = notificationObjects.value.search(o => o.id === model.id);
        if (index >= 0) {
            notificationObjects.value.splice(index, 1, [model]);
        }
    }

    return {
        notificationsLoaded, notificationObjects, notifications, unreadNotifications,

        getNotifications, storeNotification, updateNotification, removeNotification, editNotification
    }
});
