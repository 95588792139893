import OrderNotification from "../../models/order-notification.js"
import { AbstractHttpService } from "./abstract-http-service";

class OrderNotificationService extends AbstractHttpService {

    endpoint() {
        return 'order-notifications';
    }

    createModel(data) {
        return new OrderNotification(data);
    }
}

export default new OrderNotificationService;