<template>
    <modal @save="save()" @close="$emit('close')">
        <template v-slot:header>E-mailadres wijzigen</template>

        <form @submit.prevent>
            <label class="styled-label" for="email">
                <span>E-mailadres</span>
                <input id="email" type="email" class="styled-input" v-model="form.email" v-bind:class="{ 'is-invalid': form.errors.has('email') }">
                <div class="invalid-feedback" v-if="form.errors.has('email')" v-text="form.errors.get('email')"></div>
            </label>
        </form>
    </modal>
</template>

<script>
    import Form from '../../../helpers/form.js';
    import { useToast } from 'vue-toast-notification';

    export default{
        props: ['user'],

        data() {
            return {
                form: new Form({
                    email: this.user.email
                })
            }
        },

        methods: {
            save() {
                this.form.put('users/' + this.user.id).then(response => {
                    this.user.email = this.form.email;
                    useToast().success('E-mailadres gewijzigd');
                    this.$emit('saved', this.form.email);
                    document.querySelector('body').classList = '';
                });
            }
        }
    };
</script>
