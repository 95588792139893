import {AbstractModel} from './abstract-model.js'
import Chest from './chest.js';
import Type from './type.js';
import Size from './size.js';
import Content from './content.js';
import CoverSheet from './cover-sheet.js';
import Pallet from './pallet.js';
import Packaging from './packaging.js';

export default class Article extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            external_id: null,
            parent_id: null,
            name: null,
            harvest_time: null,
            content_id: null,
            chest_id: null,
            type_id: null,
            size_id: null,
            pallet_id: null,
            cover_sheet_id: null,
            conversion_rate: null,
            manual_conversion_rate: null,
            packaging_id: null,
            chest: null,
            type: null,
            size: null,
            content: null,
            packaging: null,
            pallet: null,
            coverSheet: null,
            image_url: null
        }
    }
    amountForStock() {
        return 0;
    }

    conversion() {
        if (this.manual_conversion_rate || this.manualConversionRate) {
            return (this.manual_conversion_rate ? this.manual_conversion_rate : this.manualConversionRate);
        }

        return this.conversionRate;
    }

    getTransformers() {
        return {
            chest: Chest,
            size: Size,
            type: Type,
            content: Content,
            packaging: Packaging,
            coverSheet: CoverSheet,
            pallet: Pallet,
        }
    }

    isSameStock(stock) {
        return (
            "article_id" in stock && stock.article_id == this.id
            || stock.chest_id != null
            && stock.chest_id == this.chest_id
            && stock.content_id == this.content_id
            && stock.size_id == this.size_id
            && stock.type_id == this.type_id
        );
    }

    getCcst()
    {
        return {
            chest_id: (this.chest_id ?? this.chestId),
            content_id: (this.content_id ?? this.contentId),
            size_id: (this.size_id ?? this.sizeId),
            type_id: (this.type_id ?? this.typeId)
        }
    }

    isHarvestable()
    {
        return (
            (this.chest_id !== undefined ? this.chest_id : this.chestId) != null
            && (this.content_id !== undefined ? this.content_id : this.contentId)
            && (this.size_id !== undefined ? this.size_id : this.sizeId)
            && (this.type_id !== undefined ? this.type_id : this.typeId)
        );
    }

    isPallet() {
        return ((this.pallet_id === undefined ? this.palletId : this.pallet_id) !== null);
    }

    isStatie() {
        return (this.name.includes(' statiegeld'));
    }
}
