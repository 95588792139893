<template>
    <div v-for="(zeroOrderLine, index) of zeroOrderLines" :key="'O' + index" class="alert alert-danger">
        <div>
            <Icon name="bell-alert" type="solid" />
            <span style="font-weight: bold;">
                0 cent orderregel -
            </span>
            <span>
                {{ zeroOrderLine.order.toDate('pickedUpAt').format('DD-MM-YYYY HH:mm') }} - {{ zeroOrderLine.order.customer.name }}
            </span>
            <br/>
            <span>
                {{ zeroOrderLine.amount }} stuks '{{ zeroOrderLine.article.name }}'
            </span>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { collect } from "collect.js";
    import moment from 'moment';
    import { useToast } from 'vue-toast-notification';

    /*
    |--------------------------------------------------------------------------
    | Zero orderlines
    |--------------------------------------------------------------------------
    */
    const zeroOrderLines = ref(collect());

    // Get order notifications
    import orderLineService from '@/services/http/order-line-service';
    const getZeroOrderLines = function() {
        return orderLineService.get({filters: {
            cents_is: 0,
            from: moment().subtract(5, 'day').format('YYYY-MM-DD 00:00:00'),
            article: {
                0: 'chest_id_is_not_null',
                1: 'content_id_is_not_null',
                2: 'size_id_is_not_null',
                3: 'type_id_is_not_null'
            }
        }, include: ['order.customer', 'article']}).then(zeroOrderLinesModels => {
            zeroOrderLines.value = zeroOrderLinesModels;
        });
    }
    getZeroOrderLines();

</script>
