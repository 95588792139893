import Packaging from "../../models/packaging.js"
import { AbstractHttpService } from "./abstract-http-service";

class PackagingService extends AbstractHttpService {

    endpoint() {
        return 'packagings';
    }

    createModel(data) {
        return new Packaging(data);
    }

}

export default new PackagingService;
