<template>
    <modal @save="save()" @close="$emit('close')">
        <template v-slot:header>{{ document.documentType.translate('name') }} wijzigen</template>

        <form @submit.prevent enctype="multipart/form-data">

            <label class="styled-label">
                <span>Bestand</span>
                <input class="styled-input" id="file" type="file" name="file" @change="fileChange($event.target.files)" :class="{ 'is-invalid': form.errors.has('file') }">
                <div class="invalid-feedback" v-if="form.errors.has('file')" v-text="form.errors.get('file')"></div>
            </label>

            <label v-if="document.documentType.name === 'document'" class="styled-label">
                <span>Naam</span>
                <input class="styled-input" id="type" type="text" v-model="form['type']">
                <div class="invalid-feedback" v-if="form.errors.has('type')" v-text="form.errors.get('type')"></div>
            </label>

            <label v-for="field of document.documentType.dateFields()" :key="field" class="styled-label">
                <span>{{ labels[document.documentType.name][field] }}</span>
                <input @input="dateChanged(field)" :id="field" :type="field" v-model="form[field]" class="datepicker-styling" :class="{ 'is-invalid': form.errors.has(field) }" />
                <div class="invalid-feedback" v-if="form.errors.has(field)" v-text="form.errors.get(field)"></div>
            </label>

        </form>
        <a class="btn btn-block btn-outline-danger" @click="$emit('deleteDocument')">Verwijderen</a>
    </modal>
</template>

<script>
    import Form from '../../../../helpers/form.js';
    import Document from '../../../../models/document.js';
    import moment from 'moment';
    import { useToast } from 'vue-toast-notification';

    export default{
        props: ['document', 'documentTypes'],

        data() {
            return {
                labels: {
                    kvk: {delivered_at: 'Afgiftedatum'},
                    id: {expires_at: 'Verloopdatum'},
                    contract: {starts_at: 'Contractdatum', ends_at: 'Einde contract'},
                    performance_evaluation: {starts_at: 'Gesprek op'},
                    document: {starts_at: 'Datum-1', ends_at: 'Datum-2'}
                },
                form: new Form({
                    file: null,
                    type: this.document.type,
                    expires_at: this.document.expires_at ? moment(this.document.expires_at).format('YYYY-MM-DD') : this.document.expires_at,
                    delivered_at: this.document.delivered_at ? moment(this.document.delivered_at).format('YYYY-MM-DD') : this.document.delivered_at,
                    starts_at: this.document.starts_at ? moment(this.document.starts_at).format('YYYY-MM-DD') : this.document.starts_at,
                    ends_at: this.document.ends_at ? moment(this.document.ends_at).format('YYYY-MM-DD') : this.document.ends_at
                })
            }
        },

        methods: {
            save() {
                if (this.form.file === null) {
                    this.form.put('users/' + this.document.user_id + '/documents/' + this.document.id);
                } else {
                    this.form.with_files = true;
                    this.form.put('users/' + this.document.user_id + '/documents/' + this.document.id, {
                        'Content-Type': `multipart/form-data; boundary=${this.form.file._boundary}`,
                    }).then(response => {

                        useToast().success('Document opgeslagen');
                        this.$emit('close');
                        this.$emit('update');
                        document.querySelector('body').classList = '';
                    });
                }
            },

            handleResponse(response) {
                let documentModel = new Document();
                documentModel.setAttributesFromResponse(response.data[0]);
                useToast().success('Document gewijzigd');
                this.$emit('documentEdited', documentModel);
                document.querySelector('body').classList = '';
            },

            dateChanged(field) {
                if (this.form[field] !== null && this.form[field] !== '') {
                    this.form[field] = moment(this.form[field]).format('YYYY-MM-DD');
                }
            },

            fileChange(files) {
                this.form.file = files[0]; //where file is a property on the this.originalData
            }
        }
    };
</script>
