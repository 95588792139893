import WorkSession from "../../models/work-session.js"
import { AbstractHttpService } from "./abstract-http-service";

class WorkSessionService extends AbstractHttpService {

    endpoint() {
        return 'work-sessions';
    }

    createModel(data) {
        return new WorkSession(data);
    }

    updateMany(payload, parameters = {}) {
        let url = this.makeUrl(parameters, 'update-many');

        return axios['post'](url, payload);
    }
}

export default new WorkSessionService;
