<template>
    <div v-for="(user, index) of agreementUsers" :key="`u${index}`" class="alert alert-danger">
        <Icon name="document-text" type="solid" />
        De overeenkomst van <template v-if="! authUser.can('users')">{{ user.name() }}</template>
        <a v-else @click="$router.push('/users/' + user.id )">{{ user.name() }}</a>
        verloopt op {{ user.currentAgreement.endsAt.format('DD-MM-YYYY')  }}.
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import { collect } from "collect.js";

    /*
    |--------------------------------------------------------------------------
    | Expired agreements
    |--------------------------------------------------------------------------
    */
    const agreementUsers = ref(collect());

    // Get expired agreement users.
    import userServiceNew from '@/services/http/user-service-new.js';
    const getAgreementUsers = function(){
        return userServiceNew.get({
            filters: {'expiring_agreement': 1},
            include: ['currentAgreement']
        }).then(userModels => {
            agreementUsers.value = userModels;
        });
    }

    // Refresh data and initiate get data.
    const getData = function() {getAgreementUsers();}
    getData();
    defineExpose({getData});

    /*
    |--------------------------------------------------------------------------
    | Auth user
    |--------------------------------------------------------------------------
    */
    import { useAuthStore } from "../../stores/auth";
    const authStore = useAuthStore();
    const authUser = computed(() => authStore.user);
</script>
