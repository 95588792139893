<template>
    <modal class="edit-harvest-assignment" @close="close()" @save="save()">
        <template v-slot:header>
            <span>Kisten toevoegen bij {{ data.user.name() }}</span>
        </template>

        <span>
            <form @submit.prevent enctype="multipart/form-data">
                <label>
                    <span class="styled-label">Kisten toevoegen</span>
                    <input class="styled-input" type="number" v-model="harvested" autofocus>
                </label>
            </form>
        </span>
    </modal>
</template>

<script>
    import moment from 'moment';
    import { useToast } from 'vue-toast-notification';

    export default{

        props: ['data'],

        data() {
            return {
                harvested: this.data.harvestAssignment.harvestRegistrations.filter(ha => ha.user_id === this.data.user.id).map(item => item.harvested).reduce((prev, next) => prev + next),
                originalHarvested: this.data.harvestAssignment.harvestRegistrations.filter(ha => ha.user_id === this.data.user.id).map(item => item.harvested).reduce((prev, next) => prev + next)
            }
        },

        methods: {

            async save() {
                await axios.post('harvest-registrations', {
                    harvested: this.harvested - this.originalHarvested,
                    harvest_assignment_id: this.data.harvestAssignment.id,
                    user_id: this.data.user.id,
                });

                useToast().success('Oogstregistratie opgeslagen');
                this.close();
            },

            close() {
                this.$emit('close');
                document.querySelector('body').classList = '';
            },
        },
    };
</script>
