import moment from 'moment';
import {AbstractModel} from './abstract-model.js'
import Order from './order.js'

export default class OrderNotification extends AbstractModel {

    getDefaults() {
        return {}
    }


    type() {
        if (this.orderNotificationTypeId == 1) {
            return 'Aantal';
        } else if (this.orderNotificationTypeId == 2) {
            return 'Pallet';
        } else if (this.orderNotificationTypeId == 3) {
            return 'Statiegeld';
        } else if (this.orderNotificationTypeId == 4) {
            return 'Verkeerd product';
        }
    }

    
    // V2 
    relations()
    {
        return {
            order: Order,
        }
    }

    relationPlural()
    {
        return {
            order: false
        }
    }

}