import authGuard from './../guards/authGuard';
import HarvestReportShow from './../pages/HarvestReport/Show.vue';

export default [
    {
        path: '/harvest-report/:year/:month',
        component: HarvestReportShow,
        name: 'harvest-report.show',
        meta: {permissions: ['harvest_report']},
        props: true
    }
];
