<template>
    <div v-if="ordersWithoutOrderLines.count() > 0" class="widget-holder">
        <div class="widget-header">
            <div class="widget-title">
                <router-link to="/orders">Orders met een €0,00 orderregel</router-link>
            </div>
        </div>
        <div class="widget-content">
            <table class="styled-table">
                <tr class="styled-table-row" v-for="(order, index) of ordersWithoutOrderLines" :key="index">
                    <td>{{ order.customer.name }}</td>
                    <td>{{ order.pickedUpAt.format('DD-MM-YYYY HH:mm:ss') }}</td>
                    <td class="text-right font-weight-light">
                        {{ order.bunchesDone() }}/{{ order.bunches() }}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { useOrderStore } from "../../stores/orders";

    const orderStore = useOrderStore();
    const ordersWithoutOrderLines = computed(() => orderStore.zeroOrderLineOrders);
</script>
