<template>
    <div class="container" id="compare">

        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Dagoogst vergelijken</h1>
                </div>

                <div class="page-header-settings">
                    <div class="row">
                        <div class="col-md-4 col-lg-3">
                            <div class="form-control datepicker-styling">
                                <span class="styled-label">Van</span>
                                <DatePicker @input="changeDate" v-model="from" />
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-control datepicker-styling">
                                <span class="styled-label">Tot</span>
                                <DatePicker @input="changeDate" v-model="till" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="widget-holder">
            <div class="row">
                <div class="col-12">
                    <table class="styled-table">
                        <thead class="styled-table-header">
                            <tr>
                                <th rowspan="2">&nbsp;</th>
                                <th v-for="(content, index) of contents" :key="index">
                                    <div class="content-name">{{ content.name }}</div>
                                    <div class="compare-left-D">D</div>
                                    <div class="compare-right-M">M</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="styled-table-body">
                            <TypeRow
                                :key="kindex"
                                :from="fromObj"
                                :till="tillObj"
                                :total="false"
                                :data="type"
                                v-if="data.length > 0"
                                v-for="(type, kindex) of data.filter((type, index) => type.type != 'total')"
                            />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
    import moment from 'moment';
    import DayHarvestRegistrationService from '@/services/http/day-harvest-registration-service';
    import HarvestRegistrationService from '@/services/http/harvest-registration-service';
    import TypeService from '@/services/http/type-service';
    import ContentService from '@/services/http/content-service';
    import TypeRow from './TypeRow.vue';
    import { useRoute } from 'vue-router';
    import { ref, computed } from 'vue';
    import collect from 'collect.js'

    const route = useRoute();
    const from = ref(route.query.from ? route.query.from : moment().format('YYYY-MM-DD'));
    const till = ref(route.query.till ? route.query.till : moment().format('YYYY-MM-DD'));

    const contents = ref(collect());
    const allContents = ref(collect());

    const types = ref(collect());
    const allTypes = ref(collect());

    const harvestRegistrations = ref(collect());
    const dayHarvestRegistrations = ref(collect());
    //const data = ref([]);
    const counter = ref(0);

    const miniId = 5;

    const fromObj = computed(() => moment(from.value, 'YYYY-MM-DD'));
    const tillObj = computed(() => moment(till.value, 'YYYY-MM-DD'));

    const data = computed(() => {
        const dataItems = [];

        for (let type of types.value) {
            let dataRow = {
                label: type.translate('name'),
                contents: [],
                type: type
            };

            for (let content of contents.value) {
                const hrs = harvestRegistrations.value.filter(hr => (
                    hr.contentId === content.id
                    && hr.typeId === type.id
                ));

                const dhrs = dayHarvestRegistrations.value.filter(dhr => (
                    dhr.registratable().contentId === content.id
                    && dhr.registratable().typeId === type.id
                ));

                dataRow.contents.push({
                    m: {
                        mini: hrs.where('sizeId', miniId).sum('harvested'),
                        nonMini: hrs.where('sizeId', '!=', miniId).sum('harvested')
                    },
                    d: {
                        mini: dhrs.filter((dhr) => dhr.registratable().sizeId === miniId).sum('amount'),
                        nonMini: dhrs.filter((dhr) => dhr.registratable().sizeId !== miniId).sum('amount')
                    },
                    id: content.id,
                });
            }

            dataItems.push(dataRow);
        }

        /* for (let content of contents.value) {
            data.push({
                m: harvestRegistrations.value.filter(hr => {
                    hr.contentId === content.id
                }).count(),
                d: harvestRegistrations.value.filter(hr => {
                    hr.contentId === content.id
                }).count(),
                id: '*',
                type: 'total'
            });
        } */
        return dataItems;
    });

    const changeDate = function()
    {
        let curFrom = moment(from.value, 'YYYY-MM-DD');
        let curTill = moment(till.value, 'YYYY-MM-DD');
        if (curTill.isBefore(curFrom)) {
            curTill = from.value;
            from.value = till.value;
            till.value = curTill;
        }
        getData();
    }

    const getData = function() {
        return Promise.all([
            HarvestRegistrationService.get({
                include: ['size'],
                filters: {
                    from: fromObj.value.format('YYYY-MM-DD'),
                    till: tillObj.value.format('YYYY-MM-DD')
                }
            }).then((harvestRegistrationModels) => {
                harvestRegistrations.value = harvestRegistrationModels;
            }),

            DayHarvestRegistrationService.get({
                include: ['registratable'],
                filters: {
                    'created_at_is_greater_or_equal_than': `${from.value} 00:00:00`,
                    'created_at_is_lower_or_equal_than': `${till.value} 23:59:59`
                }
            }).then((dayHarvestRegistrationModels) => {
                dayHarvestRegistrations.value = dayHarvestRegistrationModels;
            })
        ]).then(() => {
            const allObjects = collect([
                ...harvestRegistrations.value.all(),
                ...dayHarvestRegistrations.value.map(dhr => dhr.registratable()).all()
            ]);

            const typeIds = allObjects.pluck('typeId').unique().all();
            types.value = allTypes.value.filter(type => typeIds.includes(type.id));

            const contentIds = allObjects.pluck('contentId').unique().all();
            contents.value = allContents.value.filter(content => contentIds.includes(content.id));
        });
    }

    Promise.all([
        ContentService.get({filters: {'name_is_not': 'kilo'}}).then((contentModels) => {
            allContents.value = contentModels;
        }),
        TypeService.get({filters: {'name_is_not': 'micro'}}).then(typeModels => {
            allTypes.value = typeModels;
        })
    ]).then(() => {
        getData();
    })
</script>
