<template>
    <div v-if="productionControl" class="container">

        <div class="row">
            <div class="col">
                <div class="page-header">
                    <!-- HEADER -->
                    <h1>
                        Production control

                        <template v-if="! edit">{{ productionControl.at.format('DD-MM-YYYY') }}</template>
                        <div v-else class="datepicker-styling">
                            <DatePicker v-model="edit.at" format="dd-MM-yyyy" />
                        </div>
                    </h1>

                    <!-- Non-edit buttons -->
                    <button v-tooltip="'Edit'" v-if="! edit" style="margin-left: 10px;" @click="editProductionControl()">
                        <fab-button icon="pencil"></fab-button>
                    </button>

                    <button v-if="! edit && productionControl.fileModel" style="margin-left: 10px;" :href="productionControl.fileModel.publicUrl">
                        <fab-button icon="photo"></fab-button>
                    </button>

                    <ProductionControlExportExcel
                        v-if="! edit"
                        :fabButton="true"
                        :productionControl="productionControl"
                    />


                    <!-- Editting buttons -->
                    <button v-if="edit" style="margin-left: 10px;" @click="updateProductionControl()">
                        <fab-button class="success" icon="check"></fab-button>
                    </button>
                    <button v-if="edit" style="margin-left: 10px;" @click="edit = false;">
                        <fab-button class="danger" icon="x-mark"></fab-button>
                    </button>
                </div>
            </div>
        </div>


        <div class="page-header-settings" style="padding-bottom: 0; border-bottom: 0;">
            <div class="row">
                <div class="col-6 col-md-4 col-lg-3 col-xl-2">
                    <span class="styled-label">Amount</span>
                    <span class="styled-result">{{ productionControl.productionControlMeasurements.count() }} measurements</span>
                </div>
                <div class="col-6 col-md-4 col-lg-3 col-xl-2">
                    <span class="styled-label">Sow date</span>
                    <div class="styled-result">
                        <template v-if="! edit">{{ productionControl.sowedAt.format('DD-MM-YYYY') }}</template>
                        <div v-else class="datepicker-styling">
                            <DatePicker v-model="edit.sowed_at" format="dd-MM-yyyy" />
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-lg-3 col-xl-2">
                    <span class="styled-label">Location</span>
                    <div class="styled-result">
                        <template v-if="! edit">{{ productionControl.location ? productionControl.location.name : 'Unknown' }}</template>
                        <select class="styled-select" v-else v-model="edit.location_id">
                            <option v-for="location of locations" :value="location.id">{{ location.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-lg-3 col-xl-2">
                    <span class="styled-label">Date last steaming</span>
                    <div class="styled-result">
                        <template v-if="! edit">{{
                            productionControl.location && productionControl.location.lastSteamedAt
                            ? productionControl.location.lastSteamedAt.format('DD-MM-YYYY')
                            : '-' }}
                        </template>
                        <div class="datepicker-styling" v-else>
                            <DatePicker v-model="edit.last_steamed_at"  />
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-lg-3 col-xl-2">
                    <span class="styled-label">Row nr.</span>
                    <div class="styled-result">
                        <template v-if="! edit">{{ productionControl.rowNumber }}</template>
                        <input class="styled-input" v-else v-model="edit.row_number">
                    </div>
                </div>
                <div class="col-6 col-md-4 col-lg-3 col-xl-2">
                    <span class="styled-label">Type</span>
                    <div class="styled-result">
                        <template v-if="! edit">{{ productionControl.type ? productionControl.type.name : 'Unknown' }}</template>
                        <select class="styled-select" v-else v-model="edit.type_id">
                            <option v-for="(type, index) of types" :value="type.id">{{ type.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-lg-3 col-xl-2">
                    <span class="styled-label">Fraction</span>
                    <div class="styled-result">
                        <template v-if="! edit">{{ productionControl.fraction ? productionControl.fraction.milimeters + 'mm' : 'Unknown' }}</template>
                        <select class="styled-select" v-else v-model="edit.fraction_id">
                            <option v-for="(fraction, index) of fractions" :value="fraction.id">{{ fraction.milimeters }}mm</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 col-md-4 col-lg-3 col-xl-2">
                    <span class="styled-label">Lot nr.</span>
                    <div class="styled-result">
                        <template v-if="! edit">{{ productionControl.lotNumber }}</template>
                        <input class="styled-input" v-else v-model="edit.lot_number">
                    </div>
                </div>
                <div class="col-6 col-md-4 col-lg-3 col-xl-2">
                    <span class="styled-label">Grow light</span>
                    <div class="styled-result">
                        <template v-if="! edit">{{ productionControl.growlight }} PAR</template>
                        <input class="styled-input" v-else v-model="edit.growlight">
                    </div>
                </div>
                <div class="col-6 col-md-4 col-lg-3 col-xl-2">
                    <span class="styled-label">Sowing density</span>
                    <div class="styled-result">
                        <template v-if="! edit">{{ productionControl.sowingDensity }}</template>
                        <input class="styled-input" v-else v-model="edit.sowing_density" input="number">
                    </div>
                </div>
                <div class="col-6 col-md-4 col-lg-3 col-xl-2">
                    <span class="styled-label">Substrate</span>
                    <div class="styled-result">
                        <template v-if="! edit">{{ productionControl.normalSubstrateStrategy ? 'Normal' : 'Cellulose' }}</template>
                        <select class="styled-select" v-else v-model="edit.normal_substrate_strategy">
                            <option :value="true">Normal</option>
                            <option :value="false">Cellulose</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="production-control-averages" v-if="productionControl.productionControlMeasurements.count() >= 1">
            <div>
                <div><b>AVERAGES</b></div>
            </div>
            <div class="average">
                <span class="styled-label">Tuber weight</span>
                <span class="styled-result">{{ Math.round(productionControl.productionControlMeasurements.avg('tuberWeight')*10)/10 }} gr</span>
            </div>
            <div class="average">
                <span class="styled-label">Tuber Diameter</span>
                <span class="styled-result">{{ Math.round(productionControl.productionControlMeasurements.avg('tuberDiameter')*10)/10 }} mm</span>
            </div>
            <div class="average">
                <span class="styled-label">Tuber height</span>
                <span class="styled-result">{{ Math.round(productionControl.productionControlMeasurements.avg('tuberHeight')*10)/10 }} mm</span>
            </div>
            <div class="average">
                <span class="styled-label">Leafage weight</span>
                <span class="styled-result">{{ Math.round(productionControl.productionControlMeasurements.avg('leavesWeight')*10)/10 }} gr</span>
            </div>
            <div class="average">
                <span class="styled-label">Leafage length</span>
                <span class="styled-result">{{ Math.round(productionControl.productionControlMeasurements.avg('leavesLength')*10)/10 }} mm</span>
            </div>
        </div>

        <div class="production-control-averages" v-if="productionControl.productionControlMeasurements.count() >= 1">
            <div>
                <div><b>Standard deviations</b></div>
            </div>
            <div class="average">
                <span class="styled-label">Tuber weight</span>
                <span class="styled-result">{{ getStandardDeviation(productionControl.productionControlMeasurements.pluck('tuberWeight').all()) }} gr</span>
            </div>
            <div class="average">
                <span class="styled-label">Tuber Diameter</span>
                <span class="styled-result">{{ getStandardDeviation(productionControl.productionControlMeasurements.pluck('tuberDiameter').all()) }} mm</span>
            </div>
            <div class="average">
                <span class="styled-label">Tuber height</span>
                <span class="styled-result">{{ getStandardDeviation(productionControl.productionControlMeasurements.pluck('tuberHeight').all()) }} mm</span>
            </div>
            <div class="average">
                <span class="styled-label">Leafage weight</span>
                <span class="styled-result">{{ getStandardDeviation(productionControl.productionControlMeasurements.pluck('leavesWeight').all()) }} gr</span>
            </div>
            <div class="average">
                <span class="styled-label">Leafage length</span>
                <span class="styled-result">{{ getStandardDeviation(productionControl.productionControlMeasurements.pluck('leavesLength').all()) }} mm</span>
            </div>
        </div>
    </div>

    <div class="production-control-table-holder" v-if="productionControl">
        <table class="styled-table production-control">
            <thead class="styled-table-header">
                <tr>
                    <th rowspan="2" style="border-color: #999">
                        nr.
                    </th>
                    <th colspan="2" style="border-right-color: #999">
                        False mildew
                    </th>
                    <th colspan="2" style="border-right-color: #999">
                        Mite damage
                    </th>
                    <th colspan="2" style="border-right-color: #999">
                        Yellow leaves
                    </th>
                    <th rowspan="2">
                        Black spots on tuber
                    </th>
                    <th rowspan="2" style="border-color: #999">
                        Physiological diseases
                    </th>
                    <th colspan="3">
                        Leafage
                    </th>
                    <th colspan="4" style="border-right-color: #999">
                        Tuber
                    </th>
                    <th width="180px" style="border-bottom-color: transparent;">
                        &nbsp;
                    </th>
                </tr>
                <tr>
                    <th>
                        Tuber
                    </th>
                    <th style="border-right-color: #999">
                        Leaves
                    </th>
                    <th>
                        Tuber
                    </th>
                    <th style="border-right-color: #999">
                        Leaves
                    </th>
                    <th>
                        Germ (Kiem)
                    </th>
                    <th style="border-right-color: #999">
                        Leaves
                    </th>
                    <th>
                        Leaves amount
                    </th>
                    <th>
                        Length (mm)
                    </th>
                    <th>
                        Weight (gr)
                    </th>
                    <th>
                        Weight (gr)
                    </th>
                    <th>
                        Diameter (mm)
                    </th>
                    <th style="border-color: #999">
                        Height (mm)
                    </th>
                    <th style="border-color: #999">
                        Roundness (mm)
                    </th>
                    <th width="180px">
                        &nbsp;
                    </th>
                </tr>
            </thead>
            <tbody class="styled-table-body">
                <tr class="styled-table-row" v-for="(pcm, index) of productionControl.productionControlMeasurements">
                    <template v-if="! createEditMeasurement || createEditMeasurement.id !== pcm.id">
                        <td style="width: 100px; border-right-color: #999;">
                            #{{ index+1 }}
                        </td>
                        <td>
                            <span :class="{'attention': pcm.mildewTuber}">
                                {{ pcm.presentYesNo('mildewTuber') }}
                            </span>
                        </td>
                        <td>
                            <span :class="{'attention': pcm.mildewLeaves}">
                                {{ pcm.presentYesNo('mildewLeaves') }}
                            </span>
                        </td>
                        <td>
                            <span :class="{'attention': pcm.miteTuber}">
                                {{ pcm.presentYesNo('miteTuber') }}
                            </span>
                        </td>
                        <td>
                            <span :class="{'attention': pcm.miteLeaves}">
                                {{ pcm.presentYesNo('miteLeaves') }}
                            </span>
                        </td>
                        <td>
                            <span :class="{'attention': pcm.yellowGermLeaves}">
                                {{ pcm.presentYesNo('yellowGermLeaves') }}
                            </span>
                        </td>
                        <td>
                            <span :class="{'attention': pcm.yellowLeaves}">
                                {{ pcm.presentYesNo('yellowLeaves') }}
                            </span>
                        </td>
                        <td>
                            <span :class="{'attention': pcm.blackTuber}">
                                {{ pcm.presentYesNo('blackTuber') }}
                            </span>
                        </td>
                        <td>
                            <span :class="{'attention': pcm.diseaseLeaves}">
                                {{ pcm.presentYesNo('diseaseLeaves') }}
                            </span>
                        </td>
                        <td>
                            {{ pcm.leavesAmountOfLeaves }}
                        </td>
                        <td>
                            {{ pcm.leavesLength }}
                        </td>
                        <td>
                            {{ pcm.leavesWeight }}
                        </td>
                        <td>
                            {{ pcm.tuberWeight }}
                        </td>
                        <td>
                            {{ pcm.tuberDiameter }}
                        </td>
                        <td>
                            {{ pcm.tuberHeight }}
                        </td>
                        <td>
                            {{ pcm.roundness() }}
                        </td>
                        <td class="icon-holder" width="180px">
                            <template v-if="! createEditMeasurement">
                                <a v-tooltip="'General comment'" v-if="pcm.description" @click="description = pcm.description">
                                    <Icon name="chat-bubble-left" />
                                </a>
                                <a v-tooltip="'Edit'" @click="editProductionControlMeasurement(pcm)">
                                    <Icon name="pencil" />
                                </a>
                                <a v-tooltip="'Delete'" @click="deleteMeasurementId = pcm.id">
                                    <Icon name="x-mark" />
                                </a>
                            </template>
                        </td>
                    </template>

                    <CreateEditProductionControlMeasurementTr
                        :measurement="createEditMeasurement"
                        :rowNumber="index+1"
                        :updating="updating"
                        @saved="createEditMeasurement = null; getProductionControl(); updating = false"
                        @updateUpdating="(value) => {updating = value}"
                        @cancel="createEditMeasurement = null; updating = false"
                        v-else
                    />

                </tr>

                <!-- ADD NEW MEASUREMENT-->
                <tr class="styled-table-row">
                    <td colspan="50" style="text-align:center" v-if="! createEditMeasurement">
                        <a @click="createProductionControlMeasurement()" style="display: block; font-size:30px; cursor: pointer;">
                            +
                        </a>
                    </td>
                    <CreateEditProductionControlMeasurementTr
                        :measurement="createEditMeasurement"
                        :rowNumber="productionControl.productionControlMeasurements.count()+1"
                        :updating="updating"
                        @saved="createEditMeasurement = null; getProductionControl(); updating = false"
                        @updateUpdating="(value) => {updating = value}"
                        @cancel="createEditMeasurement = null; updating = false"
                        v-else-if="createEditMeasurement && ! createEditMeasurement.id"
                    />
                </tr>


            </tbody>
        </table>
    </div>

    <ConfirmModal
        confirmButton="Yes"
        cancelButton="No"
        title="Confirm"
        v-if="deleteMeasurementId"
        body="Are you sure you want te delete this measurement? This cannot be undone."
        @yes="destroyOrderNotification"
        @no="deleteMeasurementId = null"
    />

    <modal v-if="description" @close="description = null;" :excludeSaveButton="true">
        <template v-slot:header>General comment</template>

        <span>
            {{ description }}
        </span>

        <template v-slot:close>Close</template>
    </modal>
</template>

<script setup>
    import { ref } from 'vue';
    import collect from 'collect.js';
    import ProductionControlService from './../../services/http/production-control-service';
    import ProductionControlMeasurementService from './../../services/http/production-control-measurement-service';
    import ProductionControlMeasurement from './../../models/production-control-measurement';
    import ConfirmModal from './../../components/ConfirmModal.vue';
    import LocationService from './../../services/http/location-service';
    import TypeService from './../../services/http/type-service';
    import FractionService from './../../services/http/fraction-service';
    import ProductionControlExportExcel from './ProductionControlExportExcel.vue';
    import CreateEditProductionControlMeasurementTr from './CreateEditProductionControlMeasurementTr.vue';
    import { useToast } from 'vue-toast-notification';

    const props = defineProps(['id']);
    const emit = defineEmits(['breadcrumbs']);

    const edit = ref(false);

    const productionControl = ref(null);
    const updating = ref(false);

    const description = ref(null);

    const getStandardDeviation = function(array) {
        const length = array.length
        if (length <= 0) return 0;
        const mean = array.reduce((a, b) => a + b) / length
        return Math.round(Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / length) * 10) / 10
    }

    /*
    |--------------------------------------------------------------------------
    | Edit
    |--------------------------------------------------------------------------
    */
    const editProductionControl = function() {
        edit.value = {
            id: productionControl.value.id,
            at: productionControl.value.at.format('YYYY-MM-DD'),
            sowed_at: productionControl.value.sowedAt.format('YYYY-MM-DD'),
            location_id: productionControl.value.locationId,
            last_steamed_at: ((productionControl.value.location && productionControl.value.location.lastSteamedAt)
                ? productionControl.value.location.lastSteamedAt.format('YYYY-MM-DD')
                : null
            ),
            row_number: productionControl.value.rowNumber,
            type_id: productionControl.value.typeId,
            fraction_id: productionControl.value.fractionId,
            lot_number: productionControl.value.lotNumber,
            growlight: productionControl.value.growlight,
            sowing_density: productionControl.value.sowingDensity,
            normal_substrate_strategy: productionControl.value.normalSubstrateStrategy
        };
    }

    const updateProductionControl = function() {
        if (updating.value) return;
        updating.value = true;

        ProductionControlService.edit(edit.value.id, edit.value).then(() => {
            getProductionControl().then(() => {
                edit.value = null;
                updating.value = false;
                useToast().success('Production control updated');
            });
        });
    }

    /*
    |--------------------------------------------------------------------------
    | CreateEdit Measurements
    |--------------------------------------------------------------------------
    */
    const createEditMeasurement = ref(null);
    const editProductionControlMeasurement = function(productionControlMeasurement) {
        createEditMeasurement.value = new ProductionControlMeasurement(productionControlMeasurement);
    }

    const createProductionControlMeasurement = function() {
        createEditMeasurement.value = new ProductionControlMeasurement({
            mildewTuber: false,
            mildewLeaves: false,
            miteTuber: false,
            miteLeaves: false,
            yellowGermLeaves: false,
            yellowLeaves: false,
            blackTuber: false,
            diseaseLeaves: false,
            productionControlId: productionControl.value.id
        });
    }

    /*
    |--------------------------------------------------------------------------
    | Delete
    |--------------------------------------------------------------------------
    */
    const deleteMeasurementId = ref(null);
    const destroyOrderNotification = function() {
        if (updating.value) return;
        updating.value = true;
        ProductionControlMeasurementService.delete(deleteMeasurementId.value).then(() =>{
            getProductionControl().then(() => {
                deleteMeasurementId.value = null;
                updating.value = false;
                useToast().success('Production control measurement deleted');
            });
        })
    }


    /*
    |--------------------------------------------------------------------------
    | Getters
    |--------------------------------------------------------------------------
    */

    const getProductionControl = function() {
        return ProductionControlService.getById(props.id, {
            include: ['fraction', 'type', 'location', 'productionControlMeasurements', 'file']
        }).then(productionControlModel => {
            productionControl.value = productionControlModel;

            emit('breadcrumbs', [
                {to: `/production-control`, label: 'Production control'},
                {label: productionControl.value.at.format('DD-MM-YYYY')}
            ]);
        });
    }
    getProductionControl();

    const locations = ref(collect());
    LocationService.get().then(locationModels => {
        locations.value = locationModels;
    });

    const types = ref(collect());
    TypeService.get().then(typeModels => {
        types.value = typeModels;
    });

    const fractions = ref(collect());
    FractionService.get().then(fractionModels => {
        fractions.value = fractionModels;
    });
</script>
