<template>
    <div class="login-container">
        <div class="login-column">
            <div class="login-content-container right-half shadow-lvl-5">
                <div class="login-content">
                    <div class="jongfresh-login-logo"></div>

                    <template v-if="agreements.length < 1">
                        <div class="explain-block">
                            <p v-if="hasOldAgreements">
                                Je overeenkomst is verlopen. Er is geen nieuwe overeenkomst beschikbaar.
                            </p>
                            <p v-else>
                                Er is geen overeenkomst beschikbaar.
                            </p>
                        </div>

                        <a @click="logout" class="btn btn-block btn-danger logout-button">
                            Uitloggen
                        </a>
                    </template>

                    <template v-else-if="agreements[0].accepted_at !== null">
                        <div class="explain-block">
                            <p>
                                Je overeenkomst gaat in vanaf {{ agreements[0].starts_at.format('DD-MM-YYYY') }}.
                            </p>
                        </div>

                        <a @click="logout" class="btn btn-block btn-danger logout-button">
                            Uitloggen
                        </a>
                    </template>

                    <template v-else>
                        <form @submit.prevent="onSubmit">

                            <div class="">

                                <!-- WINDOW -->
                                <div class="agreement-window">

                                    <h1>OVEREENKOMST VAN OPDRACHT</h1>

                                    <h2>Partijen:</h2>

                                    <p style="padding: 0 10px">1. Jongfresh v.o.f. gevestigd te Monster, 2681 PN, aan de Madeweg 60, rechtsgeldig vertegenwoordigd door de heer E.P.S. de Jong, hierna te noemen: “Opdrachtgever”;</p>
                                    <p style="padding: 0 10px">En</p>
                                    <p style="padding: 0 10px">2. {{ agreements[0].company }} gevestigd te {{ agreements[0].established_at }}, {{ agreements[0].postal_code }}, aan de {{ agreements[0].address }}, BTW NR. {{ agreements[0].btw }} hierna te noemen: “Opdrachtnemer”;</p>
                                    <p style="padding: 0 10px">Gezamenlijk te noemen: “Partijen”;</p>

                                    <h2>Overwegende dat:</h2>

                                    <ul style="list-style-type: lower-alpha;">
                                        <li>
                                            Opdrachtgever een tuinbouwbedrijf exploiteert gericht op de teelt van radijzen;
                                        </li>
                                        <li>
                                            Opdrachtgever in het kader hiervan behoefte heeft aan personen die radijs oogsten;
                                        </li>
                                            Opdrachtnemer als zodanig in staat en bereid is deze werkzaamheden uit te voeren;
                                        <li>
                                            Partijen uitsluitend met elkaar wensen te contracteren op basis van een overeenkomst van opdracht in de zin van artikel 7:400 e.v. BW;
                                        </li>
                                        <li>
                                            Partijen uitdrukkelijk niet beogen om een arbeidsovereenkomst aan te gaan in de zin van artikel 7:610 e.v. BW;
                                        </li>
                                        <li>
                                            Partijen ervoor kiezen om in voorkomende gevallen de fictieve dienstbetrekking van thuiswerkers of gelijkgestelden zoals bedoeld in de artikelen 2b en 2c Uitvoeringsbesluit Loonbelasting 1965 en de artikelen 1 en 5 van het Besluit aanwijzing gevallen waarin arbeidsverhouding als dienstbetrekking wordt beschouwd (Besluit van 24 december 1986, Stb. 1986, 655), buiten toepassing te laten, en daartoe deze overeenkomst opstellen en ondertekenen voordat uitbetaling plaatsvindt;
                                        </li>
                                        <li>
                                            deze overeenkomst gelijkluidend is aan de door de Belastingdienst op 19-10-2015 onder nummer 9015550000-05 opgestelde modelovereenkomst;
                                        </li>
                                        <li>
                                            Partijen de voorwaarden waaronder Opdrachtnemer voor Opdrachtgever zijn werkzaamheden zal verrichten, in deze overeenkomst wensen vast te leggen.
                                        </li>
                                    </ul>
                                    <div style="page-break-after: always"></div>

                                    <h2>Partijen komen het volgende overeen:</h2>

                                    <h3>Artikel 1 De opdracht</h3>
                                    <ol>
                                        <li>
                                            Opdrachtnemer verplicht zich voor de duur van de overeenkomst de navolgende werkzaamheden te verrichten:
                                        </li>
                                        <li>
                                            Het zelfstandig voorbereiden en uitvoeren van het bossen van radijs;
                                        </li>
                                        <li>
                                            De geoogste bossen in fust (kisten/kratten) doen
                                        </li>
                                        <li>
                                            Geoogste radijs in de schuur zetten
                                        </li>
                                        <li>
                                            Na afloop de werkplek opruimen waar gewerkt wordt
                                        </li>
                                        <li>
                                            Het informeren / rapporteren aan directie over de voortgang van deze werkzaamheden.
                                        </li>
                                        <li>
                                            Na overeenstemming tussen opdrachtgever en opdrachtnemer kan de opdrachtnemer andere agrarische werkzaamheden uitvoeren.
                                        </li>
                                    </ol>

                                    <h3 style="margin-top: 30px">Artikel 2 Uitvoering van de opdracht</h3>
                                    <ol>
                                        <li>
                                            Opdrachtgever kan aanwijzingen geven over de wijze waarop Opdrachtnemer de opdracht realiseert.
                                        </li>
                                        <li>
                                            Opdrachtnemer verplicht zich deze werkzaamheden naar beste weten en kunnen te zullen uitvoeren.
                                        </li>
                                        <li>
                                            Opdrachtnemer zal zich als goed opdrachtnemer gedragen en de werkzaamheden overeenkomstig de wet en het maatschappelijk gebruik uitvoeren.
                                        </li>
                                    </ol>

                                    <h3 style="margin-top: 30px">Artikel 3 Duur van de overeenkomst</h3>
                                    <ol>
                                        <li>
                                            De opdracht vangt aan op <b>{{ agreements[0].starts_at.format('DD-MM-YYYY') }}</b> en wordt aangegaan tot <b>{{ agreements[0].ends_at.format('DD-MM-YYYY') }}</b>.
                                        </li>
                                        <li>
                                            Opdrachtgever verklaart zich er uitdrukkelijk mee akkoord dat Opdrachtnemer ook ten behoeve van andere opdrachtgevers werkzaamheden verricht.
                                        </li>
                                    </ol>

                                    <h3 style="margin-top: 30px">Artikel 4 Nakoming en vervanging</h3>
                                    <ol>
                                        <li>
                                            Indien op enig moment de Opdrachtnemer voorziet dat hij de verplichtingen in verband met een geaccepteerde opdracht niet, niet tijdig of niet naar behoren kan nakomen, dan dient de Opdrachtnemer de Opdrachtgever hiervan onmiddellijk op de hoogte te stellen.
                                        </li>
                                        <li>
                                            Het staat de Opdrachtnemer vrij zich bij de werkzaamheden te laten vervangen door een ander. Opdrachtnemer meldt voorafgaand aan de vervanging aan Opdrachtgever wie de werkzaamheden namens hem uitvoer(en)(t). Daarbij heeft opdrachtgever niet het recht de vervanger(s) te weigeren, anders dan op grond van objectieve kwalificaties zoals vermeld in bijlage I.
                                        </li>
                                        <li>
                                            Indien Opdrachtnemer gedurende een aaneengesloten periode van langer dan 2 weken niet in staat is tot uitvoering van de Opdracht, zal Opdrachtnemer gerechtigd zijn zorg te dragen voor een behoorlijke vervanging, waarbij Opdrachtgever gerechtigd is te beoordelen of de door Opdrachtnemer voorgedragen vervanger geschikt is voor de uitoefening van de in artikel 2 genoemde taak.
                                        </li>
                                        <li>
                                            Opdrachtnemer en opdrachtgever formuleren voorafgaand aan het aanvaarden van de opdracht, samen de objectieve kwalificaties waaraan Opdrachtnemer en de eventuele vervanger(s) moet(en) voldoen. De objectieve kwalificaties worden als bijlage bij deze overeenkomst gevoegd.
                                        </li>
                                        <li>
                                            Opdrachtnemer blijft ook tijdens de vervanging verantwoordelijk voor de kwaliteit van het werk en het naleven van de gemaakte afspraken.
                                        </li>
                                        <li>
                                            Opdrachtnemer overhandigt de volgende informatie van haar vervanger aan Opdrachtgever;<br>
                                            <ul>
                                                <li>geldig legitimatiebewijs;</li>
                                                <li>BTW-nummer;</li>
                                                <li>Uittreksel uit het handelsregister van de Kamer van Koophandel.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Opdrachtnemer mag bij de uitvoering van de werkzaamheden samenwerken met derden. Ten aanzien van deze derden gelden dezelfde voorwaarden als voor vervangers.
                                        </li>
                                    </ol>

                                    <h3 style="margin-top: 30px">Artikel 5 Opzegging overeenkomst</h3>
                                    <ol>
                                        <li>
                                            Partijen hebben het recht om de Overeenkomst tussentijds met onmiddellijke ingang op te zeggen indien:
                                            <ol style="list-style-type: lower-roman;">
                                                <li>
                                                    de wederpartij surseance van betaling aanvraagt of in staat van faillissement wordt verklaard of anderszins in een blijvende toestand van betalingsonmacht verkeert dan wel wordt ontbonden;
                                                </li>
                                                <li>
                                                    sprake is van onvoorziene omstandigheden (daaronder begrepen wijziging van wet- en regelgeving) die niet voor rekening van de opzeggende partij komen en die zo ingrijpend zijn, dat van de opzeggende partij naar maatstaven van redelijkheid en billijkheid instandhouding van de overeenkomst niet verlangd kan worden.
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            Opdrachtgever kan de overeenkomst per direct stop zetten bij onrechtmatig handelen door Opdrachtnemer.
                                        </li>
                                    </ol>

                                    <h3 style="margin-top: 30px">Artikel 6 Vergoeding, facturering en betaling</h3>
                                    <ol>
                                        <li>Opdrachtgever betaalt Opdrachtnemer de tarieven zoals vermeld in bijlage II. </li>
                                        <li>Opdrachtnemer zal voor de verrichte werkzaamheden aan Opdrachtgever een factuur (doen) zenden. De factuur zal voldoen aan de wettelijke vereisten.</li>
                                        <li>Opdrachtgever betaalt het gefactureerde bedrag aan Opdrachtnemer binnen 14 dagen na ontvangst van de factuur.</li>
                                        <li>In geval hulpmiddelen van Opdrachtgever noodzakelijk zijn bij de uitvoering van de opdracht, brengt Opdrachtgever de daarmee samenhangende kosten in rekening aan Opdrachtnemer.</li>
                                    </ol>

                                    <h3>Artikel 7 Aansprakelijkheid/ schade</h3>
                                    <ol>
                                        <li>
                                            Opdrachtnemer is aansprakelijk voor alle schade die door hemzelf of door hem ingeschakelde derden wordt toegebracht aan Opdrachtgever of aan derden bij de uitvoering van de werkzaamheden van deze overeenkomst. Opdrachtnemer is eveneens aansprakelijk voor alle schade van Opdrachtgever die het gevolg is van het feit dat Opdrachtnemer zijn verplichtingen in verband met de overeenkomst niet of niet voldoende is nagekomen.
                                        </li>
                                        <li>
                                            De Opdrachtgever aanvaardt geen aansprakelijkheid voor, en de Opdrachtnemer zal de Opdrachtgever vrijwaren tegen, elke vordering of eis, die tegen de Opdrachtgever mocht worden ingesteld ter zake van ziekte, letsel en/of overlijden van de door de Opdrachtnemer ingeschakelde derde(n) en/of verlies en/of schade aan de eigendommen van de door de Opdrachtnemer ingeschakelde derde(n) dan wel van de Opdrachtnemer zelf, behoudens situaties waarbij de vordering of eis het gevolg is van het handelen van de Opdrachtgever zelf.
                                        </li>
                                        <li>
                                            De Opdrachtgever moet de Opdrachtnemer alle niet aan de Opdrachtnemer toe te rekenen schade vergoeden die deze in verband met de uitvoering van de opdracht lijdt ten gevolge van de verwezenlijking van het aan de opdracht verbonden bijzonder gevaar dat de risico’s, welke de uitoefening van het beroep van Opdrachtnemer naar zijn aard meebrengt, te buiten gaan.
                                        </li>
                                        <li>
                                            Opdrachtnemer vrijwaart Opdrachtgever voor alle aanspraken van derden, verband houdend met en voortvloeiend uit de uitvoering door Opdrachtnemer dan wel door de vervanger van Opdrachtnemer van de werkzaamheden van deze Overeenkomst.
                                        </li>
                                        <li>
                                            Bij de aansprakelijkheidsverdeling tussen Opdrachtgever en Opdrachtnemer dienen de normen van redelijkheid en billijkheid en de in de branche gebruikelijke beperkingen van aansprakelijkheid in acht te worden genomen.
                                        </li>
                                    </ol>

                                    <h3 style="margin-top: 30px">Artikel 8 Verzekeringen</h3>
                                    <p style="padding: 0 10px; font-size: 0.9rem">
                                        Opdrachtnemer zal zich voldoende verzekeren ter zake zijn aansprakelijkheden voor zover gebruikelijk voor de werkzaamheden die hij in opdracht verricht.
                                    </p>

                                    <h3 style="margin-top: 30px">Artikel 9 Rechts- en forumkeuze</h3>
                                    <ol>
                                        <li>
                                            Op deze overeenkomst en al hetgeen daarmee verband houdt, is Nederlands recht van toepassing.
                                        </li>
                                        <li>
                                            Geschillen met betrekking tot deze overeenkomst of met betrekking tot al hetgeen daarmee verband houdt of daaruit voortvloeit, zullen aan de bevoegde rechter in Nederland worden voorgelegd.
                                        </li>
                                    </ol>

                                    <h3 style="margin-top: 30px">Artikel 10 Wijziging van de overeenkomst</h3>
                                    <p style="padding: 0 10px; font-size: 0.9rem">
                                        Wijzigingen van en aanvullingen op deze overeenkomst zijn slechts geldig voor zover deze schriftelijk tussen partijen zijn overeengekomen.
                                    </p>

                                    <table style="width:100%; margin-top: 50px">
                                        <thead>
                                            <tr>
                                                <th width="50%">
                                                    Opdrachtgever
                                                </th>
                                                <th width="50%">
                                                    Opdrachtnemer
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td width="50%">
                                                    Namens deze:
                                                </td>
                                                <td width="50%">
                                                    Namens deze:
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img src="img/Jongfresh-signature-erik-de-jong.jpg" width="140px">
                                                </td>
                                                <td>
                                                        ..............
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    E.P.S. de Jong
                                                </td>
                                                <td>
                                                    {{ user.name() }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <!-- BIJLAGE I -->
                                    <h2 style="margin-top:50px">Bijlage I</h2>

                                    <p>
                                        Objectieve criteria voor weigering vervanger:
                                        <ul>
                                            <li>
                                                Vervanger is geen zzp-er, of;
                                            </li>
                                            <li>
                                                Vervanger is niet ingeschreven in de Kamer van Koophandel, of;
                                            </li>
                                            <li>
                                                Vervanger heeft van de belastingdienst geen BTW identificatienummer ontvangen.
                                            </li>
                                        </ul>
                                    </p>

                                    <!-- BIJLAGE II -->
                                    <h2 style="margin-top:50px">Bijlage II</h2>
                                    <p>
                                        Tarieventabel*
                                    </p>
                                    <table style="border-collapse: collapse;">
                                        <thead>
                                            <tr>
                                                <th width="40%" style="border-right: 1px solid #ccc">Te oogsten radijs</th>
                                                <th width="30%" style="text-align:center; border-right: 1px solid #eee">Bossen per doos/krat</th>
                                                <th width="30%" style="text-align:center">Tarief per doos/krat</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td rowspan="4" style="border-top: 1px solid #999; border-right: 1px solid #ccc;font-size: 0.8rem;">Bos of pondschaal radijs van een willekeurige kleur of vorm en de mix van witte en rode radijs, in de sorteringen middel/grof of grof.</td>
                                                <td style="text-align:center; border-top: 1px solid #999; border-right: 1px solid #eee">15</td>
                                                <td style="text-align:center; border-top: 1px solid #999">€1,36</td>
                                            </tr>
                                            <tr>
                                                <td style="text-align:center; border-top: 1px solid #eee; border-right: 1px solid #eee">10</td>
                                                <td style="text-align:center; border-top: 1px solid #eee">€0,90</td>
                                            </tr>
                                            <tr>
                                                <td style="text-align:center; border-top: 1px solid #eee; border-right: 1px solid #eee">9</td>
                                                <td style="text-align:center; border-top: 1px solid #eee">€0,81</td>
                                            </tr>
                                            <tr>
                                                <td style="text-align:center; border-top: 1px solid #eee; border-right: 1px solid #eee">8</td>
                                                <td style="text-align:center; border-top: 1px solid #eee">€0,72</td>
                                            </tr>
                                            <tr>
                                                <td rowspan="5" style="border-top: 1px solid #999; border-right: 1px solid #ccc;font-size: 0.8rem;">Bos of pondschaal radijs met drie verschillende kleuren in één bos. In vaktermen Rainbow radijs.</td>
                                                <td style="text-align:center; border-top: 1px solid #999; border-right: 1px solid #eee">15</td>
                                                <td style="text-align:center; border-top: 1px solid #999">€2,04</td>
                                            </tr>
                                            <tr>
                                                <td style="text-align:center; border-top: 1px solid #eee; border-right: 1px solid #eee">10</td>
                                                <td style="text-align:center; border-top: 1px solid #eee">€1,36</td>
                                            </tr>
                                            <tr>
                                                <td style="text-align:center; border-top: 1px solid #eee; border-right: 1px solid #eee">9</td>
                                                <td style="text-align:center; border-top: 1px solid #eee">€1,22</td>
                                            </tr>
                                            <tr>
                                                <td style="text-align:center; border-top: 1px solid #eee; border-right: 1px solid #eee">8</td>
                                                <td style="text-align:center; border-top: 1px solid #eee">€1,08</td>
                                            </tr>
                                            <tr>
                                                <td style="text-align:center; border-top: 1px solid #eee; border-right: 1px solid #eee">6</td>
                                                <td style="text-align:center; border-top: 1px solid #eee">€0,81</td>
                                            </tr>
                                            <tr>
                                                <td rowspan="3" style="border-top: 1px solid #999; border-right: 1px solid #ccc;font-size: 0.8rem;">Bos of schaal Mini radijs in alle kleuren en kleurencombinaties met elk minimaal 25 knollen.</td>
                                                <td style="text-align:center; border-top: 1px solid #999; border-right: 1px solid #eee">16</td>
                                                <td style="text-align:center; border-top: 1px solid #999">€2,90</td>
                                            </tr>
                                            <tr>
                                                <td style="text-align:center; border-top: 1px solid #eee; border-right: 1px solid #eee">15</td>
                                                <td style="text-align:center; border-top: 1px solid #eee">€2,72</td>
                                            </tr>
                                            <tr>
                                                <td style="text-align:center; border-top: 1px solid #eee; border-right: 1px solid #eee">9</td>
                                                <td style="text-align:center; border-top: 1px solid #eee">€1,62</td>
                                            </tr>
                                            <tr>
                                                <td style="border-top: 1px solid #999; border-right: 1px solid #ccc;font-size: 0.8rem;">Alle overige werkzaamheden</td>
                                                <td style="border-top: 1px solid #999; text-align:center; border-right: 1px solid #eee">Per uur</td>
                                                <td style="border-top: 1px solid #999; text-align:center">€{{ agreements[0].hourly_wage.replace('.', ',') }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p style="color: #444; margin: 10px 0 30px;font-size: 0.8rem;">
                                        *Alle tarieven zijn exclusief BTW. Wanneer er een andere inhoud per doos/krat zich voordat dan hierboven beschreven zal er gerekend worden met €0,09 per geoogste bos of pondschaal radijs.
                                    </p>

                                    <!-- CHECKMARK -->
                                    <label for="agreement_accepted" class="text-center" style="background-color: #ebf3ed;border-radius:3px;padding: 10px 10px 0 10px;">
                                        <input id="agreement_accepted" type="checkbox" style="width: 20px; height: 20px" v-model="form.agreement_accepted" v-bind:class="{ 'is-invalid': form.errors.has('agreement_accepted') }">
                                        <span style="margin-left: 8px; vertical-align: super;">Ik heb de overeenkomst gelezen en ga ermee akkoord.</span>
                                    </label>
                                    <div class="invalid-feedback" v-if="form.errors.has('agreement_accepted')" v-text="form.errors.get('agreement_accepted')"></div>

                                </div>

                            </div>

                            <button class="login-button">
                                Verder
                            </button>

                            <a @click="logout" class="login-sub-button">
                                Uitloggen
                            </a>
                        </form>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import moment from 'moment';
    import { useAuthStore } from './../../stores/auth.js';
    import Form from './../../helpers/form.js';
    import Agreement from './../../models/agreement';
    import { ref } from 'vue';
    import { useToast } from 'vue-toast-notification';
    import { useRouter } from 'vue-router';


    const router = useRouter();

    const form = ref(new Form({
        agreement_accepted: false,
        accepted_at: moment().format('YYYY-MM-DD')
    }));
    const agreements = ref([]);
    const hasOldAgreements = ref(false);

    const store = useAuthStore();
    const user = store.user;

    const logout = function() {
        store.logout();
    }

    const onSubmit = function() {
        form.value.put('users/' + user.id + '/agreements/' + agreements.value[0].id + '/accept')
        .then(response => {
            agreements.value[0].accepted_at = moment();
            useToast().success('Overeenkomst geaccepteerd');

            if (agreements.value[0].starts_at.format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')) {
                user.currentAgreement = agreements.value[0];
            }

            checkForRedirect();
        }).catch(error => {
            useToast().error(error.message);
        });
    }

    const getUser = function() {
        axios.get('/users/' + user.id + '/agreements?include=file').then(response => {
            checkForRedirect();
            agreements.value = [];
            for (let agreementData of response.data.data) {
                hasOldAgreements.value = true;
                let agreement = new Agreement();
                agreement.setAttributesFromResponse(agreementData);
                if (agreement.ends_at >= moment()) {
                    agreements.value.push(agreement);
                }
            }

            agreements.value = _.orderBy(agreements.value, 'starts_at', 'asc');
        });
    }
    getUser();

    const checkForRedirect = function()
    {
        if (user.currentAgreement) {
            router.push('/dashboard');
        }
    }
</script>
