import { AbstractModel } from './abstract-model';
import Customer from './customer';
import Type from './type';
import User from './user';

export default class SaleForecast extends AbstractModel {

    getMutators()
    {
        return {
            at: 'date',
            createdAt: 'datetime',
        }
    }

    relations() {
        return {
            customer: Customer,
            type: Type,
            user: User
        }
    }

    relationPlural()
    {
        return {
            customer: false,
            type: false,
            user: false
        }
    }

}
