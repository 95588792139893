import Fraction from "../../models/fraction.js"
import { AbstractHttpService } from "./abstract-http-service";

class FractionService extends AbstractHttpService {

    endpoint() {
        return 'fractions';
    }

    createModel(data) {
        return new Fraction(data);
    }
}

export default new FractionService;
