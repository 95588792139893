<template>
    <modal id="history" :large="true" :excludeSaveButton="true" @close="$emit('close')">
        <template v-slot:header>{{ product.name() }}</template>

        <table class="stock-history">
            <tr class="stock-history-header">
                <th>Type</th>
                <th>Extra</th>
                <th>Door</th>
                <th>Datum/tijd</th>
                <th>+/-</th>
                <th>Cum.</th>
            </tr>
            <tr class="stock-history-row" v-for="(item, index) of timeline" :class="{'first-day': ['startstock', 'endstock'].includes(item.objectName)}">
                <template v-if="item.objectName === 'startstock'">
                    <td>Startvoorraad</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{{ item.date.format('DD-MM-YYYY') }}</td>
                    <td>+{{ item.totalAmountPlus !== 0 ? (Math.round(item.totalAmountPlus*10) / 10) : '-' }} | {{ item.totalAmountMinus !== 0 ?  (Math.round(item.totalAmountMinus*10) / 10)  : '-' }}</td>
                    <td>{{ item.amount }}</td>
                </template>

                <template v-else-if="item.objectName === 'stock'">
                    <td>{{ item.order ? 'Order' : 'Voorraadactie'}}</td>
                    <td>-</td>
                    <td>{{ item.order ? item.order.customer.name : '-'}}</td>
                    <td>{{ item.createdAt.format('HH:mm') }}</td>
                    <td>{{ item.amount > 0 ? '+' : '' }}{{ item.amount }}</td>
                    <td>{{ Math.round(item.prev + item.amount) }}</td>
                </template>

                <template v-else-if="item.objectName === 'purchase'">
                    <td>Inkoop</td>
                    <td>
                        {{ item.date.format('DD-MM-YYYY') }}<br/>
                        <span v-tooltip="`Gewijzigd op ${item.updatedAt.format('DD-MM-YYYY hh:mm')}`" style="color: red" v-if="! item.date.isSame(item.updatedAt, 'day')">
                            {{ item.updatedAt.format('DD-MM-YYYY') }}
                        </span>
                    </td>
                    <td></td>
                    <td>{{ item.createdAt.format('HH:mm') }}</td>
                    <td>{{ item.amount > 0 ? '+' : '' }}{{ item.amount }}</td>
                    <td>{{ Math.round(item.prev + item.amount) }}</td>
                </template>

                <template v-else-if="item.objectName === 'harvestregistration'">
                    <td>Oogstregistratie</td>
                    <td>{{ item.harvestAssignment ? 'Totaal: ' + item.harvestAssignment.amount : 'Eigen registratie' }}</td>
                    <td>{{ item.user ? item.user.shortName() : '?' }}</td>
                    <td>{{ item.createdAt.format('HH:mm') }}</td>
                    <td>{{ item.harvested > 0 ? '+' : '' }}{{ Math.round(item.harvested) }}</td>
                    <td>{{ Math.round(item.prev + item.harvested) }}</td>
                </template>

                <template v-else>
                    <td>Huidige voorraad</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{{ item.createdAt.format('DD-MM-YYYY') }}</td>
                    <td>+{{ item.totalAmountPlus !== 0 ? (Math.round(item.totalAmountPlus*10) / 10) : '-' }} | {{ item.totalAmountMinus !== 0 ?  (Math.round(item.totalAmountMinus*10) / 10)  : '-' }}</td>
                    <td>{{ item.amount }}</td>
                </template>
            </tr>
        </table>
    </modal>
</template>

<script setup>
    import moment from 'moment';
    import HarvestRegistrationService from '../../services/http/harvest-registration-service';
    import StockService from '../../services/http/stock-service';
    import StartStockService from '../../services/http/start-stock-service';
    import PurchaseService from '../../services/http/purchase-service';
    import { collect } from "collect.js";
    import { ref } from 'vue';

    const props = defineProps(['product']);

    const timeline = ref(collect());
    const today = moment();

    const getHistory = function() {
        const hrPromise = HarvestRegistrationService.get({filters: {
            chest_id_is: props.product.chestId,
            content_id_is: props.product.contentId,
            size_id_is: props.product.sizeId,
            type_id_is: props.product.typeId,
            created_at_is_greater_or_equal_than: moment().subtract(7, 'days').format('YYYY-MM-DD 00:00:00')
        }, include: ['harvestAssignment', 'user']});

        const ssPromise = StartStockService.get({filters: {
            chest_id_is: props.product.chestId,
            content_id_is: props.product.contentId,
            size_id_is: props.product.sizeId,
            type_id_is: props.product.typeId,
            date_is_greater_or_equal_than: moment().subtract(7, 'days').format('YYYY-MM-DD')
        }});

        const sPromise = StockService.get({filters: {
            chest_id_is: props.product.chestId,
            content_id_is: props.product.contentId,
            size_id_is: props.product.sizeId,
            type_id_is: props.product.typeId,
            created_at_is_greater_or_equal_than: moment().subtract(7, 'days').format('YYYY-MM-DD 00:00:00')
        }, include: ['stockable']});

        const pPromise = PurchaseService.get({filters: {
            chest_id_is: props.product.chestId,
            content_id_is: props.product.contentId,
            size_id_is: props.product.sizeId,
            type_id_is: props.product.typeId,
            created_at_is_greater_or_equal_than: moment().subtract(7, 'days').format('YYYY-MM-DD 00:00:00')
        }});


        Promise.all([hrPromise, ssPromise, sPromise, pPromise]).then(response => {
            const unsortedTimeline = collect(response[0].filter(hr => hr.date.isSame(hr.createdAt, 'day')))
                .merge(response[1].all())
                .merge(response[2].all())
                .merge(response[3].all());

            timeline.value = unsortedTimeline.sort((a,b) => {
                const dateA = (a.objectName === 'startstock' ? a.date : a.createdAt);
                const dateB = (b.objectName === 'startstock' ? b.date : b.createdAt);

                if (dateA.isAfter(dateB)) {
                    return 1;
                } else {
                    return -1;
                }
            });

            if (timeline.value.count() > 0) {

                timeline.value.push({
                    createdAt: today,
                    objectName: 'endstock',
                })
                let prev = 0;
                let totalTodayMinus = 0;
                let totalTodayPlus = 0;
                let totalToday = 0;
                for (let item of timeline.value) {
                    item.prev = prev;

                    item.timelineDate = (item.objectName === 'startstock' ? item.date : item.createdAt);
                    item.timelineAmount = (item.objectName === 'harvestregistration' ? item.harvested : item.amount);

                    if (item.objectName === 'startstock') {
                        prev = item.timelineAmount;
                        item.totalAmount = totalToday;
                        item.totalAmountPlus = totalTodayPlus;
                        item.totalAmountMinus = totalTodayMinus;
                        totalToday = 0;
                        totalTodayPlus = 0;
                        totalTodayMinus = 0;
                    } else if(item.objectName === 'endstock') {
                        item.totalAmount = totalToday;
                        item.totalAmountPlus = totalTodayPlus;
                        item.totalAmountMinus = totalTodayMinus;
                        totalToday = 0;
                        totalTodayPlus = 0;
                        totalTodayMinus = 0;
                        item.amount = prev+totalToday;
                    } else {
                        prev += item.timelineAmount;
                        totalToday += item.timelineAmount;
                        if (item.timelineAmount > 0) {
                            totalTodayPlus += item.timelineAmount;
                        } else {
                            totalTodayMinus += item.timelineAmount;
                        }
                    }

                }
            }
        })

    }

    getHistory();

</script>
