<template>
    <div class="login-container">
        <div class="login-column">
            <div class="login-content-container">
                <div class="login-content">

                    <div class="jongfresh-login-logo"></div>

                    <form @submit.prevent="onSubmit">

                        <div class="form-group">
                            <label for="email">
                                <input id="email" type="email" class="form-control" v-model="form.email" placeholder="E-mailadres" autofocus v-bind:class="{ 'is-invalid': form.errors.has('email') }">
                                <div class="invalid-feedback" v-if="form.errors.has('email')" v-text="form.errors.get('email')"></div>
                            </label>
                        </div>

                        <div class="form-group">
                            <label for="password">
                                <input id="password" type="password" class="form-control" v-model="form.password" placeholder="Nieuw wachtwoord" v-bind:class="{ 'is-invalid': form.errors.has('password') }">
                                <div class="invalid-feedback" v-if="form.errors.has('password')" v-text="form.errors.get('password')"></div>
                            </label>
                        </div>

                        <div class="form-group">
                            <label for="password-confirm">
                                <input id="password-confirm" type="password" class="form-control" v-model="form.password_confirmation" placeholder="Nieuw wachtwoord herhalen" v-bind:class="{ 'is-invalid': form.errors.has('password_confirmation') }">
                                <div class="invalid-feedback" v-if="form.errors.has('password_confirmation')" v-text="form.errors.get('password_confirmation')"></div>
                            </label>
                        </div>

                        <input type="submit" class="login-button" value="Opslaan">

                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useToast } from 'vue-toast-notification';
    import Form from './../helpers/form.js';
    import { useRouter } from 'vue-router';
    import { ref } from 'vue';

    const router = useRouter();

    const props = defineProps(['email', 'token']);

    const form = ref(new Form({
        email: props.email,
        token: props.token,
        password: '',
        password_confirmation: ''
    }))

    const onSubmit = function() {
        form.value.post('forgot-password/reset')
        .then(response => {
            useToast().success('Wachtwoord gewijzigd');
            router.push('/login')
        });
    }
</script>
