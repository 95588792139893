<template>
    <modal @save="onsubmit()" @close="$emit('close')">
        <template v-slot:header>{{ role.translate('name') }} verwijderen</template>

        <p>
            Weet je zeker dat je de rol '{{ role.translate('name') }}' wilt verwijderen?
        </p>

        <template v-slot:close>Annuleren</template>

        <template v-slot:save>Doorgaan</template>
    </modal>
</template>

<script>
    import { useToast } from 'vue-toast-notification';
    import Form from '../../../helpers/form.js';

    export default {
        props: ['role'],

        data() {
            return {

            }
        },

        methods: {
            onsubmit() {
                axios.delete('roles/' + this.role.id).then(function(response) {
                    document.querySelector('body').classList = '';
                    this.$emit('roleRemoved', this.role.id);
                    this.$emit('close')
                    useToast().success('Rol verwijderd');
                }.bind(this));
            }
        }
    };
</script>
