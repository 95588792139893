<template>
    <div class="profile-edit-settings-group">
        <fab-button :class="{danger:edit}" @click.native="$emit('toggle')" :icon="! edit ? 'pencil' : 'x-mark'"></fab-button>
        <fab-button class="success" @click.native="$emit('submit')" v-if="edit" icon="check"></fab-button>
    </div>
</template>

<script>
    export default {
        props: ['edit'],
    }
</script>
