<template>
    <div v-if="authUser.can('notifications') && unreadNotifications.count() > 0" class="widget-holder">
        <div class="widget-header">
            <div class="widget-title">
                <router-link to="/notifications/">Meldingen</router-link>
            </div>
        </div>
        <div class="widget-content">
            <div class="flex-table">
                <div class="flex-table-row notification" v-for="(notification, index) of unreadNotifications" :key="index">
                    <div class="flex-table-content content-username">
                        <span v-if="notification.user"><template v-if="! authUser.can('users')">{{ user.name() }}</template><a v-else @click="$router.push('/users/' + notification.user.id )">{{ notification.user.name() }}</a></span>
                        <span v-else>-</span>
                    </div>
                    <div class="flex-table-content content-attachment">
                        <a class="notification-attachment" target="_blank" v-if="notification.file" :href="notification.file.publicUrl">
                            <Icon name="photo" /> <span>Bijlage openen</span>
                        </a>
                    </div>
                    <div class="flex-table-content content-notification">
                        "{{ notification.body }}"
                    </div>
                    <div class="flex-table-content content-date">
                        {{ notification.createdAt.format('DD-MM-YYYY HH:mm') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue';

    /*
    |--------------------------------------------------------------------------
    | Notifications
    |--------------------------------------------------------------------------
    */
    import { useNotificationStore } from "../../stores/notifications";
    const notificationStore = useNotificationStore();
    const unreadNotifications = computed(() => notificationStore.unreadNotifications);

    /*
    |--------------------------------------------------------------------------
    | Auth user
    |--------------------------------------------------------------------------
    */
    import { useAuthStore } from "../../stores/auth";
    const authStore = useAuthStore();
    const authUser = computed(() => authStore.user);

</script>
