<template>
    <component :is="getIcon()" />
</template>

<script setup>
    import * as solid from '@heroicons/vue/24/solid';
    import * as outline from '@heroicons/vue/24/outline';

    const props = defineProps(['name', 'type']);

    const getIcon = function() {
        const icon = toUpperCamelCase(props.name) + 'Icon';

        if ( props.type === 'solid') {
            return solid[icon];
        }

        return outline[icon];
    }

    const toUpperCamelCase = function (str) {
        let string =  toCamelCase(str);
        return (string.charAt(0).toUpperCase() + string.slice(1));
    }

    const toCamelCase = function(str) {
        return str.replace(/-./g, x=>x[1].toUpperCase());
    }

</script>
