<template>
    <div class="container" id="product-details">
        <div class="row">
            <div class="col">
                <div v-if="type" class="page-header">
                    <h1>{{ type.translate('name') }} {{ from.format('DD-MM-YYYY') }} - {{ till.format('DD-MM-YYYY') }}</h1>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div>
                    <ContentTable
                        v-for="(content, index) of contents" :key="index" :content="content"
                        :harvestRegistrations="harvestRegistrations.where('contentId', content.id)"
                        :dayHarvestRegistrations="dayHarvestRegistrations.filter(
                            (dhr) => dhr.registratable().contentId === content.id
                        )"
                        :users="users"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import moment from 'moment';
    import collect from 'collect.js';
    import { useRoute } from 'vue-router';

    // Components
    import ContentTable from './ContentTable.vue';

    // Http services
    import TypeService from '@/services/http/type-service';
    import ContentService from '@/services/http/content-service';
    import HarvestRegistrationService from '@/services/http/harvest-registration-service';
    import DayHarvestRegistrationService from '@/services/http/day-harvest-registration-service';
    import UserService from '@/services/http/user-service';

    const props = defineProps(['typeId']);
    const emits = defineEmits(['breadcrumbs'])

    const route = useRoute();

    // Data from url
    const from = ref(route.query.from ? moment(route.query.from, 'YYYY-MM-DD') : moment());
    const till = ref(route.query.till ? moment(route.query.till, 'YYYY-MM-DD') : moment());
    const mini = (route.query.mini && route.query.mini === '1' ? true : false);

    // Collections
    const harvestRegistrations = ref(collect());
    const dayHarvestRegistrations = ref(collect());
    const allContents = ref(collect());
    const contents = ref(collect());

    const type = ref(null);

    const users = ref(collect());


    /*
    |--------------------------------------------------------------------------
    | Get Data
    |--------------------------------------------------------------------------
    */
    // Get Harvest registrations, day harvest registrations, contents
    Promise.all([
        HarvestRegistrationService.get({filters: {
            from: from.value.format('YYYY-MM-DD'),
            till: till.value.format('YYYY-MM-DD'),
            type_id_is: props.typeId,
            [mini ? 'size_name' : 'not_size_name']: 'mini'
        }}),

        DayHarvestRegistrationService.get({
            include: ['registratable'],
            filters: {
                'created_at_is_greater_or_equal_than': `${from.value.format('YYYY-MM-DD')} 00:00:00`,
                'created_at_is_lower_or_equal_than': `${till.value.format('YYYY-MM-DD')} 23:59:59`,
                'registratable': {'type_id_is': props.typeId}
            }
        }),

        ContentService.get({filters: {'name_is_not': 'kilo'}}),

        UserService.get({filters: {no_optimus_prime: false}})
    ]).then(([harvestRegistrationModels, dayHarvestRegistrationModels, contentModels, userModels]) => {

        harvestRegistrations.value = harvestRegistrationModels;
        dayHarvestRegistrations.value = dayHarvestRegistrationModels;
        allContents.value = contentModels;
        users.value = userModels;

        setContents();
    });

    // Set contents by getting all used contents from all (day)harvest registrations.
    const setContents = function() {
        const allObjects = collect([
            ...harvestRegistrations.value.all(),
            ...dayHarvestRegistrations.value.map(dhr => dhr.registratable()).all()
        ]);

        const contentIds = allObjects.pluck('contentId').unique().all();
        contents.value = allContents.value.filter(content => contentIds.includes(content.id));
    }

    // Get types
    TypeService.getById(props.typeId).then(typeModel => {
        type.value = typeModel
        breadCrumbs();
    });

    /*
    |--------------------------------------------------------------------------
    | Other
    |--------------------------------------------------------------------------
    */
    const breadCrumbs = function() {
        emits('breadcrumbs', [
            {to: `/compare?from=${from.value.format('YYYY-MM-DD')}&till=${till.value.format('YYYY-MM-DD')}`, label: 'Vergelijken'},
            {label: type.value.name}
        ]);
    }
</script>
