import {AbstractModel} from './abstract-model.js'

export default class FormulaFactor extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            name: null,
            formula_factor: null
        }
    }

    translation() {
        return 'FormulaFactor';
    }

    translate(field) {
        const translations =  {
            "seeds_per_meter": "Zaden per meter",
            "temperature": "Temperatuur"
        }

        return translations[this[field]];
    }

}