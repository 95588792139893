import Notification from "../../models/notification.js"
import { AbstractHttpService } from "./abstract-http-service";

class NotificationService extends AbstractHttpService {

    endpoint() {
        return 'notifications';
    }

    createModel(data) {
        return new Notification(data);
    }
}

export default new NotificationService;
