import axios from 'axios';
import { collect } from "collect.js";
import moment from 'moment';
import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import orderService from './../services/http/order-service';

export const useOrderStore = defineStore('orders', () => {

    const defaultSort = (a, b) => ((a.picked_up_at < b.picked_up_at) ? -1 : 1);

    const ordersLoaded = ref(false);
    const orders = ref(collect());

    const completedOrders = computed(() => orders.value.filter(order => (order.isDone())).sort(defaultSort));
    const incompletedOrders = computed(() => orders.value.filter(order => (! order.isDone())).sort(defaultSort));
    const orderWithoutLoadingTime = computed(() => orders.value.filter(order => (
            order.pickedUpAt !== null
            && order.pickedUpAt.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')
            && order.pickedUpAt.format('HH:mm') === '00:00')
    ));

    const zeroOrderLineOrders = computed(() => {
        return orders.value.filter(order => (order.zeroOrderLine === 1 || order.zeroOrderLine === '1'));
    });

    const getOrders = function ()
    {
        return orderService.get({filters: {default: 1}}).then(orderModels => {
            setOrders(orderModels);
            setOrdersLoaded();
        });

    };

    const setOrders = (newOrders) => {
        (orders.value = newOrders.filter(o => o.pickedUpAt !== null))
    };

    const storeOrder = async function({}, data) { await axios.post('orders', data) };


    const setOrdersLoaded = () => {ordersLoaded.value = true;}

    const addOrder = (order) => {
        const index = orders.value.search(o => o.id === order.id);

        if (index !== false) {
            orders.value.splice(index, 1, [order]);
        } else {
            orders.value.push(order);
        }
    };

    const addOrders = function(newOrders){ orders.value.push(...newOrders) };

    const editOrder = function(order) {
        const index = orders.value.search(o => o.id === order.id);

        if (index !== false) {
            orders.value.splice(orders.value.search(o => o.id === order.id), 1, [order]);
        }
    };

    const removeOrder = function(order) {
        const index = orders.value.search(o => o.id === order.id);

        if (index !== false) {
            orders.value.splice(orders.value.search(o => o.id === order.id), 1);
        }
    };

    const removeOrderById = function(id) {
        const index = orders.value.search(o => o.id === id);

        if (index !== false) {
            orders.value.splice(orders.value.search(order => order.id === id), 1);
        }
    }

    return {
        // State
        ordersLoaded, orders,

        // Getters
        completedOrders, incompletedOrders, orderWithoutLoadingTime, zeroOrderLineOrders,

        // Mutation
        getOrders, setOrders, storeOrder, setOrdersLoaded, addOrder, addOrders,
        editOrder, removeOrder, removeOrderById
    };
});
