<template>
    <div class="container">
        <div class="settings-overview">
            <a class="settings-button" v-if="authUser.can('sowing_planning_settings')" @click="$router.push('/settings/breeds/')">
                <Icon name="share" type="solid" />
                <span>Rassen</span>
            </a>
            <a class="settings-button" v-if="authUser.can('sowing_planning_settings')" @click="$router.push('/settings/sow-planning-settings/')">
                <Icon name="cog-6-tooth" type="solid" />
                <span>Zaaiplanning instellingen</span>
            </a>
            <a class="settings-button" v-if="authUser.can('roles')" @click="$router.push('/settings/roles/')">
                <Icon name="lock-open" type="solid" />
                <span>Rollen</span>
            </a>
            <a class="settings-button" v-if="authUser.can('settings')" @click="$router.push('/settings/emails')">
                <Icon name="envelope" type="solid" />
                <span>E-mails</span>
            </a>
            <a class="settings-button" v-if="authUser.can('reviews')" @click="$router.push('/settings/review-categories/')">
                <Icon name="star" type="solid" />
                <span>Beoordelingscategorieën</span>
            </a>
            <a class="settings-button" v-if="authUser.can('tasks')" @click="$router.push('/settings/tasks/')">
                <Icon name="wrench" type="solid" />
                <span>Taken</span>
            </a>
            <a class="settings-button" v-if="authUser.can('articles')" @click="$router.push('/settings/articles')">
                <Icon name="shopping-cart" type="solid" />
                <span>Artikelen</span>
            </a>
            <a class="settings-button" v-if="authUser.can('assortments')" @click="$router.push('/settings/assortments')">
                <Icon name="funnel" type="solid" />
                <span>Assortimenten</span>
            </a>
            <a class="settings-button" v-if="authUser.can('formula')" @click="$router.push('/settings/formula')">
                <Icon name="beaker" type="solid" />
                <span>Doorlooptijden formule</span>
            </a>
            <a class="settings-button" v-if="authUser.can('customer-insights')" @click="$router.push('/settings/customer-insights')">
                <Icon name="currency-euro" type="solid" />
                <span>Insight kosten</span>
            </a>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useAuthStore } from '../../stores/auth.js';

    export default {

        computed: mapState(useAuthStore, {
            authUser: 'user'
        })


    }
</script>
