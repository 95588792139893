import {AbstractModel} from './abstract-model.js'

export default class CoverSheet extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            name: null,
            code: null,
            cents: null,
        }
    }

    getMutators()
    {
        return {
        }
    }

    relations() {
        return {
        }
    }

    relationPlural()
    {
        return {
        }
    }

    modelName() {
        return 'Dekvel';
    }

}
