import {AbstractModel} from './abstract-model.js'
import FileModel from './file-model.js';
import DocumentType from './document-type.js';
import User from './user.js';
import moment from 'moment';

export default class Document extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            name: null,
            expires_at: null,
            delivered_at: null,
            starts_at: null,
            ends_at: null,
            user_id: null,
            document_type_id: null,
            file: null,
            type: null,
            documentType: null,
            user: null
        }
    }

    getTransformers() {
        return {
            documentType: DocumentType,
            file: FileModel,
            user: User
        }
    }

    dateFormatted() {
        let date = this.docDate();
        if (! date) {
            return '-'
        }

        if (Array.isArray(date)) {
            return date[0].format('DD-MM-YYYY') + ' - ' + date[1].format('DD-MM-YYYY');
        } else {
            return date.format('DD-MM-YYYY');
        }

        return '-';
    }

    docDate() {
        if (this.documentType.name === 'id') {
            return moment(this.expiresAt ?? this.expires_at, "YYYY-MM-DD HH-mm-ss");
        } else if (this.documentType.name === 'kvk') {
            return moment(this.deliveredAt ?? this.delivered_at, "YYYY-MM-DD HH-mm-ss");
        } else if (this.documentType.name === 'contract' || this.documentType.name === 'document' && (this.startsAt ?? this.starts_at) && (this.endsAt ?? this.ends_at)) {
            return [moment(this.startsAt ?? this.starts_at, "YYYY-MM-DD HH-mm-ss"), moment(this.endsAt ?? this.ends_at, "YYYY-MM-DD HH-mm-ss")];
        } else if (this.documentType.name === 'performance_evaluation' || this.documentType.name === 'document' && this.starts_at) {
            return moment(this.startsAt ?? this.starts_at, "YYYY-MM-DD HH-mm-ss");
        } else if (this.documentType.name === 'document' && this.ends_at) {
            return moment(this.endsAt ?? this.ends_at , "YYYY-MM-DD HH-mm-ss");
        }

        return false;
    }

    status() {
        if (this.documentType.name === 'id' || this.documentType.name === 'contract') {
            let date = (this.documentType.name === 'id' ? this.docDate() : this.docDate()[1]);
            if (date < moment()) {
                return 'expired';
            } else if(date < moment().add(1, 'months')) {
                return 'danger';
            } else {
                return 'good'
            }
        }

        return 'good';
    }

    statusIcon() {
        let status = this.status();
        if (status === 'expired') {
            return 'x-mark';
        } else if (status === 'danger') {
            return 'exclamation-circle';
        } else if (status === 'good') {
            return 'check';
        }

    }

    docType() {
        if (this.documentType.name === 'document') {
            return this.type;
        } else {
            return this.documentType.translate('name');
        }
    }

    relations()
    {
        return {
            user: User,
            documentType: DocumentType,
        }
    }

    relationPlural()
    {
        return {
            documentType: false,
            user: false,
        }
    }
}
