<template>
    <div v-click-outside="() => {close()}" class="side-modal-wrapper">
        <div class="modal" :class="{'no-fade': noFade}" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" :class="{'modal-lg': large === true}" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <slot name="header">Pop-up</slot>
                        </h5>
                        <button type="button" class="close" aria-label="Close" @click="close()">
                            <span aria-hidden="true">
                                <Icon name="x-mark" />
                            </span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <slot></slot>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="$emit('save')" class="btn-save btn btn-success">
                            <slot name="save">Opslaan</slot>
                        </button>
                        <slot name="buttons"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    const props = defineProps(['large', 'noFade'])
    const emits = defineEmits(['close', 'save'])

    const opening = ref(true);

    const close = function() {
        if (! opening.value ) {
            emits('close');
            document.querySelector('body').classList = '';
        }
    }

    setTimeout(() => {
        opening.value = false;
    }, 500)

    document.querySelector('body').classList = 'side-modal-open';
</script>
