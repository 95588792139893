import ProductionControlMeasurement from "../../models/production-control-measurement";
import { AbstractHttpService } from "./abstract-http-service";

class ProductionControlMeasurementService extends AbstractHttpService {

    endpoint() {
        return 'production-control-measurements';
    }

    createModel(data) {
        return new ProductionControlMeasurement(data);
    }
}

export default new ProductionControlMeasurementService;
