import { AbstractModel } from './abstract-model';
import User from './user.js'

export default class PermanentWorkSession extends AbstractModel {

    relations()
    {
        return {
            user: User
        }
    }

    relationPlural()
    {
        return {
            user: false
        }
    }

    getMutators() {
        return {
            at: 'datetime',
            startsAtFull: 'datetime',
            endsAtFull: 'datetime'
        };
    }
}
