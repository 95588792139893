import PermanentWorkSession from "../../models/permanent-work-session";
import { AbstractHttpService } from "./abstract-http-service";

class PermanentWorkSessionService extends AbstractHttpService {

    endpoint() {
        return 'permanent-work-sessions';
    }

    createModel(data) {
        return new PermanentWorkSession(data);
    }

    updateMany(payload, parameters = {}) {
        let url = this.makeUrl(parameters, 'update-many');

        return axios['post'](url, payload);
    }
}

export default new PermanentWorkSessionService;
