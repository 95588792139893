<template>
    <div class="user-data">
        <h2>Bedrijfsgegevens</h2>
        <form @submit.prevent>
            <DataRow v-for="(item, index) in dataRows" :key="index" :item="item"
                v-model="user[item.field]"
                :edit="('only_label' in item && item['only_label']) ? false : edit"
                :errors="form.errors"
            />

            <div class="profile-settings-group">
                <EndOfForm @submit="onSubmit" @toggle="toggleEdit" :edit="edit" />
            </div>
        </form>
    </div>
</template>

<script>
    import Form from '../../../helpers/form.js';
    import DataRow from './Components/DataRow.vue';
    import EndOfForm from './../Components/EndOfForm.vue';
    import { useToast } from 'vue-toast-notification';

    export default {
        props: ['user', 'authUser'],
        components: {DataRow, EndOfForm},

        data() {
            return {
                edit: false,
                dataRows: [
                    {field: 'company_name', label: 'Bedrijfsnaam'},
                    {field: 'location', label: 'Vestigingsplaats'},
                    {field: 'address', label: 'Straat en huisnummer'},
                    {field: 'postal_code', label: 'Postcode'},
                    {field: 'kvk', label: 'KvK nr.'},
                    {field: 'btw', label: 'BTW nr.'}
                ],
                form: null,
                originalData: {}
            }
        },

        methods: {
            onSubmit() {
                this.form.put('/users/' + this.user.id + '?include=agreements,userType,roles,mediumAvatar,documents.documentType&deactivated=1')
                .then(function(response){
                    this.$emit('update', response);
                    this.edit = (! this.edit);
                    useToast().success('Gegevens opgeslagen');
                }.bind(this));
            },

            toggleEdit() {
                if (! this.edit) {
                    this.createForm();
                    this.originalData = _.cloneDeep(this.form.data());
                } else {
                    this.form.reset(this.originalData);
                }
                this.edit = (! this.edit);
            },

            createForm() {
                this.form = new Form(
                    ['company_name', 'location', 'address', 'postal_code', 'kvk', 'btw'],
                    this.user
                );
            }
        },

        created() {
            this.createForm();
        }
    };
</script>
