import {AbstractModel} from './abstract-model';
import User from './user';
import Type from './type.js';
import Chest from './chest.js';
import Content from './content.js';
import Size from './size.js';

export default class DayHarvestRegistration extends AbstractModel {

    getMutators()
    {
        return {
            'createdAt': 'datetime',
            'date': 'date'
        }
    }

    name() {
        return this.chest.name + ' ' + this.size.translate('name') + ' ' + this.type.translate('name') + ' ' + this.content.name;
    }

    getCcst()
    {
        return {
            chest_id: this.chestId,
            content_id: this.contentId,
            size_id: this.sizeId,
            type_id: this.typeId,
        }
    }

    relations() {
        return {
            user: User,
            chest: Chest,
            content: Content,
            size: Size,
            type: Type,
        }
    }

    relationPlural()
    {
        return {
            user: false,
            chest: false,
            content: false,
            size: false,
            type: false,
        }
    }

    getModelName() {
        return 'day-harvest-registration';
    }
    
}
