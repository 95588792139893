<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Artikel groei en krimp</h1>
                    <ul class="switch">
                        <li :class="{active: showAs == 'percentage'}" @click="showAs = 'percentage'">
                            %
                        </li>
                        <li :class="{active: showAs == 'numbers'}" @click="showAs = 'numbers'">
                            1
                        </li>
                    </ul>
                </div>

                <div class="page-header-settings">
                    <div class="row">
                        <div class="col-xl-6 col-xxl-2">
                            <div class="datepicker-styling">
                                <span class="styled-label">
                                    Van
                                </span>
                                <DatePicker :clearable="false"  @input="getData" v-model="from" />
                            </div>
                        </div>
                        <div class="col-xl-6 col-xxl-2">
                            <div class="datepicker-styling">
                                <span class="styled-label">
                                    Tot
                                </span>
                                <DatePicker :clearable="false"  @input="getData" v-model="till" />
                            </div>
                        </div>

                        <div class="col-xl-6 col-xxl-4">
                            <span class="styled-label">
                                Klanten
                            </span>
                            <MultiSelect
                                :numberOfSelectedOptionsShown="2"
                                :selectAllOption="true"
                                v-model="customerIds"
                                placeholder="Kies één of meerdere klanten"
                                :options="customers.sortBy('name').map(c => ({label: c.name, value: c.id})).all()"
                                @update:modelValue="getData()"
                            />
                        </div>

                        <div class="col-6 col-xl-4 col-xxl-4">
                            <span class="styled-label">
                                Artikelgroepen
                            </span>
                            <MultiSelect
                                v-show="! article"
                                :numberOfSelectedOptionsShown="2"
                                :selectAllOption="true"
                                v-model="typeIds"
                                placeholder="Kies één of meerdere artikelgroepen"
                                :options="types.map(t => ({label: t.translate('name'), value: t.id})).all()"
                                @update:modelValue="getData()"
                            />
                            <span v-if="article">
                                {{ article.name }}
                                <a @click="article = null">
                                    <Icon name="x-mark" style="width: 20px;" />
                                </a>
                            </span>
                        </div>

                        <div class="col-6 col-xl-12">
                            <span class="styled-label">
                                Toon
                            </span>
                            <div class="styled-checkbox d-inline-block">
                                <label>
                                    <input type="checkbox" disabled checked>
                                    <span>Producten zonder kilo's</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="graph-holder article-graph-holder" v-if="preparedData.count() > 0">

        <MultiCustomerGraph
            v-if="! article"
            :preparedData="preparedData"
            :barHeight="barHeight"
            :formatNumber="formatNumber"
            :showAs="showAs"
            :maxValue="maxValue"
            @articleSelected="(selectedArticle) => article = selectedArticle"
        />
        <OneArticleGraph v-else
            :article="article" :customerIds="customerIds" :from="from" :till="till"
            :percentage="percentage" :customers="customers" :formatNumber="formatNumber"
            :showAs="showAs"
        />
    </div>
    <div v-else class="no-data-placeholder">
        Geen gegevens gevonden met de huidige filters.
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import MultiSelect from '@/components/Form/MultiSelect.vue';
    import OrderService from '@/services/http/order-service';
    import collect from 'collect.js'
    import useCustomerInsightsFunctionalities from './useCustomerInsightsFunctionalities';
    import MultiCustomerGraph from './Article/MultiArticleGraph.vue';
    import OneArticleGraph from './Article/OneArticleGraph.vue';

    const emit = defineEmits(['breadcrumbs']);
    emit('breadcrumbs', [
        {label: 'Insights', to: '/customer-insights'},
        {label: 'Artikel groei en krimp'}
    ]);

    const {
        // Variables
        from, till,
        customerIds, customers,
        typeIds, types,

        // Computed
        selectedCustomers,

        // Functions
        getCustomers, getTypes, formatNumber, getMaxValueRounded
    } = useCustomerInsightsFunctionalities();

    const data = ref(collect());

    const showAs = ref('percentage');
    const article = ref(null);

    const getData = function() {
        if (customerIds.value.length === 0 || typeIds.value.length === 0) {
            data.value = collect();
            return;
        }

        OrderService.getArticleInsightsGrowth({
            from: from.value,
            till: till.value,
            customer_ids: customerIds.value,
            type_ids: typeIds.value,
        }).then(dataItems => {
            data.value = collect(dataItems);
        });
    }

    const preparedData = computed(() => {
        const articleData = collect();

        for(const articleId of data.value.pluck('article_id').unique()) {
            articleData.push({
                ...percentage(articleId),
                name: data.value.firstWhere('article_id', articleId).name,
                id: articleId
            });
        }

        const sortOn = (showAs.value === 'percentage' ? 'percentage' : 'diffrence');

        return articleData
            .filter(c => ((! c.infinity || c.percentage !== 0)))
            .sort((a, b) => {
                if (sortOn === 'percentage') {
                    if (a.infinity && ! b.infinity) {
                        return -1;
                    } else if (! a['infinity'] && b['infinity']) {
                        return 1;
                    }
                }

                if (a[sortOn] > b[sortOn]) {
                    return -1;
                }

                return 1;
            });
    });

    const percentage = function(articleId) {
        const articleData = data.value.where('article_id', articleId);
        const currentData = articleData.firstWhere('period', 'current');
        const previousData = articleData.firstWhere('period', 'previous');

        let percentageData = {};
        if (! currentData || ! previousData) {
            percentageData = {
                infinity: ! currentData ? false : true,
                percentage: currentData ? 100 : (! previousData ? 0 : -100),
                currentAmount: currentData ? currentData.amount : 0,
                previousAmount: previousData ? previousData.amount : 0
            }
        } else {
            const amount = (currentData.amount - previousData.amount) / previousData.amount * 100;
            percentageData = {
                infinity: false,
                percentage: amount,
                currentAmount: currentData.amount,
                previousAmount: previousData.amount
            }
        }

        percentageData['diffrence'] = (percentageData.currentAmount - percentageData.previousAmount);

        return percentageData;
    }

    const maxValue = computed(() => {
        const value = preparedData.value.map(c => Math.abs(c.diffrence)).max();

        return getMaxValueRounded(value);
    });

    const barHeight = function(article) {
        if (showAs.value === 'numbers') {
            return (article.diffrence > 0 ? 1 : -1) * (Math.abs(article.diffrence) / maxValue.value * 100);
        }

        if (article.percentage > 100) {
            return 100;
        }

        return article.percentage < -100 ? -100 : article.percentage;
    }

    Promise.all([
        getCustomers(),
        getTypes()
    ]).then(() => {
        getData();
    })

</script>
