<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div id="users">

                    <div class="page-header">
                        <h1>Medewerkers</h1>
                        <fab-button @click.native="create_user = (! create_user)" icon="plus"></fab-button>
                    </div>

                    <div class="page-header-settings">

                        <div class="page-toolbar">
                            <div class="toolbar-item">
                                <div class="styled-checkbox">
                                    <label>
                                        <input type="checkbox" id="inactive" v-model="showInactive">
                                        <span for="inactive">Inactieve medewerkers weergeven</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="users-search-holder">
                            <input class="styled-input" type="text" placeholder="Medewerker zoeken" v-model="search">
                        </div>
                    </div>

                    <UsersList :users="filteredUsers" :showInactive="showInactive" />

                    <CreateUser v-if="create_user" @close="create_user = false " @saved="userAdded" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import UsersList from './List/UsersList.vue';
    import CreateUser from './List/CreateUser.vue';
    import User from './../../models/user.js';
    import { mapState } from 'pinia';
    import { useAuthStore } from '../../stores/auth';
    import { useToast } from 'vue-toast-notification';

    export default {

        components: {UsersList, CreateUser},

        computed: {
            ...mapState(useAuthStore, {authUser: 'user'}),

            filteredUsers: function() {
                if (this.search === ''){
                    return this.users;
                }

                return this.users.filter((u) => u.name().toLowerCase().includes(this.search.toLowerCase()));
            }
        },


        data() {
            return {
                users: [],
                create_user: false,
                showInactive: false,
                search: ''
            }
        },

        methods: {
            getUsers() {
                axios.get('users?include=agreements,avatars,documents,userType&deactivated=1&order=surname&only-real-persons=1')
                .then(response => {
                    this.users = [];
                    for (let userData of response.data.data) {
                        let user = new User();
                        user.setAttributesFromResponse(userData);
                        this.users.push(user);
                    }
                });
            },

            userAdded(user) {
                this.getUsers();
                useToast().success('Medewerker opgeslagen')
            },
        },

        created() {
            this.getUsers();
        },
  }
</script>
