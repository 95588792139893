<template>
    <div class="container" id="select_user_or_day_harvest">

        <div class="row">
            <div class="col-sm-12">
                <div class="page-header">
                    <h1>Oogstregistratie</h1>
                </div>

                <div class="page-header-settings">
                    <div class="row">
                        <div class="col-6 col-md-4 col-lg-3">
                            <div class="form-control datepicker-styling">
                                <span class="styled-label">
                                    Datum
                                </span>
                                <DatePicker :clearable="false" v-model="date" @input="changeDate" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- USERS HARVEST -->
        <div class="styled-list">
            <div v-for="(user, index) of sortedUsers" :key="index" :class="{'has-no-registrations': user.harvestRegistrationsCount < 1}" class="list-item" @click="$router.push(`/registrations/harvest-registrations/${user.id}?date_string=${dateObj.format('YYYY-MM-DD')}`)">
                <div class="list-avatar">
                    <img :src="user.avatars && user.avatars.length > 0 ? user.avatar('small').public_url : '/img/profile-placeholder.jpg'" />
                </div>
                <div class="list-text">
                    {{ user.name() }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import User from '../../models/user.js';
    import moment from 'moment';
    import { useRoute } from 'vue-router';
    import { computed, ref } from 'vue';

    const emit = defineEmits(['breadcrumbs']);

    const route = useRoute();

    const users =  ref([]);
    const date = ref(route.query.date_string ? route.query.date_string: moment().format('YYYY-MM-DD'));
    const today = moment();

    const dateObj = computed(() => moment(date.value, 'YYYY-MM-DD'));

    const sortedUsers = computed(() =>
        users.value.sort((a, b) => {
            if (a.name() > b.name()) {
                return 1;
            } else if (b.name() > a.name()) {
                return -1;
            }

            return 0;
        })
    );


    const changeDate = function() {
        getUsers();
    };

    const getUsers = function() {
        users.value = [];
        axios.get(`users?include=avatars&has-role=harvest-worker&count-harvest-registrations=${dateObj.value.format('YYYY-MM-DD')}`).then(response => {
            for (let userData of response.data.data) {
                let user = new User(userData);
                users.value.push(user);
            }

            users.value.sort((a) => (a.harvestRegistrationsCount == 0 ? 1 : -1));
        });
    };

    getUsers();

    emit('breadcrumbs', [
        {label: 'Oogstregistraties', to: '/registrations/select-date'},
        {label: dateObj.value.format('DD-MM-YYYY')}
    ]);
</script>
