import {AbstractModel} from './abstract-model.js'

export default class Content extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            name: null,
            latest_harvest_assignments_count: null,
            formula_factor: null
        }
    }

    amount() {
        if (! this.name.includes('x')) {
            return 0;
        }

        return parseInt(this.name.replace('x', ''));
    }
}