import authGuard from './../guards/authGuard';
import CompareIndex from './../pages/Compare/Index/Index.vue';
import CompareShow from './../pages/Compare/Show/Show.vue';

export default [
    {
        path: '/compare',
        component: CompareIndex,
        name: 'compare',
        meta: { permissions: ['registrations'] }
    }, {
        path: '/compare/:typeId',
        component: CompareShow,
        meta: {permissions: ['registrations']},
        props: true
    }
];
