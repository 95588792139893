<template>
    <div v-if="contents.count() > 0">
        <template v-for="content of contents" :key="content.id">
            <div class="widget-holder" v-if="harvestRegistrations.filter(harvestRegistration => harvestRegistration.contentId == content.id).count() > 0">
                <div class="row">
                    <div class="col-12">
                        <div class="worklist-holder">
                            <table class="worklist-table harvested" v-if="data.length > 0">
                                <thead class="worklist-table-header">
                                    <tr>
                                        <th>{{ content.name }}</th>
                                        <th v-for="(weekday, index) in 7" :key="index">
                                            {{ weekday}}
                                        </th>
                                        <th>Totaal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="typeModel of types" :key="typeModel.id">
                                        <tr v-if="harvestRegistrations.filter(harvestRegistration => harvestRegistration.contentId == content.id && harvestRegistration.typeId == typeModel.id).count() > 0">
                                            <td>{{ typeModel.translate('name') }}</td>
                                            <td v-for="(weekday, index) in 7" :key="index">
                                                <span v-if="! editting">{{ data[content.id][typeModel.id][getDate(weekday).format('YYYY-MM-DD')] === 0 ? '-' : data[content.id][typeModel.id][getDate(weekday).format('YYYY-MM-DD')] }}</span>
                                                <input v-else type="number" v-model="data[content.id][typeModel.id][getDate(weekday).format('YYYY-MM-DD')]">
                                            </td>
                                            <td>{{ data[content.id][typeModel.id]['total'] }}</td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tfoot class="worklist-table-total">
                                    <tr>
                                        <td colspan="8">Totaal</td>
                                        <td>{{ data[content.id]['total'] }}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script setup>
    import { watch, ref } from 'vue'
    import typeService from '../../../services/http/type-service';
    import contentService from '../../../services/http/content-service';
    import harvestRegistrationService from '../../../services/http/harvest-registration-service';
    import harvestCorrectionService from '../../../services/http/harvest-correction-service';
    import collect from 'collect.js';

    const props = defineProps(['activeWeek', 'activeYear', 'editting', 'id']);

    watch(() => props.activeWeek, () => {
        getData();
    });

    const originalData = ref([]);

    /*
    |--------------------------------------------------------------------------
    | Edit/Update
    |--------------------------------------------------------------------------
    */
    const update = function() {
        let diffData = [];

        for (let contentId in data.value) {
            for (let typeId in data.value[contentId]) {
                let day = 0;
                for (let date in data.value[contentId][typeId]) {
                    if (data.value[contentId][typeId][date] != originalData.value[contentId][typeId][date]) {

                        let amount = 0;
                        for (let hr of harvestRegistrations.value) {
                            if (isSame(hr, contentId, typeId, day)) {
                                amount += parseInt(hr.harvested);
                            }
                        }

                        diffData.push({
                            content_id: contentId,
                            type_id: typeId,
                            date,
                            user_id: props.id,
                            amount: (data.value[contentId][typeId][date] - amount)
                        });
                    }
                    day++;
                }
            }
        }
        axios.put('harvest-corrections', diffData).then(() => {
            getHarvestCorrections().then(() => setData());
        });
    };

    const edit = function() {
        originalData.value = _.cloneDeep(data.value);
    };

    const reset = function() {
        data.value = originalData.value;
    }

    const save = function () {};


    /*
    |--------------------------------------------------------------------------
    | Get/Set data
    |--------------------------------------------------------------------------
    */


    const data = ref([]);
    const setData = function() {
        data.value = [];

        for (let content of contents.value) {
            data.value[content.id] = {};
            data.value[content.id]['total'] = 0;

            for (let type of types.value) {
                data.value[content.id][type.id] = {};
                data.value[content.id][type.id]['total'] = 0;
                let date = getDate().add(-1, 'day');
                for (let day = 1; day <= 7; day++) {
                    date.add(1, 'day');

                    let hcAmount = 0;

                    for (let hc of harvestCorrections.value.filter(harvestCorrection => isSame(harvestCorrection, content.id, type.id, day))) {
                        hcAmount += hc.amount;
                    }

                    let amount = harvestRegistrations.value.filter(
                        harvestRegistration => isSame(harvestRegistration, content.id, type.id, day)
                    ).reduce(
                        (a, b) => a + parseInt(b.harvested), 0
                    ) + hcAmount;

                    data.value[content.id][type.id][date.format('YYYY-MM-DD')] = amount;
                    data.value[content.id][type.id]['total'] += amount;
                    data.value[content.id]['total'] += amount;
                }
            }
            //this.console.log(data.value[content.id]['total']);
        }

    };

    const harvestRegistrations = ref(collect());
    const getHarvestRegistrations = function() {
        return harvestRegistrationService.get({filters: {
            with_micro: 1,
            user_id: props.id,
            from: props.activeWeek.from.format('YYYY-MM-DD'),
            till: props.activeWeek.till.format('YYYY-MM-DD')
        }}).then(harvestRegistrationModels => {
            harvestRegistrations.value = harvestRegistrationModels;
        });
    };

    const harvestCorrections = ref(collect());
    const getHarvestCorrections = function() {
        return harvestCorrectionService.get({filters: {
            user_id: props.id,
            from: props.activeWeek.from.format('YYYY-MM-DD'),
            till: props.activeWeek.till.format('YYYY-MM-DD')
        }}).then(harvestCorrectionModels => {
            harvestCorrections.value = harvestCorrectionModels;
        });
    };

    const getData = function(promises = []) {
        return Promise.all([
            getHarvestRegistrations(),
            getHarvestCorrections(),
            ...promises
        ]).then(() => {
            setData();
        });
    }

    const types = ref(collect());
    const contents = ref(collect());
    getData([

        typeService.get({filters: {all: 1}}).then(typeModels => {
            types.value = typeModels;
        }),

        contentService.get().then(contentModels => {
            contents.value = contentModels;
        })

    ]);



    /*
    |--------------------------------------------------------------------------
    | Other
    |--------------------------------------------------------------------------
    */

    const isSame = function(harvestRegistration, contentId, typeId, day) {
        return (
            harvestRegistration.contentId == contentId
            && harvestRegistration.typeId == typeId
            && harvestRegistration.date.format('YYYY-MM-DD') == props.activeWeek.from.clone().add(day-1, 'day').format('YYYY-MM-DD')
        );
    };

    const getDate = function(day = null) {
        const date = props.activeWeek.from.clone();

        if (day) {
            date.add(day-1, 'day');
        }

        return date;
    }




    defineExpose({update, edit, reset});
</script>
