import {AbstractModel} from './abstract-model.js'
import User from './user.js'
import FileModel from './file-model.js'

export default class Notification extends AbstractModel {

    getDefaults() {
        return {
        }
    }


    getMutators() {
        return {
            'createdAt': 'datetime'
        };
    }

    relations()
    {
        return {
            user: User,
            file: FileModel
        }
    }

    relationPlural()
    {
        return {
            order: false,
            user: false
        }
    }
}
