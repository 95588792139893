import Article from "../../models/article.js"
import { AbstractHttpService } from "./abstract-http-service";

class ArticleService extends AbstractHttpService {

    endpoint() {
        return 'articles';
    }

    createModel(data) {
        return new Article(data);
    }
}

export default new ArticleService;
