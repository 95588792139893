<template>
    <modal
        @close="$emit('close')"
        @save="store"
    >
        <template v-slot:header>Nieuwe dagoogst registratie</template>


        <label class="styled-label">
            <span>Aantal</span>
            <input type="number" data-day-harvest-focus step="1" class="styled-input" required v-model="amount">
        </label>

    </modal>
</template>

<script setup>
    import { useToast } from 'vue-toast-notification';
    import { watch, ref, computed, onMounted } from 'vue';
    import moment from 'moment';
    import DayHarvestRegistrationService from '@/services/http/day-harvest-registration-service';

    import { useAuthStore } from '@/stores/auth';
    const user = computed(() => useAuthStore().user);

    const props = defineProps(['chestId', 'contentId', 'sizeId', 'typeId', 'date']);
    const emit = defineEmits(['stored', 'close']);

    const amount = ref('');

    const store = function() {
        if (! amount.value || amount.value == '0') {
            useToast().error('Vul alle velden in')
            return;
        }
        DayHarvestRegistrationService.create({
            'chest_id': props.chestId,
            'content_id': props.contentId,
            'size_id': props.sizeId,
            'type_id': props.typeId,
            amount: amount.value,
            date: props.date
        }).then(() => {
            emit('stored');
            useToast().success('Dagoogst registratie opgeslagen');
        });
    }

    onMounted(() => {
        document.querySelector('[data-day-harvest-focus]').focus()
    });

</script>
