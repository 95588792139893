import {AbstractModel} from './abstract-model.js'
import User from './user.js'
import moment from 'moment';

export default class WorkSession extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            starts_at: null,
            ends_at: null,
            user_id: null,
            user: null
        }
    }

    getTransformers() {
        return {
            user: User
        }
    }

    hours()
    {
        return this.toDate('ends_at').diff(this.toDate('starts_at'), 'hour', true);
    }

    isBetween(from, till)
    {
        return this.toDate('starts_at').isBetween(from, till);
    }

    hoursWithoutBreak()
    {
        const date = this.ends_at !== null ? this.toDate('ends_at') : moment();

        let totalMinutes = date.diff(this.toDate('starts_at'), 'minute', true);
        let breakMinutes = 0;


        if (totalMinutes > 450) {
            breakMinutes = 60;
        } else if (totalMinutes > 300) {
            breakMinutes = 45;
        } else if (totalMinutes > 150) {
            breakMinutes = 15;
        }

        return (totalMinutes - breakMinutes) / 60;
    }

    isInWeek(week)
    {
        return (
            week.isoWeek() === week.isoWee
        );
    }

    // V2
    relations()
    {
        return {
            user: User
        }
    }

    relationPlural()
    {
        return {
            user: false
        }
    }

    getMutators() {
        return {
            startsAt: 'datetime',
            endsAt: 'datetime'
        };
    }

}
