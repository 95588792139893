import Echo from '@ably/laravel-echo';
import * as Ably from 'ably';

window.Ably = Ably;

window.Echo = new Echo({
    broadcaster: 'ably',
});

window.Echo.connector.ably.connection.on(stateChange => {
    if (stateChange.current === 'connected') {
        console.log('Connected to Ably server.');
    }
});

// ---------------------------------------------------------------------------------------------------------------------
// Harvest assignments
// ---------------------------------------------------------------------------------------------------------------------
import { useHarvestAssignmentStore } from './stores/harvestAssignments';

window.Echo.channel('harvest-assignments')
.listen('.add', (event) => {
    useHarvestAssignmentStore().addHarvestAssignment(event.id).then(() => {
        useHarvestAssignmentStore().moveHarvestAssignments(event.sequence);
    });
}).listen('.edit', (event) => {
    useHarvestAssignmentStore().editHarvestAssignment(event.id);
}).listen('.remove', (event) => {
    useHarvestAssignmentStore().removeHarvestAssignment(event.id);
    useHarvestAssignmentStore().moveHarvestAssignments(event.sequence);
}).listen('.move', (event) => {
    useHarvestAssignmentStore().moveHarvestAssignments(event.id);
});


// ---------------------------------------------------------------------------------------------------------------------
// Notifications
// ---------------------------------------------------------------------------------------------------------------------
import Notification from './models/notification';
import { useNotificationStore } from './stores/notifications';
window.Echo.channel('notifications')
.listen('.add', (event) => {
    let notificationModel = new Notification(event.modelData);
    this.addNotification(notificationModel);
}).listen('.edit', (event) => {
    let notificationModel = new Notification(event.modelData);
    useNotificationStore().editNotification(notificationModel);
});

// ---------------------------------------------------------------------------------------------------------------------
// Orders
// ---------------------------------------------------------------------------------------------------------------------
import Order from './models/order';
import { useOrderStore } from './stores/orders';
window.Echo.channel('orders')
.listen('.add', (event) => {
    let orderModel = new Order(event.modelData);
    useOrderStore().addOrder(orderModel);
}).listen('.edit', (event) => {
    let orderModel = new Order(event.modelData);
    useOrderStore().editOrder(orderModel);
}).listen('.remove', (event) => {
    useOrderStore().removeOrderById(event.id);
});

// ---------------------------------------------------------------------------------------------------------------------
// Stock
// ---------------------------------------------------------------------------------------------------------------------
import { useStockStore } from './stores/stocks'
import Stock from './models/stock';
window.Echo.channel('stock')
.listen('.add', (event) => {
    let stockItem = new Stock(event.modelData);
    useStockStore().addStockItem(stockItem);
});

// ---------------------------------------------------------------------------------------------------------------------
// Purchases
// ---------------------------------------------------------------------------------------------------------------------
import Purchase from './models/purchase';
import { usePurchaseStore } from './stores/purchases';
window.Echo.channel('purchases')
.listen('.add', (event) => {
    let purchaseModel = new Purchase(event.modelData);
    usePurchaseStore().addPurchase(purchaseModel);
})
.listen('.edit', (event) => {
    let purchaseModel = new Purchase(event.modelData);
    usePurchaseStore().editPurchase(purchaseModel);
})
.listen('.remove', (event) => {
    usePurchaseStore().removePurchaseById(event.id);
});
