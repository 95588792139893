<template>
    <modal
        :large="true"
        @close="$emit('close')"
        :excludeSaveButton="true"
        v-if="info"
    >
        <template v-slot:header>Productie voorspellingen</template>

        <div class="row">

            <div class="col-6">
                <span class="styled-label">Te verwachten bosjes</span>
                <form @submit.prevent="saveProductionForecast(true)">
                    <input ref="focus" class="styled-input" type="text" v-model="info.expectedAmount">
                    <button class="styled-button">Bosjes opslaan</button>
                </form>

                <hr>

                <span class="styled-label">Kilo's geoogst</span>
                <form @submit.prevent="saveProductionForecast(false)">
                    <input class="styled-input" type="text" v-model="info.harvestedAmount">
                    <button class="styled-button">Kilo's opslaan</button>
                </form>
            </div>

            <div class="col-6">
                <h3>Wijzigingen</h3>

                <div class="styled-list">
                    <div class="list-item" v-for="productionForecast of info.productionForecasts">

                        <div class="container">
                            <div class="row">
                                <div class="col-9" >{{ productionForecast.isExpected ? 'Te verwachten bosjes' : 'Kilo\'s geoogst' }}</div>
                                <div class="col-3 text-end">{{ new Intl.NumberFormat('nl-NL').format(productionForecast.amount) }}</div>
                            </div>
                            <div  class="row">
                                <div class="col-6">{{ productionForecast.updatedAt.format('DD-MM-YYYY HH:mm') }}</div>
                                <div class="col-6 text-end">{{ productionForecast.user ? productionForecast.user.name() : 'Onbekend' }}</div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </modal>
</template>

<script setup>
    import productionForecastService from '@/services/http/production-forecast-service';
    import { useToast } from 'vue-toast-notification';
    import { watch, ref, computed, watchEffect } from 'vue';
    import moment from 'moment';
    import collect from 'collect.js'

    import { useAuthStore } from '@/stores/auth';
    const user = computed(() => useAuthStore().user);

    const props = defineProps(['data', 'weeklyProductionForecasts']);
    const emit = defineEmits(['close', 'stored']);

    const info = ref(null);

    const focus = ref(null);

    watchEffect(() => {
        if (focus.value) {
            focus.value.focus()
        }
    });

    watch(() => props.data, (newData) => {
        if (! newData) {
            info.value = null;
            return;
        }

        let curProductionForecasts = props.weeklyProductionForecasts.get(newData.week.isoWeek());
        if (! curProductionForecasts) {
            curProductionForecasts = collect();
        }
        curProductionForecasts = curProductionForecasts.filter((pf) => (
            pf.weekNumber === newData.week.isoWeek() && pf.typeId === newData.typeId
        ));
        const myProductionExpectedForecast = curProductionForecasts.first((pf) => (
            pf.userId === user.value.id && pf.isExpected && pf.createdAt.isSame(moment(), 'day')
        ));
        const myProductionHarvestedForecast = curProductionForecasts.first((pf) => (
            pf.userId === user.value.id && ! pf.isExpected && pf.createdAt.isSame(moment(), 'day')
        ));

        info.value = {
            productionForecasts: curProductionForecasts,
            week: newData.week.isoWeek(), typeId: newData.typeId,

            expectedId: myProductionExpectedForecast ? myProductionExpectedForecast.id : null,
            harvestedId: myProductionHarvestedForecast ? myProductionHarvestedForecast.id : null,
            expectedAmount: myProductionExpectedForecast ? myProductionExpectedForecast.amount : null,
            harvestedAmount: myProductionHarvestedForecast ? myProductionHarvestedForecast.amount : null,
        }
    });


    const saveProductionForecast = function(isExpected) {
        const amount = info.value[isExpected ? 'expectedAmount' : 'harvestedAmount'];
        const id = info.value[isExpected ? 'expectedId' : 'harvestedId'];

        (
            id
            ? productionForecastService.edit(id, {amount: amount})
            : productionForecastService.create({
                amount: amount,
                is_expected: isExpected,
                week_number: props.data.week.isoWeek(),
                year: props.data.year,
                type_id: props.data.typeId
            })
        ).then(() => {
            useToast().success('Productie voorspelling opgeslagen');
            emit('stored');
        });
    }
</script>
