import BreedPerType from "../../models/breed-per-type.js"
import { AbstractHttpService } from "./abstract-http-service";

class breedPerTypeService extends AbstractHttpService {

    endpoint() {
        return 'breed-per-types';
    }

    createModel(data) {
        return new BreedPerType(data);
    }
}

export default new breedPerTypeService;
