import DayHarvestKilo from "../../models/day-harvest-kilo";
import { AbstractHttpService } from "./abstract-http-service";

class DayHarvestKiloService extends AbstractHttpService {

    endpoint() {
        return 'day-harvest-kilos';
    }

    createModel(data) {
        return new DayHarvestKilo(data);
    }
}

export default new DayHarvestKiloService;
