import { useAuthStore } from '@/stores/auth';

export default async (to, from, next) => {
    if (to.meta && to.meta.middleware === 'guest') {
        return next();
    }

    const auth = useAuthStore();

    // If the user isn't authed, redirect user to the login page.
    if (! auth.authed) {
        return next({ name: 'login' });
    } else {
        // Wait on authed user details before going further.
        if (! auth.user) {
            await auth.authedUserPromise;
        }


        // Check if there are certain actions the user has to preform before he can continue.
        if (! to.meta || to.meta.requiredUserAction !== true) {
            if (auth.user.renew_password === 1) {
                return next({'name': 'renew-password'});
            }

            if (
                (auth.user.userType.name === 'freelancer' || auth.user.userType.name === 'temporary_worker')
                && ! auth.user.currentAgreement
            ) {
                return next({'name': 'accept-agreement'});
            }
        }

        // If to route has permissions, check if the authed user has authorization.
        if (
            'meta' in to
            && 'permissions' in to.meta
            && ! auth.user.can(to.meta.permissions)
        ) {
            return next({'name': 'no-permissions'});
        }

        return next()
    }
};
