<template>
    <div data-articles id="assortments" class="container">

        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Assortimenten</h1>
                    <div class="horizontal-fab-button-holder">
                        <fab-button @click.native="createItem()" icon="plus"></fab-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="alert alert-danger hover">
            <b>LET OP:</b> De assortimenten op deze pagina hebben een koppeling met de asssortimenten in Exact Online.
            De assortimenten in Exact Online en Jongfresh Online worden aan elkaar gekoppeld op basis van de code, zorg ervoor dat
            de code van elk assortiment in beide applicaties identiek is. De naam van het assortiment mag wel afwijken van de naam
            in Exact Online. Wijzig de assortimenten met een Engelse naam niet, dit zijn de basis assortimenten en worden automatisch
            vertaald in Jongfresh Online. Neem contact op met Webbundels als er een assortiment moet worden verwijderd.
        </div>

        <div class="row">
            <div class="col">
                <div class="tabs-holder">
                    <ul class="tabs">
                        <li @click="changeTab('types')" :class="{active: activeTab === 'types'}"><a>Radijs</a></li>
                        <li @click="changeTab('sizes')" :class="{active: activeTab === 'sizes'}"><a>Sortering</a></li>
                        <li @click="changeTab('packagings')" :class="{active: activeTab === 'packagings'}"><a>Verpakking</a></li>
                        <li @click="changeTab('chests')" :class="{active: activeTab === 'chests'}"><a>Fust</a></li>
                        <li @click="changeTab('cover-sheets')" :class="{active: activeTab === 'cover-sheets'}"><a>Toevoeging</a></li>
                        <li @click="changeTab('contents')" :class="{active: activeTab === 'contents'}"><a>Inhoud</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="styled-list">
                    <div class="list-item hover" v-for="item of filteredData" :key="item.id" @click="editItem(item)">
                        <div class="container">
                            <div class="row">
                                <div class="col-4">
                                    <div class="list-text">
                                        {{ item.name }}
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="list-text">
                                        {{ item.code }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal v-if="edit" @close="close()" @save="update()">
            <template v-slot:header>{{ edit === true ? 'Nieuw assortiment' : 'Assortiment wijzigen' }}</template>

            <form @submit.prevent>

                <label class="styled-label" for="name">
                    <span>Naam</span>
                    <input autofocus id="name" v-model="form.name"  type="name" class="styled-input">
                </label>

                <label class="styled-label" for="name">
                    <span>Exact Code</span>
                    <input id="code" v-model="form.code" class="styled-input">
                </label>

            </form>
    </modal>

    </div>
</template>

<script>
    import Form from '../../helpers/form.js';
    import { useToast } from 'vue-toast-notification';

    export default {
        computed: {
            filteredData() {
                return this.data.filter(item => (
                    ! ('harvest' in item)
                    || item.harvest == 1
                ));
            }
        },

        data() {
            return {
                activeTab: null,
                data: [],
                edit: false,
                form: new Form({
                    name: null,
                    code: null
                }),
            }
        },

        methods: {
            createItem() {
                this.form.name = null;
                this.form.code = null;
                this.edit = true;
            },

            editItem(item) {
                this.form.name = item.name;
                this.form.code = item.code;
                this.edit = item.id;
            },

            update() {
                if (this.edit === true) {
                    this.form.post(this.activeTab)
                    .then(response => {
                        let index = this.data.push(response.data[0]);
                        useToast().success('Assortiment opgeslagen');
                    });
                } else {
                    this.form.put(this.activeTab + '/' + this.edit)
                    .then(response => {
                        let index = this.data.findIndex(item => item.id == response.data[0].id);
                        this.data[index].name = response.data[0].name;
                        this.data[index].code = response.data[0].code;
                    });
                }

                this.close();
            },

            changeTab(tab) {
                this.activeTab = tab;
                this.getData();
            },

            getData()
            {
                axios.get(this.activeTab)
                .then(response => {
                    this.data = [];
                    for (let data of response.data.data) {
                        this.data.push(data);
                    }
                });
            },

            close() {
                this.edit = false;
                document.querySelector('body').classList = '';
            },
        },

        created() {


            this.$emit('breadcrumbs', [
                {label: 'Instellingen', to: '/settings'},
                {label: 'Assortimenten'}
            ])

            this.changeTab('types')
        }
    }
</script>
