<template>
    <div class="avatar-holder">
        <div class="avatar">

            <Loader v-if="loading" :center="true"></Loader>

            <img :src="avatar">

            <div class="buttons">
                <label class="file-upload-label" for="uploadPhoto">
                    <a>
                        <Icon name="plus" />
                    </a>
                </label>

                <input id="uploadPhoto" name="uploadPhoto" style="position:absolute; left: -9999px;" @change="onFileChange" type="file">

                <div class="avatar-edit-button" v-if="user.mediumAvatar">
                    <a @click="editAvatar = (! editAvatar)">
                        <Icon name="pencil" />
                    </a>
                </div>

                <div class="avatar-remove-button" v-if="user.mediumAvatar">
                    <a @click="deleteAvatar = (! deleteAvatar)">
                        <Icon name="trash" type="solid" />
                    </a>
                </div>
            </div>
        </div>

        <DeleteAvatar
            v-if="deleteAvatar"
            :user="user"
            :self="self"
            @close="deleteAvatar = false"
            @deleted="avatarDeleted"
        />

        <EditAvatar
            v-if="editAvatar"
            @updating="avatarUpdating"
            :user="user"
            @updated="avatarUpdated"
            @close="editAvatar = false"
            @edited="imageEdited"
            :image="editAvatar"

            :class="{'visually-hidden': editAvatar === 'updating'}"
        />
    </div>
</template>

<script setup>
    import DeleteAvatar from './Avatar/DeleteAvatar.vue';
    import EditAvatar from './Avatar/EditAvatar.vue';
    import { useToast } from 'vue-toast-notification';
    import Loader from './../../../components/Loader.vue';
    import { ref } from 'vue';
    import userService from '@/services/http/user-service-new.js';
    import { useAuthStore } from '@/stores/auth';

    const props = defineProps(['user', 'self']);

    const editAvatar = ref(false);
    const deleteAvatar = ref(false);
    const loading = ref(false);
    const user = ref(props.user);

    const avatar = ref(
        props.user.mediumAvatar
        ? props.user.mediumAvatar.public_url
        : '/img/profile-placeholder.jpg'
    );

    const avatarUpdating = function(){
        editAvatar.value = 'updating';
        loading.value = true;
    }

    const avatarUpdated = function(user){
        setAvatar(user);

        editAvatar.value = false;
        loading.value = false;
    }

    const onFileChange = function(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;

        loading.value = true;
        createImage(files[0]);
    };

    const createImage = function(file) {
        var reader = new FileReader();

        reader.onload = (e) => {
            let img_data = e.target.result;
            userService.create(
                {data: img_data, name: file.name},
                {include: ['mediumAvatar'], urlExtra: `${props.user.id}/avatars`}
            ).then(user => {

                setAvatar(user);
                document.getElementById("uploadPhoto").value = ""; // Reset file upload input so it can detect a new filechange
                editAvatar.value = true;
                loading.value = false;
                useToast().success('Profielfoto opgeslagen')
            });
        };

        reader.readAsDataURL(file);
    }

    const setAvatar = function(updatedUser) {
        avatar.value = updatedUser.mediumAvatar
        ? updatedUser.mediumAvatar.publicUrl
        : '/img/profile-placeholder.jpg';

        user.value = updatedUser;
        if (user.value.id === useAuthStore().user.id ){
            useAuthStore().renewAuthedUser();
        }
    }

    const avatarDeleted = function(user) {
        setAvatar(user);
        deleteAvatar.value = false
    }

    const imageEdited = function(img) {
        props.user['mediumAvatar'].public_url = img;
    }
</script>
