import {AbstractModel} from './abstract-model.js'

import User from './user.js'
import Location from './location.js'
import HarvestAssignment from './harvest-assignment.js'
import Size from './size.js'
import Content from './content.js'
import Chest from './chest.js'
import Type from './type.js'
import moment from 'moment';
import DayHarvestRegistration from './day-harvest-registration.js';

export default class HarvestRegistration extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            user_id: null,
            harvest_assignment_id: null,
            added_by_user_id: null,
            harvested: null,
            location_id: null,
            size_id: null,
            chest_id: null,
            type_id: null,
            content_id: null,
            user: null,
            addedByUser: null,
            location: null,
            harvestAssignment: null,
            size: null,
            content: null,
            chest: null,
            type: null,
            date: moment(),

            created_at: moment()
        }
    }

    amountForStock() {
        return this.harvested;
    }

    getCcst()
    {
        return {
            chest_id: (this.chest_id ?? this.chestId),
            content_id: (this.content_id ?? this.contentId),
            size_id: (this.size_id ?? this.sizeId),
            type_id: (this.type_id ?? this.typeId)
        }
    }

    getTransformers() {
        return {
            user: User,
            addedByUser: User,
            location: Location,
            harvestAssignment: HarvestAssignment,
            location: Location,
            size: Size,
            content: Content,
            chest: Chest,
            type: Type,
        }
    }

    getDateFormats() {
        return {
            date: 'YYYY-MM-DD'
        }
    }

    isBetween(from, till)
    {
        return this.date.isBetween(from, till);
    }

    is(content_id, type) {
        return (
            content_id == this.content_id
            && (
                type === 'mini' && this.size.name == 'mini'
                || this.type_id == type
            )
        );
    }

    dayHarvestDate() {
        return this.date;
    }

    name() {
        return this.chest.name + ' ' + this.size.translate('name') + ' ' + this.type.translate('name') + ' ' + this.content.name;
    }

    // V2
    relations()
    {
        return {
            harvestAssignment: HarvestAssignment,
            location: Location,
            user: User,
            chest: Chest,
            content: Content,
            size: Size,
            type: Type,
        }
    }

    relationPlural()
    {
        return {
            location: false,
            harvestAssignment: false,
            user: false,
            chest: false,
            content: false,
            size: false,
            type: false
        }
    }

    getMutators() {
        return {
            createdAt: 'datetime',
            date: 'datetime'
        };
    }

    getModelName() {
        return 'harvest-registration';
    }
}
