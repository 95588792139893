import {AbstractModel} from './abstract-model.js'

export default class UserType extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            name: null
        }
    }

    translation() {
        return 'UserType';
    }

    translate(field) {
        const translations =  {
            "freelancer": "ZZPer",
            "temporary_worker": "Uitzendkracht",
            "work_aid": "Werk hulp",
            "employee": "Vaste medewerker",
            "other": "Overig"
        }

        return translations[this[field]];
    }
}
