import authGuard from './../guards/authGuard';
import PerformanceShow from './../pages/Performance/Show.vue'

export default [
    {
        path: '/performance',
        component: PerformanceShow,
        name: 'performance',
        meta: { permissions: ['performance'] }
    }
];
