import Chest from "../../models/chest.js"
import { AbstractHttpService } from "./abstract-http-service";

class ChestService extends AbstractHttpService {

    endpoint() {
        return 'chests';
    }

    createModel(data) {
        return new Chest(data);
    }
}

export default new ChestService;
