<template>
    <a class="fab-button" @click="active = (! active)" :class="classes" >
        <Icon :name="icon" />

        <div class="list-holder" v-if="$slots.default" v-show="active">
            <ul class="list">
                <slot></slot>
            </ul>
        </div>
    </a>
</template>

<script setup>
    import { ref } from 'vue';

    const props = defineProps(['icon', 'classes']);

    // active
    const active = ref(false);
</script>
