import HarvestScheduleShow from './../pages/HarvestSchedule/Show.vue';

export default [
    {
        path: '/harvest-schedule',
        component: HarvestScheduleShow,
        name: 'harvest-schedule.schow',
        meta: {permissions: ['harvest_schedule']}
    }
];
