<template>
    <modal @save="save()" @close="$emit('close')">

        <template v-slot:header>Nieuwe overeenkomst</template>

        <form @submit.prevent enctype="multipart/form-data">
            <div v-if="! this.companyDetailsFilledIn()" style="min-height: 60px;padding: 5px; border-radius: 5px; background-color: #bf4a4a; color:white">
                Opslaan is nog niet mogelijk, vul eerst de verplichte velden in.
            </div>

            <label class="styled-label">
                <span>Onderneming</span>
                <div class="styled-result">{{ user.company_name ? user.company_name : 'Niet ingevuld' }}</div>
            </label>

            <label class="styled-label">
                <span>Gevestigd te</span>
                <div class="styled-result">{{ user.location ? user.location : 'Niet ingevuld' }}</div>
            </label>

            <label class="styled-label">
                <span>Postcode</span>
                <div class="styled-result">{{ user.postal_code ? user.postal_code : 'Niet ingevuld' }}</div>
            </label>

            <label class="styled-label">
                <span>Straat en huisnummer</span>
                <div class="styled-result">{{ user.address ? user.address : 'Niet ingevuld' }}</div>
            </label>

            <label class="styled-label">
                <span>BTW nr</span>
                <div class="styled-result">{{ user.btw ? user.btw : 'Niet ingevuld' }}</div>
            </label>

            <label class="styled-label">
                <span>Uurtarief</span>
                <div class="styled-result">{{ user.hourly_wage ? user.hourly_wage : 'Niet ingevuld' }}</div>
            </label>

            <label class="styled-label">
                <span>Startdatum</span>

                <DatePicker @input="dateChanged" id="starts_at"  v-model="form['starts_at']"  :class="{ 'is-invalid': form.errors.has('starts_at') }" />
                <div class="invalid-feedback" v-if="form.errors.has('starts_at')" v-text="form.errors.get('starts_at')"></div>
            </label>

            <label class="styled-label">
                <span>Einddatum</span>
                <DatePicker @input="dateChanged" id="starts_at"  v-model="form['ends_at']"  :class="{ 'is-invalid': form.errors.has('ends_at') }" />
                <div class="invalid-feedback" v-if="form.errors.has('ends_at')" v-text="form.errors.get('ends_at')"></div>
            </label>

        </form>
    </modal>
</template>

<script>
    import Form from '../../../../helpers/form.js';
    import Agreement from '../../../../models/agreement.js';
import { useToast } from 'vue-toast-notification';

    export default{
        props: ['user', 'lastAgreement'],

        components: { Agreement },

        data() {
            return {
                form: new Form({
                    company: this.user.company_name,
                    established_at: this.user.location,
                    postal_code: this.user.postal_code,
                    address: this.user.address,
                    btw: this.user.btw,
                    starts_at: null,
                    ends_at: null,
                    hourly_wage: this.user.hourly_wage.replace(',', ''),
                    user_id: this.user.id
                }),
                disabledDates: {'ranges': []}
            }

        },

        methods: {
            save() {
                this.form.post('users/' + this.user.id + '/agreements').then(response => {
                    let agreement = new Agreement();
                    agreement.setAttributesFromResponse(response.data[0]);
                    useToast().success('Overeenkomst opgeslagen');
                    this.$emit('close');
                    this.$emit('agreementCreated', agreement);
                    document.querySelector('body').classList = '';
                });
            },

            dateChanged() {
                if (this.form.starts_at !== null && this.form.ends_at !== null && this.form.starts_at > this.form.ends_at) {
                    let tempStartsAt = this.form.starts_at;
                    this.form.starts_at = this.form.ends_at;
                    this.form.ends_at = tempStartsAt;
                }
            },

            companyDetailsFilledIn()
            {
                return ! (
                    this.form.company === null || this.form.company === ''
                    || this.form.established_at === null || this.form.established_at === ''
                    || this.form.postal_code === null || this.form.postal_code === ''
                    || this.form.address === null || this.form.address === ''
                    || this.form.btw === null || this.form.btw === ''
                );
            }
        },


        created()
        {
            for (let agreement of this.user.agreements) {
                this.disabledDates.ranges.push({
                    from: new Date(agreement.starts_at.format('YYYY'), agreement.starts_at.format('MM')-1, agreement.starts_at.format('DD')),
                    to: new Date(agreement.ends_at.format('YYYY'), agreement.ends_at.format('MM')-1, parseInt(agreement.ends_at.format('DD'))+1)
                });
            }
        }
    };
</script>
