import Stock from "../../models/stock";
import { AbstractHttpService } from "./abstract-http-service";

class StockService extends AbstractHttpService {

    endpoint() {
        return 'stocks';
    }

    createModel(data) {
        return new Stock(data);
    }
}

export default new StockService;
