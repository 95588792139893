<template>
    <modal
        @close="$emit('close')"
        @save="store"
    >
        <template v-slot:header>Nieuwe dagoogst kilo registratie</template>

        <label class="styled-label">
            <span>Datum</span>
            <input type="date" class="styled-input" required v-model="date">
        </label>

        <label class="styled-label">
            <span>Type</span>
            <select class="styled-input" v-model="typeId">
                <option :value="null">Kies een type</option>
                <option v-for="(type, index) of types" :value="type.id">
                    {{ type.translate('name') }}
                </option>
            </select>
        </label>

        <label class="styled-label">
            <span>Sortering</span>
            <select class="styled-input" v-model="sizeId">
                <option :value="null">Kies een sortering</option>
                <option v-for="size of sizes.filter(s => ['medium', 'large'].includes(s.name))" :value="size.id">
                    {{ size.translate('name') }}
                </option>
            </select>
        </label>

        <label class="styled-label">
            <span>Locatie</span>
            <select class="styled-input" v-model="locationId">
                <option :value="null">Kies een locatie</option>
                <option v-for="location of locations" :value="location.id">
                    {{ location.name }}
                </option>
            </select>
        </label>

        <label class="styled-label">
            <span>Aantal</span>
            <input type="number" step="1" class="styled-input" required v-model="kilo">
        </label>

    </modal>
</template>

<script setup>
    import { useToast } from 'vue-toast-notification';
    import { watch, ref, computed } from 'vue';
    import moment from 'moment';
    import collect from 'collect.js'
    import DayHarvestKiloService from '@/services/http/day-harvest-kilo-service';
    import LocationService from '@/services/http/location-service';
    import SizeService from '@/services/http/size-service';

    import { useAuthStore } from '@/stores/auth';
    const user = computed(() => useAuthStore().user);

    const props = defineProps(['date', 'locations', 'types']);
    const emit = defineEmits(['stored', 'close']);

    const kilo = ref(0);
    const date = ref(props.date);

    const locationId = ref(null);
    const locations = ref(collect());

    const sizeId = ref(null);
    const sizes = ref(collect());

    const typeId = ref(null);

    const store = function() {
        if (
            ! locationId.value || ! typeId.value
            || ! sizeId.value || ! date.value
            || kilo.value === 0
        ) {
            useToast().error('Vul alle velden in')
            return;
        }

        DayHarvestKiloService.create({
            gram: kilo.value*1000,
            location_id: locationId.value,
            type_id: typeId.value,
            size_id: sizeId.value,
            date: date.value
        }).then(() => {
            emit('stored');
            useToast().success('Dagoogst registratie opgeslagen')
        });
    }

    const getLocations = function() {
        return LocationService.get().then(locationModels => {
            locations.value = locationModels;
        })
    }

    const getSizes = function() {
        return SizeService.get().then(sizeModels => {
            sizes.value = sizeModels;
        })
    }

    getLocations();
    getSizes();

</script>
