import SaleIgnore from "../../models/sale-ignore.js"
import { AbstractHttpService } from "./abstract-http-service";

class SaleIgnoreService extends AbstractHttpService {

    endpoint() {
        return 'sale-ignores';
    }

    createModel(data) {
        return new SaleIgnore(data);
    }

    getSaleIgnoresPerType(betweens) {
        let url = this.makeUrl({filters: {betweens}}, 'sale-ignores-per-type');
        
        return axios.get(url).then(data => {
            return data.data;
        });
    }
}

export default new SaleIgnoreService;
