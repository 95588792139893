<template>
    <div class="widget-holder">
        <div class="widget-header">
            <div class="widget-title">Aanwezigen</div>
            <div class="widget-subtitle">{{ workSessions.filter(ws => ws.endsAt == null).count() }} medewerkers aanwezig</div>
        </div>
        <div class="widget-content">
            <table class="styled-table">
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th class="d-none d-md-table-cell">
                            Starttijd
                        </th>
                        <th class="d-none d-md-table-cell">
                            Eindtijd
                        </th>
                        <th>
                            Bossen p.u.
                        </th>
                        <th class="d-none d-md-table-cell">
                            Geschatte eindtijd
                        </th>
                    </tr>
                </thead>
                <tr v-if="contents.count() > 0" class="styled-table-row" v-for="(workSession, index) of workSessions.sort((a,b) => a.user.name() > b.user.name() ? 1 : -1)" :key="index">
                    <td>
                        <img class="styled-table-avatar" :src="workSession.user !== null && workSession.user.avatars && workSession.user.avatars.count() > 0 ? workSession.user.avatar('small').publicUrl : '/img/profile-placeholder.jpg'" />
                        <a class="styled-link" @click="$router.push('/users/' + workSession.user.id + '/work-lists/' + workSession.toDate('startsAt').format('YYYY'))">
                            <span v-if="workSession.user">
                                <template v-if="! authUser.can('users')">{{ workSession.user.name() }}</template>
                                <a v-else @click="$router.push('/users/' + workSession.user.id )">{{ workSession.user.name() }}</a>
                            </span>
                        </a>
                    </td>
                    <td class="d-none d-md-table-cell">
                        <span class="align-middle">{{ workSession.toDate('startsAt').format('HH:mm') }}</span>
                        <small class="font-weight-light text-muted align-middle ms-1">{{ workSession.toDate('startsAt').format('DD-MM-YYYY') }}</small>
                    </td>
                    <td class="d-none d-md-table-cell">
                        <span class="align-middle">{{ workSession.endsAt ? workSession.toDate('endsAt').format('HH:mm') : '-' }}</span>
                    </td>
                    <td>
                        {{ amount(workSession) }}
                    </td>
                    <td class="d-none d-md-table-cell">
                        <template v-if="workSession.endsAt || harvestAssignmentUsers.length <= 0">-</template>
                        <template v-else>{{ harvestAssignmentUsers.findIndex(u => workSession.user.id === u.id) >= 0 ? harvestAssignmentUsers.find(u => workSession.user.id === u.id).busyUntil.format('HH:mm') : '-' }}</template>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, watch } from 'vue';
    import { collect } from "collect.js";
    import moment from 'moment';

    /*
    |--------------------------------------------------------------------------
    | Calculate amounts per hour.
    |--------------------------------------------------------------------------
    */
    const taskHourSeconds = ref(collect());
    const amount = function(workSession) {
        let amount = harvestRegistrations.value.filter(hr =>
            hr.userId === workSession.userId
            && workSession.toDate('startsAt').format('YYYY-MM-DD HH:mm:ss') <= hr.createdAt.format('YYYY-MM-DD HH:mm:ss')
        ).reduce((curValue, hr) =>
            curValue + hr.harvested * contents.value.filter(content => content.id == hr.contentId).first().amount(), 0
        );

        let starts_at = (workSession.toDate('startsAt').isSame(new Date(), "day") ? workSession.toDate('startsAt') : moment().hour(0).minute(0).second(0));
        let ends_at = (workSession.endsAt ? workSession.toDate('endsAt') : moment());

        let minutes = ends_at.diff(starts_at, 'minute');

        let taskHourSecond = taskHourSeconds.value.search(taskHourSecond => taskHourSecond.userId === workSession.user_id)

        if (taskHourSecond) {
            minutes -= taskHourSecond.secondsWorked/60;
        }

        if (minutes >= (8.25*60)) {
            minutes -= 60;
        } else if (minutes >= (5.25*60)) {
            minutes -= 45;
        } else if (minutes >= (2.5*60)) {
            minutes -= 15;
        }

        if (minutes <= 0) {
            return 0;
        }

        amount /= (minutes/60)

        return Math.round(amount);
    };

    /*
    |--------------------------------------------------------------------------
    | Harvest assignment users
    |--------------------------------------------------------------------------
    */
    import { useHarvestAssignmentStore } from "../../stores/harvestAssignments";
    const harvestAssignmentStore = useHarvestAssignmentStore();
    const harvestAssignmentUsers = harvestAssignmentStore.harvestAssignmentUsers;
    watch(() => harvestAssignmentStore.harvestAssignmentUsers, (newVal) => {
        harvestAssignmentUsers.value = newVal;
    })

    /*
    |--------------------------------------------------------------------------
    | Harvest registrations
    |--------------------------------------------------------------------------
    */
    import harvestRegistrationService from '@/services/http/harvest-registration-service.js';

    const harvestRegistrations = ref(collect());

    const getHarvestRegistrations = function() {
        return harvestRegistrationService.get({
            filters: {today_and_active: 1, today: 1}
        }).then(harvestRegistrationModels => {
            harvestRegistrations.value = harvestRegistrationModels;
        });
    }

    /*
    |--------------------------------------------------------------------------
    | Work sessions
    |--------------------------------------------------------------------------
    */
    import workSessionService from '@/services/http/work-session-service.js';

    const workSessions = ref(collect());

    const getWorkSessions = function() {
        return workSessionService.get({
            filters: {today_and_active: 1,'user': {'only-real-persons': 1}},
            include: ['user.avatars']
        }).then(workSessionModels => {
            const duplicates = workSessionModels.map(wsm => wsm.userId).duplicates().values().unique();

            for (const duplicatedUserId of duplicates) {
                const latestStartsAt = workSessionModels.where('userId', duplicatedUserId).max('startsAt');
                workSessionModels = workSessionModels.filter((wsm) => (
                    wsm.userId !== duplicatedUserId
                    || wsm.startsAt == latestStartsAt
                ));
            }

            workSessions.value = workSessionModels;
            workSessions.value.sort((a, b) => a.user.name() >= b.user.name() ? 1 : -1)
        });
    }

    /*
    |--------------------------------------------------------------------------
    | Task hours
    |--------------------------------------------------------------------------
    */
    import taskHourService from '@/services/http/task-hour-service.js';
    const getTaskHours = function() {
        return taskHourService.get({
            filters: {date: moment().format('YYYY-MM-DD')}
        }).then(taskHourModels => {
            for (let taskHourModel of taskHourModels) {
                let taskHourSecond = taskHourSeconds.value.first(ths => ths.userId === taskHourModel.userId);
                if (! taskHourSecond) {
                    taskHourSeconds.value.push({
                        userId: taskHourModel.userId,
                        secondsWorked: taskHourModel.secondsWorked
                    });
                    taskHourSecond = taskHourSeconds.value.first(ths => ths.userId === taskHourModel.userId)
                }

                taskHourSecond.secondsWorked += taskHourModel.secondsWorked;
            }
        });
    }

    /*
    |--------------------------------------------------------------------------
    | Contents
    |--------------------------------------------------------------------------
    */
    import contentService from '@/services/http/content-service.js';

    const contents = ref(collect());

    const getContents = function() {
        return contentService.get().then(contentModels => {
            contents.value = contentModels;
        });
    }

    /*
    |--------------------------------------------------------------------------
    | Initiate and Refresh getData.
    |--------------------------------------------------------------------------
    */
    const getData = function() {
        getContents();
        getTaskHours();
        getWorkSessions();
        getHarvestRegistrations();
    }
    getData();
    defineExpose({getData});

    /*
    |--------------------------------------------------------------------------
    | Auth user
    |--------------------------------------------------------------------------
    */
    import { useAuthStore } from "../../stores/auth";
    const authStore = useAuthStore();
    const authUser = computed(() => authStore.user);

</script>
