<template>
    <modal @save="onSubmit()" @close="$emit('close')">
        <template v-slot:header>Nieuwe rol</template>

        <form @submit.prevent>

            <label class="styled-label" for="password">
                <span>Naam</span>
                <input id="name" type="name" class="styled-input" v-model="form.name" v-bind:class="{ 'is-invalid': form.errors.has('name') }">
                <div class="invalid-feedback" v-if="form.errors.has('name')" v-text="form.errors.get('name')"></div>
            </label>

        </form>

    </modal>
</template>

<script>
    import Form from '../../../helpers/form.js';
    import Role from '../../../models/role.js';
    import { useToast } from 'vue-toast-notification';

    export default {
        data() {
            return {
                form: new Form({
                    name: '',
                })
            }
        },

        methods: {
            onSubmit() {
                this.form.post('/roles?include=permissions')
                .then(response => {
                    useToast().success('Rol opgeslagen');
                    let role = new Role();
                    role.setAttributesFromResponse(response.data[0]);
                    this.$emit('saved', role);
                    document.querySelector('body').classList = '';
                });
            },
        },

        mounted()
        {
            document.getElementById("name").focus();
        }
    };
</script>
