import ProductionControlIndex from './../pages/ProductionControl/Index.vue';
import ProductionControlShow from './../pages/ProductionControl/Show.vue';

export default [
    {
        path: '/production-control',
        component: ProductionControlIndex,
        name: 'production-control-index',
        meta: {
            permissions: ['production_control_admin']
        },
    },
    {
        path: '/production-control/:id',
        component: ProductionControlShow,
        name: 'production-control-show',
        meta: {
            permissions: ['production_control_admin']
        },
        props: true
    }
];
