import { useHarvestAssignmentStore } from "./stores/harvestAssignments";

let dragObject = {
    dragEl: null,
    scrollInterval: null,

    handleDragStart(e) {
        setTimeout(function(){ // Zonder kleine timeout werkt de drag niet
            let el = e.target; // Reference to the object being dragged
            el.classList.add('being-dragged'); // Add class

            // Set dragged element on class attribute for later usage
            dragObject.dragEl = el;

            // Get the full harvest assignment where the dragged object is part of and set it in curHarvestEl
            let curHarvestEl = el;
            while (!('harvestAssignment' in curHarvestEl.dataset) && (curHarvestEl = curHarvestEl.parentElement));

            // The id of the current harvest assignment
            let id = curHarvestEl.dataset['harvestAssignment'];

            // Get all dropzone containers, (should be two: rush and normal)
            let dropzoneContainers = el;
            while ((dropzoneContainers = dropzoneContainers.parentElement) && !('harvestAssignments' in dropzoneContainers.dataset));
            dropzoneContainers = dropzoneContainers.querySelectorAll("[data-dropzone-container]");

            for (let createHarvestBetween of document.querySelectorAll('.create-harvest-between')) {
                createHarvestBetween.style.display = 'none';
            }

            // Set an dropzone between all(some exceptions) harvest assignments within the dropzonecontainers
            for (let dropzoneContainer of dropzoneContainers) {

                let lastId = null;
                for (let harvestAssignment of dropzoneContainer.querySelectorAll("[data-harvest-assignment]"))
                {
                    if (harvestAssignment.dataset['harvestAssignment'] === dragObject.dragEl.dataset['harvestAssignment']) {
                        lastId = harvestAssignment.dataset["harvestAssignment"];
                        continue;
                    }

                    let curId = harvestAssignment.dataset["harvestAssignment"];

                    // If the current dragged object is not part of an multi harvest assignment and the dropzone is before
                    // or after the the dragged object itself the dropzone should not be created.
                    if ( id !== curId && id !== lastId) {
                        let dropzone = document.createElement("div");

                        dropzone.classList.add('dropzone');
                        dropzone.addEventListener('dragenter', dragObject.handleDragEnter, false);
                        dropzone.addEventListener('dragleave', dragObject.handleDragLeave, false);
                        dropzone.addEventListener('dragover', dragObject.handleDragOver, false);
                        dropzone.addEventListener('drop', dragObject.handleDrop, false);
                        dropzoneContainer.insertBefore(dropzone, harvestAssignment.parentElement);
                    }
                    lastId = curId;
                    /* document.getElementById('top_scroll_bar').addEventListener('dragleave', dragObject.handleDragLeave, false); */
                }

                for (let container of document.querySelectorAll('[data-scroll-bar-container]')) {
                    container.classList.add('active');
                    for(let child of container.children) {
                        child.addEventListener('dragenter', dragObject.handleDragEnter, false);
                        child.addEventListener('dragleave', dragObject.handleDragLeave, false);
                    }
                }

                // Last dropzone is appended to the dropzoneContainer
                if (id !== lastId) {
                    let dropzone = document.createElement("div");
                    dropzone.classList.add('dropzone');
                    dropzone.addEventListener('dragenter', dragObject.handleDragEnter, false);
                    dropzone.addEventListener('dragleave', dragObject.handleDragLeave, false);
                    dropzone.addEventListener('dragover', dragObject.handleDragOver, false);
                    dropzone.addEventListener('drop', dragObject.handleDrop, false);
                    dropzoneContainer.appendChild(dropzone);
                }
            }
        }, 50);
    },

    handleDragOver(e) {
        /* if (e.preventDefault) {
            e.preventDefault(); // Necessary. Allows us to drop.
        } */

        //e.dataTransfer.dropEffect = 'move';  // See the section on the DataTransfer object.

        return false;
    },

    handleDragEnter(e) {
        // this / e.target is the current hover target.
        if (this.classList.contains('hover-scroll-bar')) {
            var speed = this.dataset.speed;
            this.scrollInterval = window.setInterval(function(){
                window.scrollBy({
                    top: speed,
                    left: 0,
                    behavior: 'instant'
                });
            }, 15);
        } else {
            this.classList.add('fly-by');
        }
    },

    handleDragLeave(e) {
        if (this.classList.contains('hover-scroll-bar')) {
            clearInterval(this.scrollInterval);
        } else {
            this.classList.remove('fly-by');  // this / e.target is previous target element.
        }
    },

    handleDrop(e) {
        if (e.stopPropagation) {
            e.stopPropagation(); // stops the browser from redirecting.
        }

        let harvestAssignment = dragObject.dragEl;
        let dropzone = e.target;

        if (! dropzone.classList.contains('dropzone')) {
            while ((dropzone = dropzone.parentElement) && !dropzone.classList.contains('dropzone'));
        }

        let to_rush = dropzone;
        while ((to_rush = to_rush.parentElement) && !('dropzoneContainer' in to_rush.dataset));
        to_rush = (to_rush.dataset.dropzoneContainer === 'rush');

        let to_id = null;
        if (dropzone.nextSibling) {
            to_id = dropzone.nextSibling.dataset['harvestAssignmentId'];
        }

        let data = {
            to_id: to_id,
            from_id: (('harvestAssignment' in harvestAssignment.dataset) ? harvestAssignment.dataset['harvestAssignment'] : harvestAssignment.dataset['id']),
            to_rush: to_rush
        };

        useHarvestAssignmentStore().move(data.from_id, data.to_id, data.to_rush);
    },

    handleDragOver(e) {
        if (e.preventDefault) {
          e.preventDefault(); // Necessary. Allows us to drop.
        }

        e.dataTransfer.dropEffect = 'move';  // See the section on the DataTransfer object.

        return false;
    },

    handleDragEnd(e) {
        document.getElementById('top_scroll_bar').classList.remove('active');
        document.getElementById('bottom_scroll_bar').classList.remove('active');
        for (let dropzone of document.querySelectorAll('[data-harvest-assignment]')) {
            dropzone.classList.remove('dropzone');
            dropzone.removeEventListener('dragenter', dragObject.handleDragEnter, false);
            dropzone.removeEventListener('dragleave', dragObject.handleDragLeave, false);
            dropzone.removeEventListener('dragover', dragObject.handleDragOver, false);
            dropzone.removeEventListener('drop', dragObject.handleDrop, false);
        }
        for (let dropzone of document.querySelectorAll('.dropzone')) {
            dropzone.parentNode.removeChild(dropzone);
            dropzone.removeEventListener('dragenter', dragObject.handleDragEnter, false);
            dropzone.removeEventListener('dragleave', dragObject.handleDragLeave, false);
            dropzone.removeEventListener('dragover', dragObject.handleDragOver, false);
            dropzone.removeEventListener('drop', dragObject.handleDrop, false);
        }
        for (let createHarvestBetween of document.querySelectorAll('.create-harvest-between')) {
            createHarvestBetween.style.display = 'block';
        }

        dragObject.dragEl.classList.remove('being-dragged');
    },
}


export default dragObject;
