import {AbstractModel} from './abstract-model.js'
import Fraction from './fraction.js'
import Type from './type.js'

export default class Breed extends AbstractModel {

    relations()
    {
        return {
            fractions: Fraction,
            type: Type,
        }
    }

    relationPlural()
    {
        return {
            fractions: true,
            type: false,
        }
    }
}