<template>
    <modal @save="onsubmit()" @close="$emit('close')">
        <template v-slot:header>{{ user.name() }} {{ user.deactivated_at ? 'activeren' : 'deactiveren' }}</template>

        <p>
            Weet je zeker dat je {{ user.name() }} wilt {{ user.deactivated_at ? 'activeren' : 'deactiveren' }}?
        </p>

        <template v-slot:close>Annuleren</template>

        <template v-slot:save>Doorgaan</template>
    </modal>
</template>

<script>
    import moment from 'moment';
    import { useToast } from 'vue-toast-notification';

    export default{
        props: ['user'],

        methods: {
            onsubmit() {
                let deactivated_at = (this.user.deactivated_at ? null : moment().format('YYYY-MM-DD HH:mm:ss'));
                axios.put('users/' + this.user.id + '?deactivated=1', {
                    deactivated_at: deactivated_at
                }).then(response => {
                    useToast().success(this.user.deactivated_at ? 'Medewerker geactiveerd' : 'Medewerker gedeactiveerd');
                    this.$emit('close');
                    this.$emit('deactivated', deactivated_at);
                    document.querySelector('body').classList = '';
                })
            }
        }
    };
</script>
