<template>
    <div class="main-menu" :class="{ active: menuOpen }">
        <div class="main-menu-holder">
            <div class="main-menu-close-button">
                <a @click="$emit('close')"><Icon name="x-mark"/></a>
            </div>
            <ul class="main-menu-list">
                <!-- Dashboard -->
                <li class="main-menu-list-item">
                    <router-link to="/dashboard" :class="{active: $route.fullPath.includes('/dashboard')}">
                        <div class="main-menu-link-icon">
                            <Icon name="home" type="solid" />
                        </div>
                        <span class="main-menu-link-name">Dashboard</span>
                    </router-link>
                </li>

                <!-- Bosopdrachten -->
                <li v-if="user.can('harvest_assignments_planning')" class="main-menu-list-item">
                    <router-link to="/harvest-assignments" :class="{active: $route.fullPath.includes('/harvest-assignments') && ! $route.fullPath.includes('harvest-assignments/report')}">
                        <div class="main-menu-link-icon">
                            <Icon name="inbox" type="solid" />
                        </div>
                        <span class="main-menu-link-name">Bosopdrachten</span>
                        <div v-if="unassignedHarvestAssignments.count() > 0" class="harvest-assignments-not-assigned">@{{ unassignedHarvestAssignments.count() }}</div>
                    </router-link>
                </li>

                <!-- Werklijsten -->
                <li v-if="user.can('work_lists')" class="main-menu-list-item">
                    <router-link :class="{active: $route.fullPath.includes('work-lists')}" to="/users/work-lists">
                        <div class="main-menu-link-icon">
                            <Icon name="clock" type="solid" />
                        </div>
                        <span class="main-menu-link-name">Werklijsten</span>
                    </router-link>
                </li>
                <li v-else-if="! user.can('work_lists') && user.can('own_work_list')" class="main-menu-list-item">
                    <router-link :class="{active: $route.fullPath.includes('work-lists')}" :to="'/users/' + user.id + '/work-lists/' + (new Date).getFullYear()">
                        <div class="main-menu-link-icon">
                        <Icon name="clock" type="solid" />
                        </div>
                        <span>Mijn werklijst</span>
                    </router-link>
                </li>

                <!-- Voorraad -->
                <li v-if="user.can('stock')" class="main-menu-list-item">
                    <router-link to="/stocks" :class="{active: $route.fullPath.includes('/stocks')}">
                        <div class="main-menu-link-icon">
                            <Icon name="arrows-up-down" type="solid" />
                        </div>
                        <span class="main-menu-link-name">Voorraad</span>
                    </router-link>
                </li>

                <!-- Oogstregistratie -->
                <li v-if="user.can('registrations')" class="main-menu-list-item">
                    <router-link class="active-on-childs" to="/registrations" :class="{active: $route.fullPath.includes('/registrations')}">
                        <div class="main-menu-link-icon">
                            <Icon name="pencil-square" type="solid" />
                        </div>
                        <span class="main-menu-link-name">Oogstregistratie</span>
                    </router-link>
                    <div @click="$router.push('/registrations/select-date')" class="registrations-calendar-button">
                        <Icon name="calendar-days" />
                    </div>
                </li>

                <li v-if="user.can('registrations')" class="main-menu-list-item">
                    <router-link class="active-on-childs" to="/day-harvest" :class="{active: $route.fullPath.includes('/day-harvest')}">
                        <div class="main-menu-link-icon">
                            <Icon name="queue-list" type="solid" />
                        </div>
                        <span class="main-menu-link-name">Dagoogst</span>
                    </router-link>
                </li>

                <li v-if="user.can('registrations') && false" class="main-menu-list-item">
                    <router-link class="active-on-childs" to="/compare" :class="{active: $route.fullPath.includes('/compare')}">
                        <div class="main-menu-link-icon">
                            <Icon name="swatch" type="solid" />
                        </div>
                        <span class="main-menu-link-name">Dagoogst vergelijken</span>
                    </router-link>
                </li>

                <!-- Oogst totalen -->
                <li v-if="user.can('harvest_report')" class="main-menu-list-item">
                    <router-link class="active-on-childs" :to="'/harvest-report/' + (new Date()).getFullYear() + '/' + ((new Date()).getMonth()+1)" :class="{active: $route.fullPath.includes('/harvest-report')}">
                        <div class="main-menu-link-icon">
                            <Icon name="list-bullet" type="solid" />
                        </div>
                        <span class="main-menu-link-name">Oogst totalen</span>
                    </router-link>
                </li>

                <!-- Inkopen -->
                <li v-if="user.can('purchase')" class="main-menu-list-item">
                    <router-link to="/purchase"  :class="{active: $route.fullPath.includes('/purchase')}">
                        <div class="main-menu-link-icon">
                            <Icon name="tag" type="solid" />
                        </div>
                        <span class="main-menu-link-name">Inkopen</span>
                    </router-link>
                </li>

                <!-- Werkverdeling -->
                <li v-if="user.can('harvest_schedule')" class="main-menu-list-item">
                    <router-link to="/harvest-schedule" :class="{active: $route.fullPath.includes('/harvest-schedule')}">
                        <div class="main-menu-link-icon">
                            <Icon name="chart-pie" type="solid" />
                        </div>
                        <span class="main-menu-link-name">Werkverdeling</span>
                    </router-link>
                </li>

                <!-- Insights -->
                <li v-if="user.can('customer-insights')" class="main-menu-list-item">
                    <router-link :to="'/customer-insights'" :class="{active: $route.fullPath.includes('customer-insights')}">
                        <div class="main-menu-link-icon">
                            <icon name="chart-bar-square" type="solid" />
                        </div>
                        <span class="main-menu-link-name">Insights</span>
                    </router-link>
                </li>

                <!-- Orders -->
                <li v-if="user.can('orders')" class="main-menu-list-item">
                    <router-link class="active-on-childs" to="/orders" :class="{active: $route.fullPath.includes('/orders')}">
                        <div class="main-menu-link-icon">
                            <Icon name="shopping-cart" type="solid"></Icon>
                        </div>
                        <span class="main-menu-link-name">Orders Exact</span>
                    </router-link>
                </li>

                <!-- Meldingen -->
                <li v-if="user.can('notifications')" class="main-menu-list-item">
                    <router-link class="active-on-childs" to="/notifications" :class="{active: $route.fullPath.includes('/notifications')}">
                        <div class="main-menu-link-icon">
                            <Icon name="bell" type="solid" />
                        </div>
                        <span class="main-menu-link-name">Meldingen</span>
                        <span v-if="unreadNotifications.count() > 0" class="harvest-assignments-not-assigned">@{{ unreadNotifications.count() }}</span>
                    </router-link>
                </li>

                <!-- Zaaiplanning -->
                <li v-if="user.can('sowing_planning')" class="main-menu-list-item">
                    <router-link to="/sowing-planning" :class="{active: $route.fullPath.includes('sowing-planning')}">
                        <div class="main-menu-link-icon">
                            <Icon name="calendar" />
                        </div>
                        <span class="main-menu-link-name">Zaaiplanning</span>
                    </router-link>
                </li>

                <!-- Production control -->
                <li v-if="user.can('production_control_admin')" class="main-menu-list-item">
                    <router-link to="/production-control" :class="{active: $route.fullPath.includes('production-control')}">
                        <div class="main-menu-link-icon">
                            <Icon name="document-magnifying-glass" />
                        </div>
                        <span class="main-menu-link-name">Production control</span>
                    </router-link>
                </li>

                <!-- Production prognosis -->
                <li v-if="user.can('production_forecast')" class="main-menu-list-item">
                    <router-link to="/production-forecast" :class="{active: $route.fullPath.includes('production-forecast')}">
                        <div class="main-menu-link-icon">
                            <Icon name="pencil-square" />
                        </div>
                        <span class="main-menu-link-name">Productieprognose</span>
                    </router-link>
                </li>

            </ul>

            <ul v-if="user.can(['roles', 'articles', 'tasks', 'users', 'tasks', 'logs'])" class="main-menu-list secondary-list">
                <!-- Medewerkers -->
                <li v-if="user.can('users')" class="main-menu-list-item">
                    <router-link to="/users/" :class="{active: $route.fullPath.includes('/users/') && ! $route.fullPath.includes('work-lists')}">
                        <div class="main-menu-link-icon">
                            <Icon name="users" type="solid" />
                        </div>
                        <span class="main-menu-link-name">Medewerkers</span>
                    </router-link>
                </li>

                <!-- Settings -->
                <li v-if="user.can(['settings', 'roles', 'tasks', 'reviews', 'articles', 'assortments', 'formula'])" class="main-menu-list-item">
                    <router-link to="/settings" :class="{active: $route.fullPath.includes('settings')}">
                        <div class="main-menu-link-icon">
                            <Icon name="cog-6-tooth" type="solid" />
                        </div>
                        <span class="main-menu-link-name">Instellingen</span>
                    </router-link>
                </li>

                <!-- Prestaties -->
                <li v-if="user.can('performance')" class="main-menu-list-item">
                    <router-link :to="'/performance'" :class="{active: $route.fullPath.includes('performance')}">
                        <div class="main-menu-link-icon">
                            <icon name="chart-bar" type="solid" />
                        </div>
                        <span class="main-menu-link-name">Prestaties</span>
                    </router-link>
                </li>

                <!-- Bosopdrachten rapport -->
                <li v-if="user.can('harvest_assignments_report')" class="main-menu-list-item">
                    <router-link to="/harvest-assignments/report" :class="{active: $route.fullPath.includes('harvest-assignments/report')}">
                        <div class="main-menu-link-icon">
                            <Icon name="inbox" type="solid" />
                        </div>
                        <span class="main-menu-link-name">Bosopdrachten rapportage</span>
                    </router-link>
                </li>

                <!-- Documentatie -->
                <li v-if="user.hasRole('admin')" class="main-menu-list-item">
                    <a href="/documentatie" :class="{active: $route.fullPath.includes('documentatie')}">
                        <div class="main-menu-link-icon">
                            <Icon name="book-open" />
                        </div>
                        <span class="main-menu-link-name">Documentatie</span>
                    </a>
                </li>

                <!-- Changelog -->
                <li v-if="user.hasRole('admin')" class="main-menu-list-item">
                    <a href="/changelog" :class="{active: $route.fullPath.includes('changelog')}">
                        <div class="main-menu-link-icon">
                            <Icon name="clipboard-document-list" />
                        </div>
                        <span class="main-menu-link-name">Changelog</span>
                    </a>
                </li>
            </ul>

            <ul class="main-menu-footer">
                <li class="main-menu-privacy-button">
                    <a href="https://jongfresh.nl/privacy" target="_blank">Privacy</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
    import { collect } from 'collect.js';

    const props = defineProps(['menuOpen', 'user'])

    // ToDo:
    const unassignedHarvestAssignments = collect();
    const unreadNotifications = collect();
</script>
