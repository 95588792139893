<template>

    <div class="container">
        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Rollen</h1>
                    <fab-button @click.native="createButtonClicked()" icon="plus"></fab-button>
                </div>
            </div>
        </div>
    </div>

    <div id="roles">
        <div class="roles-list">
            <div class="roles-header">
                <div class="roles-header-name">Rolnaam</div>
                <div v-tooltip="permission.present('description')" class="roles-header-permissions" v-for="permission in permissions" :key="permission.id">
                    <span>
                        {{ permission.present('name') }}
                    </span>
                    <div class="roles-header-icons">
                        <Icon name="device-phone-mobile" v-if="permission.app" style="width:16px" />
                        <Icon name="computer-desktop" v-if="permission.web" style="width:16px" />
                    </div>
                </div>
            </div>
            <div class="roles-content">
                <template v-for="role in roles">
                    <role v-if="role"  :key="role.id" :role="role" :permissions="permissions" @roleRemoved="removeRole" />
                </template>
            </div>
        </div>

        <create-role v-if="create_role" @close="create_role = false" @saved="addRole"></create-role>
    </div>
</template>

<script>
    import role from './Roles/Role.vue';
    import Role from '../../models/role.js';
    import CreateRole from './Roles/CreateRole.vue';
    import Permission from '../../models/permission.js';

    export default {

        components: {CreateRole, role},

        data() {
            return {
                create_role: false,
                permissions: [],
                roles: []
            }
        },

        methods: {

            createButtonClicked()
            {
                this.create_role = true;

                if (document.getElementById("name") != undefined) {
                     //.focus();s
                }
            },

            getRoles() {
                axios.get('roles?include=permissions')
                .then(response => {
                    this.roles = [];
                    for (let roleData of response.data.data) {
                        var role = new Role();
                        role.setAttributesFromResponse(roleData);
                        this.roles.push(role);
                    }
                });
            },

            addRole(role) {
                this.roles.push(role);
                this.create_role = (! this.create_role);
            },

            removeRole (id) {
                let i = this.roles.map(role => role.id).indexOf(id) // find index of your object
                this.roles.splice(i, 1);
            },

            getPermissions() {
                axios.get('permissions')
                .then(response => {
                    this.permissions = [];
                    for (let permissionData of response.data.data) {
                        let permission = new Permission();
                        permission.setAttributesFromResponse(permissionData);
                        this.permissions.push(permission);
                    }
                });
            },
        },

        created() {
            this.getRoles();
            this.getPermissions();

            this.$emit('breadcrumbs', [
                {label: 'Instellingen', to: '/settings'},
                {label: 'Rollen'}
            ])
        }
    }
</script>
