import authGuard from './../guards/authGuard';
import SettingsIndex from './../pages/Settings/Index.vue';
import SettingsBreed from './../pages/Settings/Breeds.vue';
import SettingsSowPlanning from './../pages/Settings/SowPlanningSettings/Show.vue';
import SettingsRoles from './../pages/Settings/Roles.vue';
import SettingsEmails from './../pages/Settings/Emails.vue';
import SettingsReviewCategories from './../pages/Settings/ReviewCategories/List.vue';
import SettingsTasks from './../pages/Settings/Tasks/List.vue';
import SettingsArticles from './../pages/Settings/Articles/List.vue';
import SettingsAssortments from './../pages/Settings/Assortments.vue';
import SettingsFormula from './../pages/Settings/Formula.vue';
import SettingsCustomerInsights from './../pages/Settings/CustomerInsights/Index.vue';

export default [
    {
        path: '/settings',
        component: SettingsIndex,
        name: 'settings.overview',
    }, {
        path: '/settings/breeds',
        component: SettingsBreed,
        name: 'settings.breeds',
        meta: { permissions: ['sowing_planning_settings'] },
    }, {
        path: '/settings/sow-planning-settings',
        component: SettingsSowPlanning,
        name: 'settings.sowing_planning_settings',
        meta: { permissions: ['sowing_planning_settings'] },
    }, {
        path: '/settings/roles',
        component: SettingsRoles,
        name: 'settings.roles',
        meta: { permissions: ['roles'] }
    }, {
        path: '/settings/emails',
        component: SettingsEmails,
        name: 'settings.settings',
        meta: { permissions: ['settings'] },
    }, {
        path: '/settings/review-categories',
        component: SettingsReviewCategories,
        name: 'settings.reviews',
        meta: { permissions: ['reviews'] },
    }, {
        path: '/settings/tasks',
        component: SettingsTasks,
        name: 'settings.tasks',
        meta: { permissions: ['tasks'] },
    }, {
        path: '/settings/articles',
        component: SettingsArticles,
        name: 'articles.list',
        meta: { permissions: ['articles'] },
    }, {
        path: '/settings/assortments',
        component: SettingsAssortments,
        name: 'settings.assortments',
        meta: { permissions: ['assortments'] },
    }, {
        path: '/settings/formula',
        component: SettingsFormula,
        name: 'settings.formula',
        meta: { permissions: ['formula'] },
    }, {
        path: '/settings/customer-insights',
        component: SettingsCustomerInsights,
        name: 'settings.customer-insights',
        meta: { permissions: ['customer-insights'] },
    }
];
