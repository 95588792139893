import Task from "../../models/task.js"
import { AbstractHttpService } from "./abstract-http-service";

class TaskService extends AbstractHttpService {

    endpoint() {
        return 'tasks';
    }

    createModel(data) {
        return new Task(data);
    }
}

export default new TaskService;
