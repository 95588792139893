import {AbstractModel} from './abstract-model.js';
import Article from './article.js';
import moment from 'moment';

export default class StartStock extends AbstractModel
{
    getDefaults() {
        return {
            id: null,
            date: moment(),
            chest_id: null,
            content_id: null,
            size_id: null,
            type_id: null,
            article_id: null,
            amount: 0,
            article: null
        }
    }

    amountForStock() {
        return this.amount;
    }

    getCcst()
    {
        return {
            chest_id: (this.chest_id ?? this.chestId),
            content_id: (this.content_id ?? this.contentId),
            size_id: (this.size_id ?? this.sizeId),
            type_id: (this.type_id ?? this.typeId)
        }
    }

    isHarvest(chest_id = null, content_id = null, size_id = null, type_id = null) {
        if (chest_id) {
            return (
                this.chest_id == chest_id
                && this.content_id == content_id
                && this.size_id == size_id
                && this.type_id == type_id
            );
        }

        return ! this.article_id;
    }

    isArticle(article_id = null) {
        if (article_id) {
            return this.article_id == article_id;
        }

        return this.article_id !== null;
    }

    getDateFormats() {
        return {
            date: 'YYYY-MM-DD'
        }
    }

    getTransformers() {
        return {
            article: Article
        }
    }


    getMutators() {
        return {
            'date': 'date'
        };
    }

    // V2
    relations()
    {
        return {
            article: Article
        }
    }

    relationPlural()
    {
        return {
            article: false,
        }
    }

    getMutators() {
        return {
            date: 'datetime'
        };
    }
}
