<template>
    <modal @save="onSubmit()" @close="$emit('close')">
        <template v-slot:header>Nieuwe taak</template>

        <form @submit.prevent>

            <label class="styled-label" for="name">
                <span>Naam</span>
                <input autofocus id="name" type="name" class="styled-input" v-model="form.name" v-bind:class="{ 'is-invalid': form.errors.has('name') }">
                <div class="invalid-feedback" v-if="form.errors.has('name')" v-text="form.errors.get('name')"></div>
            </label>

        </form>
    </modal>
</template>

<script>
    import Form from '../../../helpers/form.js';
    import { useToast } from 'vue-toast-notification';

    export default {
        data() {
            return {
                form: new Form({
                    name: '',
                })
            }
        },

        methods: {
            onSubmit() {
                this.form.post('/tasks')
                .then(response => {
                    useToast().success('Taak opgeslagen');
                    this.$emit('saved', response.data[0]);
                    this.$emit('close');
                    document.querySelector('body').classList = '';
                });
            },
        }
    };
</script>
