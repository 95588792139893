<template>
    <div class="week-overview">
        <div class="row">

            <!-- LABELS -->
            <div class="col-2">
                <div class="labels">
                    <div v-for="(type, index) of types" class="label">
                        {{ type.translate('name') }}
                    </div>
                </div>
            </div>

            <!-- BARS -->
            <div class="col-9">
                <div class="overview">

                    <!-- DAY LINES -->
                    <div class="day-lines">
                        <div v-for="n in 11" class="daypart"></div>
                    </div>

                    <!-- TYPES -->
                    <div class="type-bar" v-for="(dataItem, index) of data">

                        <div
                            :style="`width:${dataItem.percentageMaxed}%;`"
                            class="inner-bar"
                            :class="dataItem.classes"
                        >

                            <!-- COLLECTION OF DAYS -->
                            <div
                                v-for="dayCollection in dataItem.dayCollections"
                                :style="`left: ${dayCollection.percentageMaxed}%`"
                                class="week-day-collection"
                            >
                                <span
                                    class="week-day-percentage"
                                    v-if="dayCollection.percentage"
                                >
                                    {{ Math.round(dayCollection.percentageMaxed * (dataItem.percentageMaxed/100)) }}%
                                </span>

                                <!-- DAYS-->
                                <div
                                    v-tooltip="productionDay.label"
                                    :class="{zero: productionDay.production <= 0}"
                                    v-for="productionDay in dayCollection.days"
                                    class="week-day"
                                ></div>
                            </div>
                        </div>

                        <!-- CURRENT RADISH TYPE PERCENTAGE -->
                        <div v-if="dataItem.percentage > 0.5" class="current-percentage-label" :style="`left: ${dataItem.percentageMaxed}%`">
                            {{ Math.round(percentageCompletedPerType[index]) }}%
                        </div>
                    </div>

                    <!-- DAY LABELS -->
                    <div class="bottom-labels">
                        <div class="bottom-label">MA</div>
                        <div class="bottom-label">DI</div>
                        <div class="bottom-label">WO</div>
                        <div class="bottom-label">DO</div>
                        <div class="bottom-label">VR</div>
                        <div class="bottom-label">ZA</div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import nl from 'moment/dist/locale/nl';

    const props = defineProps([
        'types', 'productionPerDay', 'productionForecasts', 'year', 'week', 'saleHistory'
    ]);
    import moment from 'moment';
    import collect from 'collect.js';

    const today = moment();
    const currentWeek = computed(() => {
        return moment(props.year + '-06-01', 'YYYY-MM-DD').isoWeek(props.week);
    });

    // Collect all data and combine it into one data array that is easy to use in the DOM.
    const data = computed(() => {

        const dataItems = [];
        for (const typeIndex in props.types.all()) {
            const type = props.types.get(typeIndex);

            const dataItem = {
                model: type,

                // Total needed for current type.
                needed: totalNeededPerType.value[type.id],

                // Percentage production of total needed.
                percentage: percentageCompletedPerType.value[typeIndex],
                percentageMaxed: percentageCompletedPerType.value[typeIndex] > 100 ? 100 : percentageCompletedPerType.value[typeIndex],

                classes: {
                    // Expected is zero but there is production
                    'infinity': totalNeededPerType.value[type.id] === 0 && productionPerType.value[type.id] > 0,

                    // There is more production than expected
                    'over-production': percentageCompletedPerType.value[typeIndex] > 100,

                    // There is no exptected filled in, expected is based on saleforecast
                    'prediction': (
                        currentWeek.value.isSame(today, 'isoWeek')
                        && props.productionForecasts.filter((pf) => (
                            pf.typeId === type.id && pf.isExpected
                        )).count() <= 0
                    )
                },
                dayCollections: productionDays.value[type.id]
            };

            dataItems.push(dataItem);
        }

        return dataItems;
    });

    // Get production percentage of expected per type.
    const percentageCompletedPerType = computed(() => {
        const percentages = [];

        for (const type of props.types) {

            // Infinity.
            if(totalNeededPerType.value[type.id] === 0 && productionPerType.value[type.id] > 0) {
                percentages.push(100);

            // Expected is zero.
            } else if (totalNeededPerType.value[type.id] <= 0) {
                percentages.push(0);

            // Production is zero.
            } else if(productionPerType.value[type.id] <= 0) {
                percentages.push(0);

            // Normal, there is production and there is expected values.
            } else {
                percentages.push((productionPerType.value[type.id] / totalNeededPerType.value[type.id]) * 100);
            }
        }

        return percentages;
    });

    // Get production percentage of expected per type.
    const productionPerType = computed(() => {
        const data = {};

        for (const type of props.types) {
            const productionForecast = props.productionForecasts.filter((pf) => (
                pf.typeId === type.id && ! pf.isExpected
            )).last();

            data[type.id] = props.productionPerDay.filter(ppd => (
                ppd.type_id === type.id
            )).sum('production') + (productionForecast ? productionForecast.amount : 0);
        }

        return data;
    });

    // Set total expected harvest per type.
    const totalNeededPerType = computed(() => {
        const data = {};
        const date = currentWeek.value.isoWeek(props.week);

        for (const type of props.types) {
            const productionForecast = props.productionForecasts.filter((pf) => (
                pf.typeId === type.id && pf.isExpected
            )).last();

            if (productionForecast) {
                data[type.id] = productionForecast.amount;
            } else if(
                date.isSameOrBefore(moment(), 'isoWeek')
                && props.saleHistory && props.saleHistory.firstWhere('type_id', type.id)
            ) {
                data[type.id] = props.saleHistory.firstWhere('type_id', type.id).average_sold;
            } else {
                data[type.id] = 0;
            }
        }

        return data;
    });

    // Set info per day that has production per radish type.
    const productionDays = computed(() => {
        const productionDaysPerType = {};
        const date = currentWeek.value.isoWeek(props.week);

        // Loop through every rasish type.
        for (const typeIndex in props.types.all()) {
            const type = props.types.get(typeIndex);
            const productionDaysCollection = collect();
            if (totalNeededPerType.value[type.id] <= 0) {
                productionDaysPerType[type.id] = productionDaysCollection;
                continue;
            }

            // Loop through every week day, set info if there was production on the week day, otherwise skip.
            for (let weekday = 1; weekday <= 7; weekday++) {
                const productionDay = props.productionPerDay.first((ppd) => (
                    ppd.type_id === type.id
                    && moment(ppd.date, 'YYYY-MM-DD').isoWeekday() === weekday
                ));
                date.isoWeekday(weekday);

                if (! productionDay && (weekday === 7 || date.isAfter(moment()))) continue;

                // Set info per day that had production on it.
                const production = productionDay ? productionDay.production : 0;
                const needed = (
                    productionPerType.value[type.id] > totalNeededPerType.value[type.id]
                    ? totalNeededPerType.value[type.id]
                    : productionPerType.value[type.id]
                );
                const data = {
                    label: `${date.locale('nl', nl).format('dddd')} ${new Intl.NumberFormat('nl-NL').format(production)} bosjes`,
                    production: production,
                    procent: productionDaysCollection.sum((col)=> {
                        return col.sum('currentProcent');
                    }) + (production / needed) * 100,
                    currentProcent: (production / needed) * 100
                }
                const totalPercentage = (percentageCompletedPerType.value[typeIndex] > 100 ? 1 : percentageCompletedPerType.value[typeIndex]/100);

                // Production days with less percentage difference than 3 will be grouped together.
                if (
                    productionDaysCollection.count() > 0
                    && productionDaysCollection.last()
                    && (
                        (data.procent*totalPercentage) - (productionDaysCollection.last().avg('procent')*totalPercentage) <= 3
                        || productionDaysCollection.last().avg('procent')*totalPercentage >= 97
                    )
                ) {
                    productionDaysCollection.last().push(data);
                } else {
                    productionDaysCollection.push(collect([data]));
                }
            }

            // Set extra info per group of production days.
            productionDaysPerType[type.id] = productionDaysCollection.map((pdc => {
                return {
                    days: pdc,
                    percentage: pdc.avg('procent'),
                    percentageMaxed: pdc.avg('procent') > 100 ? 100 : pdc.avg('procent')
                };
            }));
        }

        return productionDaysPerType;
    })
</script>
