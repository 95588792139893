import WorklistList from './../pages/Users/WorkLists/UsersList.vue';
import WorklistShow from './../pages/Users/WorkLists/Show.vue';
import PermanentWorkHoursShow from './../pages/Users/PermanentWorkHours/Show.vue';
import authGuard from './../guards/authGuard';

export default [
    {
        path: '/users/work-lists',
        component: WorklistList,
        name: 'worklists-list',
        meta: {
            permissions: ['work_lists']
        },
    }, {
        path: '/users/:id/work-lists/:activeYear/:activeWeek?',
        component: WorklistShow,
        name: 'worklists-show',
        meta: {
            label: ':user',
            permissions: ['work_lists', 'own_work_list']
        },
        props: true
    }, {
        path: '/users/:id/permanent-work-hours/:activeYear',
        component: PermanentWorkHoursShow,
        name: 'permanent-work-hours-show',
        meta: {
            label: ':user',
            permissions: ['work_lists', 'own_work_list']
        },
        props: true
    }
];
