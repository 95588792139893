import userService from './user-service';

class AuthService {

    auth(email, password) {
        return axios({method: 'post', url: 'login', baseURL: '', data: {
            email: email,
            password: password
        }});
    }

    getAuthedUser() {
        return userService.first({
            filters: {id: 'authed'},
            include: ['roles.permissions', 'avatars', 'currentAgreement', 'userType']
        });
    }

    logout() {
        return axios.get({url: 'logout', baseUrl: ''});
    }
}

export default new AuthService;
