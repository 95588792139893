import TubersPerArticle from "../../models/tubers-per-article.js"
import { AbstractHttpService } from "./abstract-http-service";

class TubersPerArticleService extends AbstractHttpService {

    endpoint() {
        return 'tubers-per-articles';
    }

    createModel(data) {
        return new TubersPerArticle(data);
    }

    getTubersPerType(betweens) {
        let url = this.makeUrl({filters: {betweens}}, 'tubers-per-type');
        
        return axios.get(url).then(data => {
            return data.data;
        });
    }
}

export default new TubersPerArticleService;
