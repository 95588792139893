import SaleForecast from "../../models/sale-forecast";
import { AbstractHttpService } from "./abstract-http-service";

class SaleForecastService extends AbstractHttpService {

    endpoint() {
        return 'sale-forecast';
    }

    createModel(data) {
        return new SaleForecast(data);
    }

    getGroupedTotals(from, till, groupBy = [], select = [], where = []) {
        let url = this.makeUrl({filters: {
            from: from.clone().format('YYYY-MM-DD'),
            till: till.clone().format('YYYY-MM-DD')
        }}, `grouped-totals`);

        return axios.get(url).then(data => {
            return data.data;
        });
    }
}

export default new SaleForecastService;
