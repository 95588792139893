<template>
    <div class="list-item">
        <div class="container">
            <div class="row">
                <div class="col-6 lg:col-1">
                    <div class="list-text-label">
                        Aantal
                    </div>
                    <div class="list-text">
                        {{ harvestRegistration.harvested }}
                    </div>
                </div>
                <div class="col-6 lg:col-1">
                    <div class="list-text-label">
                        Inhoud
                    </div>
                    <div class="list-text">
                        {{ harvestRegistration.content.name }}
                    </div>
                </div>
                <div class="col-6 lg:col-2">
                    <div class="list-text-label">
                        Type
                    </div>
                    <div class="list-text">
                        {{ harvestRegistration.type.translate('name') }}
                    </div>
                </div>
                <div class="col-6 lg:col-1">
                    <div class="list-text-label">
                        Sortering
                    </div>
                    <div class="list-text">
                        {{ harvestRegistration.size.translate('name') }}
                    </div>
                </div>
                <div class="col-6 lg:col-3">
                    <div class="list-text-label">
                        Fust
                    </div>
                    <div class="list-text">
                        {{ harvestRegistration.chest.name }}
                    </div>
                </div>
                <div class="col-6 lg:col-1">
                    <div class="list-text-label">
                        Locatie
                    </div>
                    <div class="list-text">
                        {{ harvestRegistration.location != null ? harvestRegistration.location.name : 'onbekend' }}
                    </div>
                </div>
                <div class="col-6 lg:col-1" v-if="harvestAssignments">
                    <div class="list-text-label">
                        Bosopdracht
                    </div>
                    <div class="list-text">
                        {{ harvestRegistration.harvestAssignment.harvested }}/{{ harvestRegistration.harvestAssignment.amount }}
                    </div>
                </div>
                <div class="col-6 lg:col-2">
                    <div class="list-text-label">
                        Tijd
                    </div>
                    <div class="list-text">
                        {{ harvestRegistration.created_at.format('YYYY-MM-DD') === harvestRegistration.date.format('YYYY-MM-DD') ? harvestRegistration.created_at.format('DD-MM-YYYY HH:mm') : '-' }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {

    props: ['harvestRegistration', 'harvestAssignments'],

    data(){
        return {
        }
    },

    created() {
    },

    methods: {
    }
};
</script>
