import Document from "../../models/document";
import { AbstractHttpService } from "./abstract-http-service";

class DocumentService extends AbstractHttpService {

    endpoint() {
        return 'documents';
    }

    createModel(data) {
        return new Document(data);
    }
}

export default new DocumentService;
