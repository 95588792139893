<template>
    <div id="sow_settings">

        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="page-header">
                        <h1>Zaaiplanning instellingen</h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="sow-planning-settings">
                        <div class="widget-holder">
                            <div class="row">
                                <div class="col">
                                    <h2>
                                        Trend instellingen
                                    </h2>
                                    <div class="header-fab-button-holder">
                                        <fab-button :class="{danger:weeklyTrendEdit}" @click.native="toggle('weeklyTrendEdit')" :icon="! weeklyTrendEdit ? 'pencil' : 'x-mark'"></fab-button>
                                        <fab-button class="success" @click.native="updateWeeklyTrend()" v-if="weeklyTrendEdit" icon="check"></fab-button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label>
                                    <span class="styled-label">Aantal weken</span>
                                    <input class="styled-input" v-if="weeklyTrendEdit" type="number" v-model="weeklyTrend">
                                    <div v-else style="width: 136px; text-align: center">{{ weeklyTrend }}</div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="sow-planning-settings">
                    <div class="widget-holder">

                        <div class="row">
                            <div class="col">
                                <h2>
                                    Teeltkalender
                                </h2>
                                <div class="header-fab-button-holder">
                                    <fab-button :class="{danger:cultivationCalendarEdit}" @click.native="cultivationCalendarEdit ? resetCultivationCalendar() :toggle('cultivationCalendarEdit')" :icon="! cultivationCalendarEdit ? 'pencil' : 'x-mark'"></fab-button>
                                    <fab-button class="success" @click.native="updateCultivationCalendar()" v-if="cultivationCalendarEdit" icon="check"></fab-button>
                                </div>
                            </div>
                        </div>
                        <div class="cultivation-calendar">
                            <table class="styled-table">
                                <tbody class="styled-table-body">
                                    <tr>
                                        <td v-for="(cultivationCalendarDay, index) of cultivationCalendar" :key="index" class="text-center">
                                            <div style="width: 60px">
                                                <b>{{ cultivationCalendarDay.sowingDate.format('DD-MM') }}</b>
                                                <div v-if="cultivationCalendarEdit">
                                                    <input class="styled-input" style="width: 50px; text-align:center;" type="number" v-model="cultivationCalendarDay.days">
                                                </div>
                                                <div v-else>{{ cultivationCalendarDay.days }}</div>
                                                <div>{{ cultivationCalendarDay.sowingDate.clone().add(cultivationCalendarDay.days, 'day').format('DD-MM') }}</div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="sow-planning-settings">
                    <div class="widget-holder">

                        <div class="row">
                            <div class="col">
                                <h2>Zaden per M2</h2>
                                <div class="header-fab-button-holder">
                                    <fab-button :class="{danger:seedsPerSquaredMeterEdit}" @click.native="seedsPerSquaredMeterEdit ? resetSeedsPerSquaredMeter() : toggle('seedsPerSquaredMeterEdit');" :icon="! seedsPerSquaredMeterEdit ? 'pencil' : 'x-mark'"></fab-button>
                                    <fab-button class="success" @click.native="updateSeedsPerSquaredMeter()" v-if="seedsPerSquaredMeterEdit" icon="check"></fab-button>
                                </div>
                            </div>
                        </div>
                        <div style="overflow-x: scroll; scroll-snap-type: x mandatory; position: relative;">
                            <div v-if="seedsPerSquaredMeterReady" style="white-space:nowrap">

                                <div style="display:inline-block; vertical-align:top; width: 160px; line-height: 36px; border-right:2px solid black; scroll-snap-align: start; position: sticky; left: 0; background-color: #f7f7f7;">
                                    <div style="border-bottom: 1px solid transparent">&nbsp;</div>
                                    <div v-for="(type, index) of types" :key="index" style="border-bottom: 1px solid #ccc">
                                        <b>{{ type.translate('name') }}</b>
                                    </div>
                                </div>

                                <div v-for="week in 53" :key="week"  style="display:inline-block; vertical-align:top; width: 70px; line-height: 36px; border-right:1px solid #999; scroll-snap-align: start;">
                                    <div class="text-center" style="border-bottom: 1px solid #666; font-weight: 600;">
                                        {{ week }}
                                    </div>
                                    <div class="text-center" v-for="(type, index) of types" :key="index" style="border-bottom: 1px solid #ccc; ">
                                        <div v-if="seedsPerSquaredMeterEdit">
                                            <input
                                                class="styled-input text-center"
                                                style="line-height: 24px; padding: 4px"
                                                type="number"
                                                v-model="seedsPerSquaredMeters.where('weekNumber', week).firstWhere('typeId', type.id).seeds"
                                            >
                                        </div>
                                        <div v-else>{{ seedsPerSquaredMeters.where('weekNumber', week).firstWhere('typeId', type.id).seeds }}</div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="sow-planning-settings">
                    <div class="widget-holder">

                        <div class="row">
                            <div class="col">
                                <h2>Ras</h2>
                                <div class="header-fab-button-holder">
                                    <fab-button :class="{danger:breedPerTypeEdit}" @click.native="breedPerTypeEdit ? resetBreedPerType() : toggle('breedPerTypeEdit');" :icon="! breedPerTypeEdit ? 'pencil' : 'x-mark'"></fab-button>
                                    <fab-button class="success" @click.native="updateBreedPerType()" v-if="breedPerTypeEdit" icon="check"></fab-button>
                                </div>
                            </div>
                        </div>
                        <div style="overflow-x: scroll; scroll-snap-type: x mandatory; position: relative;">
                            <div v-if="seedsPerSquaredMeterReady && breedPerTypes" style="white-space:nowrap">

                                <div style="display:inline-block; vertical-align:top; width: 160px; line-height: 36px; border-right:2px solid black; scroll-snap-align: start; position: sticky; left: 0; background-color: #f7f7f7;">
                                    <div style="border-bottom: 1px solid transparent">&nbsp;</div>
                                    <div v-for="(type, index) of types.whereIn('id', breeds.pluck('typeId').unique().all())" :key="index" style="border-bottom: 1px solid #999">
                                        <div style="font-weight: 600">
                                            {{ type.translate('name') }}
                                        </div>
                                        <div style="height:24px">
                                            &nbsp;
                                        </div>
                                    </div>
                                </div>

                                <div v-for="week in 53" :key="week"  style="display:inline-block; vertical-align:top; width: 100px; line-height: 36px; border-right:1px solid #999; scroll-snap-align: start;">
                                    <div class="text-center" style="border-bottom: 1px solid #999">
                                        <span style="font-weight: 600">
                                            {{ week }}
                                        </span>
                                    </div>
                                    <div v-for="(type, index) of types.whereIn('id', breeds.pluck('typeId').unique().all())" :key="index">

                                        <div class="text-center">
                                            <div style="height: 24px;" v-if="breedPerTypeEdit">
                                                <select v-model="breedPerType(week, type.id).breedId">
                                                    <option v-if="! breedPerType(week, type.id).breedId" :value="null">-</option>
                                                    <option v-for="(breed, index) of breeds.where('typeId', type.id)" :key="index" :value="breed.id">
                                                        {{ breed.name }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="text-center" style="padding: 0 4px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" v-else>
                                                {{ breedPerType(week, type.id).breedId ? breeds.firstWhere('id', breedPerType(week, type.id).breedId).name : '-' }}
                                            </div>
                                        </div>

                                        <div class="text-center" style="border-bottom: 1px solid #ccc">
                                            <div v-if="breedPerTypeEdit">
                                                <select v-model="breedPerType(week, type.id).fractionId">
                                                    <option v-if="! breedPerType(week, type.id).fractionId" :value="null">-</option>
                                                    <option v-for="(fraction, index) of fractions" :key="index" :value="fraction.id">
                                                        {{ fraction.milimeters }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div v-else style="line-height:24px; font-size: 0.8rem; color: #666">
                                                {{ breedPerType(week, type.id).fractionId ? fractions.firstWhere('id', breedPerType(week, type.id).fractionId).milimeters : '-'  }}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import settingService from '../../../services/http/setting-service';
    import cultivationCalendarDayService from '../../../services/http/cultivation-calendar-day-service';
    import breedPerTypeService from '../../../services/http/breed-per-type-service';
    import seedsPerSquaredMeterService from '../../../services/http/seeds-per-squared-meter-service';
    import typeService from '../../../services/http/type-service';
    import sizeService from '../../../services/http/size-service';
    import breedService from '../../../services/http/breed-service';
    import fractionService from '../../../services/http/fraction-service';
    import moment from 'moment'
    import { collect } from "collect.js";

    export default {

        computed: {
        },

        data() {
            return {
                today: moment(),

                weeklyTrendEdit: false,
                weeklyTrend: 0,
                originalWeeklyTrend: 0,

                cultivationCalendar: collect(),
                originalCultivationCalendar: collect(),
                cultivationCalendarEdit: false,

                seedsPerSquaredMeterEdit: false,
                seedsPerSquaredMeterReady: false,
                seedsPerSquaredMeters: collect(),
                originalSeedsPerSquaredMeters: collect(),

                breedPerTypeEdit: false,
                breedPerTypeReady: false,
                breedPerTypes: false,
                originalBreedPerTypeFractions: collect(),
                originalBreedPerTypeBreeds: collect(),

                types: collect(),
                fractions: collect(),
                breeds: collect(),
                sizes: collect()
            }
        },

        methods: {
            // ---------------------------------------------------------------------------------------------------------------------
            // Weekly trend
            // ---------------------------------------------------------------------------------------------------------------------
            getWeeklyTrend() {
                settingService.first({filters: {
                    key_is: 'weekly_trend'
                }}).then(setting => {
                    this.setWeeklyTrend(setting ? setting.value : 0);
                });
            },

            updateWeeklyTrend() {
                this.weeklyTrendEdit = false
                settingService.create({
                    weekly_trend: this.weeklyTrend
                }).then(setting => {
                    this.setWeeklyTrend(setting.value);
                });
            },

            setWeeklyTrend(weeklyTrend) {
                this.weeklyTrend = parseInt(weeklyTrend);
                this.originalWeeklyTrend = parseInt(weeklyTrend);
            },

            // ---------------------------------------------------------------------------------------------------------------------
            // Cultivation calendar
            // ---------------------------------------------------------------------------------------------------------------------
            resetCultivationCalendar(){
                this.toggle('cultivationCalendarEdit');

                for (let index in this.cultivationCalendar.all()) {
                    this.cultivationCalendar.get(index).days = this.originalCultivationCalendar.get(index);
                }
            },

            updateCultivationCalendar()
            {
                let data = this.cultivationCalendar.filter((day, index) => {
                    return (
                    day.days !== this.originalCultivationCalendar.get(index)
                )}).map((day) => ({
                    month: day.month,
                    day_of_month: day.dayOfMonth,
                    days: day.days,
                }));

                cultivationCalendarDayService.create(data.all()).then(() => {
                    for (let ccd of data) {
                        let index = this.cultivationCalendar.search(cc => {
                            return (
                                cc.month === ccd.month
                                && cc.dayOfMonth === ccd.day_of_month
                            );
                        });

                        this.originalCultivationCalendar.splice(index, 1, [this.cultivationCalendar.get(index).days]);
                    }
                    this.cultivationCalendarEdit = false;
                });
            },

            getCultivationCalendar()
            {
                cultivationCalendarDayService.get().then((data) => {
                    this.cultivationCalendar = data;
                    this.originalCultivationCalendar = data.pluck('days');
                });
            },


            // ---------------------------------------------------------------------------------------------------------------------
            // Seeds per m2
            // ---------------------------------------------------------------------------------------------------------------------
            getSeedsPerSquaredMeter() {
                return seedsPerSquaredMeterService.get().then((seedsPerSquaredMeter) => {
                    this.seedsPerSquaredMeters = seedsPerSquaredMeter;
                    this.originalSeedsPerSquaredMeters = seedsPerSquaredMeter.pluck('seeds');
                });
            },

            resetSeedsPerSquaredMeter() {
                this.toggle('seedsPerSquaredMeterEdit');

                for (let index in this.seedsPerSquaredMeters.all()) {
                    this.seedsPerSquaredMeters.get(index).seeds = this.originalSeedsPerSquaredMeters.get(index);
                }
            },

            updateSeedsPerSquaredMeter() {
                let data = this.seedsPerSquaredMeters.filter((squaredMeter, index) => (
                    squaredMeter.seeds !== this.originalSeedsPerSquaredMeters.get(index)
                )).map((squaredMeter) => ({
                    week_number: squaredMeter.weekNumber,
                    type_id: squaredMeter.typeId,
                    seeds: squaredMeter.seeds,
                }));

                seedsPerSquaredMeterService.create(data.all()).then(() => {
                    for (let ccd of data) {
                        let index = this.seedsPerSquaredMeters.search(spsm => {
                            return (
                                spsm.weekNumber === ccd.week_number
                                && spsm.typeId === ccd.type_id
                            );
                        });

                        this.originalSeedsPerSquaredMeters.splice(index, 1, [this.seedsPerSquaredMeters.get(index).seeds]);
                    }
                    this.seedsPerSquaredMeterEdit = false;
                });
            },


            // ---------------------------------------------------------------------------------------------------------------------
            // Breed per type
            // ---------------------------------------------------------------------------------------------------------------------
            getBreedPerTypes() {
                return breedPerTypeService.get().then((breedPerTypes) => {
                    this.breedPerTypes = breedPerTypes;
                    this.originalBreedPerTypeFractions = breedPerTypes.pluck('fractionId');
                    this.originalBreedPerTypeBreeds = breedPerTypes.pluck('breedId');
                });
            },

            breedPerType(weekNumber, typeId) {
                return this.breedPerTypes.where('weekNumber', weekNumber).firstWhere('typeId', typeId);
            },

            resetBreedPerType() {
                this.toggle('breedPerTypeEdit');

                for (let index in this.breedPerTypes.all()) {
                    this.breedPerTypes.get(index).breedId = this.originalBreedPerTypeBreeds.get(index);
                    this.breedPerTypes.get(index).fractionId = this.originalBreedPerTypeFractions.get(index);
                }
            },

            updateBreedPerType() {
                let data = this.breedPerTypes.filter((breedPerType, index) => {
                    return(
                        breedPerType.breedId !== this.originalBreedPerTypeBreeds.get(index)
                        || breedPerType.fractionId !== this.originalBreedPerTypeFractions.get(index)
                    )}
                ).map((breedPerType) => ({
                    week_number: breedPerType.weekNumber,
                    type_id: breedPerType.typeId,
                    fraction_id: breedPerType.fractionId,
                    breed_id: breedPerType.breedId
                }));


                breedPerTypeService.create(data.all()).then(() => {
                    this.originalBreedPerTypeFractions = this.breedPerTypes.pluck('fractionId');
                    this.originalBreedPerTypeBreeds = this.breedPerTypes.pluck('breedId');
                    this.breedPerTypeEdit = false;
                });
            },

            // ---------------------------------------------------------------------------------------------------------------------
            // Other
            // ---------------------------------------------------------------------------------------------------------------------
            getTypes()
            {
                return typeService.get({filters: {name_is_not: 'micro'}}).then((types) => {
                    this.types = types;
                });
            },

            getSizes()
            {
                return sizeService.get().then((sizes) => {
                    this.sizes = sizes;
                });
            },

            getBreeds()
            {
                return breedService.get({include: ['fractions']}).then((breeds) => {
                    this.breeds = breeds;
                });
            },

            getFractions()
            {
                return fractionService.get().then((fractions) => {
                    this.fractions = fractions;
                });
            },


            toggle(variable) {
                this[variable] = ! this[variable];
            }
        },

        created() {
            this.getWeeklyTrend();
            this.getCultivationCalendar();

            let typesPromise = this.getTypes();
            Promise.all([this.getSeedsPerSquaredMeter(), typesPromise]).then(() => {
                this.seedsPerSquaredMeterReady = true;
            });
            Promise.all([this.getFractions(), this.getBreeds(), this.getBreedPerTypes(), typesPromise]).then(() => {
                this.breedPerTypeReady = true;
            });


            this.$emit('breadcrumbs', [
                {label: 'Instellingen', to: '/settings'},
                {label: 'Zaaiplanning instellingen'}
            ])
        }
    }
</script>
